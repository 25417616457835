<template>
<div class="beige-bg">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "SuperPower"
}
</script>

<style scoped>
  .beige-bg {
    background: #FFF7F0;
  }
</style>