<template>
  <div class="main-container rounded-t-3xl wrap auth h-full w-full">
    <div class="back-button">
      <router-link :to="{name: 'SignIn'}" class="mr-6 cursor-pointer">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="#1C2326" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.81836 18H28.182" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M15.0911 10.7273L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M15.0911 25.2727L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </router-link>
    </div>
    <h1 class="text-center mt-4">{{$t('signUp.email.title')}}</h1>
    <p class="my-3 text-center">{{$t('signUp.email.content')}}</p>
    <div class="separator"></div>
    <p class="mt-2 text-center">{{ $t('signUp.email.spam') }}</p>
  </div>
</template>

<script>
export default {
  name: "SignInEmail"
}
</script>

<style scoped lang="scss">
.separator {
  height:2px;
  background:var(--color-surface-divider);
  width: 80%;
  margin: 0 auto;
}

</style>