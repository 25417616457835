<template>
  <div>
    <div class="wrapper" style="height: 100vh;">
      <h2 class="mb-4">{{ $t('home.superpowers') }}</h2>

      <Loader :color="true" v-if="isLoading"/>
      <div v-else class="powers-container">
        <div v-if="powers.length === 0">{{$t('home.noSuperpowers')}}
        </div>
        <div class="powers" v-else>
          <router-link
              :to="{name: 'kidPowerView', params:{name: currentUser.firstname, id: currentUser.id, powerId:power.sys.id}}"
              v-for="power in powers">
            <div v-if="!power.fields.new" class="badge">
              <img class="pentagone" v-if="!power.fields.new" src="../assets/img/pentagone.png" alt="Polygone">
              <img class="power" :src="power.fields.icon.fields.file.url" alt="Pouvoir">
            </div>
            <div v-else class="new-badge">
              <img class="power" :src="power.fields.superPowerCategory.fields.picto.fields.file.url" alt="Pouvoir">
            </div>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {mapGetters} from "vuex";

export default {
  name: "kidPowers",
  components: {Loader},
  computed: {
    ...mapGetters(['currentUser', 'currentLocale'])
  },
  data() {
    return {
      isLoading: true,
      powers: []
    }
  },
  created() {
    this.$http.get('/users/' + this.currentUser.id + '/super_powers').then(resp => {
      let powersId = []
      let comma = null
      resp.data.forEach(el => powersId.push(el.contentful_id))
      let string = ""
      powersId.length > 1 ? comma = true : comma = false
      powersId.forEach(el => string += el + (comma ? ',' : ''))
      this.$contentfulv2.getEntries({
        content_type: "superPower",
        locale: this.currentLocale,
        'sys.id[in]': string
      }).then(res => {
        let data = res.items.map(item => {
          if (Object.keys(item.fields).length) {
            return item
          }
        }).filter(el => el !== undefined)
        this.powers = data
        this.isLoading = false
      });
    })
  }
}
</script>

<style scoped lang="scss">
.powers-container {
  > .powers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;

    > a {

      > .badge {
        width: 72px;
        height: 72px;
        position: relative;

        img {
          width: 100%;
        }

        .power {
          width: 36px;
          height: auto;
          position: absolute;
          top: calc(50% - 18px);
          left: calc(50% - 18px);
        }
      }

      > .new-badge {
        width: 72px;
        height: 72px;
      }


    }
  }
}
</style>