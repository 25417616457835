import Vue from 'vue'
import Vuex from 'vuex'

import capsules from "@/store/capsules";
import auth from "@/store/auth";
import familyState from "@/store/family";
import locales from "@/store/locales"
import currentUser from "@/store/currentUser"
import newCapsule from "@/store/newCapsule"
import style from "@/store/style"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        capsules,
        auth,
        familyState,
        locales,
        currentUser,
        newCapsule,
        style
    }
})
