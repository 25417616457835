<template>
  <div class="main-container rounded-t-3xl wrap auth h-full w-full">
    <locale-switcher-on-right @localeSwitched="localeSwitched"></locale-switcher-on-right>
    <h2 v-if="type.includes('adult')" class="text-align-center mb-8">{{ $t('child.who') }}</h2>
    <h2 v-else class="text-align-center mb-8">{{ $t('child.title') }}</h2>

    <user-form :type="type" :button-text="$t('child.next')" v-model="user" @user-submitted="userSubmitted()">
    </user-form>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import {mapActions, mapGetters} from "vuex"
import Loader from "@/components/Loader.vue";
import UserForm from "@/components/settings/UserForm.vue";
import LocaleSwitcherOnRight from "@/views/security/LocaleSwitcherOnRight.vue";

export default {
  name: "NewPerson",
  components: {LocaleSwitcherOnRight, Loader, PrimaryButton, UserForm},
  props: {
    type: String,
  },
  computed: {
    ...mapGetters(['currentFamily', 'currentLocale']),
  },
  data() {
    return {
      user: {
        name: null,
        age: null,
        tagField: [],
        selectedImage: null,
        customImage: false,
        file: null
      }
    }
  },
  methods: {
    ...mapActions(['setUsers', "setKids", "addUser", "addCurrentUser"]),

    localeSwitched() {
      window.location.reload()
    },
    userSubmitted() {
      if (this.$route.params.from) {
        switch (this.$route.params.from) {
          case 'capsule':
            this.$router.push({name: 'Capsule', params: {slug: this.$route.params.from.slug}})
            break;
          case 'SignInWelcomeHome':
            this.$router.push({name: 'SignInWelcomeHome'})
            break;
          case 'account':
            this.$router.push({name: 'Account'})
            break;
          case 'profile':
            this.$router.push({name: 'profile'})
            break;
          default:
            this.$router.push({name: 'Home'})
        }
      } else {
        this.$router.push({name: 'Home'})
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.helper {
  margin: var(--space-4) 0 0 var(--space-4);
  display: flex;
  align-items: center;
}

.helper.invalid > span {
  width: calc(100% - var(--space-20));
}

.helper.invalid {
  color: var(--color-error);
}

.helper > svg {
  margin-right: var(--space-4);
}

.upload-photo {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--color-surface-soft);
  /* Style as you please, it will become the visible UI component. */
  border: 2px solid;
}

#upload-photo, .input-radio {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
}

.grid-container > * {
  align-self: center;
  justify-self: center;
}

.container-custom-img {
  width: 77px;
  height: 77px;
  position: relative;
  display: inline-block;

  > #remove-img {
    line-height: 1rem;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }
}

.container-img {
  width: 77px;
  height: 77px;
  border-radius: 100%;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    border: 4px solid transparent;

  }

  /* CHECKED STYLES */
  input[type=radio]:checked + label > img {
    box-shadow: 0 3.81111px 7.62222px rgba(0, 0, 0, 0.15), 0 0 0.952778px rgba(0, 0, 0, 0.1);
    border: 4px solid #09A6E6;
  }

  /* IMAGE STYLES */
  input[type=radio] + label > img {
    cursor: pointer;
  }

  .label-input-radio {
    width: 100%;
    height: 100%;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  input {
    position: absolute;
    left: -9999px;

  }

  input:checked + label {
    box-shadow: 0 2px 0 #09A6E6;
    background: #E6F6FC;
    border: 2px solid #09A6E6;
  }
}

label {
  &.tag {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: var(--space-8);
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    border: 2px solid #C4C8C9;
    box-shadow: inset 0px -2px 0px #E5E0DF;
  }
}

p {
  &.mandatory {
    margin: 40px 0 8px 0;
    text-align: right;
    font-style: italic;
    font-size: 0.75rem;

    color: red;
    text-transform: lowercase;
  }
}


</style>