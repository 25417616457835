<template>
  <div class="text-align-center intro-color" :style="cssVars">
    <div class="w-min mx-auto relative grid mb-4">
      <svg width="122" height="126" viewBox="0 0 122 126" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M121.5 71.5C121.5 104.637 93.6371 126 60.5 126C27.3629 126 0.5 95.6371 0.5 62.5C0.5 29.3629 36.8629 0.5 70 0.5C103.137 0.5 121.5 38.3629 121.5 71.5Z"
            :fill="color"/>
      </svg>
      <p class="absolute top-2/4 left-2/4 intro-number">{{ number }}</p>
    </div>
    <h2 class="mb-4">{{ step }}</h2>
    <p class="display-1 mx-auto">{{ title }}</p>
    <img :src="currentIllu[currentLocale][step].url" class="mx-auto img-men" alt="">
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'intro-caps-section',
  props: {
    step: {type: String, required: true},
    title: {type: String, required: true},
    number: {type: String, required: true},
    color: {type: String, required: true}

  },
  computed: {
    ...mapGetters(['currentLocale']),
    cssVars() {
      return {
        '--color': this.color
      }
    }
  },
  data() {
    return {
      currentIllu: {
        fr: {
          "Problème ?": {url: require('../assets/illus/1 Problème _.png')},
          "Solution !": {url: require('../assets/illus/4 Solution.png')},
          "Action": {url: require("../assets/illus/10-Passer-action.png")},
        },
        en: {
          "Problem ?": {url: require('../assets/illus/1 Problème _.png')},
          "Solution !": {url: require('../assets/illus/4 Solution.png')},
          "Action": {url: require("../assets/illus/10-Passer-action.png")},
        }

      }
    }
  },
}

</script>
<style lang="scss" scoped>
.img-men{
  width: auto;
  margin-top: 1rem;
  height: 204px;
}
.intro-color {
  h2, p {
    color: var(--color)
  }

}

.intro-number {
  width: min-content;
  color: white !important;
  font-size: 80px;
  transform: translate(-50%, -50%);
}


</style>