<template>
  <div class="template">
    <div class="menu" v-if="showSidebar">
      <div class="flex space-between align-items-center">
        <img src="../assets/wow-logo.png">
        <router-link :to="{name: 'Home'}">
          <sprite-icon name="home" size="big" color="white" fill="transparent"/>
        </router-link>
        <button @click="toggleSidebar" class="menu-button">
          <sprite-icon name="chevron-left" size="big" color="white" fill="transparent"/>
        </button>
      </div>

      <div class="nav">
        <router-link :to="{name: 'admin.home'}" @click="selected = 'admin.home'">
          Liste des familles
        </router-link>
        <router-link :to="{name: 'admin.stats'}" @click="selected = 'admin.stats'">
          Statistiques
        </router-link>
        <router-link :to="{name: 'admin.statsCapsules'}" @click="selected = 'admin.statsCapsules'">
          Statistiques capsules
        </router-link>
      </div>
    </div>
    <div class="content">
      <button @click="toggleSidebar" class="menu-button">
        <sprite-icon name="chevron-right" size="big" fill="transparent"/>
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SpriteIcon from "@/components/SpriteIcon";
import {mapGetters} from "vuex";
import PrimaryButton from "@/components/PrimaryButton.vue";

export default {
  name: "Admin",
  components: {PrimaryButton, SpriteIcon},
  computed: {
    ...mapGetters(['currentFamily'])
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    setWindowWidth(e) {
      this.showSidebar = e.target.innerWidth > 800
    }
  },
  data() {
    return {
      selected: '',
      showSidebar: window.innerWidth > 800
    }
  },
  created() {
    window.addEventListener("resize", this.setWindowWidth);
    window.scrollTo(0, 0);
    // redirect to home if user is not admin
    if (!this.currentFamily.admin) {
      this.$router.push({name: 'Home'})
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.setWindowWidth);
  },
}
</script>

<style scoped lang="scss">
.menu {
  height: 100vh;
  background: var(--color-secondary);
  padding: 36px 28px 28px 32px;

  > span {
    display: flex;
  }
}

.content {
  padding: 40px 30px 40px 40px;
  height: 100vh;
  overflow-y: auto;
  background: var(--color-secondary-soft);
}

.template {
  display: grid;
  grid-template-columns: 270px 1fr;
  justify-items: stretch;
  overflow: hidden;
}

.nav a {
  display: flex;
  text-decoration: none;
  padding: 12px 0;
  position: relative;


  &.router-link-exact-active:before, &:hover:before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    background: var(--color-primary);
    position: absolute;
    left: -32px;
    top: 0;
  }

  &.router-link-exact-active, &:hover {
    color: white;
  }
}

.menu-button {
  display: none;
}

@media screen and (max-width: 800px) {
  .template {
    display: block;
  }

  .menu-button {
    display: inline;
  }
}
</style>