<template>
  <div>
    <div v-if="capsTags && capsTags.length > 0">
      <div class="tag-parent" v-if="size.includes('normal')">
        <div v-for="tag in capsTags" style="background: white;"
             class="py-1 px-2 text-align-center">
          {{ getTag(tag).name }}
        </div>
      </div>
      <div v-else class="flex flex-wrap">
        <div v-for="tag in capsTags" class="tag flex align-items-center margin-bot-4 caption mr-4"
             :style="{'color': getTag(tag).color}">
          <span class="rounded-full w-2 h-2 block mr-3"
                :style="{'background': getTag(tag).color}"></span>
          {{ getTag(tag).name }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Tag",
  computed: {
    ...mapGetters(['currentLocale'])
  },
  props: {
    capsTags: {
      type: Array,
      required: true
    },
    size: {
      default: 'normal',
      type: String,
      required: false
    }
  },
  data() {
    return {
      tags: [
        {
          locale: 'fr',
          values: [
            {name: "Héro-ïnes", color: '#5E2DEA'},
            {name: "Nature", color: '#07BD58'},
            {name: "Planète", color: '#09A6E6'},
            {name: "Santé", color: '#F6365C'},
            {name: "Science", color: '#FDB600'},
            {name: "Sport", color: '#F56437'},
            {name: "Vivre ensemble", color: '#CB039F'},
          ]
        },
        {
          locale: 'en',
          values: [
            {name: "Heroes", color: '#5E2DEA'},
            {name: "Nature", color: '#07BD58'},
            {name: "Planet", color: '#09A6E6'},
            {name: "Health", color: '#F6365C'},
            {name: "Science", color: '#FDB600'},
            {name: "Sport", color: '#F56437'},
            {name: "Community", color: '#CB039F'},
          ]
        }
      ],
    }
  },
  methods: {
    getTag(tag) {
      return this.tags.find(el => el.locale === this.currentLocale).values.find(el => el.name === tag.fields.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-parent {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

div {
  border-radius: var(--space-8);
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
}
</style>