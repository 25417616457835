<template>
<div class="article-layout">
  <slot></slot>
</div>
</template>

<script>
import expiredCheck from "@/library/expiredCheck";
import currentFamilyCheck from "@/library/currentFamilyCheck";
import cacheMixin from "@/library/cache-mixin";
import {mapGetters} from "vuex";

export default {
  name: "Article",
  mixins: [expiredCheck, currentFamilyCheck, cacheMixin],
  computed: {
    ...mapGetters(['currentLocale']),

  },
  created() {
    this.$i18n.locale = this.currentLocale
  }
}
</script>

<style scoped>
</style>