<template>
  <div class="h-screen">
    <header class="max-container pb-16 relative">
      <div class="pt-7">
        <a @click="$router.go(-1)" class="absolute left-7">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81836 18H28.182" stroke="var(--color-text-strong)" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 10.7273L7.81836 18" stroke="var(--color-text-strong)" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 25.2727L7.81836 18" stroke="var(--color-text-strong)" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </a>

      </div>
      <div v-if="!power.fields.new" class="badge">
        <img class="pentagone" v-if="!power.fields.new" src="../assets/img/pentagone.png" alt="Polygone">
        <img class="power" :src="power.fields.icon.fields.file.url" alt="Pouvoir">
      </div>
      <div v-else>
        <img class="badge" :src="power.fields.superPowerCategory.fields.picto.fields.file.url" alt="Pouvoir">
      </div>
    </header>
    <loader v-if="isLoading" class="center-loader"/>
    <div class="main-container wrap rounded-t-3xl h-full" v-else>
      <div class="basic-container">
        <h3 v-if="power.fields.new" class="mt-8 text-center">{{ power.fields.superPowerCategory.fields.famillyName }}</h3>
        <h3 v-else class="mt-8 text-center">{{ power.fields.category }}</h3>
        <h1 class="mt-8 text-center">{{ power.fields.title }} {{ power.fields.subtitle }}</h1>
        <div class="grid gap-4 mt-11">
          <ApiRichRenderer text-type="power-text" :rich-text="power.fields.content.content"></ApiRichRenderer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuperPower from "@/components/SuperPowers";
import Avatar from "@/components/Avatar";
import {mapGetters} from "vuex"
import Loader from "@/components/Loader";
import ApiRichRenderer from "@/components/ApiRichRenderer";

export default {
  name: "KidPowerView",
  data() {
    return {
      isLoading: true,
      kidName: '',
      power: {},
      kid: {},
    }
  },
  computed: {
    ...mapGetters({
      familyState: "familyState",
      currentLocale: "currentLocale"
    })
  },
  methods: {
    setPower() {

      this.$contentfulv2.getEntry(this.$route.params.powerId, {
        locale: this.currentLocale,
        include: 10
      })
      .then(resp => {
        this.power = resp
        this.isLoading = false
      })
    }
  },
  created() {
    this.kidName = this.$route.params.name
    this.kid = this.familyState.users.find(u => u.id === parseInt(this.$route.params.id))
    this.setPower()
  },
  components: {ApiRichRenderer, Loader, Avatar, SuperPower}
}
</script>

<style scoped lang="scss">
.center-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > ::v-deep svg {
    color: var(--color-primary);
    width: 50px;
    height: 50px;
  }
}

.pb-16 {
  padding-bottom: 4rem !important;
}

header {
  height:180px;
  .container-avatar {
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    border-radius: 9999px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: fill;
    }
  }
}

.parent-power {
  border-radius: var(--radius-default);
  position: relative;
  padding: 3px;

  .second-parent {
    border-radius: var(--radius-default);
    background: white;
    padding: var(--space-12);

    .pentagon {
      width: 64px;
      height: 64px;
      position: relative;
      transition: filter 0.2s ease-in-out;
    }

    .power {
      display: block;
      width: 32px;
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
}

.badge {
  width: 94px;
  height: 94px;

  position: absolute;
  top: 160px;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
  }

  .power {
    width: 72px;
    height: 72px;
    position: absolute;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
  }
}



</style>