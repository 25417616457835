<template>
  <div class="h-screen">
    <header class="max-container pb-16 relative">
      <div class="pt-7">
        <a @click="$router.go(-1)" class="absolute left-7">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </a>
        <router-link to="/" class="absolute right-7">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.1841 11.8373L13.1279 2.78573L12.5208 2.1787C12.3825 2.0413 12.1955 1.96419 12.0005 1.96419C11.8056 1.96419 11.6185 2.0413 11.4802 2.1787L1.81694 11.8373C1.67521 11.9785 1.5632 12.1466 1.48753 12.3318C1.41185 12.5169 1.37403 12.7154 1.37631 12.9154C1.38569 13.7404 2.0724 14.399 2.8974 14.399H3.8935V22.0326H20.1076V14.399H21.1247C21.5255 14.399 21.9029 14.242 22.1865 13.9584C22.3261 13.8192 22.4367 13.6537 22.512 13.4714C22.5872 13.2892 22.6255 13.0938 22.6247 12.8967C22.6247 12.4982 22.4677 12.1209 22.1841 11.8373V11.8373ZM13.313 20.3451H10.688V15.5639H13.313V20.3451ZM18.4201 12.7115V20.3451H14.813V15.0014C14.813 14.4834 14.3935 14.0639 13.8755 14.0639H10.1255C9.60756 14.0639 9.18803 14.4834 9.18803 15.0014V20.3451H5.581V12.7115H3.331L12.0029 4.04667L12.5443 4.58807L20.6724 12.7115H18.4201Z"
                fill="white"/>
          </svg>
        </router-link>
        <p class="display-1 white text-align-center">{{ kidName }}</p>

      </div>
      <avatar class="absolute container-avatar" type="child"
              :img="kid.custom_image  ? kid.attachment_url: kid.avatar" :show-sticker="false"/>
    </header>
    <loader v-if="isLoading" class="center-loader"/>
    <div class="main-container wrap rounded-t-3xl h-full" v-else>
      <div class="basic-container">
        <h2>{{ $t('home.superpowers') }}</h2>
        <div class="grid gap-4 mt-11">
          <router-link
              :to="{name: 'kidPowerView', params: {name: $route.params.name, id: $route.params.id, powerId: power.sys.id} }"
              class="parent-power" ref="parentPower" :id="index"
              v-for="(power, index) in powers">
            <div class="second-parent flex">
              <div class="pentagon">
                <img class="" src="../assets/img/pentagon_unlocked.png">
                <img class="power" :src="power.fields.icon.fields.file.url" alt="">
              </div>
              <div class="flex justify-center flex-col ml-4 flex-1 ">
                <p class="strong mb-1">{{ power.fields.title }}</p>
                <p class="caption"> {{ power.fields.subtitle }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuperPower from "@/components/SuperPowers";
import Avatar from "@/components/Avatar";
import {mapGetters} from "vuex"
import Loader from "@/components/Loader";

export default {
  name: "KidPower",
  data() {
    return {
      isLoading: true,
      kidName: '',
      powers: [],
      kid: {},
    }
  },
  computed: {
    ...mapGetters({
      familyState: "familyState",
      currentLocale: "currentLocale"
    })
  },
  methods: {
    setPowers() {
      this.powers = []
      let comma = null
      this.$http.get('/users/' + this.$route.params.id + '/super_powers').then(resp => {
        let powers = resp.data

        this.$contentfulv2.getEntries({
          content_type: "superPower",
          locale: this.currentLocale,
          'sys.id[in]': this.$createStringForContentfulFilter(powers)
        }).then(res => {

          this.powers = res.items
          this.isLoading = false
        });
      })
    }
  },
  created() {
    this.kidName = this.$route.params.name
    this.kid = this.familyState.users.find(u => u.id === parseInt(this.$route.params.id))
    this.setPowers()
  },
  components: {Loader, Avatar, SuperPower}
}
</script>

<style scoped lang="scss">
.center-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > ::v-deep svg {
    color: var(--color-primary);
    width: 50px;
    height: 50px;
  }
}

.pb-16 {
  padding-bottom: 4rem !important;
}

header {

  .container-avatar {
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    border-radius: 9999px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      object-fit: fill;
    }
  }
}

.parent-power {
  border-radius: var(--radius-default);
  position: relative;
  background: linear-gradient(to top, #F2A53A, #FFD85A);
  padding: 3px;

  .second-parent {
    border-radius: var(--radius-default);
    background: white;
    padding: var(--space-12);

    .pentagon {
      width: 64px;
      height: 64px;
      position: relative;
      transition: filter 0.2s ease-in-out;
    }

    .power {
      display: block;
      width: 32px;
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
}


</style>