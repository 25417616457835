<template>
  <div class="main-container rounded-t-3xl wrap auth h-full w-full">
    <h2 class="text-align-center mb-8">{{$t('password.createTitle')}}</h2>
    <NewPassword :is-old-pwd-needed="false" :section-message="pwdMessage" @submit="sendReset" :processing="processing"/>
  </div>
</template>

<script>
import UniInputPassword from "@/components/Password/UniInputPassword.vue";
import PrimaryButton from "@/components/PrimaryButton";
import UniSectionMessage from "@/components/UniSectionMessage";
import NewPassword from "@/components/Password/NewPassword.vue";
import i18n from "@/locales";

export default {
  name: "Login",
  data() {
    return {
      user: {
        password: "",
        password_confirmation: ""
      },
      sent: false,
      clicked: false,
      badCredentials: false,
      loadingLogin: false,
      pwdMessage: {
        type: '',
        title: '',
        text: ''
      },
      processing: false
    }
  },
  components: {
    NewPassword,
    UniSectionMessage,
    PrimaryButton, UniInputPassword
  },

  methods: {
    sendReset(newPassword) {
      this.processing = true
      this.user.id = this.$route.query.id
      this.user.password = newPassword
      this.user.password_confirmation = newPassword
      this.user.recover_password = this.$route.query.token
      this.$http.post('/create-password', {user: {...this.user}})
          .then((result) => {
            if (result.status === 200 && result.data.auth_token) {
              this.requestSuccess(result)
            } else {
              this.failed()
            }
          })
          .catch(err => {
            this.failed(err)
          })
    },
    login() {

    },
    requestSuccess(req) {
      this.pwdMessage.type = "success"
      this.pwdMessage.title = i18n.t('settings.updatePassword.supportTitleLogout')
      this.pwdMessage.tessage = i18n.t('settings.updatePassword.supportTextLogout')
      this.processing = false
      localStorage.clear()
      this.$router.push({name: 'Login', params: {newPwd: true}})
    },
    failed(err) {
      this.pwdMessage.type = "error"
      this.pwdMessage.title = i18n.t('settings.updatePassword.supportTitleError')
      this.pwdMessage.text = i18n.t('settings.updatePassword.supportTextError')
      this.processing = false
    },
  },
  created() {
    if (!this.$route.query.id && !this.$route.query.token) {
      this.$router.push({name: "Login"})
    }
  }
}
</script>
<style lang="scss" scoped>

.divider {
  width: 100%;
  height: 1px;
  background: #C4C8C9;
}

</style>