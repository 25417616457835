<template>
	<svg class="spinner" viewBox="0 0 50 50">
		<circle v-if="!isColored" class="path" cx="25" cy="25" r="20" fill="none"stroke-width="5"></circle>
		<circle v-else class="path" cx="25" cy="25" r="20" fill="none" style="stroke: var(--color-primary);" stroke-width="5"></circle>
	</svg>
</template>

<script>
	export default{
		name: 'UniSpinner',
        props: {
		    isColored: Boolean,
            default: false
        }
	}
</script>

<style lang="scss" scoped>
	.spinner {
		animation: rotate 2s linear infinite;
		width: 20px;
		height: 20px;

		& .path {
			stroke-linecap: round;
			animation: dash 1.5s ease-in-out infinite;
		}

	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}

</style>