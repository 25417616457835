<template>
<div>
  <new-person :type="$route.params.type"></new-person>
</div>
</template>

<script>
import NewPerson from "@/components/NewPerson.vue";
export default {
name: "SignInWelcome",
  components: {NewPerson}
}
</script>

<style lang="scss" scoped>

</style>