import Storage from "../../class/storage";

const storage = new Storage("newCapsule")

function initialState() {
    return {
        id: "",
        currentStep: "",
        contentful_id: "",
        blocks: [],
    }
}

const state = storage.init(initialState())


const getters = {
    newCapsule: state => {
        return state
    },
    currentBlock: state => {
        return state.blocks.find(b => b.id === state.currentStep)
    }
}

const mutations = {
    SET_CURRENT_BLOCK: (state, step) => {
        state.currentStep = step
        storage.update(state)
    },
    INIT: (state, {blocks, id, currentStep, contentful_id}) => {
        state.blocks = blocks
        state.id = id
        state.contentful_id = contentful_id
        state.currentStep = currentStep
        storage.update(state)
    },
    CREATE: (state, params) => {
        state.currentStep = params.currentStep
        state.id = params.id
        state.blocks = params.blocks
        storage.update(state)
    },
    UPDATE_BLOCK: (state, block) => {
        state.blocks = state.blocks.map(b => {
            if (b.id === block.id) {
                return block
            }
            return b
        })

        storage.update(state)
    },
    ADD_SUPER_POWER: (state, params) => {
        state.blocks.find(b => b.id === params.blockId)['superPower'] = params.superPower
        storage.update(state)
    }
}
const actions = {
    setCurrentBlock(store, step) {
        store.commit('SET_CURRENT_BLOCK', step)
    },
    init(store, params) {
        store.commit('INIT', params)
    },
    create(store, params) {
        store.commit('CREATE', params)
    },
    updateBlock(store, block) {
        store.commit('UPDATE_BLOCK', block)
    },
    addSuperPower(store, params) {
        store.commit('ADD_SUPER_POWER', params)
    }
}

export default {
    state,
    mutations,
    actions,
    getters
};
