<template>
  <div>
    <validation-observer tag="form" ref="userForm" v-slot="{valid}" @submit.prevent="submit">
      <!-- Firstname -->
      <uni-input
          :label="inputText"
          id="input-test"
          type="text"
          placeholder="Votre prénom"
          v-model="user.name"
          rules="required"
      />

      <!-- Themes -->
      <div v-if="type.includes('child')">
        <p class="mb-2">{{ $t('validation.placeholders.theme') }} <span style="color: red"> *</span></p>
        <div class="mb-6">
          <Loader :color="true" v-if="loading"/>
          <div v-else>
            <ValidationProvider mode="eager" v-slot="{ errors, classes }" rules="required" tag="div"
                                class="tags-container ">
              <div v-for="tag in tags" class="input-container">
                <input type="checkbox" :id="tag.sys.id" :value="tag.sys.id" v-model="user.tagField">
                <label :for="tag.sys.id" class="tag">{{ tag.fields.name }}</label>

              </div>
            </ValidationProvider>

          </div>
        </div>
      </div>

      <!-- Age -->
      <div v-if="type.includes('child')">
        <uni-input
            :label="inputDate"
            id="input-date"
            type="number"
            :placeholder="$t('validation.placeholders.age')"
            v-model="user.age"
            style="margin-bottom: 0"
        />
        <p class="italic text-xs mb-1">{{ $t('validation.placeholders.ageHelp') }}</p>
      </div>

      <!-- Avatar -->
      <p class="mt-6">{{ $t('child.avatar') }} <span style="color: red"> *</span></p>
      <div>
        <validation-provider tag="div" v-slot="{ errors }">
          <div class="grid gap-3 grid-cols-4 grid-container">
            <div class="container-img" v-if="Object.entries(image).length === 0">
              <label for="upload-photo"
                     class="flex align-items-center justify-center upload-photo">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.3333 4.25V9.91667" stroke="#171414" stroke-width="2.125" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M25.5 7.08333H31.1667" stroke="#171414" stroke-width="2.125" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path
                      d="M19.8333 7.08334H7.08333C5.51792 7.08334 4.25 8.35126 4.25 9.91668V26.9167C4.25 28.4821 5.51792 29.75 7.08333 29.75H25.5C27.0654 29.75 28.3333 28.4821 28.3333 26.9167V15.5833"
                      stroke="#171414" stroke-width="2.125" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.86975 20.4949L12.0004 17.1147C12.4948 16.3313 13.5956 16.2251 14.2302 16.8994L15.7532 18.5172L17.3497 15.1243C17.8413 14.0788 19.3118 14.0307 19.8714 15.0422L22.9229 20.5643C23.4442 21.5092 22.76 22.6667 21.6819 22.6667H11.0682C9.95191 22.6667 9.27475 21.4384 9.86975 20.4949Z"
                        stroke="#171414" stroke-width="2.125" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </label>
              <input type="file" accept="image/*" name="photo" id="upload-photo" ref="file" @change="handleFileUpload"/>
            </div>
            <div class="container-custom-img" v-if="Object.entries(image).length > 0">
              <div class="container-img">
                <input type="radio" name="img" class="input-radio" accept="image/*" id="img-custom" required
                       :value="user.selectedImage" :checked="customImage === true">
                <label class="label-input-radio" for="img-custom">
                  <img :src="image.pathLong" :id="image.name" alt="" @click="customImage = true">
                </label>
              </div>
              <button id="remove-img" @click="removeCustomImage">
                <span style="font-size: 11px">x</span>
              </button>
            </div>
            <div class="container-img" v-for="n in 15">
              <input type="radio" name="img" class="input-radio" accept="image/*" :id="'img-'+n" required
                     :value="user.selectedImage"
                     :checked="user.selectedImage === `https://storage.googleapis.com/wow--news/v2/${n}.png`">
              <label class="label-input-radio" :for="'img-'+n">
                <img
                    :src="'https://storage.googleapis.com/wow--news/v2/' + n + '.png'"
                    :id="`${n}.png`" alt="" @click="getAvatar">
              </label>
            </div>
          </div>
          <small v-if="errors[0]" class="flex align-items-center">
            <svg class="mr-1"
                 v-if="errors[0]"
                 xmlns="http://www.w3.org/2000/svg"
                 height="14"
                 viewBox="0 0 24 24"
                 width="14"
                 fill="var(--color-r100)"
            >
              <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
              />
            </svg>
            <span>{{ $t('child.selectImage') }}</span>
          </small>
        </validation-provider>
      </div>

      <p class="mandatory">* {{ $t('settings.mandatory') }}</p>
      <primary-button type="submit" :clicked="clicked" :disabled="!canValidate">{{ buttonText }}</primary-button>
    </validation-observer>
    <uni-modal title="Pourquoi cette information ?" :is-visible="openModal" @close-modal="openModal = false"
               :has-footer-slot="false">
      <template v-slot="body">
        <div>
          <p>La date de naissance nous permet de connaitre l’âge des enfants participant à l’application et de pouvoir
            ainsi, à terme, adapter notre contenu.</p>
        </div>
      </template>
    </uni-modal>
  </div>
</template>

<script>
import UniInput from "@/components/UniInput.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import {mapActions, mapGetters} from "vuex"
import Help from "@/components/Help.vue";
import UniModal from "@/components/UniModal.vue";
import Loader from "@/components/Loader.vue";
import LocaleSwitcherOnRight from "@/views/security/LocaleSwitcherOnRight.vue";

export default {
  name: "UserForm",
  components: {LocaleSwitcherOnRight, Loader, UniModal, Help, PrimaryButton, UniInput},
  data() {
    return {
      inputText: this.type.includes('adult') ? this.$t('child.placeholderParent') : this.$t('child.placeholderChild'),
      inputDate: this.$t('validation.placeholders.age'),
      image: {},
      clicked: false,
      openModal: false,
      customImage: false,
      loading: true,
      tags: [],
      update: false
    }
  },
  model: {
    prop: 'user',
    event: 'userChanged'
  },
  props: {
    type: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      default: () => ({
        name: null,
        age: null,
        tagField: [],
        selectedImage: null,
        customImage: false,
        file: null
      })
    }
  },
  computed: {
    ...mapGetters(['currentFamily', 'currentLocale']),
    canValidate() {
      return this.user.name !== ''
          && (!this.customImage && this.user.selectedImage != null || this.customImage && this.user.file != null)
          && this.user.tagField?.length > 0
    }
  },
  watch: {
    user() {
      this.$emit('userChanged')
    }
  },
  methods: {
    ...mapActions(["addUser", "addCurrentUser", "updateUser"]),
    localeSwitched() {
      window.location.reload()
    },
    /**
     * function qui gère 'l'upload' du fichier et sa prévisualisation
     */
    handleFileUpload() {
      this.user.file = this.$refs.file.files[0];
      let blob = URL.createObjectURL(this.user.file)
      this.image = {pathLong: blob, pathShort: '', name: "Image Imported"}
      this.$nextTick(() => {
        this.customImage = true
        this.user.selectedImage = this.image.pathLong
      })
    },
    getAvatar(e) {
      this.customImage = false
      this.user.selectedImage = e.target.src
    },
    removeCustomImage() {
      this.image = {}
      this.$nextTick(() => {
        this.customImage = false
      })
    },
    submit() {
      this.$refs.userForm.validate().then(_ => {
        let user = {
          'firstname': this.user.name,
          'age': this.user.age,
          'custom_image': this.customImage,
          'user_type': this.type,
          'family_id': this.currentFamily.id,
          'prefered_tag_id': JSON.stringify(this.user.tagField),
          'avatar': this.user.selectedImage
        }
        if (this.customImage) {
          user = {
            ...user,
            'attachment': this.user.file,
          }
        } else {
          user = {
            ...user,
            'purge_attachment': true,
          }
        }
        let formData = new FormData()
        // boucler sur chaque entrée de l'objet pour peupler le formData
        Object.entries(user).forEach(
            ([key, value]) => formData.append(key, value)
        )
        // envoyer le formData en paramètre
        console.log(this.update)
        this.clicked = true
        if (this.update) {
          this.$http.patch('/users/' + this.$route.params.id, formData)
              .then(response => {
                this.updateUser(response.data)
                this.clicked = false
                this.$router.push({name: 'profile'})
                this.$nextTick(() => {
                  this.$emit('user-submitted')
                })
              })
              .catch(err => {
                console.log(err)
                if (this.$isSignatureExpired(err.response.data.message)) {
                  this.$store.dispatch('logout')
                  this.$router.push({name: 'Login', params: {q: 'expired'}})
                }
              })
        } else {
          this.$http.post('/users', formData)
              .then(response => {
                this.addUser(response.data)
                this.addCurrentUser(response.data)
                this.clicked = false
                this.$nextTick(() => {
                  this.$emit('user-submitted')
                })
              })
              .catch(err => {
                if (this.$isSignatureExpired(err.response.data.message)) {
                  this.$store.dispatch('logout')
                  this.$router.push({name: 'Login', params: {q: 'expired'}})
                }
              })
        }
      })
    }
  },
  created() {
    this.$contentfulv2.getEntries({
      content_type: 'tags',
      locale: this.currentLocale
    }).then(response => {
      this.loading = false
      this.tags = response.items
    }).catch(_ => {
      this.loading = false
    })

    this.update = Object.keys(this.user).length !== 0 && this.user.name.length !== 0

    if (this.user.customImage) {
      this.image = {pathLong: this.user.file, pathShort: '', name: "Image Imported"}
      this.customImage = this.user.customImage
    }
  }
}
</script>

<style lang="scss" scoped>
.helper {
  margin: var(--space-4) 0 0 var(--space-4);
  display: flex;
  align-items: center;
}

.helper.invalid > span {
  width: calc(100% - var(--space-20));
}

.helper.invalid {
  color: var(--color-error);
}

.helper > svg {
  margin-right: var(--space-4);
}

.upload-photo {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--color-surface-soft);
  /* Style as you please, it will become the visible UI component. */
  border: 2px solid;
}

#upload-photo, .input-radio {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
}

.grid-container > * {
  align-self: center;
  justify-self: center;
}

.container-custom-img {
  width: 77px;
  height: 77px;
  position: relative;
  display: inline-block;

  > #remove-img {
    line-height: 1rem;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }
}

.container-img {
  width: 77px;
  height: 77px;
  border-radius: 100%;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    border: 4px solid transparent;

  }

  /* CHECKED STYLES */
  input[type=radio]:checked + label > img {
    box-shadow: 0 3.81111px 7.62222px rgba(0, 0, 0, 0.15), 0 0 0.952778px rgba(0, 0, 0, 0.1);
    border: 4px solid #09A6E6;
  }

  /* IMAGE STYLES */
  input[type=radio] + label > img {
    cursor: pointer;
  }

  .label-input-radio {
    width: 100%;
    height: 100%;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  input {
    position: absolute;
    left: -9999px;

  }

  input:checked + label {
    box-shadow: 0 2px 0 #09A6E6;
    background: #E6F6FC;
    border: 2px solid #09A6E6;
  }
}

label {
  &.tag {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: var(--space-8);
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    border: 2px solid #C4C8C9;
    box-shadow: inset 0px -2px 0px #E5E0DF;
  }
}

p {
  &.mandatory {
    margin: 40px 0 8px 0;
    text-align: right;
    font-style: italic;
    font-size: 0.75rem;

    color: red;
    text-transform: lowercase;
  }
}


</style>