<template>
  <div style="height: 100vh;">
    <header class="flex space-between align-items-center">
      <div class="back-button">
        <router-link to="/" class="mr-6">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="#1C2326" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81836 18H28.182" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 10.7273L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 25.2727L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </header>
    <div class="wrapper">
      <h1 class="mt-2">{{ $t('home.journalists') }}</h1>

      <Loader :color="true" v-if="isLoadingAuthors"></Loader>
      <div v-else class="authors-container mt-8">
        <div @click="openAuthorModal(author)" v-for="author in authors">
          <img class="rounded-full" :src="Object.assign({}, author.fields.profil.fields.file).url + '?fit=thumb'"
               :alt="author.fields.firstname">
          <p>{{ author.fields.firstname }}</p>
        </div>
      </div>
    </div>
    <uni-modal :is-visible="isAuthorModalOpen" @close-modal="closeAuthorModal"
               :has-footer-slot="false">
      <template slot="body">
        <Loader :color="true" v-if="!currentAuthor"></Loader>
        <div v-else>
          <h2 class="text-align-center">{{ currentAuthor.fields.firstname }} {{ currentAuthor.fields.name }}</h2>
          <img class="rounded-full block mx-auto my-4" style="width: 80px;"
               :src="currentAuthor.fields.profil.fields.file.url + '?fit=thumb'"
               :alt="currentAuthor.fields.firstname">
          <api-rich-renderer :timer="false" text-type="article"
                             :rich-text="currentAuthor.fields.description.content"></api-rich-renderer>
          <h2 class="mt-6 mb-4" v-if="currentAuthor.capsule">{{ $t('home.journalistLastNews') }} {{ currentAuthor.fields.firstname }}</h2>
          <Loader :color="true" v-if="isLoadingAuthorCapsule"></Loader>
          <div v-else>
            <CardCapsule v-if="currentAuthor.capsule" :title="currentAuthor.capsule.fields.title"
                         :img="Object.assign({}, currentAuthor.capsule.fields.cover.fields.file).url"
                         :read-duration="currentAuthor.capsule.fields.readDuration"
                         :date="new Date(currentAuthor.capsule.sys.createdAt)"
                         :description="currentAuthor.capsule.fields.accroche || currentAuthor.capsule.fields.description"
                         :slug="currentAuthor.capsule.fields.slug"
                         :big-button="false" card-width="full"
                         :author="currentAuthor.capsule.fields.author.fields"
                         :tags="currentAuthor.capsule.fields.capsuleTags"
                         :is-free="currentAuthor.capsule.fields.freeCapsule"
                         :new-caps="!!currentAuthor.capsule.fields.blocs"
                         @open-modal="openModal"></CardCapsule>
          </div>
        </div>
      </template>
    </uni-modal>
    <handle-subscription-modal :capsule-id="capsuleId" :open-modal="modalSub" @close-modal="modalSub = false"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import UniModal from "@/components/UniModal";
import ApiRichRenderer from "@/components/ApiRichRenderer";
import CardCapsule from "@/components/CardCapsule";
import HandleSubscriptionModal from "@/components/handleSubscriptionModal";
import {mapGetters} from "vuex";

export default {
  name: "Journalists",
  components: {HandleSubscriptionModal, CardCapsule, ApiRichRenderer, UniModal, Loader},
  computed: {
    ...mapGetters(['currentLocale'])
  },
  data() {
    return {
      isLoadingAuthors: true,
      isLoadingAuthorCapsule: true,
      isAuthorModalOpen: false,
      authors: [],
      currentAuthor: null,
      capsuleId: null,
      modalSub: false,
    }
  },
  methods: {
    openModal(capsule) {
      this.modalSub = true;
      this.capsuleId = capsule.sys.id
    },
    openAuthorModal(author) {
      this.isAuthorModalOpen = true;
      this.currentAuthor = author

      this.$contentfulv2.getEntries({
        content_type: "capsule",
        limit: 1,
        include: 2,
        locale: this.currentLocale,
        order: "-sys.createdAt",
        "fields.title[exists]": true,
        "fields.blocs[exists]": true,
        links_to_entry: author.sys.id,
      })
          .then(resp => {
            let data = resp.items.map(item => {
              if (item.fields.title) {
                return item
              }
            }).filter(el => el !== undefined)
            this.currentAuthor.capsule = data[0]
            this.isLoadingAuthorCapsule = false
          })

    },
    closeAuthorModal() {
      this.isAuthorModalOpen = false;
      this.currentAuthor = null
      this.isLoadingAuthorCapsule = true
    },
  },
  created() {
    this.$contentfulv2.getEntries({
      content_type: 'authors',
      locale: this.currentLocale,
      include: 2,
      order: 'fields.order'
    }).then(res => {
      this.authors = res.items

      if (this.currentLocale === 'en') {
        // loop through authors, add the promise to an array, then execute Promise.all
        let promises = []
        this.authors.forEach(author => {
          promises.push(this.$contentfulv2.getAsset(author.fields.profil.sys.id))
        })
        Promise.all(promises).then(res => {
          res.forEach((asset, index) => {
            this.authors[index].fields.profil.fields.file = asset.fields.file
          })
          this.isLoadingAuthors = false
        })
      } else {
        this.$nextTick(() => {
          this.isLoadingAuthors = false
        })
      }
    })
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="scss">
.authors-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  grid-gap: 20px;
  padding-bottom: 72px;

  > div {
    cursor: pointer;
    text-align: center;

    p {
      font-weight: bold;
    }

    > img {
      width: 72px;
      height: 72px;
      object-fit: cover;
    }
  }
}
</style>