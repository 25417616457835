<template>
  <validation-observer tag="form" ref="pwdForm" v-slot="{valid}" @submit.prevent="submit">
    <div v-if="passwordUpdateDivSeen">
      <UniInputPassword v-if="isOldPwdNeeded" :label="$t('settings.updatePassword.old')" v-model="old"
                        :rules="isOldPwdNeeded ? 'required' : ''"
                        :helper-message="errorMessage ? $t('settings.passwordIncorrect') : ''"></UniInputPassword>
      <div v-if="isOldPwdNeeded" class="divider my-5"></div>
      <UniInputPassword :label="$t('settings.updatePassword.new')"
                        vid="password"
                        id="password"
                        v-model="newPwd"
                        rules="required|passwordTest"
      ></UniInputPassword>
      <UniInputPassword :label="$t('settings.updatePassword.confirm')"
                        v-model="check"
                        rules="required|confirmed:password|passwordTest"
      ></UniInputPassword>
    </div>
    <primary-button :clicked="clicked"
                    @click.native="valid? clicked = true: clicked = false" class="mt-8">
      {{ $t('settings.validate') }}
    </primary-button>
    <UniSectionMessage v-if="message" :type="message.type"
                       :title="message.title"
                       :description="message.text"></UniSectionMessage>

  </validation-observer>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import UniInputPassword from "./UniInputPassword.vue";
import UniButton from "../UniButton.vue";
import UniSectionMessage from "../UniSectionMessage.vue";
import PrimaryButton from "../PrimaryButton.vue";
import uidMixin from "@/library/uid-mixin";
import i18n from "@/locales";

export default {
  name: "NewPassword",
  mixins: [uidMixin],
  components: {
    ValidationProvider,
    UniButton,
    UniInputPassword,
    UniSectionMessage,
    PrimaryButton
  },
  props: {
    isOldPwdNeeded: {
      type: Boolean,
      default: true
    },
    passwordUpdateDivSeen: {
      type: Boolean,
      default: true
    },
    sectionMessage: {
      type: Object,
      default() {
        return {
          type: 'warning',
          title: i18n.t('settings.updatePassword.supportTitle'),
          text: i18n.t('settings.updatePassword.supportText')
        }
      }
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    sectionMessage: {
      handler(val) {
        if (this.$options.propsData.sectionMessage
            && Object.keys(this.sectionMessage).length > 0
            && (this.sectionMessage.type.length > 0
            || this.sectionMessage.title.length > 0
            || this.sectionMessage.text.length > 0)) {
          this.message = this.sectionMessage
        }
      },
      immediate: true,
      deep: true
    },
    processing: function (val) {
      this.clicked = this.processing
    }
  },
  data() {
    return {
      isPasswordVisible: false,
      clicked: false,
      old: '',
      newPwd: '',
      check: '',
      errorMessage: false,
      message: {
        type: 'warning',
        title: i18n.t('settings.updatePassword.supportTitle'),
        text: i18n.t('settings.updatePassword.supportText')
      }
    }
  },
  methods: {
    submit() {
      this.$refs.pwdForm.validate().then(resp => {
        if (resp) {
          if (this.isOldPwdNeeded) {
            this.$http.post('check-password', {family: {password: this.old}})
                .then(resp => {
                  if (resp.data.status === 200) {
                    this.$emit('submit', this.newPwd)
                  } else {
                    this.errorMessage = true
                    this.clicked = false
                  }
                })
          } else {
            this.$emit('submit', this.newPwd)
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.label {
  width: 100%;
  position: absolute;
  padding: 16px;
  font-size: 1rem;
  display: inline;
  z-index: 1;
  border: none;
  transition: var(--transition-quick);
  color: var(--color-text);
}

.input-password {
  margin-bottom: var(--space-16);
}

label {
  display: flex;
  overflow: hidden;

  outline: none;
  width: 100%;
  position: relative;
  height: 56px;
  border: 2px solid #DFE1E6;
  border-radius: var(--radius-default);
  transition: var(--transition-quick);
  background: #F2F4F6;

  ::v-deep .button {
    margin: 8px 8px 0 8px;
    opacity: 0;
  }

  &:hover {
    border: 2px solid var(--color-surface-extra-strong);
  }

  &.hasFocused {
    border: 2px solid #DFE1E6;
  }
}

label.isFocused {
  .label {
    padding: 2px 12px 0 12px;
    font-size: 0.8125rem;
    color: var(--color-text-disabled);
  }

  input {
    opacity: 1;
  }

  ::v-deep .button {
    opacity: 1;
  }
}

input {
  background: transparent;
  padding: 26px 12px 10px 12px;
  border: none;
  outline: none;
  width: calc(100% - 56px);
  opacity: 0;
  transition: var(--transition-quick);
}

/* Errors and helper */
.input-password.invalid {
  border: 1px solid var(--color-error);
}

.helper {
  margin: var(--space-4) 0 0 var(--space-4);
  display: inline-flex;
  align-items: center;
}

.helper.invalid > span {
  flex: 1;
  width: calc(100% - var(--space-20));
}

.helper.invalid {
  color: var(--color-error);
}

.helper > svg {
  margin-right: var(--space-4);
}
</style>
