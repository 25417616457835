<template>
  <button :class="(isActive? 'active' : '')" @click="$emit('clicked', $event,  emitValue )">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ChoixButton",
  props:{
    isActive: {
      required: true,
      type: Boolean
    },
    value:{
      type: Boolean,
      required: true
    },

  },
  computed:{
    emitValue(){
      return this.value
    }
  },

}
</script>

<style lang="scss" scoped>
button{
  outline: none;
  background: var(--color-surface-soft);
  width: 100%;
  border-radius: var(--radius-default);
  border: 2px solid #CAC5C4;
  box-shadow: inset 0px -4px 0px #CAC5C4;
  padding: var(--space-12);

  &.active{
    box-shadow: inset 0px -4px 0px #09A6E6;
    border: 2px solid #09A6E6;
    color :#09A6E6;
  }
}



</style>