<template>
  <div class="admin-stats">
    <h1>Statistiques de la capsule <i v-if="stats">{{ stats.capsule_name }}</i></h1>

    <div class="contain" v-if="stats">
      <table class="simple-table" ref="reading_stats">
        <caption><h3>Infos</h3></caption>
        <tbody>
        <tr>
          <th scope="row">Titre</th>
          <td>{{ stats.capsule_name }}</td>
        </tr>
        <tr>
          <th scope="row">Date de publication</th>
          <td>{{ displayDate(stats.published_date) }}</td>
        </tr>
        <tr>
          <th scope="row">Auteur·rice</th>
          <td>{{ stats.published_by }}</td>
        </tr>
        <tr>
          <th scope="row">Nombre de blocs</th>
          <td>{{ stats.blocks_nbr }}</td>
        </tr>
        </tbody>
      </table>
      <table class="simple-table" ref="reading_stats">
        <caption><h3>Lectures</h3></caption>
        <tbody>
        <tr>
          <th scope="row">Total</th>
          <td>{{ stats.beginning.total + stats.middle.total + stats.end.total }}</td>
        </tr>
        <tr>
          <th rowspan="2">Début</th>
          <td>{{ stats.beginning.total }}</td>
        </tr>
        <tr>
          <td>{{ stats.beginning.percentage }} %</td>
        </tr>
        <tr>
          <th rowspan="2">Milieu</th>
          <td>{{ stats.middle.total }}</td>
        </tr>
        <tr>
          <td>{{ stats.middle.percentage }} %</td>
        </tr>
        <tr>
          <th rowspan="2">Fin (terminées)</th>
          <td>{{ stats.end.total }}</td>
        </tr>
        <tr>
          <td>{{ stats.end.percentage }} %</td>
        </tr>
        <tr>
          <th scope="row">Reprises</th>
          <td>{{ stats.middle.resume }}</td>
        </tr>
        <tr>
          <th scope="row">Redémarrages</th>
          <td>{{ stats.middle.restart }}</td>
        </tr>
        </tbody>
      </table>
      <table class="double-table" ref="ratings_stats" v-if="stats.ratings.length > 0">
        <caption><h3>Notes</h3></caption>
        <thead>
        <tr>
          <th></th>
          <th>0</th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>x̄</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Heureux</th>
          <td>{{ stats.ratings.enjoy.count[0] }}</td>
          <td>{{ stats.ratings.enjoy.count[1] }}</td>
          <td>{{ stats.ratings.enjoy.count[2] }}</td>
          <td>{{ stats.ratings.enjoy.count[3] }}</td>
          <td>{{ stats.ratings.enjoy.count[4] }}</td>
          <td>{{ stats.ratings.enjoy.count[5] }}</td>
          <td>{{ stats.ratings.enjoy.average }}</td>
        </tr>
        <tr>
          <th>Pédagogique</th>
          <td>{{ stats.ratings.learn.count[0] }}</td>
          <td>{{ stats.ratings.learn.count[1] }}</td>
          <td>{{ stats.ratings.learn.count[2] }}</td>
          <td>{{ stats.ratings.learn.count[3] }}</td>
          <td>{{ stats.ratings.learn.count[4] }}</td>
          <td>{{ stats.ratings.learn.count[5] }}</td>
          <td>{{ stats.ratings.learn.average }}</td>
        </tr>
        <tr>
          <th>Inspirant</th>
          <td>{{ stats.ratings.inspire.count[0] }}</td>
          <td>{{ stats.ratings.inspire.count[1] }}</td>
          <td>{{ stats.ratings.inspire.count[2] }}</td>
          <td>{{ stats.ratings.inspire.count[3] }}</td>
          <td>{{ stats.ratings.inspire.count[4] }}</td>
          <td>{{ stats.ratings.inspire.count[5] }}</td>
          <td>{{ stats.ratings.inspire.average }}</td>
        </tr>
        </tbody>
      </table>
      <table class="simple-table emotions-table" ref="emotions_stats" v-if="emotions.length > 0">
        <caption><h3>Émotions</h3></caption>
        <tbody>
        <tr v-for="(emotion, index) in emotions">
          <th v-if="index === 0 || emotions[index - 1][0] !== emotion[0]"
              :rowspan="emotions.filter(x => x[0] === emotion[0]).length">
            {{ emotion[0] }}
          </th>
          <td>{{ emotion[1] }}</td>
          <td>{{ emotion[2] }}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>

</template>

<script>
import moment from 'moment';

export default {
  name: "AdminStatsCapsulesDetails",
  data() {
    return {
      stats: null,
      emotions: []
    }
  },
  methods: {
    displayDate(date) {
      return moment(String(date)).format('DD/MM/YYYY')
    }
  },
  created() {
    this.$http.get(`/stats/capsules/${this.$route.params.capsule_id}/detail`)
        .then(r => {
          this.stats = r.data
          Object.keys(this.stats.emotions).forEach(block => {
            Object.keys(this.stats.emotions[block]).forEach(emotion => {
              this.emotions.push([block, emotion, this.stats.emotions[block][emotion]])
            })
          })
        })
  }
}
</script>

<style scoped lang="scss">

.contain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;

  h3 {
    margin: 5px;
  }
}

table {
  margin-bottom: 2rem;
  display: table;
  text-align: center;
  font-weight: 400;
  border-collapse: separate;
  border-spacing: 0;

  tr {
    display: table-row;

    th, td {
      border: {
        top: var(--color-text-soft) 1px solid;
        right: var(--color-text-soft) 1px solid;
      }
    }

    *:first-child {
      border-left: var(--color-text-soft) 3px solid;
    }

    *:last-child {
      border-right: var(--color-text-soft) 3px solid;
    }
  }

  tbody {
    tr {
      &:last-child {
        * {
          border-bottom: var(--color-text-soft) 3px solid;

          &:first-child {
            border-radius: 0 0 0 1.5rem;
          }

          &:last-child {
            border-radius: 0 0 1.5rem 0;
          }
        }
      }
    }
  }

  th {
    display: table-cell;
    background: var(--color-secondary);
    color: black;
    font-weight: 700;
    column-span: all;
    margin: 0;
    padding: 8px 6px;
    width: 30%;
  }

  td {
    display: table-cell;
    padding: 8px 6px;

    &.title {
      text-align: left;
    }

    &.date {
      text-align: left;
      font-style: italic;
    }

    &.figure {
      text-align: center;
      font-weight: bold;
    }
  }
}

.simple-table {
  tr {
    &:first-child {
      * {
        border-top: var(--color-text-soft) 3px solid;

        &:first-child {
          border-radius: 1.5rem 0 0 0;
        }

        &:last-child {
          border-radius: 0 1.5rem 0 0;
        }
      }
    }

    td {
      border-left: var(--color-text-soft) 0 solid;
    }
  }
}

.emotions-table {
  table-layout: fixed;
  width: 100%;
  text-transform: capitalize;

  tr:nth-child(n + 2) th:last-of-type {
    border-radius: 0 0 0 1.5rem;
    border-bottom: var(--color-text-soft) 3px solid;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 0;
  }
}

.double-table {
  table-layout: fixed;
  width: 100%;

  thead {
    tr {
      * {
        &:first-child {
          border-radius: 1.5rem 0 0 0;
          width: 115px;
        }

        &:last-child {
          border-radius: 0 1.5rem 0 0;
        }
      }

      &:first-child {
        * {
          border-top: var(--color-text-soft) 3px solid;
        }
      }
    }
  }
}


@media (max-width: 1100px) {
  .contain {
    grid-template-columns: 1fr;
  }
}

</style>