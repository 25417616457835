<template>
  <div class="skeleton-text" :style="'width :' + width + ';height:' + height"></div>
</template>

<script>
  export default {
    name: "UniSkeletonImage",
    props:{
      height:{
        type:String,
      },
      width:{
        type: String,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .skeleton-text {
    position: relative;
    border-radius: var(--radius-default);
    animation-name: aniHorizontal;
    animation-duration: 1.7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(45deg, var(--color-surface-strong) 0%, var(--color-surface-strong) 30%, var(--color-surface-extra-strong) 50%,var(--color-surface-strong) 65%, var(--color-surface-strong) 100%);
    background-size: 200%;
  }

  @keyframes aniHorizontal {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0% 0;
    }
  }
</style>