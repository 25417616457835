<template>
  <div class="skeleton-text" :class="textVariation" :style="'width :' + width"></div>
</template>

<script>
export default{
  name: 'UniSkeletontext',
  props:{
    textVariation:{
      type:String,
    },
    width:{
      type: String,
      default: '80%'
    }
  }
}
</script>

<style scoped>
.skeleton-text {
  position: relative;
  border-radius: var(--radius-default);
  animation-name: aniHorizontal;
  animation-duration: 1.7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background: linear-gradient(45deg, var(--color-surface-strong) 0%, var(--color-surface-soft) 50%, var(--color-surface-strong) 100%);
  background-size: 200%;
}

@keyframes aniHorizontal {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}
</style>
