<template>
  <Loader :color="true" v-if="isLoading"/>
  <div v-else>
    <div class="web">
      <div v-for="(block, index) in article.fields.blocs">
        <MainSummary @go-back="goBack" @next-step="goToNextStep"
                     v-if="block.sys.contentType.sys.id === 'summary' && currentStep === block.sys.id"
                     :block="block" :index="index" :totalSteps="article.fields.blocs.length"
                     :author="article.fields.author"
                     :date="new Date(article.sys.createdAt)"
                     :cover="Object.assign({}, article.fields.cover.fields.file).url" :title="article.fields.title"
                     :description="article.fields.description"/>
        <Quizz @go-back="goBack" @next-step="goToNextStep"
               v-if="block.sys.contentType.sys.id === 'quizz'  && currentStep === block.sys.id"
               :block="block" :index="index" :totalSteps="article.fields.blocs.length"/>
        <Subject @go-back="goBack" @next-step="goToNextStep"
                 v-if="block.sys.contentType.sys.id === 'subject'  && currentStep === block.sys.id" :block="block"
                 :index="index" :totalSteps="article.fields.blocs.length" :author="article.fields.author"/>
        <Fix @next-step="goToNextStep" v-if="block.sys.contentType.sys.id === 'fix'  && currentStep === block.sys.id"
             :block="block" :index="index" :totalSteps="article.fields.blocs.length" :author="article.fields.author"
             @go-back="goBack" :pdfPays="article.fields.pdfPays"/>
        <Powers @go-back="goBack" @next-step="goToNextStep"
                v-if="block.sys.contentType.sys.id === 'powers'  && currentStep === block.sys.id"
                :block="block" :index="index" :totalSteps="article.fields.blocs.length"/>
        <End @retry="retry" @go-back="goBack"
             v-if="block.sys.contentType.sys.id === 'end'  && currentStep === block.sys.id"
             :block="block" :index="index" :totalSteps="article.fields.blocs.length" :pdf="article.fields.postPdf"
             :pdfPays="article.fields.pdfPays" :capsule-id="article.sys.id"/>

      </div>
    </div>

    <uni-modal :title="$t('capsule.continueModale.title')" :is-visible="openRetryModal"
               @close-modal="openRetryModal = false"
               type="intro-caps">
      <template slot="body"><p class="mb-8">{{ $t('capsule.continueModale.contentKeep') }}</p>
        <primary-button class="mb-4" @click.native="openRetryModal = false">{{
            $t('capsule.continueModale.actions.keep')
          }}
        </primary-button>
        <primary-button color="white" class="mb-4" @click.native="retry">{{
            $t('capsule.continueModale.actions.restart')
          }}
        </primary-button>
      </template>
    </uni-modal>

    <!-- Modal de blocage -->
    <uni-modal :title="$t('capsule.limitedAccess.title')" :is-visible="openLimitedAccessModal" @close-modal="openLimitedAccessModal = false">
      <template slot="body">
        <p class="mb-8">{{ $t('capsule.limitedAccess.text') }}</p>
      </template>
    </uni-modal>

  </div>

</template>

<script>
import Loader from "@/components/Loader";
import MainSummary from "@/components/blocks/MainSummary.vue";
import Quizz from "@/components/blocks/Quizz";
import Subject from "@/components/blocks/Subject";
import Fix from "@/components/blocks/Fix";
import Powers from "@/components/blocks/Powers";
import PrimaryButton from "@/components/PrimaryButton";
import End from "@/components/blocks/End";
import * as Contentful from "contentful";
import {mapActions, mapGetters} from "vuex";
import ApiRichRenderer from "@/components/ApiRichRenderer";
import UniModal from "@/components/UniModal";

export default {
  name: "Article",
  components: {UniModal, ApiRichRenderer, End, PrimaryButton, Powers, Fix, Subject, Quizz, MainSummary, Loader},
  computed: {
    ...mapGetters(["newCapsule", "currentLocale", "currentUser", "currentBlock", "currentFamily"])
  },
  data() {
    return {
      isLoading: true,
      isSpotlight: false,
      article: {},
      dbData: {},
      currentStep: '',
      openRetryModal: false,
      openLimitedAccessModal: false
    }
  },
  methods: {
    ...mapActions(['setCurrentBlock', 'init']),
    retry() {
      this.$nextTick(() => {
        this.currentStep = this.article.fields.blocs[0].sys.id
        this.init({
          blocks: this.article.fields.blocs.map(block => {
            return {id: block.sys.id}
          }), id: this.dbData.id, currentStep: this.currentStep, contentful_id: this.dbData.contentful_id
        })
        this.$http.post('/capsules/', {
          user_id: this.currentUser.id,
          current_step: this.newCapsule.currentStep,
          contentful_id: this.article.sys.id,
          is_restart: true,
        })
        this.openRetryModal = false
      })
    },
    goToNextStep(index) {
      if (index === 1 && !this.currentFamily.isFullAccess() && !this.isSpotlight) {
        this.openLimitedAccessModal = true;
      } else {
        if (this.article.fields.blocs[index + 1] !== undefined) {
          this.currentStep = this.article.fields.blocs[index + 1].sys.id
          this.setCurrentBlock(this.currentStep)
        }
        this.$nextTick(() => {
          this.$http.put('/capsules/' + this.newCapsule.id, {
            current_step: this.newCapsule.currentStep,
            is_completed: this.currentStep === this.article.fields.blocs.at(-1).sys.id,
          })
        })
      }
    },
    goBack(currentIndex) {
      if (this.article.fields.blocs[currentIndex - 1] !== undefined) {
        this.currentStep = this.article.fields.blocs[currentIndex - 1].sys.id
        this.setCurrentBlock(this.currentStep)
      }
      this.$nextTick(() => {
        this.$http.put('/capsules/' + this.newCapsule.id, {
          current_step: this.newCapsule.currentStep,
          is_completed: this.currentStep === 'end',
        })
      })
    },
  },
  created() {
    console.log(this.$route.params)
    if (this.$route.params.isSpotlight) {
      this.isSpotlight = this.$route.params.isSpotlight
    }

    let API_URL
    if (this.$route.query.env === 'preview') {
      API_URL = process.env.VUE_APP_CONTENT_PREVIEW_V2 || "kpk4sngn9Fb4jJRu53bagELNFV2_vP4QIL9z60VlU_Q"
      const client = Contentful.createClient({
        space: 'pj60a3cyj2i1',
        accessToken: API_URL,
        host: 'preview.contentful.com',
        environment: 'wow-v2.1',

      })
      client.getEntries({
        content_type: "capsule",
        include: 3,
        locale: this.$route.query.locale,
        'sys.id': this.$route.params.slug
      }).then((res) => {
        this.article = res.items[0]
        this.currentStep = this.article.fields.blocs[0].sys.id

        if (this.$route.query.locale === 'en') {
          this.$contentfulv2.getAsset(this.article.fields.cover.sys.id)
              .then((res) => {
                this.article.fields.cover.fields.file.url = res.fields.file.url
                this.isLoading = false
              })
        } else {
          this.isLoading = false
        }
      }).catch(console.error)

    } else {
      this.$contentfulv2.getEntries({
        content_type: "capsule",
        include: 3,
        locale: this.currentLocale,
        'fields.slug[in]': this.$route.params.slug
      }).then(res => {
        this.$http.get('/get-capsule-by-id', {
          params: {
            contentful_id: res.items[0].sys.id,
            user_id: this.currentUser.id
          }
        }).then(async response => {
          this.article = res.items[0]
          let blocks = this.article.fields.blocs.map(block => {
            return {
              id: block.sys.id
            }
          })
          // si la capsule existe en base
          if (response.data.length > 0) {
            this.dbData = response.data[0]
            if (this.currentLocale === 'en') {
              let resp = await this.$contentfulv2.getAsset(this.article.fields.cover.sys.id)
              this.article.fields.cover.fields = {
                ...this.article.fields.cover.fields,
                file: {url: resp.fields.file.url}
              }
            }
            this.$nextTick(() => {
              this.currentStep = this.dbData.current_step
              this.init({
                blocks: blocks,
                id: this.dbData.id,
                currentStep: this.dbData.current_step,
                contentful_id: this.dbData.contentful_id
              })
              if (this.currentStep !== this.article.fields.blocs[0].sys.id) {
                this.openRetryModal = true
              }
              this.isLoading = false
            })
          } else {
            // créer une nouvelle capsule
            if (this.currentLocale === 'en') {
              let resp = await this.$contentfulv2.getAsset(this.article.fields.cover.sys.id)
              this.article.fields.cover.fields = {
                ...this.article.fields.cover.fields,
                file: {url: resp.fields.file.url}
              }
            }
            let resp = await this.$http.post('/capsules', {
              user_id: this.currentUser.id,
              contentful_id: this.article.sys.id,
              current_step: this.newCapsule.currentStep,
            })
            this.init({
              blocks: blocks,
              id: resp.data.id,
              currentStep: this.article.fields.blocs[0].sys.id,
              contentful_id: this.article.sys.id
            })
            this.currentStep = this.article.fields.blocs[0].sys.id
            this.isLoading = false
          }
        })
      })
    }


  }
}
</script>


<style scoped lang="scss">

</style>
