<template>
  <div class="uppercase">
    {{ $t('capsule.imagine.separator') }}
  </div>
</template>

<script>
export default {
name: "Separator"
}
</script>

<style lang="scss" scoped>

div{
  display: flex;
  width:100%;
  position: relative;
  text-align:center;
  font-weight: var(--font-weight-bold);
  align-items: center;
  justify-content: center;


  &:before{
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left:0%;
    content:"";
    height:2px;
    background:var(--color-surface-divider);
    width: 45%;
  }
  &:after{
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    margin: auto;
    content:"";
    left:55%;
    height:2px;
    background:var(--color-surface-divider);
    width: 45%;
  }
}
</style>