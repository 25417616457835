import Storage from "../../class/storage";
import i18n from "../locales/index"
import {setLocale} from "@vee-validate/i18n";

const storage = new Storage("locale");

const state = storage.init({
    locale: 'fr'
});

const getters = {
    currentLocale: (state) => {
        return state.locale;
    },
};

const mutations = {

    TOGGLE_LOCALE: (state, locale) => {
        state.locale = locale
        i18n.locale = locale
       // setLocale(locale)
        storage.update(state)
    }
};

const actions = {

    toggleLocale(store, params) {
        store.commit("TOGGLE_LOCALE", params);
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};
