<template>
  <validation-provider mode="eager" v-slot="{ errors, classes }" tag="div" :rules="rules" class="input-container">
    <label
        v-if="label"
        :id="uid"
        :class="[{isFocused: ( inputVal !== '' || isFocused) }, {hasFocused: isFocused}]"
    >
      <div class="label">{{ label }}<span style="color: red" v-if="rules.includes('required')"> *</span></div>
    </label>
    <select v-model="inputVal" @change="onInputValueChange">
      <option v-for="d in data" :value="d" :key="d">{{ d }}</option>
    </select>
    <small v-if="errors[0] || helperMessage" class="helper" :class="classes">
      <svg
          v-if="errors[0]"
          xmlns="http://www.w3.org/2000/svg"
          height="14"
          viewBox="0 0 24 24"
          width="14"
          fill="var(--color-r100)"
      >
        <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
        />
      </svg>
      <span>{{ errors[0] || helperMessage }}</span>
    </small>
  </validation-provider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import uidMixin from "@/library/uid-mixin";

export default {
  name: "UniInput",
  mixins: [uidMixin],
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'value'
  },
  props: {
    value: {
      type: String | null,
      required: true
    },
    label: {
      type: String
    },
    size: {
      type: String
    },
    helperMessage: {
      type: String
    },
    rules: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      inputVal: this.value,
      isFocused: false
    };
  },
  methods: {
    onInputValueChange() {
      this.$emit("input", this.inputVal);
    },
    onFocusin() {
      this.isFocused = true
      this.$emit("focusin", true);
    },
    onBlur() {
      this.isFocused = false
      this.$emit("blur", false);
    }
  },
  watch: {
    value: function (val) {
      this.inputVal = val
    }
  }
};
</script>

<style scoped lang="scss">
.input-container {
  margin-bottom: var(--space-16);
}

select {
  width: 100%;
  margin: 0;
  position: relative;
  height: 56px;
  border-radius: var(--radius-default);
  transition: var(--transition-quick);
  //background: var(--color-surface-default);
  background: #F2F4F6;
  //border: 2px solid var(--color-surface-strong);
  border: 2px solid #DFE1E6;
  overflow: hidden;

  &:hover {
    border: 2px solid var(--color-surface-extra-strong);
  }

  &.hasFocused {
    //border: 2px solid var(--color-surface-strong);
    border: 2px solid #DFE1E6;
  }
}

label {
  width: 100%;
  margin: 0;
  position: relative;
  height: 56px;
  border-radius: var(--radius-default);
  transition: var(--transition-quick);
  //background: var(--color-surface-default);
  background: #F2F4F6;
  //border: 2px solid var(--color-surface-strong);
  border: 2px solid #DFE1E6;
  overflow: hidden;

  &:hover {
    border: 2px solid var(--color-surface-extra-strong);
  }

  &.hasFocused {
    //border: 2px solid var(--color-surface-strong);
    border: 2px solid #DFE1E6;
  }
}

.label {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 16px 16px;
  font-size: 1rem;
  display: inline;
  z-index: 1;
  border: none;
  transition: var(--transition-quick);
  color: var(--color-text-disabled);
}

label.isFocused {
  .label {
    padding: 2px 12px 0 12px;
    font-size: 0.8125rem;
    color: var(--color-text-disabled);
  }

  input {
    opacity: 1;
    height: 100%;
  }
}

.helper {
  margin: var(--space-4) 0 0 var(--space-4);
  display: inline-flex;
  align-items: center;
}

.xsmall {
  width: var(--space-80);
}

/* Errors and helper message */
input.invalid {
  border: 1px solid var(--color-error);
}

.helper.invalid {
  color: var(--color-error);
}

.helper > svg {
  margin-right: var(--space-4);
}
</style>
