<template>
<div class="help">
  <span>?</span>
</div>
</template>

<script>
export default {
  name: "Help"
}
</script>

<style scoped>
.help {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 2px solid var(--color-surface-strong);
  cursor: pointer;
}
</style>