import axios from 'axios'
import router from '../../router'
import store from '../../store'
import installGoogleAuthPlugin from "vue-google-oauth2/index";

let API_URL;

if (process.env.NODE_ENV === "development") {
    //API_URL = "http://localhost:3000"
    API_URL = "https://wownews-api-staging.herokuapp.com"
} else {
    API_URL = process.env.VUE_APP_API_ENDPOINT
}
let request = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-type': 'application/json;charset=UTF-8"',
        'Authorization': 'Bearer ' + localStorage.token
    }
})

request.interceptors.response.use((response) => {
    if (response.status === 401) {
        alert("You are not authorized");
    }
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        if (error.response.data.message.includes('expired')) {
            store.dispatch('sessionExpired', true)
            //store.dispatch('logout')
            router.push({name: 'Logout'})
        }
    }
    return error.response.data.message
});

export default request