<template>
  <div>
    <div class="head pt-8 pb-16">
      <div class="wrapper">
        <div class="head-infos">
          <span @click="$emit('go-back', index)" class="mr-6 cursor-pointer">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </span>
          <div class="progress" :style="{'--width': getProgress}"></div>
          <div class="p-2">
            <router-link :to="{name: 'Home'}">
              <sprite-icon name="home" size="big" color="white"/>
            </router-link>
          </div>
        </div>
        <div class="flex align-items-center space-between mt-6">
          <h2 class="white">{{ $t('capsuleV2.summary.subject') }}</h2>
          <div class="icon-container" style="--icon-container-color: #F6365C;">
            <img src="../../assets/img/search.png" alt="">
          </div>

        </div>
      </div>
    </div>
    <div class="bubble reverse" :style="{'--head-bg': block.fields.color === 'rouge' ? '#F6365C' : '#63C1A8'}">
      <div class="triangle" :style="{'--head-bg': block.fields.color === 'rouge' ? '#F6365C' : '#63C1A8'}"></div>
    </div>

    <div class="wrapper" style="margin-top: -32px;">
      <h2>{{ block.fields.title }}</h2>
      <div class="infos-container flex flex-col gap-5 mt-6 mb-10">
        <div v-for="info in block.fields.infos">
          <div class="info">
            <div class="flex flex-col" v-if="info.fields.chiffre">
              <div class="number-container">
                {{ info.fields.chiffre }}
              </div>
            </div>
            <div v-else>
              <div class="flex flex-col">
                <div class="rounded-xl overflow-hidden">
                  <img class="w-full h-full  object-cover"
                       :src="info.fields.image.fields.file.url +'?fit=fill&w=84&h=64&fm=webp'" alt="Phoo du sujet">
                </div>
              </div>

            </div>
            <p>{{ info.fields.description }}</p>
          </div>
          <p class="italic mt-1" style="font-size: 14px;" v-if="info.fields.source">source :
            {{ info.fields.source }}</p>
        </div>
      </div>
      <div class="content" ref="content" :class="{active: readMore}">
        <ApiRichRenderer :rich-text="block.fields.article.content" text-type="article" :timer="false"/>
      </div>
      <PrimaryButton v-if="!readMore" color="white" @click.native="readMore = true">{{
          $t('cards.readMore')
        }}
      </PrimaryButton>

      <div class="emotions mt-8">
        <h3>{{ $t("capsuleV2.subject.emotions.title") }}</h3>
        <div class="flex flex-col gap-1.5 mt-4">
          <div @click="toggleEmotion(emotion)" class="emotion flex gap-3 align-items-center"
               :class="{selected: selectedEmotions.find(el => el.id === index +1)}"
               v-for="(emotion, index) in emotions">
            <div class="emotion-icon">
              <img :src="emotion.icon" alt="">
            </div>
            <p>{{ emotion.text }}</p>
          </div>
        </div>
      </div>
      <div class="rounded-full overflow-hidden author-picture mt-8 cursor-pointer">
        <img width="70px" @click="openModal = true"
             :src="author.fields.profil.fields.file.url +'?fit=fill&w=70&h=70&fm=webp'"
             alt="Photo de l'auteur">
      </div>
    </div>
    <div class="bubble-down">
      <div class="triangle-down"></div>
    </div>
    <div class="footer">
      <div class="wrapper">
        {{ block.fields.authorDescription }}
        <primary-button class="mt-8" @click.native="nextStep">{{ block.fields.buttonText }}</primary-button>
      </div>
    </div>
    <uni-modal :is-visible="openModal" @close-modal="openModal = false"
               :has-footer-slot="false">
      <template v-slot="body">
        <div>
          <h2 class="text-align-center">{{ author.fields.firstname }} {{ author.fields.name }}</h2>
          <img class="rounded-full block mx-auto my-4" style="width: 80px;"
               :src="author.fields.profil.fields.file.url + '?fit=thumb'"
               :alt="author.fields.firstname">
          <api-rich-renderer :timer="false" text-type="article"
                             :rich-text="author.fields.description.content"></api-rich-renderer>
        </div>
      </template>
    </uni-modal>


  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import ApiRichRenderer from "@/components/ApiRichRenderer";
import SpriteIcon from "@/components/SpriteIcon";
import UniModal from "@/components/UniModal";
import {mapActions, mapGetters} from "vuex";


export default {
  name: "Subject",
  components: {UniModal, ApiRichRenderer, PrimaryButton, SpriteIcon},
  props: {
    block: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    author: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      readMore: false,
      emotions: [
        {
          id: 1,
          name: 'sad',
          icon: require('@/assets/illus/triste.png'),
          text: this.$t('capsuleV2.subject.emotions.sad')
        },
        {
          id: 2,
          name: 'worried',
          icon: require('@/assets/illus/inquiet.png'),
          text: this.$t('capsuleV2.subject.emotions.worry')
        },
        {
          id: 3,
          name: 'neutral',
          icon: require("@/assets/illus/ça-m'est-égal.png"),
          text: this.$t('capsuleV2.subject.emotions.neutral')
        },
        {
          id: 4,
          name: 'angry',
          icon: require('@/assets/illus/colère.png'),
          text: this.$t('capsuleV2.subject.emotions.angry')
        },
        {
          id: 5,
          name: 'nothing',
          icon: require("@/assets/illus/ça-m'est-égal.png"),
          text: this.$t('capsuleV2.subject.emotions.nothing')
        },
      ],
      selectedEmotions: [],
      openModal: false
    }
  },
  computed: {
    ...mapGetters(['newCapsule', 'currentUser', 'currentBlock']),
    getProgress() {
      return ((this.index + 1) / this.totalSteps) * 100 + '%';
    },
  },
  methods: {
    ...mapActions(['updateBlock']),
    nextStep() {
      this.updateBlock({
        id: this.block.sys.id,
        emotions: this.selectedEmotions
      })

      let emotions = []
      this.selectedEmotions.forEach(se => {
        emotions.push({
          capsule_id: this.newCapsule.contentful_id,
          user_id: this.currentUser.id,
          block_id: this.currentBlock.id,
          block_type: 'subject',
          name: se.name
        })
      })
      this.$http.post(`/capsules/${this.newCapsule.id}/emotions`, {
        emotion: emotions
      }).then(_ => {
        this.$emit('next-step', this.index)
      }).catch(err => console.log(err))
    },
    toggleEmotion(emotion) {
      if (this.selectedEmotions.find(el => el.id === emotion.id)) {
        this.selectedEmotions = this.selectedEmotions.filter(el => el.id !== emotion.id)
      } else {
        this.selectedEmotions.push(emotion)
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    if (this.currentBlock && this.currentBlock.emotions) {
      this.selectedEmotions = this.currentBlock.emotions
    }
  },
  mounted() {
    window.setTimeout(() => {
      this.readMore = this.$refs.content.getBoundingClientRect().height < 400
    }, 100)
  }
}
</script>

<style scoped lang="scss">
.head {
  --head-bg: #F6365C;
  background: var(--head-bg);

}

.bubble {
  background: white;
  position: relative;
  z-index: 10;
  height: 70px;
  border-radius: 100%;
  transform: translateY(-50%);

  &.reverse {
    transform: rotate(180deg) translateY(50%);
  }
}

.triangle {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 150px);
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid white;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  transform: rotate(10deg);
}

.bubble-down {
  background: #FCF2D6;
  position: relative;
  height: 70px;
  border-radius: 100%;
  transform: rotate(180deg) translateY(-40px);
  z-index: -1;
}

.triangle-down {
  position: absolute;
  bottom: -5px;
  right: calc(50% - 150px);
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid #FCF2D6;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}


.head-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.progress {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  height: 10px;
  width: 100%;
  position: relative;
}

.progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: 100%;
  border-radius: 12px;
  background: white;
}

.icon-container img {
  width: 48px;
}

.info {
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: center;
  grid-gap: 20px;
}

.number-container {
  background: #E6F6FC;
  border-radius: 12px;
  padding: 20px 0;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #09A6E6;
}

.content {
  position: relative;
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;


  &:before {
    z-index: 10;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 85%, white 100%);
  }

  &.active {
    max-height: none;

    &:before {
      display: none;
    }
  }
}


.emotion-icon {
  img {
    width: 32px;
  }
}

.emotion {
  padding: 12px;
  border: 2px solid #C4C8C9;
  position: relative;
  border-radius: 12px;
  cursor: pointer;

  &.selected {
    background: #E6F6FC;
    border: 2px solid #09A6E6;
    box-shadow: 0 2px 0 #09A6E6;
  }
}

.author-picture {
  width: 70px;
  height: 70px;
}

.footer {
  background: #FCF2D6;
  padding-bottom: 48px;
}

@include screen-m {
  .triangle-down {
    transform: translateY(10px);
  }
}
</style>