<template>
  <div class="load-more mb-4">
    <p v-if="totalProducts > 0" class="margin-bot-16">
      {{ productsNumber }} {{ $t('home.outOf') }} {{ totalProducts }}
    </p>
    <!--<div class="progress">
      <div class="bar" :style="'width:'+ productsNumber/totalProducts*100 +'%'"></div>
    </div>-->
    <primary-button :is-loading="isLoading" v-if="productsNumber !== totalProducts" @click.native="$emit('loadMore')">
      {{ $t('home.seeMore') }}
    </primary-button>
    <!--<primary-button v-else @click.native="scrollTop">Retour en haut</primary-button>-->
  </div>
</template>

<script>
import UniButton from "@/components/UniButton";
import PrimaryButton from "@/components/PrimaryButton";

export default {
  name: "LoadMore",
  components: {PrimaryButton, UniButton},
  props: {
    productsNumber: {
      type: Number,
      required: true
    },
    totalProducts: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.load-more {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>