<template>
  <div class="parent" ref="container">
    <smiley-circle v-for="(smiley, index ) in smileys[currentLocale] " :class="smileyClicked.id.includes(index)? 'selected': ''"
                   class="p-3 smiley cursor-pointer"
                   @click.native="clicked($event, index)" ref="div">
      <p class="emoji">{{ smiley.emoji }}</p>
      <p>{{ smiley.name }}</p>
    </smiley-circle>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SmileyCircle from "@/views/SmileyCircle";

export default {
  name: "SmileyWordChoice",
  components: {SmileyCircle},
  data() {
    return {
      id: null,
      smileyClicked: {id: null, person: ''}
    }

  },
  props: {
    smileys: {
      type: Object,
      required: true
    },
    person: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      capsules: "capsules",
      currentLocale: "currentLocale"
    }),
    checkStore() {
      return this.capsules.currentUsers[this.person].currentWord
    }
  },
  watch: {
    checkStore(val) {
      if (val) {
        this.person === 'child' ? this.smileyClicked.id = this.capsules.currentUsers.child.currentWord : this.person === "parent" ? this.smileyClicked.id = this.capsules.currentUsers.parent.currentWord : ''
      }
    }
  },
  /**
   * Si composant doit être réutilisé utiliser le store dans le composant parent
   */
  methods: {
    ...mapActions(['setCurrentSmileyKid', "setCurrentSmileyParent", "setCurrentWordKid", "setCurrentWordParent"]),
    /**
     * function qui permet de savoir quel element est cliqué et donc d'afficher le smiley
     * remplie le store et envoie un msg au parent
     * @param e
     * @param index
     */
    clicked(e, index) {
      this.smileyClicked.person = this.person
      if (this.smileyClicked.id.includes(index)) {
        let id = this.smileyClicked.id.indexOf(index)
        this.smileyClicked.id.splice(id, 1)
      } else {
        if (this.smileyClicked.id.length < 4 && !this.smileyClicked.id.includes(index)) {
          this.smileyClicked.id.push(index)
        }
      }
      this.smileyClicked.person === "child" ? this.setCurrentWordKid(this.smileyClicked.id) : this.smileyClicked.person === "parent" ? this.setCurrentWordParent(this.smileyClicked.id) : ''
    },

  },
  async mounted() {
    await this.$nextTick();
    let divs = document.querySelectorAll('.smiley')
    let parent = document.querySelector('.parent')
    const mainWidth = parent.clientWidth
    const mainHeight = parent.clientHeight

    this.$refs.div.forEach((box) => {
    });
  },
  updated() {
    this.person === 'child' ? this.smileyClicked.id = this.capsules.currentUsers.child.currentWord : this.person === "parent" ? this.smileyClicked.id = this.capsules.currentUsers.parent.currentWord : ''
  },
  created() {
    this.person === 'child' ? this.smileyClicked.id = this.capsules.currentUsers.child.currentWord : this.person === "parent" ? this.smileyClicked.id = this.capsules.currentUsers.parent.currentWord : ''
  },
}
</script>

<style lang="scss" scoped>
.emoji{
  font-size: 1.5rem!important;
  margin-bottom: 4px;
  display: none;
}
.parent {
  width: 100%;
  height: 600px;
  position: relative;
}

.smiley {

  &:nth-child(1) {
    left: 5%;
    top: -2%;
  }

  &:nth-child(2) {
    width: 142px;
    height: 142px;
    left: 60%;
    top: 0%;
  }

  &:nth-child(3) {
    width: 98px;
    height: 98px;
    left: 48%;
    top: 22%;
  }

  &:nth-child(4) {
    left: 68%;
    top: 41%;
  }

  &:nth-child(5) {
    left: 8%;
    top: 55%;
  }

  &:nth-child(6) {
    left: 3%;
    top: 20%;
  }

  &:nth-child(7) {
    width: 98px;
    height: 98px;
    left: 50%;
    top: 80%;
  }

  &:nth-child(8) {
    left: 3%;
    top: 78%;
  }

  &:nth-child(9) {
    width: 112px;
    height: 112px;
    left: 30%;
    top: 38%;
  }

  &:nth-child(10) {
    left: 50%;
    top: 59%;
  }

  &.selected {
    background: #E6F6FC;
    color: #09A6E6;
    border: 2px solid #09A6E6;
  }
}
</style>