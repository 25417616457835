<template>
  <button class="uppercase box-shadow-network">
    <img height="24" width="24" src="../assets/img/logo-US.png">
    <span v-if="!isSmall">Continuer avec United Schools</span>
    <span v-else>United Schools</span>
  </button>
</template>

<script>
export default {
  name: "UnitedSchoolsButton",
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  background: var(--color-surface-soft);
  width: 100%;
  border-radius: var(--radius-default);
  padding: var(--space-12);
  border: 2px solid #E5E0DF;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #51CF95;
}

svg, img {
  margin-right: var(--space-8);
}
</style>