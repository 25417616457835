<template>
  <div :style="cssVars">
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProgressBar",
  computed: {
    ...mapGetters({
      capsules: "capsules"
    }),
    /**
     * function qui récupère les datas de step de mon store et qui les returns pour les utiliser dans le css
     * @returns {{"--curr": number, "--steps": (number|*)}}
     * pour être totalement réutilisable penser à les faires passer en props pour ne pas être dépendant du store
     */
    cssVars() {
      return {
        '--steps': this.capsules.stepsLength,
        '--curr': this.capsules.currentStep.step
      }
    }
  },
}
</script>

<style lang="scss" scoped>

div {
  background: rgba(247, 243, 242, 0.4);
  border-radius: var(--radius-medium);
  height: 10px;
  width: 100%;

  &:after {
    content: "";
    display: block;
    background: var(--color-surface-soft);
    width: calc(var(--curr) / var(--steps) * 100%);
    max-width: 100%;
    height: 100%;
    border-radius: var(--radius-medium);

  }
}

</style>