<template>
  <div class="flex align-items-center parent-timer">
    <div :style="cssVars" class="progress mr-2">
    </div>
    <div class="flex align-items-center">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1">
        <path
            d="M10 4.99805C13.4533 4.99805 16.2526 7.79743 16.2526 11.2507C16.2526 14.7039 13.4533 17.5033 10 17.5033C6.54682 17.5033 3.74744 14.7039 3.74744 11.2507C3.74744 7.79743 6.54682 4.99805 10 4.99805"
            stroke="#565151" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.99997 7.49902V10.8337" stroke="#565151" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M8.33264 2.49692H11.6674" stroke="#565151" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M16.6695 5.83173L15.8358 4.99805" stroke="#565151" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
      {{ timeMin }}min
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "Timer",
  components: {Loader},
  data() {
    return {
      width: null,
      height: null,
      value: null,
      max: null,
      divTop: null,
      screenSize: null,
    }
  },
  props: {
    timeMin: {
      type: Number,
      required: true
    },
    barTop: {
      type: Number
    }
  },
  computed: {
    cssVars() {
      return {}
    },
    /**
     * créer une balise style
     * @returns {HTMLStyleElement}
     */
    style() {
      return document.createElement("style");
    },
    ...mapGetters({
      capsules: "capsules",
    }),
    /**
     * recup dans le store le boolean start
     * @returns {boolean}
     */
    checkStart() {
      return this.capsules.timer.start
    }
  },
  watch: {
    /**
     * watch le boolean si oui alors lance un reload de la page
     * @param e
     * @param b
     */
    checkStart(e, b) {
      if (e && !localStorage.getItem('reloaded')) {
        localStorage.setItem('reloaded', '1');
        location.reload();
      }
    }


  },
  methods: {
    ...mapActions(['setTopTimer', 'setScreenSizeTimer']),
    /**
     * methode qui permet de retourné la valeur d'auteur qui reste à défilé (partie de la page pas visible par l'utilisateur)
     * @returns {number}
     */
    getMax() {
      if (this.$route.path !== "/" && this.capsules.currentStep.contentType ==="article") {
        let div = this.$parent.$refs.text.getBoundingClientRect()
        return div.height - document.documentElement.clientHeight
      }
    },
    /**
     * function qui donne la valeur du top de la page
     * @returns {number}
     */
    getValue() {
      if (window.scrollY >= this.divTop) {
        let value = window.pageYOffset - this.divTop
        return value
      } else {
        return 0
      }

    },
    /**
     * function qui donne la taille du scroll
     * @returns {null}
     */
    getWidth() {
      this.value = this.getValue()
      this.max = this.getMax()
      this.width = (this.value / this.max) * 100;
      this.width = this.width + '%!important'
      return this.width


    },
    /**
     * function qui permet de donner le taille a la bar de progression
     * donc de récupèrer le computed et de l'insérer dans le dom et d'ajouter du css qui corréspond à nos calcus de width
     */
    setWidth() {
      document.head.appendChild(this.style);
      let sheet = this.style.sheet
      if (this.getWidth()) {
        sheet.addRule('.progress::after', 'width: ' + this.getWidth());
      }
    },
    /**
     * function qui appelle les fonctions nécessaires
     */
    handleScroll() {
      this.max = this.getMax()
      this.setWidth()
    },
    /**
     * function qui récupère la distance entre le haut de la page et la div
     *
     */
    setTop() {
      let div = this.$parent.$refs.text.getBoundingClientRect()
      let top = null
      if (this.capsules.timer.top === null || this.capsules.timer.top === 0) {
        top = div.top
      } else {
        top = this.capsules.timer.top
      }
      this.divTop = top
      this.setTopTimer(this.divTop)
    },

  },
  /**
   * function qui lance les functions plus haute au scroll
   */
  mounted() {
    this.$nextTick(() => {
      this.setTop()
      window.addEventListener('scroll', this.handleScroll);
    })
  },

}
</script>

<style lang="scss" scoped>
.progress {
  background: var(--color-surface-default);
  border-radius: var(--radius-medium);
  height: 10px;
  width: 100%;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    background: linear-gradient(
            180deg, #E62145 0%, #B81230 100%);
    height: 100%;
    width: 0%;
    border-radius: var(--radius-medium);
  }
}

</style>