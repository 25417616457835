const en = {
    layout: {
        welcome: "Welcome to",
    },
    home: {
        title: "Hi",
        capsuleOfTheWeek: "Here's this week's news",
        interest: "More like this",
        superpowers: "Your superpowers",
        noSuperpowers: "You haven’t started your collection yet. Complete at least one news adventure to start finding your superpowers!",
        noTheme: "You haven't selected a theme yet. You can add one by clicking ",
        noThemeHere: "here",
        freeCapsules: "Free news",
        discover: "Explore other topics",
        journalists: "WoW! News journalists",
        journalistLastNews: "Latest news by",
        children: "Kids",
        previousCapsules: "Earlier news",
        all: "See all",
        free: "Free",
        seeMore: "More",
        outOf: "out of",
        search: "Search : "
    },
    cards: {
        writtenBy: "By",
        today: "Today",
        yesterday: "Yesterday",
        thisWeek: "This week",
        readMore: "Read more",
        subscribe: "Subscribe",
    },

    profil: {
        title: "Who's there?",
        edit: "Edit",
    },
    child: {
        welcome: "Welcome to WoW! News",
        title: "Add a profile",
        placeholderChild: "Child's first name",
        placeholderParent: "Adult's name",
        error: "Please fill this field",
        next: "Next",
        selectImage: "Please select an image",
        who: "Who are you?",
        avatar: "Choose an avatar"
    },

    // ************** SETTINGS ***************
    settings: {
        superpowers: "My superpowers",
        profiles: "Other users",
        handleProfiles: "Manage users",
        help: "Help",
        helpMessage: "Need help or assistance? Contact us by email at: ",
        password: "Password",
        passwordIncorrect: "Wrong password",
        preferences: "Preferences",
        preferencesQuestion: "Would you like to get our newsletter?",
        preferencesDyslexia: "Enable the easy-reading font for dyslexia.",
        preferencesText: "Click here to receive a weekly dose of positive news to share at home or in class",
        preferencesChoose: "Choose your newsletter language",
        preferencesSave: "Save",
        preferencesDelete: "Delete",
        preferencesDeleteUnauthorized: "You cannot delete this user, you have to have one user at least.",
        handleSubscription: "Manage subscription",
        cancelSubscription: "Do you really want to delete your account ? This would cancel your subscription.",
        logout: "Logout",
        delete: "Delete my account",
        checkPassword: "Checking your password",
        checkPasswordSentence: "Please enter your password to confirm your action",
        validate: "Validate",
        deleteAccountTitle: "Delete your account",
        deleteAccountText: "If you can't delete your account, please contact us at: contact@wow-news.eu",
        updatePassword: {
            title: "Update your password",
            old: "Old password",
            new: "New password",
            confirm: "Confirm",
            noMatch: "Both password must be identical",
            supportTitle: "Password update",
            supportTitleLogout: "Don't panic !",
            supportTitleError: "An eror occured",
            supportText: "If you can't update your password, please contact us at: contact@wow-news.eu.",
            supportTextLogout: "A moment please, you're about to be logout.",
            supportTextError: "Please, contact technical support."
        },
        admin: {
            createAccount: {
                title: "Create account",
                email: "E-mail address",
                password: "Password",
                notifCb: "Send password change request email?",
                testCb: "Is it a guest account?",
                genereatePwdButton: "Generate random password"
            },
            stats: {
                type: "Type",
                startValue: "Start value",
                endValue: "End value",
                percentage: "Evolution",
                rate: "Rate",
            },
            updateState: "Update state"
        },
        mandatory: "Mandatory"
    },

    // ************ PASSWORD ******************* //

    password: {
        resetTitle: "Reset password",
        createTitle: "Create password"
    },

    // ************ CAPSULE ******************* //
    capsule: {
        free: "Free news",
        finished: "News capsule completed",
        memories: "Memories",
        superpowerFinished: "{name}'s superpower:",
        next: "Next",
        solutionTitle: "Ready to work out those brain cells?",
        actionTitle: "Action! Now it's your turn...",
        titles: {
            problem: "Problem?",
            solution: "Solution!",
            action: "Action"
        },
        closeQuestion: {
            yes: "Yes",
            no: "No",
            titleYes: "Great! You already know something about this",
            titleNo: "It's fine to not know everything",
            content: "Watch the video to learn more!",
            action: "Roll the video"
        },
        qcm: {
            title: "WoW! News has looked into solutions. Which of these is real?",
            false: "Not bad, but not right. Have another go!",
            true: "Good answer! That's the solution WoW! News reports on this week",
            falseAction: "Try again!",

        },
        content: {
            title: "Do you want to watch a video or read an article?",
            description: "You can also do both!",
            actions: {
                video: "Watch the video",
                post: "Read the article",
                both: "See both"
            }
        },
        solutionVideo: {
            title: "Let's watch the solution",
            help: "Don't forget to click for full-screen",
            helpParent: "Don't forget to click for full-screen",
            question: "Have you watched the video?",
            action: "Next",
        },
        emotions: {
            title: "So what do you think about this solution? Let's play together to describe our feelings!",
            conclusion: "Thanks team for this nice word cloud!",
            capsuleFinished: "The words in common from the world cloud:",
            child: {
                choose: ", choose 4 words that match how you feel when discovering the solution",
                hero: ", what would you say to "
            },
            parent: {
                choose: ", choose 4 words that match how you feel when discovering the solution",
                hero: ", what would you say to "
            }
        },
        problem: {
            title: "So that's the problem!",
            question: "Have you watched the video?",
            help: "Don't forget to watch in full screen",
            action: "Next"
        },
        action: {
            info: "Hearing about solutions helps us feel we can do something too. Any thoughts?",
            strength: {
                title: "Fortunately, we all have the power to make life better",
                content: "What we like doing and what we're good at - those are our superpowers. Together, we can change the world!",
                action: "Find my superpowers!"
            }
        },
        superpower: {
            action: "Add to my collection",
            congrats: "Fantastic. Enjoy using your superpower!"
        },
        imagine: {
            title: "It's your turn to create a superpower!",
            description: "film or record yourself describing your superpower to help us find solutions!",
            video: "shoot a short video",
            audio: "Record a voice message",
            idea: "The idea of ",
            separator: "Or",
            skip: "Skip this step",
            newVideo: "New video",
            newAudio: "New record",
            deleteVideo: "Delete video",
            deleteAudio: "delete record",
            consentVideo: "I wish to share my video with the Wow! tribe",
            consentAudio: "I wish to share my record with the Wow! tribe",
            conclusion: {
                title: "Your superpower is very interesting!",
                content: "You will find your record in your memories!"
            }
        },
        actions: {
            freeTrial: "Start trial offer",
            subscribe: "Subscribe",
            start: "Launch the news capsule",
            back: "Back home",
        },
        selectChild: {
            title: "Who goes there?",
            parent: "Parent",
            child: "Child",
            action: "Launch the news capsule",
            modale: {
                title: "Select child",
                action: "Explore the news with"
            }
        },
        continueModale: {
            title: "Would you like...",
            contentFinish: "The user has previously completed this news capsule, would you like to",
            contentKeep: "The user has already started this news capsule, would you like to",
            actions: {
                restart: "Run the capsule again",
                keep: "Continue where you left off",
                see: "Review the capsule"
            }
        },
        end: {
            title: "That's all for today. Have great week!",
            content: "It's been great spending time with you. Come back any time to check your memories on this news capsule's page.",
            action: "Finish"
        },
        limitedAccess: {
            title: "Limited access",
            text: ""
        }
    },
    capsuleV2: {
        summary: {
            subject: "The issue",
            solution: "The fix",
            action: "Let's go!",
            worldOfSolutionText: "This report is part of our series “A World of Solutions” - produced within the 2022-23 Solutions Journalism Accelerator programme of the European European Journalism Centre and the Solutions Journalism Network, supported by the Bill & Melinda Gates Foundation.",
            worldOfSolutionImg:"awos-en.png",
        },
        quizz: {
            check: "Check your answer",
            good: "Good answer!",
            bad: "Could be, but... no, that's not right"
        },
        subject: {
            emotions: {
                title: "How do you feel about it?",
                sad: "It makes me sad",
                worry: "It worries me",
                neutral: "I don't care",
                angry: "It makes me angry",
                nothing: "None of that",
            }
        },
        fix: {
            emotions: {
                title: "How do you feel about that?",
                impressed: "I'm impressed",
                amazed: "I'm amazed",
                happy: "It cheered me up",
                reassure: "I feel less worried",
                inspire: "I feel inspired",
                nothing: "Meh, I'm not that bothered",
            },
            printPdfText: "Learn more about the news",
            print: "Print",
        },
        superpowers: {
            title: "Choose your superpower",
            learnMore: "Learn more",
            add: "Add to my collection",
        },
        end: {
            think: "What did you think of this report?",
            enjoy: "Did you enjoy it?",
            learn: "Did you learn something?",
            inspire: "Did it inspire you to do something?",
            save: "Save your answer",
            printPdfText: "Print your news",
            print: "Print",
            retry: "Start again",
            backHome: "Back home",
            otherSubjects: "More on similar issues",
            savedRating: "Your rating has been saved!"
        }
    },
    // ****************** SIGN IN ************
    signIn: {
        title: "Sign in",
        action: "Log in",
        separator: "Or",
        notAccount: "No account yet?",
        createAction: "Create my account",
        forgotPassword: "Forgot password?",
        errors: {
            sessionExpired: {
                title: "Timed out",
                content: "Connection timed out. Please log in again."
            },
            badCredentials: {
                title: "Incorrect log-in",
                content: "Please try again"
            },
            google: {
                title: "Error",
                content: "No user linked to this Google ID. Please create an account."
            },
            facebook: {
                title: "Error",
                content: "No user linked to this Facebook ID. Please create an account."
            },
            unitedSchools: {
                title: "Error",
                unknown: "This user has no United Schools account.",
                badCredentials: "Bad credentials.",
                emailTaken: "Email already taken without United Schools, contact the support.",
                idTaken: "An account already exists with your United Schools credentials."
            }
        },
        unitedSchools: {
            login: "Enter you United Schools credentials",
            username: "Username",
            password: "Password"
        }
    },

    // ************* FORGOT PASSWORD ************
    forgotPassword: {
        title: "Forgot password",
        email: "Adresse e-mail",
        action: "Reset password",
        info: {
            title: "E-mail send",
            content: "A reset password e-mail has been sent"
        }
    },

    // ***************** SIGNUP ¨*****************
    signUp: {
        title: "Create my account",
        discover: "You want to discover WoW! at school or at home? Sign up for free and discover all the available news.",
        howItWorks: "How does it work?",
        howItWorksVideo: "https://www.youtube.com/shorts/-1t3klAi_Vo",
        teacher: "I'm a teacher",
        action: "Create my account",
        separator: "Or",
        alreadyAccount: "Already subscribed?",
        alreadyAccountAction: "Log in",
        confirmAccount: "Please confirm your account. An e-mail has been sent to: ",
        email: {
            title: "Check your emails",
            content: "To complete the creation of your account, please check your emails and click on the link we sent you",
            spam: "Check your spam folder"
        },
        errors: {
            google: {
                title: "Error",
                content: "This Google account is already linked. Please log in."
            },
            facebook: {
                title: "Error",
                content: "This Facebook account is already linked. Please log in."
            },
            email: {
                title: "Error",
                content: "An account already exists for this e-mail. Please log in. "
            }
        }
    },
    //********* PRICING ***********

    pricing: {
        title: "Bill monthly or annually",
        description: "Explore a new news capsule every week. Unlimited access to all previous capsules, ad-free.By taking yearly subscription, save 25%.",
        action: "Start my trial offer",
        month: {
            title: "Monthly billing. Cancel any time.",
            price: "{price} per month",
            info: "(possible to suspend the subscription at any time)"

        },
        year: {
            title: "Annual billing.",
            price: "59,99€/year ({monthPrice}€ per month, {percent}% discount)",
            info: "(subscription for 1 year)",
            promotion: "SPME offer (-50%)\n29.99€ for one year! (2.50€/month)\nPromotional code: <i>SPME2023</i>"
        }
    },

    thanks: {
        title: "Hello {name}! 🎉",
        content: "You now have access to all WoW! News topics.</br>To discover the experience with a child, click on the button below and choose your first news topic.",
        action: "Take me back home",
        actionArticle: "Take me to the capsule"
    },
    onboard: {
        title: "My name's SuperWoW!",
        p1: "Along with our journalists, I guide kids through each news adventure within the WoW! News app.",
        p2: "Each week, we take them through a solution to an issue in the news. It lasts about 15 minutes and they can do it alone or with an adult.",
        p3: "To discover all our reports, simply create a child’s profile here.",
        p4: "(and you can create as many as you like in your profil)",
        steps: {
            video: "https://youtu.be/ub2a5XgDJsk",
            howItWorks: "How it works",
            freeContent: "1 free news each month",
            beforeYouStart: "Before you start",
            freeContentText: "This symbol means the report is open access. You don't need a paid subscription to view it.",
            action: "Let's go!",
            locale: {
                title: "Communication",
                content: "In which langage do you want us to communicate with you ?",
                newsletter: "Subscribe to the newsletter"
            }
        }
    },
    validation: {
        placeholders: {
            childName: "Child's first name",
            age: "Age",
            ageHelp: "The age helps us adapt our content to your child.",
            theme: "What are they most interested in?",
            optional: "Optional",
            email: "Email address",
            password: "Password",
            confirmPassword: "Confirm Password"
        },
        errors: {
            required: "This field is required",
            imageRequired: "Please select an image",
            email: "This email address is not valid",
            password: "Must contain at least 6 characters",
            passwordConfirm: "Must be same as password",
            emailExists: "This email address is already registered.",
            emailOk: "This email address is not registered"

        }

    }
}

export default en