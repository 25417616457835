<template>
  <div class="main-div wrapper">
    <div class=" mb-4 flex space-between align-items-center">
      <h2>{{ $t('settings.superpowers') }}</h2>
      <router-link :to="{name: 'KidPowers'}" class="big-link">{{ $t('home.all') }}</router-link>
    </div>

    <Loader :color="true" v-if="isLoadingPowers"/>
    <div v-else class="powers-container">
      <div v-if="powers.length === 0">{{ $t('home.noSuperpowers') }}
      </div>
      <div class="powers" v-else>
        <router-link
            :to="{name: 'kidPowerView', params:{name: currentUser.firstname, id: currentUser.id, powerId:power.sys.id}}"
            v-for="power in powers">
          <div v-if="!power.fields.new" class="badge">
            <img class="pentagone" v-if="!power.fields.new" src="../assets/img/pentagone.png" alt="Polygone">
            <img class="power" :src="power.fields.icon.fields.file.url" alt="Pouvoir">
          </div>
          <div v-else>
            <img class="power" :src="power.fields.superPowerCategory.fields.picto.fields.file.url" alt="Pouvoir">
          </div>
        </router-link>

      </div>
    </div>

    <div class="divider my-8"></div>
    <div class="mb-8 flex space-between align-items-center">
      <h2>{{ $t('settings.profiles') }}</h2>
      <router-link :to="{name: 'profile'}" class="big-link">{{ $t('settings.handleProfiles') }}</router-link>
    </div>
    <Loader :color="true" v-if="isLoadingKids"/>
    <div v-else class="grid grid-cols-2 gap-y-8 gap-x-2">
      <div class="flex flex-col align-items-center" v-for="kid in kids" :key="kid.id">
        <div @click="goToHome(kid)" class="kid-start-avatar rounded-full overflow-hidden selected cursor-pointer">
          <img :src="kid.custom_image ? kid.attachment_url : kid.avatar" style="width: 80px;" alt="">
        </div>

        <p class="display-3 mt-3">{{ kid.firstname || "-" }}</p>
      </div>
      <div class="justify-self-center">
        <router-link :to="{name: 'SignInWelcome', params: {type: 'child', from: 'account'}}" class="add-kid">
          <svg width="" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="39.2593" fill="white" fill-opacity="0.3" stroke="#EEB706"
                    stroke-width="1.48148"
                    stroke-dasharray="3.7 3.7"/>
            <path
                d="M33.2255 43.1955C32.8166 43.1955 32.4611 43.0444 32.1588 42.7422C31.8566 42.4399 31.7055 42.0844 31.7055 41.6755V41.3022C31.7055 40.8933 31.8566 40.5377 32.1588 40.2355C32.4611 39.9333 32.8166 39.7822 33.2255 39.7822H37.5722C37.7322 39.7822 37.8122 39.7022 37.8122 39.5422V35.2755C37.8122 34.8666 37.9544 34.5111 38.2388 34.2088C38.5411 33.9066 38.8966 33.7555 39.3055 33.7555H39.9455C40.3544 33.7555 40.7011 33.9066 40.9855 34.2088C41.2877 34.5111 41.4388 34.8666 41.4388 35.2755V39.5422C41.4388 39.7022 41.5188 39.7822 41.6788 39.7822H46.0255C46.4344 39.7822 46.79 39.9333 47.0922 40.2355C47.3944 40.5377 47.5455 40.8933 47.5455 41.3022V41.6755C47.5455 42.0844 47.3944 42.4399 47.0922 42.7422C46.79 43.0444 46.4344 43.1955 46.0255 43.1955H41.6788C41.5188 43.1955 41.4388 43.2755 41.4388 43.4355V47.7022C41.4388 48.1111 41.2877 48.4666 40.9855 48.7688C40.7011 49.0711 40.3544 49.2222 39.9455 49.2222H39.3055C38.8966 49.2222 38.5411 49.0711 38.2388 48.7688C37.9544 48.4666 37.8122 48.1111 37.8122 47.7022V43.4355C37.8122 43.2755 37.7322 43.1955 37.5722 43.1955H33.2255Z"
                fill="#EEB706"/>
          </svg>
        </router-link>
      </div>
    </div>


    <div class="divider my-8"></div>
    <h2 class="mb-6">{{ $t('settings.help') }}</h2>
    <p>{{ $t('settings.helpMessage') }} <a class="underline" target="_blank" href="mailto:hello@wow-news.eu">hello@wow-news.eu</a>
    </p>


    <div class="divider my-8"></div>
    <h2 class="mb-6">{{ $t('settings.preferences') }}</h2>

    <uni-checkbox value="dyslexia" v-model="dyslexia" :label="$t('settings.preferencesDyslexia')"/>

    <AskNewsletter :locale="currentFamily.locale" :newsletter="currentFamily.newsletter" @handleChange="changeInfos"/>
    <primary-button class="mt-2" :clicked="updateInfosloading" @click.native="updateInfos">
      {{ $t('settings.preferencesSave') }}
    </primary-button>

    <div class="divider my-8"></div>
    <h2 class="mb-6">{{ $t('settings.appSettings') }}</h2>
    <primary-button @click.native="openPasswordModalFunc(false)" :disabled="loadingPortal"
                    v-if="currentFamily.isSubscribed()">{{
        $t('settings.handleSubscription')
      }}
    </primary-button>
    <div class="center pb-4 mt-8">
      <router-link class="link-big mt-4" :to="{name: 'Logout'}">{{ $t('settings.logout') }}</router-link>
    </div>
    <primary-button class="link-big mt-4" style="color: inherit; background: inherit; box-shadow: inherit;"
                    @click.native="openPasswordModalFunc(true)">{{ $t('settings.delete') }}
    </primary-button>

    <primary-button class="link-big mt-4" style="color: inherit; background: inherit; box-shadow: inherit;"
                    @click.native="openUpdatePasswordModal = true">{{ $t('settings.updatePassword.title') }}
    </primary-button>

    <div class="divider my-8" v-if="currentFamily.admin"></div>
    <section class="section-accounts" v-if="currentFamily.admin">
      <p class="display-2 mb-4">Administration</p>
      <router-link class="button flex justify-center align-items-center" :to="{name: 'admin.home'}">Accéder à
        l'administration
      </router-link>
    </section>

    <div class="divider my-8"></div>

    <uni-modal :is-visible="openPasswordModal" @close-modal="openPasswordModal = false"
               :has-footer-slot="false" :title="$t('settings.checkPassword')">
      <template slot="body">
        <div>
          <p class="mb-4">{{ $t('settings.checkPasswordSentence') }}</p>
          <uni-input-password :label="$t('settings.password')" v-model="password"></uni-input-password>
          <p class="mt-1" v-if="errorMessage">{{ $t('settings.passwordIncorrect') }}</p>
          <primary-button :clicked="passwordLoading" @click.native="validatePassword" class="mt-8">
            {{ $t('settings.validate') }}
          </primary-button>
          <UniSectionMessage v-if="!currentFamily.teacher" type="error" :title="$t('settings.deleteAccountTitle')"
                             :description="$t('settings.deleteAccountText')"></UniSectionMessage>
        </div>
      </template>
    </uni-modal>

    <!--Update password modal-->
    <uni-modal :is-visible="openUpdatePasswordModal" @close-modal="openUpdatePasswordModal = false"
               :has-footer-slot="false" :title="$t('settings.updatePassword.title')">
      <template slot="body">
        <NewPassword :section-message="newPassword.modal"
                     :password-update-div-seen="newPassword.passwordUpdateDivSeen"
                     @submit="handlePasswordUpdate"
                     :processing="newPassword.clicked"/>

      </template>
    </uni-modal>

  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {mapActions, mapGetters} from "vuex";
import PrimaryButton from "@/components/PrimaryButton";
import AccountGrid from "@/views/AccountGrid";
import UniModal from "@/components/UniModal";
import UniInput from "@/components/UniInput";
import UniInputPassword from "@/components/Password/UniInputPassword.vue";
import MD5 from "crypto-js/md5";
import UniSectionMessage from "@/components/UniSectionMessage";
import UniCheckbox from "@/components/UniCheckbox.vue";
import i18n from '@/locales/index.js'
import AskNewsletter from "@/components/Locale/AskNewsletter.vue";
import AskLocale from "@/components/Locale/AskLocale.vue";
import NewPassword from "@/components/Password/NewPassword";
import UniButton from "@/components/UniButton.vue";


export default {
  name: "newAccount",
  components: {
    UniButton,
    AskNewsletter,
    UniCheckbox,
    UniSectionMessage, AskLocale, UniInputPassword, UniInput, UniModal, AccountGrid, PrimaryButton, Loader,
    NewPassword
  },
  computed: {
    ...mapGetters(['currentUser', "currentFamily", "familyState", "currentLocale"])
  },
  data() {
    return {
      isLoadingPowers: true,
      isLoadingKids: true,
      loadingPortal: false,
      fakeAccountsLoading: true,
      openAccountModal: false,
      openPasswordModal: false,
      openUpdatePasswordModal: false,
      passwordForDelete: false,
      passwordLoading: false,
      errorMessage: false,
      errorMessageConfirm: '',
      updateInfosloading: false,
      locale: 'fr',
      newsletter: false,
      dyslexia: false,
      password: '',
      newPassword: {
        clicked: false,
        old: '',
        new: '',
        check: '',
        passwordUpdateDivSeen: true,
        modal: {
          text: i18n.t('settings.updatePassword.supportText'),
          title: i18n.t('settings.updatePassword.supportTitle'),
          type: "warning"
        }
      },
      powers: [],
      kids: [],
      newAccount: {
        email: '',
        password: '',
        localRb: 'fr',
        notifCb: true,
        testCb: true
      },
      testPwd: ''
    }
  },
  methods: {
    ...mapActions(['setUsers', 'addCurrentUser']),
    changeInfos(args) {
      this.locale = args.locale
      this.newsletter = args.newsletter
    },

    updateInfos() {
      this.updateInfosloading = true
      this.$http.put(`families/${this.currentFamily.id}?locale=${this.locale}&newsletter=${this.newsletter}`, {
        family: {
          locale: this.locale,
          newsletter: this.newsletter,
          dyslexia: this.dyslexia
        }
      }).then((resp) => {
        localStorage.token = resp.data.auth_token
        this.$store.dispatch('login')

        let aeEvent = {};
        /* {{KEY}} must be updated for each rule */
        aeEvent.key = '61f3bd0566d3891597f0e456-61f3a7a166d3891597f0e3e7';
        aeEvent.Conversion = {};
        /* Values below must be updated */
        aeEvent.Conversion.id = MD5(this.currentFamily.email).toString();
        aeEvent.Conversion.customer = this.currentFamily.id;
        aeEvent.Conversion.currency = 'EUR';
        ('AeTracker' in window)
            ? AeTracker.sendConversion(aeEvent)
            : (window.AE = window.AE || []).push(aeEvent);
        this.updateInfosloading = false
        // window.location.reload()
      })

    },
    cancelSubscription() {
      this.passwordLoading = true
      if (confirm(i18n.t('settings.cancelSubscription'))) {
        this.$http.get('/cancel-subscription')
            .then(resp => {
              if (resp.status === 200) {
                this.passwordLoading = false
                this.openPasswordModal = false
                this.$store.dispatch('logout')
                this.$router.push({name: 'Home'})
              }
            })
      }

    },
    goToHome(kid) {
      this.addCurrentUser(kid)
      window.location.reload()
      this.$nextTick(() => {
        this.$router.push({name: "Home"});
      })
    },
    openCustomerPortal() {
      this.passwordLoading = true
      this.$http.post('create-portal-session', {
        base_url: process.env.NODE_ENV === "development" ? "localhost" : window.location.origin
      })
          .then(res => {
            let session = res.data.session
            this.passwordLoading = false
            this.openPasswordModal = false
            window.location.href = session.url
          })
    },
    validatePassword() {
      this.passwordLoading = true
      this.$http.post('check-password', {family: {password: this.password}})
          .then(resp => {
            if (resp.data.status === 200) {
              this.errorMessage = false
              if (this.passwordForDelete) {
                this.cancelSubscription()
              } else {
                this.openCustomerPortal()
              }

            } else {
              this.errorMessage = true
              this.passwordLoading = false
            }
          })
    },
    handlePasswordUpdate(newPassword) {
      let currentUser = {
        id: this.currentFamily.id,
        password: newPassword,
        password_confirmation: newPassword,
      }
      this.$http.post('/change-password', {user: {...currentUser}})
          .then(resp => {
            if (resp.status === 200 && resp.data.auth_token) {
              this.newPassword.modal.type = "success"
              this.newPassword.modal.title = i18n.t('settings.updatePassword.supportTitleLogout')
              this.newPassword.modal.tessage = i18n.t('settings.updatePassword.supportTextLogout')
              this.newPassword.passwordUpdateDivSeen = false
              setTimeout(_ => {
                localStorage.clear()
                this.$router.push({name: 'Login', params: {newPwd: true}})
              }, 5000)
            } else {
              this.newPassword.modal.type = "error"
              this.newPassword.modal.title = i18n.t('settings.updatePassword.supportTitleError')
              this.newPassword.modal.message = i18n.t('settings.updatePassword.supportTextError')
            }
          })
    },
    openPasswordModalFunc(bool) {
      if (!this.currentFamily.teacher && !bool) {
        this.openCustomerPortal()
      } else {
        this.passwordForDelete = bool
        this.errorMessage = false
        this.openPasswordModal = true
      }
    },
  },
  created() {
    this.$http.get('/users/' + this.currentUser.id + '/super_powers').then(resp => {
      let powersId = []
      let comma = null
      if (resp.data) {
        resp.data.forEach(el => powersId.push(el.contentful_id))
        let string = ""
        powersId.length > 1 ? comma = true : comma = false
        powersId.forEach(el => string += el + (comma ? ',' : ''))
        this.$contentfulv2.getEntries({
          content_type: "superPower",
          locale: this.currentLocale,
          limit: 4,
          'sys.id[in]': string,
          include: 4,
        }).then(res => {
          this.powers = res.items.map(item => {
            if (Object.keys(item.fields).length) {
              return item
            }
          }).filter(el => el !== undefined)
          this.isLoadingPowers = false
        });
      }
    })
    this.kids = this.familyState.users.filter(el => el.user_type === 'child' && el.id !== this.currentUser.id)
    this.isLoadingKids = false

    this.locale = this.currentFamily.locale
    this.newsletter = this.currentFamily.newsletter
    this.dyslexia = this.currentFamily.dyslexia
  }
}

</script>

<style scoped lang="scss">
.kid-start-avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;

  > svg, img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.big-link {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #C4C8C9;
}

.link-big {
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.powers-container {
  > .powers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;

    > a {
      display: flex;
      justify-content: center;

      > .badge {
        width: 72px;
        height: 72px;
        position: relative;

        img {
          width: 100%;
        }

        .power {
          width: 36px;
          height: auto;
          position: absolute;
          top: calc(50% - 18px);
          left: calc(50% - 18px);
        }
      }


    }
  }
}

.center {
  display: flex;
  justify-content: center;
}
</style>