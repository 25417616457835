<!--suppress CssRedundantUnit -->
<template>
  <div class="parent">
    <div class="head py-8">
      <div class="wrapper">
        <div class="buttons">
          <div class="back-button">
            <router-link to="/" class="">
              <sprite-icon name="back-arrow" size="big" color="#1C2326" fill="#1C2326"/>
            </router-link>
          </div>
          <div class="logout-button">
            <router-link :to="{name: 'Logout'}" :alt="$t('settings.logout')">
              <sprite-icon name="logout" size="big" color="#1C2326" fill="#1C2326"/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="body relative">
      <img class="avatar object-cover overflow-hidden object-cover rounded-full" style="width: 80px; height: 80px;"
           :src="currentUser.attachment_url || currentUser.avatar">
      <h1 class="text-align-center pt-12 pb-8">{{ currentUser.firstname }}</h1>
      <slot></slot>
    </div>

  </div>
</template>

<script>
import PrimaryButton from "../components/PrimaryButton";
import {mapGetters} from "vuex";
import SpriteIcon from "@/components/SpriteIcon.vue";

export default {
  name: "account",
  components: {SpriteIcon, PrimaryButton},
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.parent {
  background: #FFF7F0;
}

.body {
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 24px 24px 0px 0px;
}

.buttons {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  height: 24px;

  > * {
    float: left;
    margin: auto;
  }

  > *:last-child {
    float: right;
  }
}

.avatar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.powers-container {
  > .powers {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    align-items: center;

    > a {
      width: 52px;
      height: 52px;
      position: relative;

      .power {
        width: 22px;
        height: auto;
        position: absolute;
        top: calc(50% - 11px);
        left: calc(50% - 11px);
      }
    }
  }
}

</style>