<template>
  <div class="main-div">
    <header class="flex space-between align-items-center">
      <div class="back-button">
        <router-link to="/" class="mr-6">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="#1C2326" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81836 18H28.182" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 10.7273L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 25.2727L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
      <uni-input
          v-if="isResearch"
          v-model="searchText"
          type="text"
          class="col-6"
      />
      <div class="avatar rounded-full overflow-hidden">
        <img :src="currentUser.attachment_url || currentUser.avatar" alt="">
      </div>
    </header>
    <div class="wrapper">
      <h1 v-if="isResearch" class="mt-2">{{ title + searchText }}</h1>
      <h1 v-else class="mt-2">{{ title }}</h1>
      <!--      <router-link tag="div" v-for="capsule in capsules" class="cursor-pointer"-->
      <!--                   :to="{name: 'Capsule', params:{slug: capsule.fields.slug, from:'home'}}">-->
      <!--        <card-mini-old-caps-skeleton v-for="i in 3" class="pb-10" v-if="isLoadingLittleCard || capsules.length === 0"/>-->
      <!--        <card-mini-old-caps v-for="capsule in capsules" class="pb-10 mt-6"-->
      <!--                            v-if="!isLoadingLittleCard && capsules.length > 0" :capsule="capsule"/>-->
      <!--      </router-link>-->
      <card-mini-old-caps-skeleton class="pb-10" v-if="isLoadingLittleCard || filteredCapsules.length === 0"/>
      <div v-else>
        <div v-for="capsule in filteredCapsules">
          <router-link v-if="currentFamily.isFullAccess() || capsule.fields.freeCapsule" tag="div"
                       class="cursor-pointer"
                       :to="{name: capsule.fields.blocs ? 'article' : 'Capsule', params:{slug: capsule.fields.slug, from:'home'}}">

            <card-mini-old-caps class="pb-10 mt-6" :capsule="capsule"/>

          </router-link>

          <card-mini-old-caps v-else @click.native="openModal(capsule)" class="pb-10 mt-6" :capsule="capsule"/>
        </div>

        <load-more v-if="!isResearch" :products-number="filteredCapsules.length"
                   :total-products="total"
                   @loadMore="loadMore"/>
      </div>
    </div>
    <handle-subscription-modal :capsule-id="capsuleId" :open-modal="modalSub" @close-modal="modalSub = false"/>
  </div>

</template>

<script>
import CardMiniOldCapsSkeleton from "@/components/CardMiniOldCapsSkeleton";
import {mapGetters} from "vuex";
import CardMiniOldCaps from "@/components/CardMiniOldCaps";
import LoadMore from "@/components/LoadMore";
import UniModal from "@/components/UniModal";
import PrimaryButton from "@/components/PrimaryButton";
import HandleSubscriptionModal from "@/components/handleSubscriptionModal";
import UniInput from "@/components/UniInput.vue";
import i18n from '../locales/index'

export default {
  name: "CapsulesFromCategory",
  components: {
    HandleSubscriptionModal,
    PrimaryButton,
    UniModal,
    LoadMore,
    CardMiniOldCaps,
    CardMiniOldCapsSkeleton,
    UniInput
  },
  computed: {
    ...mapGetters(['currentUser', 'currentFamily', "currentLocale"]),
    filteredCapsules() {
      if (this.searchText.length !== 0) {
        return this.capsules.filter(el => el.fields.capsuleTags && el.fields.capsuleTags.some(tag => tag.fields.name.toLowerCase().includes(this.searchText.toLowerCase())
                || tag.fields.name.toUpperCase().includes(this.searchText.toUpperCase())) ||
            el.fields.author && (el.fields.author.fields.firstname.toLowerCase().includes(this.searchText.toLowerCase())
                || el.fields.author.fields.firstname.toUpperCase().includes(this.searchText.toUpperCase())
                || el.fields.author.fields.name.toLowerCase().includes(this.searchText.toLowerCase())
                || el.fields.author.fields.name.toUpperCase().includes(this.searchText.toUpperCase())) ||
            el.fields.title && (el.fields.title.toLowerCase().includes(this.searchText.toLowerCase())
                || el.fields.title.toUpperCase().includes(this.searchText.toUpperCase())) ||
            (el.fields.blocs && el.fields.blocs.length > 0
                && ((el.fields.blocs[0].fields.intro && el.fields.blocs[0].fields.intro.toLowerCase().includes(this.searchText.toLowerCase())
                        || el.fields.blocs[0].fields.intro.toUpperCase().includes(this.searchText.toUpperCase()))
                    || (el.fields.blocs[0].fields.solution && el.fields.blocs[0] && el.fields.blocs[0].fields.solution.toLowerCase().includes(this.searchText.toLowerCase())
                        || el.fields.blocs[0].fields.solution.toUpperCase().includes(this.searchText.toUpperCase()))
                    || (el.fields.blocs[0].fields.subject && el.fields.blocs[0] && el.fields.blocs[0].fields.subject.toLowerCase().includes(this.searchText.toLowerCase())
                        || el.fields.blocs[0].fields.subject.toUpperCase().includes(this.searchText.toUpperCase())))))
      }
      return this.capsules
    }
  },
  data() {
    return {
      isLoadingLittleCard: true,
      title: null,
      capsules: [],
      filters: null,
      total: null,
      modalSub: false,
      capsuleId: null,
      environment: "wow-v2.1",

      isResearch: false,
      searchText: "",
    }
  },
  methods: {
    openModal(capsule) {
      this.modalSub = true;
      this.capsuleId = capsule.sys.id
    },
    paramsToObject(entries) {
      const result = {}
      for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
      }
      return result;
    },
    loadMore() {
      this.filters.skip = this.capsules.length
      this.$contentfulv2.getEntries(this.filters).then(res => {
        this.capsules = this.capsules.concat(res.items)
      })
    },
  },
  created() {
    this.title = this.$route.query.title;
    this.isResearch = this.title.includes(i18n.t("home.search"));
    if (this.$route.query.filters) {
      let entries = new URLSearchParams(this.$route.query.filters).entries();
      this.filters = this.paramsToObject(entries);
      this.$contentfulv2.getEntries(this.filters)
          .then(res => {
            let data = res.items.map(item => {
              if (item.fields.title) {
                return item
              }
            }).filter(el => el !== undefined)
            this.total = res.total
            this.capsules = data;

            if (this.currentLocale === 'en') {
              let promises = []
              this.capsules.forEach(capsule => {
                promises.push(this.$contentfulv2.getAsset(capsule.fields.cover.sys.id))
              })
              Promise.all(promises).then(res => {
                res.forEach((asset, index) => {
                  this.capsules[index].fields.cover.fields.file = asset.fields.file
                })
                this.isLoadingLittleCard = false;
              })
            } else {
              this.$nextTick(() => {
                this.isLoadingLittleCard = false;
              })
            }
          })
    } else {
      this.title = "Sujets récents"
      this.filters = {
        skip: 0,
        limit: 10,
        include: 2,
        content_type: 'capsule',
        locale: this.currentLocale,
        "fields.title[exists]": true,
        "fields.cover[exists]": true,
        order: '-sys.createdAt',
      }
    }
  }
}
</script>

<style scoped lang="scss">
header {
  padding: 16px;
  background: transparent;
  max-width: 700px;
  margin: 0 auto;

  .avatar {
    width: 36px;
    height: 36px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.logo-container {
  width: 108px;
  height: 49px;
  background: transparent;

  > img, svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>