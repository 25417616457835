<template>
  <div class="main-container rounded-t-3xl wrap auth h-full w-full">
    <locale-switcher-on-right/>
    <h2 class="text-align-center mb-8">{{ $t('signIn.title') }}</h2>
    <validation-observer tag="form" ref="formInput" v-slot="{valid}" @submit.prevent="loginUser">
      <uni-input
          :label="$t('validation.placeholders.email')"
          id="input-test"
          type="email"
          :placeholder="$t('validation.placeholders.email')"
          v-model="user.email"
          rules="required|email"
      />
      <uni-input-password
          :label="$t('validation.placeholders.password')"
          id="input-connexion-password"
          :placeholder="$t('validation.placeholders.password')"
          v-model="user.password"
          :helperMessage="$t('validation.errors.password')"
          rules="required|passwordTest"/>
      <UniSectionMessage v-if="familyState.sessionExpired" type="warning"
                         :title="$t('signIn.errors.sessionExpired.title')"
                         :description="$t('signIn.errors.sessionExpired.content')"/>
      <uni-section-message v-if="badCredentials" type="warning" :title="$t('signIn.errors.badCredentials.title')"
                           :description="$t('signIn.errors.badCredentials.title')"/>
      <UniSectionMessage v-if="googleNotExists" type="warning" :title="$t('signIn.errors.google.title')"
                         :description="$t('signIn.errors.google.content')"/>
      <UniSectionMessage v-if="facebookNotExists" type="warning" :title="$t('signIn.errors.facebook.title')"
                         :description="t('signIn.errors.facebook.content')"/>
      <UniSectionMessage v-if="$route.params.newPwd" type="success" title="Nouveau mot de passe"
                         description="Votre mot de passe a bien été modifié"/>
      <primary-button type="submit" :clicked="loadingLogin">{{ $t('signIn.action') }}</primary-button>
      <router-link :to="{name: 'ForgotPwd'}" class="small float-right underline mb-5 mt-2">
        {{ $t('signIn.forgotPassword') }}
      </router-link>
    </validation-observer>
    <separator class="mb-5"/>
    <div class="grid gap-3 grid-cols-3 mb-28">
      <google-button :is-small="true" @click.native="logInWithGoogle"></google-button>
      <facebook-button :is-small="true" @click.native="logInWithFacebook"></facebook-button>
      <united-schools-button :is-small="true" @click.native="unitedSchoolsModal = true"></united-schools-button>
    </div>
    <p class="sign-in text-align-center">{{ $t('signIn.notAccount') }}
      <router-link class="underline" :to="{name: 'SignIn'}">{{ $t('signIn.createAction') }}</router-link>
    </p>

    <uni-modal :title="$t('signIn.unitedSchools.login')"
               :is-visible="unitedSchoolsModal" @close-modal="unitedSchoolsModal = false; usMessage = null; usUser.username = ''; usUser.password = ''">
      <template v-slot:body>
        <validation-observer tag="form" ref="usForm" v-slot="{valid}" @submit.prevent="loginWithUnitedSchools">
          <uni-input
              :label="$t('signIn.unitedSchools.username')"
              id="input-test"
              type="text"
              v-model="usUser.username"
              rules="required"
          />
          <uni-input-password
              :label="$t('signIn.unitedSchools.password')"
              id="input-connexion-password"
              v-model="usUser.password"
              rules="required"/>
          <UniSectionMessage v-if="usMessage" type="error" :title="$t('signIn.errors.unitedSchools.title')"
                             :description="usMessage"/>

          <primary-button type="submit" class="subtle">
            <img height="24" width="24" src="../../assets/img/logo-US.png">&nbsp
            {{ $t('signIn.action') }}
          </primary-button>
        </validation-observer>
      </template>
    </uni-modal>

  </div>
</template>

<script>
import UniInput from "@/components/UniInput";
import UniInputPassword from "@/components/Password/UniInputPassword.vue";
import PrimaryButton from "@/components/PrimaryButton";
import Separator from "@/components/Separator";
import GoogleButton from "@/components/GoogleButton";
import FacebookButton from "@/components/FacebookButton";
import {mapActions, mapGetters} from "vuex";
import UniSectionMessage from "@/components/UniSectionMessage";
import LocaleSwitcherOnRight from "./LocaleSwitcherOnRight";
import UnitedSchoolsButton from "@/components/UnitedSchoolsButton.vue";
import UniModal from "@/components/UniModal.vue";
import i18n from "@/locales";

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: ""
      },

      clicked: false,
      badCredentials: false,
      loadingLogin: false,
      googleAlreadyExists: false, // Si le compte google est déja enreistré dans notre base
      facebookAlreadyExists: false, // Si le compte Facebook est déja enreistré dans notre base
      googleNotExists: false, // Si le compte google n'est pas enregistré dans la BDD
      facebookNotExists: false, // SI le compte Facebook n'est pas enregistré dans la BDD
      emailAlreadyExists: false, // Si un compte avec la même adresse email existe déja

      unitedSchoolsModal: false,
      usUser: {
        username: "",
        password: ""
      },
      usMessage: null
    }
  },
  computed: {
    ...mapGetters({
      currentFamily: 'currentFamily',
      familyState: 'familyState'
    })
  },
  components: {
    UnitedSchoolsButton,
    LocaleSwitcherOnRight,
    UniSectionMessage,
    FacebookButton, GoogleButton, Separator, PrimaryButton, UniInputPassword, UniInput,
    UniModal
  },
  watch: {
    usUser() {
      console.log(this.usUser)
      this.loginWithUnitedSchools()
    }
  },
  methods: {
    ...mapActions(['setUsers']),
    loginUser() {
      this.$refs.formInput.validate().then(success => {
        if (success) {
          this.login()
        }
      })
    },
    login() {
      this.loadingLogin = true
      this.$http.post('/login', {family: this.user})
          .then((result) => {
            if (result.data.auth_token) {
              this.loginSuccessful(result)
            }
          })
          .catch(_ => {
            this.loginFailed()
          })
          .finally(() => {
            this.loadingLogin = false
            this.$store.dispatch('sessionExpired', false)
          })
    },
    async logInWithGoogle() {
      const authCode = await this.$gAuth.signIn()

      let family = {
        google_id: authCode.getId()
      }

      this.$http.post('/login', {family})
          .then((result) => {
            //  this.userWantsToConnect = false
            if (result.data.auth_token) {
              this.loginSuccessful(result)
            } else {
              this.googleNotExists = true

              window.setTimeout(() => {
                this.googleNotExists = false
              }, 5000)
            }

          })

    },
    logInWithFacebook() {
      FB.login((response) => {
        if (response.status === 'connected') {
          FB.api('/me', {fields: 'last_name,first_name,email'}, (response) => {
            let family = {
              facebook_id: response.id,
            }
            this.$http.post('/login', {family})
                .then((result) => {
                  //  this.userWantsToConnect = false
                  if (result.data.auth_token) {
                    this.loginSuccessful(result)
                  } else {
                    this.facebookNotExists = true

                    window.setTimeout(() => {
                      this.facebookNotExists = false
                    }, 5000)
                  }

                })
          })
        }
      }, {scope: 'public_profile,email'})
    },
    loginWithUnitedSchools() {
      this.$refs.usForm.validate().then(success => {
        if (success) {
          this.$http.post('/united_schools/login', this.usUser)
              .then((result) => {
                if (result.status === 200 && !result.data.code && result.data.auth_token) {
                  this.loginSuccessful(result)
                } else {
                  switch (result.data.code) {
                    case 1:
                      this.usMessage = i18n.t('signIn.errors.unitedSchools.unknown')
                      break;
                    case 2:
                      this.usMessage = i18n.t('signIn.errors.unitedSchools.badCredentials')
                      break;
                    default:
                      this.usMessage = i18n.t('signIn.errors.unitedSchools.title')
                      break;
                  }
                }
              })
        }
      })
    },
    loginSuccessful(req) {
      localStorage.token = req.data.auth_token
      this.$store.dispatch('login')
      window.location.reload()
      //this.$router.push({name: 'Home'})
    },
    loginFailed() {
      this.badCredentials = true

      window.setTimeout(() => {
        this.badCredentials = false
      }, 3000)
    },
    isAllowed() {
      return this.currentFamily
    }
  },
  updated() {
    if (this.isAllowed()) {
      //this.$router.push({name: 'Home'})
    }
  },
  async created() {
    if (this.familyState.users.length > 0) {
      window.location.reload()
    }
    if (this.isAllowed()) {
      this.$router.push({name: 'profile'})
    }
  }
}
</script>
<style lang="scss" scoped>
</style>