<template>
  <div class="ask-newsletter">

    <p class="text-align-center font-bold mb-2" v-html="$t('settings.preferencesQuestion')"></p>
    <uni-checkbox value="newsletter" v-model="nl"
                  :label="$t('settings.preferencesText')"/>
    <p class="mt-4 text-align-center font-bold">{{$t('settings.preferencesChoose')}}</p>
    <AskLocale :locale="loc" />
    <!--    <label for="newsletter">S'abonner à la newsletter</label>-->
    <!--    <input type="checkbox" id="newsletter" v-model="newsletter">-->
  </div>
</template>

<script>
import Storage from "../../../class/storage";
import UniCheckbox from "../UniCheckbox.vue";
import AskLocale from "@/components/Locale/AskLocale.vue";

const storage = new Storage('locale')
export default {
  name: "AskNewsletter",
  components: {AskLocale, UniCheckbox},
  props: {
    locale: {
      type: String,
      default: 'fr'
    },
    newsletter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loc: this.locale,
      nl: this.newsletter,
    }
  },
  watch: {
    nl(val) {
      this.$emit('handleChange', {locale: this.locale, newsletter: val})
    }
  }
}
</script>

<style lang="scss" scoped>
.ask-newsletter {
  position: relative;
  margin: 16px 0;

  label {
    border-radius: 26px;
    border: 4px solid transparent;
  }

  input:checked + label {
    border: 4px solid var(--color-secondary);
  }

  input:not(:last-child) {
    position: absolute;
    left: -999px;
    visibility: hidden;
  }
}

@include screen-m {
  .flag-container {
    margin: 16px auto;
  }
}
</style>