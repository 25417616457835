<template>
  <div class="infos flex flex-col gap-2 space-between" :style="{'background-color': getColor, height: !bigButton ? '250px' : null}">
   <div>
     <div class="info-caps flex justify-between align-items-center">
       <div class="flex align-items-center ">
         <div class=" margin-right-8">
           <img class="avatar object-cover rounded-full" :src="author.profil.fields.file.url + '?fit=thumb'">
         </div>
         <div>
           <p class="name">{{ $t('cards.writtenBy') }} {{ author.firstname }} · {{ $relativeDate(date) }}</p>
         </div>
       </div>

     </div>
     <h1 class="title white mt-2">{{ title }}</h1>
     <div class="description mt-1">
       <p>{{ $truncate(description, 125) }}</p>
     </div>
   </div>
    <div>
      <div v-if="bigButton">
        <div>
          <router-link tag="button" class="flex justify-center align-items-center mt-4 big-button"
                       :to="{name: newCaps ? 'article' : 'Capsule', params: {slug:  slug, isSpotlight: isFree}}">{{$t('cards.readMore')}}
          </router-link>
        </div>
      </div>
      <div v-else class="flex justify-end">
        <div>
          <router-link :to="{name: newCaps ? 'article' : 'Capsule', params: {slug:  slug, isSpotlight: isFree}}" class="link-big">{{$t('cards.readMore')}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton";
import {mapGetters} from "vuex";

export default {
  name: 'InfoCaps',
  components: {PrimaryButton},
  props: {
    readDuration: {},
    title: {},
    date: Date,
    freeCapsule: Boolean,
    author: {
      type: Object,
    },
    description: {
      type: String,
    },
    slug: {
      type: String,
    },
    bigButton: {
      type: Boolean,
      required: false,
      default: true
    },
    isFree: {
      type: Boolean,
      required: false,
      default: false
    },
    newCaps: {
      type: Boolean,
      required: true,
    }

  },
  computed: {
    ...mapGetters(['currentUser', 'currentFamily']),
    getColor() {
      if (this.bigButton) {
        return "#7ABACE"
      } else {
        return this.colors[Math.floor(Math.random() * this.colors.length)];

      }
    }
  },
  data() {
    return {
      colors: ["#ED4257", "#63C1A8", "#F08857"]
    }
  }
}
</script>
<style lang="scss" scoped>
.infos {
  padding: 18px;
  border-radius: 0 0 16px 16px;
}

.container-avatar {
  width: 40px;
  height: 40px;
}

.name {
  font-size: 13px;
  line-height: 18px;
  color: white;
}

.avatar {
  width: 24px;
  height: 24px;
}

.description > p {
  color: white;
  font-size: 14px;
  line-height: 22px;
}

.title {
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
}

.link-big {
  color: white;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.big-button {
  transition: box-shadow 0.1s;
  box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  //background: linear-gradient(180deg, #E62145 0%, #B81230 100%);
  background: var(--color-primary);
  color: white;
  border-radius: var(--radius-default);
  padding: var(--space-12);
}
</style>