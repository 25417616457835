<template>
  <div>
    <div class="img-container relative pb-4 rounded-2xl">
<!--      <img class="object-cover w-full h-full rounded-2xl" :src="img"/>-->
      <uni-skeleton-image width="100%" height="100%" class="rounded-2xl" />
    </div>
    <uni-skeletontext class="h-5 margin-bot-8" width="90%"/>
    <uni-skeletontext class="h-5 margin-bot-8" width="95%"/>
    <uni-skeletontext class="h-5 margin-bot-16" width="30%"/>
    <div class="info-caps flex justify-between align-items-center">
      <div class="flex align-items-center ">
        <div class="container-avatar margin-right-8 rounded-full">
          <uni-skeleton-image width="100%" height="100%" class="rounded-2xl" />
        </div>
        <div>
          <uni-skeletontext class="h-3 margin-bot-8" width="50px"/>
          <uni-skeletontext class="h-2" width="95px"/>
        </div>
      </div>
      <div class="flex justify-end align-items-center">
        <uni-skeletontext class="h-3 margin-bot-8" width="60px"/>
      </div>

    </div>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import UniSkeletonImage from "@/components/UniSkeletonImage";
import UniSkeletontext from "@/components/UniSkeletonText";

export default {
  name: "CardCapsuleSkeleton",
  components: {UniSkeletontext, UniSkeletonImage, Tag},
}
</script>

<style lang="scss" scoped>
.img-container{
  height: 220px;
}
.container-avatar {
  width: 40px;
  height: 40px;
}

.caption {
  color: var(--color-text-disabled)
}
</style>