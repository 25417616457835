<template>
  <div id="app" ref="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

const default_layout = "default"
export default {
  computed: {
    ...mapGetters(['currentFamily']),
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    },
  },
  methods: {
    ...mapActions(['toggleDyslexiaMode'])
  },
  mounted() {
    this.toggleDyslexiaMode(this.currentFamily?.dyslexia ?? false)
  }
}
</script>
<style lang="scss">
body {
  background: linear-gradient(
          179.97deg, #FBD354 0.03%, #FBC254 53.26%) no-repeat;
}

.main-div {
  min-height: 100vh;
}

@import 'src/assets/style/style.scss';

</style>
