import { render, staticRenderFns } from "./Families.vue?vue&type=template&id=298560be&scoped=true&"
import script from "./Families.vue?vue&type=script&lang=js&"
export * from "./Families.vue?vue&type=script&lang=js&"
import style0 from "./Families.vue?vue&type=style&index=0&id=298560be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298560be",
  null
  
)

export default component.exports