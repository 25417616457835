<template>
  <div class="section-message" :class="type">
    <sprite-icon :name="type"/>
    <div>
      <strong>{{ title }}</strong>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
import SpriteIcon from "@/components/SpriteIcon";
export default {
  name: 'UniSectionMessage',
  components: {
    SpriteIcon
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    actions: {
      type: String,
    }
  },
}
</script>

<style scoped lang="scss">
.section-message {
  /* Start Variables */
  --section-message-bg: var(--color-surface-strong);
  --section-message-color: var(--color-text);
  /* End Variables */
  background: var(--section-message-bg);
  padding: var(--space-12) var(--space-16);
  border-radius: var(--radius-default);
  margin-top: var(--space-16);
  margin-bottom: var(--space-16);
  display: flex;
}

.section-message strong {
  color: var(--section-message-color);
  line-height: var(--space-24);
}

.section-message p {
  margin: 0;
}

.section-message > > > svg {
  margin-top: var(--space-2);
  float: left;
  fill: var(--section-message-color);
}

.section-message > div {
  display: inline-block;
  padding-left: var(--space-8);
  width: calc(100% - var(--space-24));
}

.error {
  /* Start Variables */
  --section-message-bg: var(--color-danger-soft);
  --section-message-color: var(--color-danger);
  /* End Variables */
}

.warning {
  /* Start Variables */
  --section-message-bg: var(--color-warning-soft);
  --section-message-color: var(--color-warning);
  /* End Variables */
}

.success {
  /* Start Variables */
  --section-message-bg: var(--color-success-soft);
  --section-message-color: var(--color-success);
  /* End Variables */
}

svg {
  margin-top: var(--space-4);
  stroke: var(--section-message-color);
}
</style>
