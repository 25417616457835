<template>
  <div>
    <h2 class="text-align-center">{{ message }}</h2>
    <loader v-if="load && !error" class="center"></loader>
    <svg v-if="!load && !error" class="center" width="47" height="47" viewBox="0 0 47 47" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <rect width="47" height="47" rx="23.5" fill="#68EA5D"/>
      <path
          d="M19.0266 30.163L13.6419 24.6441C13.0367 24.0239 12.0591 24.0239 11.4539 24.6441C10.8487 25.2644 10.8487 26.2664 11.4539 26.8867L17.9403 33.5348C18.5455 34.1551 19.5231 34.1551 20.1283 33.5348L36.5461 16.7078C37.1513 16.0875 37.1513 15.0855 36.5461 14.4652C35.9409 13.8449 34.9633 13.8449 34.3581 14.4652L19.0266 30.163Z"
          fill="white"/>
    </svg>
    <svg v-if="error" class="center" width="47" height="47" viewBox="0 0 47 47" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M40.1154 40.117C30.9359 49.2943 16.0531 49.2943 6.87361 40.117C-2.2912 30.9336 -2.2912 16.0664 6.87361 6.88299C16.0531 -2.29433 30.9359 -2.29433 40.1154 6.88299C49.2949 16.0603 49.2949 30.9397 40.1154 40.117ZM23.1332 20.2488L16.268 13.3852C15.4698 12.5872 14.1757 12.5872 13.3774 13.3852C12.5792 14.1833 12.5792 15.4771 13.3774 16.2751L20.2426 23.1387C20.4421 23.3382 20.4421 23.6617 20.2426 23.8612L13.3774 30.7247C12.5792 31.5227 12.5792 32.8166 13.3774 33.6146C14.1757 34.4127 15.4698 34.4127 16.268 33.6146L23.1332 26.7511C23.3327 26.5516 23.6563 26.5516 23.8558 26.7511L30.721 33.6146C31.5192 34.4127 32.8134 34.4127 33.6116 33.6146C34.4098 32.8166 34.4098 31.5227 33.6116 30.7247L26.7464 23.8612C26.5469 23.6617 26.5469 23.3382 26.7464 23.1387L33.6116 16.2751C34.4098 15.4771 34.4098 14.1833 33.6116 13.3852C32.8134 12.5872 31.5192 12.5872 30.721 13.3852L23.8558 20.2488C23.6563 20.4483 23.3327 20.4483 23.1332 20.2488Z"
            fill="#F14141"/>
    </svg>

  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "Families",
  components: {Loader},
  data() {
    return {
      load: true,
      message: "",
      error: false,
    }
  },
  methods: {
    loginSuccessful(req) {
      localStorage.token = req.data.auth_token
      this.$store.dispatch('login')
      window.location.reload()
    },
  },
  created() {
    if (localStorage.token) {
      this.$router.push({name: 'SignInWelcomeHome'})
      window.location.reload()
      return;
    }
    this.message = "Validation et connexion en cours ..."
    this.$http('families/validate-token?id=' + this.$route.query.id + '&token=' + this.$route.query.token)
        .then((resp) => {
          if (resp.data.auth_token) {
            this.load = false
            this.loginSuccessful(resp)
          } else {
            if (resp.data.message.includes("invalide")) {
              this.message = "Un soucis est apparu, redirection ..."
              this.error = true
              setTimeout(() => {
                this.$router.push({name: 'SignIn'})
              }, 1000)
            }
            if (resp.data.message.includes("validé")) {
              this.message = "Votre compte à déjà été validé, redirection ..."
              this.error = true
              setTimeout(() => {
                this.$router.push({name: 'Login'})
              }, 1000)
            }
          }
        })
  }
}
</script>

<style scoped lang="scss">
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  height: 62px;

  > ::v-deep svg {
    width: 62px;
    height: 62px;
  }
}
</style>