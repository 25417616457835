<template>
  <div>
    <div>
      <div class="head pt-8" :style="{'--head-bg':'#FBD354'}">
        <div class="wrapper">
          <div class="head-infos ">
            <span @click="$emit('go-back', index)" class="cursor-pointer">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="#1C2326" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.81836 18H28.182" stroke="#1C2326" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 10.7273L7.81836 18" stroke="#1C2326" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 25.2727L7.81836 18" stroke="#1C2326" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </span>
            <div class="progress" :style="{'--width': getProgress}"></div>
            <div>
              <router-link :to="{name: 'Home'}">
                <sprite-icon name="home" size="big" color="#1C2326"/>
              </router-link>
            </div>
          </div>
          <h2 class=" mt-2">{{ block.fields.title }}</h2>
          <p class=" mt-3">{{ block.fields.description }}</p>
        </div>
      </div>
      <div class="bubble" :style="{'--head-bg':'#FBD354'}">
        <div class="triangle" :style="{'--head-bg':'#FBD354'}"></div>
      </div>
    </div>
    <div class="wrapper" style="margin-top: -35px;">
      <img src="../../assets/img/personnage-heureux.png" alt="">
      <h3 class="mt-4">{{ $t('capsuleV2.end.think') }}</h3>

      <div class="ratings-container mt-5 mb-4">
        <div class="rating" v-for="rating in ratings">
          <p class="strong">{{ rating.title }}</p>
          <div class="stars-container">
            <div @click="selectStar(rating, n)" class="star" v-for="n in 5"
                 :class="{selected: rating.stars === 5 - (n) + 1}">
              <img src="../../assets/img/Star-default.svg" alt="">

            </div>
          </div>
        </div>
      </div>
      <primary-button color="white" :clicked="ratingClicked" @click.native="saveRating" class="mb-10" :disabled="ratingSaved">
        <span v-if="!ratingSaved">{{ $t('capsuleV2.end.save') }}</span>
        <div v-else class="italic flex"><sprite-icon name="check" size="big" color="green" fill="green"/><span style="display: inline-flex;align-items: center;"> {{ $t('capsuleV2.end.savedRating') }}</span></div>
      </primary-button>


      <div class="journal-container">
        <div>
          <img src="../../assets/img/Frame-journal.svg" alt="">
          <p class="strong">{{ $t('capsuleV2.end.printPdfText') }}</p>
        </div>
        <primary-button color="white" @click.native="openShareMenu" class="mb-4" v-if="pdf">{{
            $t('capsuleV2.end.print')
          }}
        </primary-button>
      </div>


      <h3 class="mt-8 mb-5" v-if="capsules.length > 0">{{ $t('capsuleV2.end.otherSubjects') }}</h3>

    </div>
    <Loader :color="true" v-if="loadCapsules"/>
    <div v-else class="card-container">
      <card-capsule v-for="capsule in capsules" :title="capsule.fields.title"
                    :img="capsule.fields.cover.fields.file.url"
                    :read-duration="capsule.fields.readDuration" :date="new Date(capsule.sys.createdAt)"
                    :description="capsule.fields.accroche || capsule.fields.description"
                    :slug="capsule.fields.slug"
                    :big-button="false" card-width="little"
                    :author="capsule.fields.author.fields" :tags="capsule.fields.capsuleTags"
                    :is-free="capsule.fields.freeCapsule" :new-caps="!!capsule.fields.blocs"
                    @open-modal="openModal"></card-capsule>
    </div>
    <div class="wrapper">
      <div class="mt-4 mb-8">
        <PrimaryButton class="mb-3" color="white" @click.native="retry">{{ $t('capsuleV2.end.retry') }}</PrimaryButton>
        <PrimaryButton @click.native="goHome">{{ $t('capsuleV2.end.backHome') }}</PrimaryButton>
      </div>
    </div>
    <handle-subscription-modal :capsule-id="capsuleId" :open-modal="modalSub" @close-modal="modalSub = false"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PrimaryButton from "@/components/PrimaryButton";
import CardCapsule from "@/components/CardCapsule";
import SpriteIcon from "@/components/SpriteIcon";
import Loader from "@/components/Loader";
import HandleSubscriptionModal from "@/components/handleSubscriptionModal";

export default {
  name: "End",
  components: {HandleSubscriptionModal, Loader, SpriteIcon, CardCapsule, PrimaryButton},
  props: {
    block: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    pdf: {
      type: Object
    },
    pdfPays: {
      type: Object
    },
    capsuleId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ratings: [
        {
          name: 'enjoy',
          title: this.$t('capsuleV2.end.enjoy'),
          stars: 0
        },
        {
          name: 'learn',
          title: this.$t('capsuleV2.end.learn'),
          stars: 0
        },
        {
          name: 'inspire',
          title: this.$t('capsuleV2.end.inspire'),
          stars: 0
        }
      ],
      ratingClicked: false,
      ratingSaved: false,
      capsules: [],
      loadCapsules: true,
      modalSub: false
    }
  },
  computed: {
    ...mapGetters(["currentUser", "newCapsule", "currentLocale", "currentBlock"]),
    getProgress() {
      return ((this.index + 1) / this.totalSteps) * 100 + '%';
    }
  },
  methods: {
    ...mapActions(['updateBlock']),
    openModal(capsule) {
      this.modalSub = true;
      this.capsuleId = capsule.sys.id
    },
    async saveRating() {
      this.ratingClicked = true

      await this.updateBlock({
        id: this.block.sys.id,
        ratings: this.ratings
      })

      let ratings = []
      this.ratings.forEach(r => {
        ratings.push({
          name: r.name,
          rating: r.stars,
          user_id: this.currentUser.id,
          capsule_id: this.newCapsule.contentful_id,
        })
      })
      await this.$http.post(`/capsules/${this.newCapsule.id}/ratings`, {
        rating: ratings
      })
      this.ratingClicked = false
      this.ratingSaved = true;
    },
    retry() {
      this.$emit('retry')
    },
    goHome() {
      this.$router.push({name: 'Home'})
    },
    async openShareMenu() {
      const pdfUrl = this.pdf.fields.file.url
      const response = await fetch(pdfUrl);
      const buffer = await response.arrayBuffer();

      const pdf = new File([buffer], this.pdf.fields.file.fileName, {type: "application/pdf"});
      const files = [pdf];

      let pdfPaysUrl
      if (this.pdfPays) {
        pdfPaysUrl = this.pdfPays.fields.file.url
        const responsePays = await fetch(pdfPaysUrl);
        const bufferPays = await responsePays.arrayBuffer();
        const pdfPays = new File([bufferPays], this.pdfPays.fields.file.fileName, {type: "application/pdf"});
        files.push(pdfPays);
      }

      // Share PDF file if supported.
      try {
        await navigator.share({files});
      } catch (err) {
        window.open(pdfUrl, '_blank');

        if (this.pdfPays) {
          window.open(pdfPaysUrl, '_blank');
        }
      }
    },
    selectStar(rating, index) {
      this.ratings.find(el => el.title === rating.title).stars = 5 - (index) + 1
    },
  },
  created() {
    window.scrollTo(0, 0);

    if (this.currentBlock && this.currentBlock.ratings) {
      this.ratings = this.currentBlock.ratings
    }

    this.$contentfulv2.getEntries({
      skip: 0,
      limit: 3,
      include: 2,
      locale: this.currentLocale,
      content_type: 'capsule',
      links_to_entry: JSON.parse(this.currentUser.prefered_tag_id)[0],
      order: '-sys.createdAt',
    }).then(res => {
      this.capsules = res.items.map(item => {
        if (item.fields.title) {
          return item
        }
      }).filter(el => el !== undefined)
      this.loadCapsules = false
    })
  }
}
</script>

<style scoped lang="scss">
.head {
  background: var(--head-bg);

}

.bubble {
  background: var(--head-bg);
  position: relative;
  z-index: -1;
  height: 100px;
  border-radius: 100%;
  transform: translateY(-50%);
}

.triangle {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 100px);
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid var(--head-bg);
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  transform: rotate(10deg);
}

.head-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.progress {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  height: 10px;
  width: 100%;
  position: relative;
}

.progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: 100%;
  border-radius: 12px;
  background: #1C2326;
}

.ratings-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rating {
  width: 100%;
  background: #F9F9F9;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.stars-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
}

.star {
  position: relative;
  cursor: pointer;
}

.star:hover:before, .star:hover ~ .star:before, .star.selected:before, .star.selected ~ .star:before {
  opacity: 1;
}

.star:before {
  content: url("../../assets/img/Star-full.svg");
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.journal-container {
  padding: 12px;
  border-radius: 12px;
  background: #F9F9F9;

  > div {
    display: flex;
    margin-bottom: 12px;
    gap: 12px;
    align-items: center;
  }

}

.card-container {
  max-width: 720px;
  margin: 0 auto;
  scroll-snap-type: x mandatory;
  overflow-scrolling: touch;
  display: flex;
  gap: 12px;
  overflow-x: auto;
  width: 100%;
  scroll-padding-left: 16px;
  scroll-padding-right: 16px;

  > div:first-child {
    margin-left: 16px;
  }

  > div:last-child {
    margin-right: 16px;
  }
}

</style>