<template>
  <div>
    <div class="flex space-between">
      <div class="left">
        <uni-skeletontext class="mb-3 h-2" width="45%"/>
        <uni-skeletontext class="mb-3 h-3" width="150%"/>
        <uni-skeletontext class="mb-3 h-3" width="100%"/>
        <uni-skeletontext class="mb-4 h-3" width="120%"/>

        <uni-skeletontext class="h-2 inline-block mr-6" width="70px"/>
        <uni-skeletontext class="h-2 inline-block" width="70px"/>

      </div>
      <div class="img-container justify-end">
        <uni-skeleton-image/>
      </div>

    </div>
  </div>
</template>

<script>
import UniSkeletontext from "@/components/UniSkeletonText";
import UniSkeletonImage from "@/components/UniSkeletonImage";
export default {
  name: "CardMiniOldCapsSkeleton",
  components: {UniSkeletonImage, UniSkeletontext},
  props:{
    color:{
      type:String,
    }
  }
}
</script>

<style lang="scss" scoped>
.left{
  max-width: 70%;
}
//.title{
//  font-weight: var(--font-weight-bold);
//}
.img-container{
  border-radius: var(--radius-default);
  width: 80px;
  height:80px;
  img, div{
    width:100%;
    height:100%;
    border-radius: var(--radius-default);
    object-fit: cover;
  }
}
</style>