<template>
  <div class="parent">
    <img src="../assets/img/logo.png" alt="" v-if="header === 'logo'">
    <div class="text-center pt-10 pb-6" v-if="header === 'welcome'">
      <div class="relative w-max mx-auto">

        <svg class="absolute top-0 left-0" width="21" height="20" viewBox="0 0 21 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M2.17188 4.79559C4.43952 7.17424 9.60942 12.2052 12.1479 13.2997C14.6864 14.3943 6.55491 8.08638 2.17188 4.79559Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M10.1084 1.46997C11.0798 3.5817 13.4429 8.15463 15.1245 9.55248C16.8061 10.9503 12.4811 4.74658 10.1084 1.46997Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M2.33203 14.5725C4.09009 15.3981 8.02197 17.5493 9.64791 17.203C10.9959 16.9159 5.45122 15.5006 2.33203 14.5725Z"
              stroke="black" stroke-linejoin="round"/>
        </svg>
        <h1 class="p-5"> Bienvenue sur <br/>WoW! News</h1>
        <svg class="absolute top-1 -right-3" width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.8125 0.517639C9.90626 3.19437 3.76611 9.2568 2.45543 12.0927C1.14474 14.9285 8.81402 5.55759 12.8125 0.517639Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M17.0732 8.69336C14.4746 9.95186 8.85176 12.9557 7.15008 14.9032C5.44841 16.8506 13.0565 11.5747 17.0732 8.69336Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <img class="absolute bottom-0 img-bud" src="../assets/img/bud-wow.png">
      </div>

    </div>

    <slot></slot>
  </div>
</template>

<script>
import expiredCheck from "@/library/expiredCheck";
import currentFamilyCheck from "@/library/currentFamilyCheck";
import cacheMixin from "@/library/cache-mixin";
import {mapGetters} from "vuex";

export default {
  name: "default",
  mixins: [expiredCheck, currentFamilyCheck, cacheMixin],
  data() {
    return {
      head: ""
    }
  },
  beforeCreate() {
    document.body.className = 'main-bg';
  },
  computed: {
    ...mapGetters(['currentLocale']),
    header() {
      return (this.$route.meta.header)
    },
  },
  created() {
    this.$i18n.locale = this.currentLocale
  }
}
</script>

<style lang="scss" scoped>
.parent {
  //display: flex;
  //flex-direction: column;
  //min-height: 100vh;
}

.img-bud {
  width: 79px;
  right: -90px;
}

img {
  width: 220px;
  margin: 0 auto;
}
</style>