import * as CryptoJS from 'crypto-js'

const SecureStorage = require('secure-web-storage');
let SECRET_KEY = '34567890POJHGFDXCVBN?'


class Storage {
    constructor(storageType) {
        this.type = storageType

        let secure = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);

                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);

                data = data.toString();

                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);

                data = data.toString(CryptoJS.enc.Utf8);

                return data;
            }
        });

        this.secureStorage = secure

        if (!secure.getItem(storageType)) {
            secure.setItem(storageType, '{}')
        }
    }

    /**
     * check si le Storage existe
     * @returns {boolean}
     */
    get isEmpty() {
        return this.secureStorage.getItem(this.type) === "{}";
    }

    /**
     *
     * retourne le Storage sous forme d'un tableau
     *
     * @returns {Array}
     */
    get get() {
        //  return Storage.parse(localStorage.getItem(this.type)) || {}
        return this.secureStorage.getItem(this.type) || {}
    }

    /**
     * Update the storage
     * @param obj {Object}
     */
    update(obj) {
        //   let str = Storage.stringify(obj)
        this.secureStorage.setItem(this.type, obj)
    }

    init(obj) {
        if (this.isEmpty) {
            this.update(obj)
            return obj
        } else {
            return this.get
        }
    }

    // /**
    //  *  Update one item in the storage object
    //  *
    //  * @param id - ID of the item
    //  * @param newObj - The new object
    //  */
    // updateItem(id, newObj) {
    //     let currentStorage = this.get
    //     let index = currentStorage.indexOf(currentStorage.find(el => el.id === newObj.id))
    //
    //     currentStorage[index] = {
    //         ...newObj
    //     }
    //
    //     this.update(currentStorage)
    // }
    // addItem(obj) {
    //     let currentStorage = this.get
    //     currentStorage.push(obj)
    //
    //     this.update(currentStorage)
    // }
    //
    // /**
    //  * Delete the current localStorage
    //  */
    // delete() {
    //     localStorage.removeItem(this.type)
    // }
    //
    // /**
    //  *  Delete an item in the storage object
    //  * @param index {Number} - The index of the item in the object
    //  */
    // deleteItem(index) {
    //     let currentStorage = this.get
    //
    //     currentStorage.splice(index, 1)
    //     this.update(currentStorage)
    //
    // }
    // =========== STATIC FUNCTIONS ===============
    /**
     *
     * Retourne un Object sous forme de String
     *
     * @param obj {Object}
     * @returns {string}
     */
    static stringify(obj) {
        return JSON.stringify(obj)
    }

    /**
     *
     * Retourne un String sour forme de Object
     *
     * @param str {String}
     * @returns {Object}
     */
    static parse(str) {
        return JSON.parse(str)
    }
}

export default Storage