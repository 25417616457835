<template>
  <div class="checkbox">
    <input
        ref="input"
        v-bind="$attrs"
        v-on="inputListeners"
        class="bx--checkbox"
        type="checkbox"
        :checked="isChecked === true"
        :aria-checked="`${isChecked}`"
        @focus="onFocus"
        @blur="onBlur"
        :value="value"
        :id="uid"
    />
    <label
        :class="[
        'label',
        {
          'disabled': $attrs.disabled !== undefined && $attrs.disabled,
          'focus': hasFocus,
        },
      ]"
        :data-contained-checkbox-state="isChecked"
        :data-contained-checkbox-disabled="$attrs.disabled"
        :for="uid"
    >
      <div class="box">
        <svg viewBox="0 0 24 24">
          <path fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
        </svg>
      </div>
      <span class="display-6 regular">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
import checkMixin from '@/library/check-mixin';
import uidMixin from '@/library/uid-mixin';

export default {
  name: 'UniCheckbox',
  mixins: [checkMixin, uidMixin],
  inheritAttrs: false,
  props: {
    hideLabel: Boolean,
    label: String,
    mixed: Boolean,
    formItem: {type: Boolean, default: true},
  },
  watch: {
    mixed() {
      this.dataMixed = this.mixed;
      if (this.dataMixed && this.checked !== true) {
        this.isChecked = false; // reset check state so mixed takes
      }
    },
  },
  data() {
    return {
      hasFocus: false,
      dataMixed: this.mixed,
    };
  },
  methods: {
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {

}

.label {
  &.focus {

  }

  &.disabled {

  }
}

.red {
  background: red;
}

label {
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  padding: var(--space-8) 0;
  user-select: none;
  width: 100%;

  span {
    display: flex;
    flex: 1;
  }
}

input {
  opacity: 0;
  height: 0;
  position: absolute;
}

.box {
  position: relative;
  z-index: 2;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Start values */
  --box-bg: var(--color-surface-default);
  --box-hover-bg: var(--color-surface-strong);
  --box-border: 2px solid var(--color-surface-extra-strong);
  --box-hover-border: 2px solid var(--color-surface-extra-strong);
  --box-icon-color: var(--color-surface-default);
  /* End values */
  border: var(--box-border);
  border-radius: 9999px;
  transition: var(--transition-quick);
  margin-right: var(--space-12);
  background: var(--box-bg);
}

.box path {
  stroke-width: var(--space-4);
  stroke-dashoffset: 30;
  stroke-dasharray: 30;
  transition: var(--transition-quick);
  stroke: var(--box-icon-color);
}

svg {
  width: 14px;
}

input:checked + label > .box path {
  stroke-dashoffset: 0;
}

input:checked + label > .box {
  border: 2px solid var(--color-blue);
  background: var(--color-blue);
}
</style>
