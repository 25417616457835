<template>
  <div class="parent" ref="container">
    <smiley-circle v-for="(smiley, index ) in smileys[currentLocale] " :class="index === smileyClicked.id? 'selected': ''"
                   class="p-3 smiley cursor-pointer"
                   @click.native="clicked($event, index)" ref="div">
      <p class="emoji">{{ smiley.emoji }}</p>
      <p>{{ smiley.name }}</p>
    </smiley-circle>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SmileyCircle from "@/views/SmileyCircle";

export default {
  name: "SmileyChoice",
  components: {SmileyCircle},
  data() {
    return {
      id: null,
      smileyClicked: {id: null, person: ''}
    }

  },
  props: {
    smileys: {
      type: Object,
      required: true
    },
    person: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      capsules: "capsules",
      currentLocale: "currentLocale"
    }),
    checkStore() {
      let person = ""
      this.person === "child" ? person = 'Kid' : person = 'Parent'
      return this.capsules.currentUsers[this.person]['currentSmiley' + person]
    }
  },
  watch: {
    checkStore(val) {
      if (val) {
        this.person === 'child' ? this.smileyClicked.id = this.capsules.currentUsers.child.currentWord : this.person === "parent" ? this.smileyClicked.id = this.capsules.currentUsers.parent.currentWord : ''

      }


    }
  },
  /**
   * Si composant doit être réutilisé utiliser le store dans le composant parent
   */
  methods: {
    ...mapActions(['setCurrentSmileyKid', "setCurrentSmileyParent"]),
    /**
     * function qui permet de savoir quel element est cliqué et donc d'afficher le smiley
     * remplie le store et envoie un msg au parent
     * @param e
     * @param index
     */
    clicked(e, index) {
      let person = this.person
      this.smileyClicked = {id: index, person: person}
      this.smileyClicked.person === "child" ? this.setCurrentSmileyKid(this.smileyClicked.id) : this.smileyClicked.person === "parent" ? this.setCurrentSmileyParent(this.smileyClicked.id) : ''
    },

  },
  async mounted() {
    await this.$nextTick();
    let divs = document.querySelectorAll('.smiley')
    let parent = document.querySelector('.parent')
    const mainWidth = parent.clientWidth
    const mainHeight = parent.clientHeight

    this.$refs.div.forEach((box) => {
    });
  },
  updated() {
    this.person === 'child' ? this.smileyClicked.id = this.capsules.currentUsers.child.currentSmileyKid : this.person === "parent" ? this.smileyClicked.id = this.capsules.currentUsers.parent.currentSmileyParent : ''
  },
  created() {
    this.person === 'child' ? this.smileyClicked.id = this.capsules.currentUsers.child.currentSmileyKid : this.person === "parent" ? this.smileyClicked.id = this.capsules.currentUsers.parent.currentSmileyParent : ''
  },
}
</script>

<style lang="scss" scoped>
.emoji{
  font-size: 1.5rem!important;
  margin-bottom: 4px;

}
.parent {
  width: 100%;
  height: 400px;
  position: relative;
}

.smiley {

  &:nth-child(1) {
    left: 5%;
    top: -2%;
  }

  &:nth-child(2) {
    left: 60%;
    top: 0%;
  }

  &:nth-child(3) {
    left: 48%;
    top: 30%;
  }

  &:nth-child(4) {
    left: 60%;
    top: 60%;
  }

  &:nth-child(5) {
    left: 8%;
    top: 65%;
  }

  &:nth-child(6) {
    left: 3%;
    top: 32%;
  }

  &.selected {
    background: #E6F6FC;
    color: #09A6E6;
    border: 2px solid #09A6E6;
  }
}
</style>