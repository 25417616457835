<template>
  <div>
    <div>
      <div class="head pt-8" :style="{'--head-bg':'#63C1A8'}">
        <div class="wrapper">
          <div class="head-infos">
            <span @click="$emit('go-back', index)" class="mr-6 cursor-pointer">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </span>
            <div class="progress" :style="{'--width': getProgress}"></div>
            <div class="p-2">
              <router-link :to="{name: 'Home'}">
                <sprite-icon name="home" size="big" color="white"/>
              </router-link>
            </div>
          </div>
          <div class="flex align-items-center space-between mt-6">
            <h2 class="white">{{ $t('capsuleV2.summary.action') }}</h2>
            <div class="icon-container" style="--icon-container-color: #F6365C; width: 32px;">
              <img src="../../assets/img/Path.png" alt="">
            </div>
          </div>
          <!--          <p class="white" v-if="block.fields.title">{{ block.fields.title }}</p>-->
        </div>
      </div>
      <div class="bubble" :style="{'--head-bg':'#63C1A8'}">
        <div class="triangle" :style="{'--head-bg':'#63C1A8'}"></div>
      </div>
    </div>
    <div class="wrapper">
      <img src="../../assets/img/personnage-muscle.png" alt="">
      <h3 class="mt-12">{{ $t('capsuleV2.superpowers.title') }}</h3>

      <div class="powers-container mt-4">
        <div class="grid gap-4">
          <div v-on:click.stop="openMore(index)" class="parent-power" ref="parentPower" :id="'power-'+index"
               :class="{'selected': unlocked.includes(index) || index === current}"
               v-for="(power, index) in block.fields.superPowers">
            <div class="second-parent">
              <div>
                <div class="pentagon">
                  <img v-if="!power.fields.new" class="" alt="pictogramme de pentagone"
                       src="../../assets/img/pentagon_unlocked.png">
                  <img style="width: 72px; height: 72px;" class="power"
                       :src="power.fields.superPowerCategory.fields.picto.fields.file.url" alt="">
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="locker">
                    <g filter="url(#filter0_i)">
                      <rect x="0.974609" y="0.383057" width="47.6087" height="47.6087" rx="19.0435"
                            fill="url(#paint0_linear)"/>
                      <path class="top-of-locker"
                            d="M20.0176 21.807V18.2363V18.2363C20.0176 15.6071 22.1493 13.4755 24.7784 13.4755V13.4755C27.4076 13.4755 29.5393 15.6071 29.5393 18.2363V18.2363V21.807"
                            stroke="white" stroke-width="2.97554" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M24.7787 26.5679V30.1385" stroke="white" stroke-width="2.97554" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M30.7299 34.8994H18.8277C17.5125 34.8994 16.4473 33.8342 16.4473 32.519V24.1874C16.4473 22.8723 17.5125 21.807 18.8277 21.807H30.7299C32.0451 21.807 33.1103 22.8723 33.1103 24.1874V32.519C33.1103 33.8342 32.0451 34.8994 30.7299 34.8994Z"
                            stroke="white" stroke-width="2.97554" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <filter id="filter0_i" x="0.974609" y="0.383057" width="47.6087" height="47.6087"
                              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dy="-3.57065"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                      </filter>
                      <linearGradient id="paint0_linear" x1="24.779" y1="0.383057" x2="24.779" y2="47.9918"
                                      gradientUnits="userSpaceOnUse">
                        <stop offset=0 stop-color="#989898"/>
                        <stop offset="1" stop-color="#303030"/>
                      </linearGradient>
                    </defs>
                  </svg>

                </div>

              </div>
              <div class="mt-4">
                <p class="display-3 mb-2">{{ power.fields.title }}</p>
                <p class="body strong"> {{ power.fields.subtitle }}</p>

                <div class="flex my-4" v-show="currentContentOpen !== index">
                  <a v-on:click.stop="openMore(index)" class="big-link">{{ $t('capsuleV2.superpowers.learnMore') }}</a>
                </div>
                <div class="content mt-4">
                  <api-rich-renderer text-type="article" :timer="false" :rich-text="power.fields.content.content"/>
                  <PrimaryButton v-if="current === null" @click.native="doAnim(index)" color="white">
                    {{ $t('capsuleV2.superpowers.add') }}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
          <div v-show="showModal" class="modal">
            <div class="bg-white h-full">
              <div class="basic-container">
                <div class="parent-modal">
                  <div class="flex flex-col align-items-center">
                    <div class="pentagon-modal" ref="pentagonModal">
                      <img v-if="indexModal >= 0 && !block.fields.superPowers[indexModal].fields.new" class="pentagon"
                           alt="pictogramme de pentagone" src="../../assets/img/pentagon_unlocked.png">
                      <img v-if="indexModal >= 0" class="power"
                           :src="block.fields.superPowers[indexModal].fields.superPowerCategory.fields.picto.fields.file.url"
                           alt="">
                      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="locker">
                        <g filter="url(#filter0_i)">
                          <rect x="0.974609" y="0.383057" width="47.6087" height="47.6087" rx="19.0435"
                                fill="url(#paint0_linear)"/>
                          <path class="top-of-locker"
                                d="M20.0176 21.807V18.2363V18.2363C20.0176 15.6071 22.1493 13.4755 24.7784 13.4755V13.4755C27.4076 13.4755 29.5393 15.6071 29.5393 18.2363V18.2363V21.807"
                                stroke="white" stroke-width="2.97554" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M24.7787 26.5679V30.1385" stroke="white" stroke-width="2.97554"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M30.7299 34.8994H18.8277C17.5125 34.8994 16.4473 33.8342 16.4473 32.519V24.1874C16.4473 22.8723 17.5125 21.807 18.8277 21.807H30.7299C32.0451 21.807 33.1103 22.8723 33.1103 24.1874V32.519C33.1103 33.8342 32.0451 34.8994 30.7299 34.8994Z"
                                stroke="white" stroke-width="2.97554" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <filter id="filter0_i" x="0.974609" y="0.383057" width="47.6087" height="47.6087"
                                  filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                           result="hardAlpha"/>
                            <feOffset dy="-3.57065"/>
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                            <feColorMatrix type="matrix"
                                           values="0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                          </filter>
                          <linearGradient id="paint0_linear" x1="24.779" y1="0.383057" x2="24.779" y2="47.9918"
                                          gradientUnits="userSpaceOnUse">
                            <stop offset=0 stop-color="#989898"/>
                            <stop offset="1" stop-color="#303030"/>
                          </linearGradient>
                        </defs>
                      </svg>

                    </div>
                    <p class="display-3 mt-4" v-if="indexModal >= 0">
                      {{ block.fields.superPowers[indexModal].fields.superPowerCategory.fields.famillyName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PrimaryButton @click.native="nextStep" :disabled="!unblock" class="mt-10 mb-8">{{ block.fields.buttonText }}
      </PrimaryButton>
    </div>
  </div>

</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import {mapActions, mapGetters} from "vuex";
import ApiRichRenderer from "@/components/ApiRichRenderer";
import SpriteIcon from "@/components/SpriteIcon";

export default {
  name: "Powers",
  components: {SpriteIcon, ApiRichRenderer, PrimaryButton},
  props: {
    block: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      clicked: false,
      current: null,
      currentPower: null,
      currentContentOpen: null,
      unlocked: [],
      showModal: false,
      indexModal: 0,
      richText: [],
      isLoading: true,
      unblock: false,
    }
  },
  computed: {
    ...mapGetters(["currentUser", "newCapsule", "currentBlock"]),
    getProgress() {
      return ((this.index + 1) / this.totalSteps) * 100 + '%';
    }
  },
  methods: {
    ...mapActions(['addSuperPower']),
    nextStep() {
      this.$emit('next-step', this.index)
    },

    goBack() {
      this.showModal = false
      this.current = null
    },
    checkIfPowerExist() {
      return new Promise((resolve, reject) => {
        this.$http.get('/users/' + this.currentUser.id + '/super_powers').then((resp) => {
          if (resp.data.find(el => el.contentful_id === this.currentPower.sys.id)) {
            reject()
          } else {
            resolve(resp.data.find(el => el.contentful_id === this.currentPower.sys.id))
          }
        })
      })
    },
    handlePower() {
      this.showModal = false
      // this.unblockPower([this.block.fields.superPowers[this.indexModal].fields.title, this.powers[this.indexModal].fields.subtitle, this.powers[this.indexModal].fields.icon.fields.file.url, this.indexModal])
      this.unblock = true
      this.unlocked.push(this.indexModal)
      // this.checkIfPowerExist().then(() => {
      //   this.$http.post('/super_powers', {
      //     'super_power': {
      //       contentful_id: this.powers[this.indexModal].sys.id,
      //       user_id: this.capsules.currentUsers.child.id
      //     }
      //   }).then(resp => {
      //     if (!this.$route.query.env) {
      //       this.capsGettingUpdated = true
      //       this.$http.put('/capsules/' + this.capsules.capsuleIdApi, {blocks: JSON.stringify(this.capsules)}).then(() => {
      //         this.capsGettingUpdated = false
      //       })
      //     }
      //   })
      // }).catch(err => {
      // })
      // this.$emit('update-caps')
    },
    /**
     * function qui requête contentful suivant l'index du pouvoir par défault 0
     */
    richTextRenderer(id) {
      this.richText = this.block.fields.superpowers[id].fields.content.content
      this.isLoading = false
    },
    /**
     * function qui récupère l'index du pouvoir et lance l'animation
     * @params {index}
     */
    doAnim(index) {
      this.current = index
      this.currentPower = this.block.fields.superPowers[index]
      this.addSuperPower({blockId: this.currentBlock.id, superPower: this.currentPower.sys.id})
      this.$http.post('/super_powers', {
        'super_power': {
          contentful_id: this.currentPower.sys.id,
          user_id: this.currentUser.id
        }
      }).then(_ => {
        if (!this.$route.query.env) {
          this.addSuperPower({blockId: this.currentBlock.id, superPower: this.currentPower.sys.id})
          let data = JSON.stringify(this.newCapsule)

          this.$http.put('/capsules/' + this.newCapsule.id, {
            current_step: this.newCapsule.currentStep,
            is_completed: this.currentStep === 'end',
          })
        }
      })
      this.animation(index)
    },
    /**
     * affiche la modal et lance l'animation du contenu à l'intérieur
     */
    animation(id) {
      this.showModal = true
      this.indexModal = id

      setTimeout(() => {
        this.$refs.pentagonModal.classList.add('show')
        setTimeout(() => {
          this.$refs.pentagonModal.classList.add('unlock')
          setTimeout(() => {
            this.$refs.pentagonModal.classList.add('color')
            setTimeout(() => {
              this.showModal = false
              this.unblock = true
            }, 2000)
          }, 1000)
        }, 1000)
      }, 500)

      // this.requestRenderer()
    },

    openMore(index) {
      if (this.currentContentOpen === index) {
        this.currentContentOpen = null
      } else {
        this.currentContentOpen = index
      }
      document.querySelector('#power-' + index).querySelector('.content').classList.toggle('open')
    }
  },
  created() {
    window.scrollTo(0, 0);
    // if (this.capsules.power.length > 0) {
    //   this.unlocked.push(this.capsules.power[3])
    //   this.unblock = true
    // }

    let block = this.newCapsule.blocks.find(el => el.id === this.block.sys.id)

    if (block && block.superPower) {
      this.unblock = true
      this.currentPower = block.superPower
      this.current = this.block.fields.superPowers.findIndex(el => el.sys.id === this.currentBlock.superPower)
      this.currentContentOpen = this.block.fields.superPowers.findIndex(el => el.sys.id === this.currentBlock.superPower)
      this.indexModal = this.block.fields.superPowers.findIndex(el => el.sys.id === this.currentBlock.superPower)
    }

    this.$nextTick(() => {
      this.richText = this.block.fields.superPowers[0].fields.content.content
      this.isLoading = false
    })

  }

}
</script>

<style scoped lang="scss">
.head {
  background: var(--head-bg);

}

.bubble {
  background: var(--head-bg);
  position: relative;
  z-index: -1;
  height: 100px;
  border-radius: 100%;
  transform: translateY(-50%);
}

.triangle {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 100px);
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid var(--head-bg);
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  transform: rotate(10deg);
}

.head-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-container img {
  width: 48px;
}

.progress {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  height: 10px;
  width: 100%;
  position: relative;
}

.progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: 100%;
  border-radius: 12px;
  background: white;
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-surface-soft);
  background: #FBD354;

  .parent-modal {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pentagon-modal {
    margin: 0 auto;
    width: 146px;
    height: 146px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    transform: scale(0) translateY(0);
    transition: all 1s ease;
    filter: grayscale(1);

    &.show {
      transform: scale(1);
    }

    &.color {
      filter: grayscale(0);
    }

    &.unlock {
      .locker {
        opacity: 0;

        .top-of-locker {
          transform-origin: 50% 50%;
          transform: rotate(45deg) translateY(-5px) translateX(-4px);

        }
      }
    }


    img:nth-child(2) {
      position: absolute;
      z-index: 2;
      width: 62px;
    }

    .locker {
      transition: opacity 0.2s linear;
      transition-delay: 0.2s;
      opacity: 1;
      position: absolute;
      bottom: 0;
      right: 0;

      .top-of-locker {
        transition: 0.15s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        transform: none;

      }
    }
  }
}


.content {
  max-height: 0;

  &.open {
    max-height: 100%;
    padding-bottom: 16px;
  }
}

.parent-power {
  border-radius: var(--radius-default);
  position: relative;
  padding: 3px;

  .second-parent {
    padding: 12px 12px 0 12px;
    border: 2px solid #C4C8C9;
    box-shadow: 0 4px 0 #C4C8C9;
    border-radius: var(--radius-default);
    transition: background-color 0.2s cubic-bezier(0.15, 0.95, 1, 1);
    background-color: var(--color-surface-soft);
    overflow: hidden;

    .pentagon {
      width: 64px;
      height: 64px;
      position: relative;
      transition: filter 0.2s ease-in-out;
      filter: grayscale(1);
    }

    .power {
      display: block;
      width: 32px;
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .locker {
      transition: opacity 0.2s linear;
      transition-delay: 0.2s;
      opacity: 1;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;

      .top-of-locker {
        transition: 0.15s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        transform: none;

      }
    }
  }
}

.parent-power.selected {


  .second-parent {
    background: #E6F6FC;
    border: 2px solid #09A6E6;
    box-shadow: 0 4px 0 #09A6E6;

    .pentagon {
      filter: grayscale(0);
    }

    .locker {
      opacity: 0;

      .top-of-locker {
        transform-origin: 50% 50%;
        transform: rotate(45deg) translateY(-5px) translateX(-4px);

      }
    }
  }


}

.big-link {
  display: block;
  margin: 0 0 0 auto;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: black;
}

@media screen and (min-width: 576px) {
  .modal-text {
    > p:nth-child(1) {
      font-size: 1.25rem;
      line-height: 2rem;
    }

    &.anim {
      overflow: hidden !important;
    }
  }


}

</style>
