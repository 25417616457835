<template>
  <div class="parent">
    <slot></slot>
  </div>
</template>

<script>
import expiredCheck from "@/library/expiredCheck";
import currentFamilyCheck from "@/library/currentFamilyCheck";
import cacheMixin from "@/library/cache-mixin";
import {mapGetters} from "vuex";

export default {
  name: "home",
  mixins: [expiredCheck, currentFamilyCheck, cacheMixin],
  computed: {
    ...mapGetters(['currentLocale']),

  },
  beforeCreate() {
    document.body.className = '';
  },
  created() {

    this.$i18n.locale = this.currentLocale
  }

}
</script>

<style lang="scss" scoped>
.parent {
  background: #FFF7F0;
}
</style>