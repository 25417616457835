<template>
  <transition name="fade" @enter="showModal = true">
    <div v-if="isVisible" class="overlay" @click="type !== 'intro-caps'? showModal = false: ''">
      <!-- Modal -->
      <transition name="fade-moove" @leave="$emit('close-modal')">
        <div v-if="showModal" class="modal" @click.stop>
          <header>
            <div class="title">
              <SpriteIcon
                  :iconName="iconName"
                  :color="type === 'error' ? 'var(--color-warning)' : 'var(--color-primary-strong)'"
                  :background="type === 'error' ? 'var(--color-warning-light)' : 'var(--color-primary-soft)'"
                  v-if="type !== 'intro-caps' && iconName !== null"
                  class="mr-4"
              />

              <h4 class="display-3">{{ title }}</h4>
            </div>
            <UniButton appearance="subtle" icon-name="close" @click.native="showModal = false"
                       v-if="type !== 'intro-caps'"/>
          </header>
          <div class="divider"></div>
          <div class="body">
            <slot name="body"></slot>
          </div>
          <footer v-if="hasFooterSlot">
            <slot name="footer"></slot>
          </footer>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import UniButton from "./UniButton";
import SpriteIcon from "@/components/SpriteIcon";

export default {
  name: "UniModal",
  components: {
    SpriteIcon,
    UniButton,
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots['footer']
    }
  },
  methods: {},
  props: {
    title: String,
    iconName: {
      type: String,
      default: null
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String, // Error
      default: '',
    }
  },
  data: function () {
    return {
      showModal: true
    };
  }
};
</script>

<style scoped lang="scss">

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
}

.modal {
  position: absolute;
  background: var(--color-surface-soft);
  box-shadow: var(--shadow-l);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: -moz-flex;
  -moz-flex-direction: column;
  -moz-flex-wrap: nowrap;

  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-flex-wrap: nowrap;

  display: -ms-flex;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

header, .body, footer {
  padding: 1rem 1.5rem;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  height: 72px;

  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;

  > h4 {
    margin: 0;
  }
}

.body {
  width: 100%;
  height: calc(100% - 80px - 97px);
  padding: 0 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 24px 0;

  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}

footer {
  height: 80px;
  z-index: 2;
  bottom: 0;
  background: #F9F9F9;
  width: 100%;

  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}

.title {
  display: flex;
  align-items: center;
}

/* Transitions */
.fade-enter-active {
  transition: opacity .2s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.fade-moove-enter-active,
.fade-moove-leave-active {
  transition: var(--transition-default);
}

.fade-moove-enter /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(100vh);
}

.divider {
  height: 1px;
  background: #C4C8C9;
  margin: 0 24px;
}

@include screen-m {
  .overlay {
    background: rgba(9, 30, 66, 0.54);
  }
  .modal {
    width: 670px;
    margin: auto;
    max-height: 650px;
    border-radius: var(--radius-big);
    overflow: clip;
  }

  /* Transitions */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .fade-moove-enter-active,
  .fade-moove-leave-active {
    transition: 0.25s;
  }
  .fade-moove-enter /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    transform: translateY(32px);
  }
  .fade-moove-leave-to {
    opacity: 0;
    transform: translateY(-32px);
  }
}
</style>