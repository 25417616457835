<template>
  <uni-modal :title="$t('pricing.title')"
             :is-visible="modalSub" @close-modal="modalSub = false">
    <template v-slot:body>
      <handle-subscription :capsule-id="capsuleId"/>
    </template>
  </uni-modal>
</template>

<script>
import UniModal from "@/components/UniModal";
import PrimaryButton from "@/components/PrimaryButton";
import HandleSubscription from "@/components/handleSubscription.vue";

export default {
  name: "handleSubscriptionModal",
  components: {HandleSubscription, PrimaryButton, UniModal},
  props: {
    capsuleId: {
      type: String,
    },
    openModal: {
      type: Boolean,
      required: true
    },
  },
  watch: {
    openModal(newValue) {
      this.modalSub = newValue;
    },
    modalSub(newValue) {
      if (!newValue) {
        this.$emit('close-modal');
      }
    }
  },
  data() {
    return {
      modalSub: false,
    }
  },
  updated() {
    this.modalSub = this.openModal
  },
  created() {
    this.modalSub = this.openModal
  }
}
</script>

<style scoped>

</style>