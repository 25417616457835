<template>
  <div class="avatar" :class="size || ''">
    <img src="../assets/img/avatars/avatar-6.png" alt="" v-if="type==='child' && !img && !home">
    <img :src="img" alt="" v-if="type==='child' && img && !home">

    <img src="../assets/img/avatars/avatar-4.png" alt="" v-if="type==='parent' && !img ">
    <img :src="img" alt="" v-if="type==='parent' && img">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="finished"
         v-show="type==='child' && showSticker" class="sticker">
      <rect width="20" height="20" rx="10" fill="#09A6E6"/>
      <path d="M15.1663 7.29175L8.74967 13.7084L5.83301 10.7917" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="sticker"
         v-if="!finished && !home" v-show="type==='child' && showSticker">
      <rect width="20" height="20" rx="10" fill="#09A6E6"/>
      <circle cx="5" cy="10" r="2" fill="white"/>
      <circle cx="10" cy="10" r="2" fill="white"/>
      <circle cx="15" cy="10" r="2" fill="white"/>
    </svg>
    <div class="edit" v-if="edit">
      <svg width="18" height="18" fill="white" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 220.001 220.001">
<g>
	<polygon points="0,220 59.34,213.86 6.143,160.661 	"/>
  <path d="M132.018,34.787l53.197,53.197L69.568,203.631L16.37,150.434L132.018,34.787z M212.696,60.502
		c9.738-9.738,9.742-25.527,0-35.268l-17.93-17.93c-9.738-9.74-25.529-9.738-35.268,0l-17.346,17.347l53.199,53.196L212.696,60.502z
		"/>
</g>
</svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Avatar',
  data() {
    return {}
  },
  props: {
    type: {
      type: String, required: true,
    },
    size: {
      type: String, required: false,
    },
    finished: {
      type: Boolean, required: false,
    },
    showSticker: {
      default: false,
      type: Boolean,
      required: false
    },
    edit: {
      default: false,
      type: Boolean,
      required: false
    },
    img: {
      type: String,
      required: false
    },
    home: {
      type: Boolean,
      default: false
    }
  },

}
</script>

<style scoped lang="scss">
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.1);

  &.large {
    width: 80px;
    height: 80px;
  }

  &.medium {
    width: 64px;
    height: 64px;
  }

  &.small {
    width: 40px;
    height: 40px;

    .sticker {
      width: 20px;
      height: 20px;
      right: -10px;
      bottom: -4px;
    }
  }

  .sticker {
    width: 24px;
    height: 24px;
    background: var(--color-success-soft);
    border-radius: 9999px;
    position: absolute;
    bottom: -6px;
    right: 0;
    display: grid;
    place-content: center;
  }

  .edit {
    width: 28px;
    height: 28px;
    background: var(--color-blue-selected);
    border-radius: 9999px;
    position: absolute;
    bottom: -6px;
    right: 0;
    display: grid;
    place-content: center;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9999px;
  }
}
</style>
