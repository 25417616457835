<!--suppress ALL -->
<template>
  <div class="admin-stats">
    <div class="header-form">
      <h1>Statistiques des capsules</h1>

      <uni-input
          label="Recherche"
          type="text"
          v-model="filterKey"
          class="col-6"
      />
    </div>

    <div class="contain">
      <table class="table" ref="capsule_table">
        <thead>
        <tr class="theader">
          <th class="table_header" rowspan="2" @click="sortBy('capsule_name')">Titre <span
              v-if="this.currentSort === 'capsule_name:asc'">▼</span><span
              v-else-if="this.currentSort === 'capsule_name:desc'">▲</span>
          </th>
          <th class="table_header" rowspan="2" @click="sortBy('published_date')">Date <span
              v-if="this.currentSort === 'published_date:asc'">▼</span><span
              v-else-if="this.currentSort === 'published_date:desc'">▲</span>
          </th>
          <th class="table_header" rowspan="2" colspan="2" @click="sortBy('beginning.total')">Début</th>
          <th class="table_header" rowspan="2" colspan="2" @click="sortBy('middle.total')">Milieu</th>
          <th class="table_header" rowspan="2" colspan="2" @click="sortBy('end.total')">Fin</th>
          <th class="table_header" colspan="3">Notes moyennes</th>
        </tr>
        <tr>
          <th class="table_header sub" @click="sortBy('happy')">Heureux</th>
          <th class="table_header sub" @click="sortBy('learn')">Pédagogique</th>
          <th class="table_header sub" @click="sortBy('inspire')">Inspirant</th>
        </tr>

        </thead>
        <tbody>
        <tr class="trow" v-for="stat in filteredStats">
          <td class="table_cell title">
            <router-link :to="{name: 'admin.statsCapsules.details', params: {capsule_id:  stat.capsule_id}}">
              {{ stat.capsule_name }}
            </router-link>
          </td>
          <td class="table_cell date">{{ displayDate(stat.published_date) }}</td>
          <td class="table_cell figure">{{ stat.beginning.total }}</td>
          <td class="table_cell figure sub">{{ stat.beginning.percentage }}%</td>
          <td class="table_cell figure">{{ stat.middle.total }}</td>
          <td class="table_cell figure sub">{{ stat.middle.percentage }}%</td>
          <td class="table_cell figure">{{ stat.end.total }}</td>
          <td class="table_cell figure sub">{{ stat.end.percentage }}%</td>
          <td class="table_cell figure">{{ stat.ratings.enjoy }}</td>
          <td class="table_cell figure sub">{{ stat.ratings.learn }}</td>
          <td class="table_cell figure sub">{{ stat.ratings.inspire }}</td>
        </tr>
        </tbody>
      </table>
      <loader class="center-loader" v-if="isLoading" :color="true"/>
    </div>
  </div>
</template>

<script>
import UniInput from "@/components/UniInput.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import UniButton from "@/components/UniButton.vue";
import SpriteIcon from "@/components/SpriteIcon.vue";
import Loader from "@/components/Loader";
import moment from 'moment';

export default {
  name: "AdminStats",
  components: {UniInput, SpriteIcon, UniButton, PrimaryButton, Loader},
  data() {
    return {
      isLoading: false,
      isLoadingExport: {},
      isLoadingCopy: {},
      hasData: false,
      statsSave: [],
      stats: [],
      sortKey: '',
      currentSort: '',
      sortedByAsc: false,
      filterKey: '',
    }
  },
  computed: {
    filteredStats() {
      if (this.stats.length > 0) {
        return this.stats.filter(x => {
          if (x.capsule_name.includes(this.filterKey)) return true
        })
      }
    }
  },
  methods: {
    displayDate(date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    getData() {
      this.isLoading = true

      this.$http.get('/stats/capsules')
          .then(res => {
            this.statsSave = res.data
            this.stats = this.statsSave.slice()
            this.isLoading = false
          })
          .catch(_ => {
            this.isLoading = false
          })
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey && this.sortedByAsc === true) {
        this.stats = this.statsSave.slice()
        this.sortKey = null
        this.sortedByAsc = true
        this.currentSort = null
      } else if (this.sortedByAsc) {
        this.stats.sort((x, y) => (x[sortKey] == null ? -1 : y[sortKey] == null ? 1 : x[sortKey] > y[sortKey] ? -1 : 1));
        this.sortKey = sortKey
        this.sortedByAsc = false
        this.currentSort = sortKey + ':asc'
      } else {
        this.stats.sort((x, y) => (x[sortKey] == null ? 1 : y[sortKey] == null ? -1 : x[sortKey] < y[sortKey] ? -1 : 1));
        this.sortKey = sortKey
        this.sortedByAsc = true
        this.currentSort = sortKey + ':desc'
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">

.header-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 8px 0;

  > * {
    margin: auto;
    height: 90%;
    width: 80%;
  }
}

.contain {
  margin-top: 20px;
}

.date-container {
  display: inline-flex;

  * {
    padding: 6px;
  }
}

.max-w {
  max-width: 300px;
}

.table {
  margin-bottom: 2rem;
  width: 100%;
  min-width: 1000px;
  display: table;
  text-align: center;
  font-weight: 400;
  border-collapse: separate;
  border-spacing: 0
}

.theader {
  display: table-row;
}

.title_col {
  max-width: 30px;
}

.table_header {
  display: table-cell;
  border-top: var(--color-text-soft) 3px solid;
  border-bottom: var(--color-text-soft) 1px solid;
  background: var(--color-secondary);
  color: black;
  padding: 6px;
  font-weight: 700;
  column-span: all;
  margin: auto;
  border-left: var(--color-text-soft) 1px solid;

  &:last-child {
    border-right: var(--color-text-soft) 3px solid;
    border-top-right-radius: 1.5rem;
  }

  &:first-child {
    border-left: var(--color-text-soft) 3px solid;
    border-top-left-radius: 1.5rem;
  }

  &:hover {
    cursor: pointer;
    background: var(--color-secondary-soft);
  }

  &.sub {
    font-style: italic;
    width: 100px;
    max-width: 100px;
    padding: 2px;
    font-size: 14px;
    border: {
      top: var(--color-text-soft) 0 solid;
      right: #1C2326 0;
    }

    &:first-child {
      border: {
        left: var(--color-text-soft) 1px solid;
      }
      border-top-left-radius: 0;
    }

    &:last-child {
      border: {
        right: var(--color-text-soft) 3px solid;
      }
      border-top-right-radius: 0;
    }
  }
}

.table_cell {
  display: table-cell;
  border: {
    top: var(--color-text-soft) 2px solid;
    left: var(--color-text-soft) 1px solid;
  }
  padding: 10px 8px;
  width: 110px;

  &.title {
    text-align: left;
    max-width: 400px;
    min-width: 300px;

    a {
      font-weight: normal;

      &:hover {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }

  &.date {
    text-align: left;
    font-style: italic;
    width: 100px;
  }

  &.figure {
    text-align: center;
    font-weight: bold;
    width: 55px;
  }

  &.sub {
    border: {
      left: var(--color-text-soft) 0 solid;
    }
  }

  &:first-child {
    border-left: var(--color-text-soft) 3px solid;
  }

  &:last-child {
    border-right: var(--color-text-soft) 3px solid;
  }
}

.trow {
  display: table-row;

  &:last-child {
    .table_cell {
      border-bottom: var(--color-text-soft) 3px solid;

      &:last-child {
        border-bottom-right-radius: 1.5rem;
      }

      &:first-child {
        border-bottom-left-radius: 1.5rem;
      }
    }
  }
}

.positive {
  color: green;

  &:before {
    content: "+ "
  }
}

.negative {
  color: darkred;
}

.copy-button {
  margin: 0;
}

.center-loader {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  > ::v-deep svg {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 650px) {
  .table {
    display: block;
  }

  .table_row:nth-child(2n+3) {
    background: none;
  }

  .theader {
    display: none;
  }

  .table_row > .table_cell:nth-child(odd) {
    display: table-cell;
    width: 50%;
  }

  .table_cell {
    display: table-cell;
    width: 50%;
  }

  .table_row {
    display: table;
    width: 100%;
    border-collapse: separate;
    padding-bottom: 20px;
    margin: 5% auto 0;
    text-align: center;
  }

  .table_row > .table_cell:last-child {
    border-left: none;
    border-top-right-radius: 1.5rem;
  }

  .table_row > .table_cell:first-child {
    border-left: var(--color-text-soft) 3px solid;
    border-right: var(--color-text-soft) 3px solid;
  }

  .table_row > .table_cell:first-child {
    border-top: var(--color-text-soft) 2px solid;
    border-top-left-radius: 1.5rem;
  }

  .table_row > .table_cell:last-child {
    border-top: var(--color-text-soft) 2px solid;
  }

  .table_row > .table_cell:last-child {
    border-right: var(--color-text-soft) 2px solid;
  }

  .table_row > .table_cell:first-child {
    border-bottom-left-radius: 1.5rem;
  }

  .table_row > .table_cell:last-child {
    border-bottom-right-radius: 1.5rem;
  }

  .table_row:last-child > .table_cell:last-child {
    border-bottom-left-radius: 0;
  }
}
</style>