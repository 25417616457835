<template>
  <component
      :is="tag"
      class="button"
      :class="[{ block: isBlock }, { blockMobileOnly: isBlockOnMobile }, appearance, size, { loading: isLoading }, { focusActive: isFocusActive }, { isIconOnly: !label }, { isIcon: iconName }, { iconRight: isIconRight }]"
      :disabled="disabled || isLoading"
      :href="isExternalLink ? to : undefined"
      :to="to"
  >
    <sprite-icon v-if="iconName && !isLoading" :name="iconName" :size="size"/>
    <span>{{ label }}</span>
    <uni-spinner v-show="isLoading" :size="size" :is-colored="appearance !== 'primary'"/>
  </component>
</template>

<script>
import SpriteIcon from "./SpriteIcon.vue";
import UniSpinner from "./UniSpinner";
// import UniSpinner from "./UniSpinner.vue";

export default {
  name: "UniButton",
  components: {
    UniSpinner,
    SpriteIcon
    // UniSPinner
  },
  props: {
    // Properties for icons
    iconName: String,
    // Properties for Button
    isBlock: Boolean,
    isBlockOnMobile: Boolean,
    to: Object | String,
    appearance: {
      type: String // primary | subtle | link | danger
    },
    size: String, // small or big
    label: String,
    isLoading: Boolean,
    isIconRight: Boolean,
    disabled: Boolean,
    isExternalLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag() {
      if (this.isExternalLink) {
        return 'a'
      } else {
        if (this.to === undefined) {
          return 'button'
        } else {
          return 'router-link'
        }
      }
    }
  },
  data: function () {
    return {
      doesUserClickedIt: false,
      isFocusActive: false
    };
  }
};
</script>
<style scoped lang="scss">
/* Default appearance */
.button {
  /* Button tokens */
  --btn-color: var(--color-text-strong);
  --btn-bg: var(--color-primary);
  --btn-bg-active: var(--color-surface-4);
  --btn-color-active: var(--color-text-strong);
  --btn-color-disabled: var(--color-disabled);
  --btn-bg-disabled: var(--color-surface-2);
  /* Icons tokens */
  --btn-icon-color: var(--color-text);
  --btn-icon-color-hover: var(--color-text);
  /* Element properties */
  background: var(--btn-bg);
  color: var(--btn-color);
  font-weight: var(--font-weight-semi-bold);
  padding: 0 var(--space-16);
  border-radius: var(--radius-default);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  transition: var(--transition-quick);
  position: relative;
  height: var(--space-40);
}

span {
  font-weight: var(--font-weight-bold);
}

.button > svg {
  transition: var(--transition-quick);
  stroke: var(--btn-icon-color);
}

.button:hover {
  background: var(--btn-bg-hover);
  text-decoration: none;

  > svg {
    stroke: var(--btn-icon-color-hover);
  }
}

.button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 4px var(--color-primary-strong);
}

.button:active {
  background: var(--btn-bg-active);
  color: var(--btn-color-active);
  transform: scale(0.97);
}

.button.focusActive {
  box-shadow: var(--focus-shadow);
}

.button.focusActive:hover {
  box-shadow: none;
}

.button.iconRight {
  flex-direction: row-reverse;
}

.button:disabled {
  color: var(--btn-color-disabled);
  background: var(--btn-bg-disabled);
  cursor: initial;
}

.button.loading {
  span {
    opacity: 0;
  }

  .spinner {
    position: absolute;
  }
}

.button:disabled:hover {
  transform: initial;
}

.button > svg {
  margin-right: var(--space-8);
}

.button.iconRight > svg {
  margin: 0 0 0 var(--space-8);
}

.button.isIcon {
  /* We have to use custom padding value because of visual adjustment */
  padding: 0 var(--space-16) 0 12px;
}

.button.isIcon.iconRight {
  padding: 0 14px 0 var(--space-16);
}

.button.isIconOnly > svg {
  margin-right: 0px;
}

.loading {
  --btn-bg-hover: var(--color-surface-2);
  --btn-bg-active: var(--color-surface-2);
  --btn-bg-disabled: var(--color-surface-2);
  cursor: initial;
}

.loading > span {
  opacity: 0;
}

.loading > > > .icon {
  opacity: 0;
}

.loading > > > .spinner {
  position: absolute;
}

/* Button sizes */
/* Big */
.big {
  padding: 0 var(--space-24);
  border-radius: var(--radius-medium);
  height: 48px;
}

.big.isIcon {
  padding: 0 var(--space-24) 0 var(--space-20);
}

.big.isIcon ::v-deep svg {
  stroke: var(--color-surface-0);
}

.big.isIcon.iconRight {
  padding: 0 var(--space-20) 0 var(--space-24);
}

.big.isIcon.isIconOnly {
  padding: 0;
  height: 48px;
}

/* Default  */
.isIcon.isIconOnly {
  padding: 0;
  height: var(--space-40);
  width: var(--space-40);
  border-radius: var(--radius-default);
}

/* Small */
.small {
  padding: 0 var(--space-12);
  height: var(--space-24);
}

.small.isIcon.iconRight {
  padding: 0 10px 0 var(--space-12);
}

.small.isIcon.isIconOnly {
  padding: 0;
  height: var(--space-32);
  width: var(--space-32);
}

.button.small > svg {
  margin-right: var(--space-4);
}

.button.isIconOnly.small > svg {
  margin-right: 0px;
}

/* Block size */
.block {
  width: 100%;
}

.blockMobileOnly {
  width: 100%;
}

/* Secondary appearance */
.secondary {
  --btn-color: var(--color-primary-strong);
  --btn-bg: var(--color-primary-soft);

  --btn-bg-hover: var(--color-primary-strong);
  --btn-color-active: var(--color-surface-0);
  /* Icons tokens */
  --btn-icon-color: var(--color-primary-strong);
  --btn-icon-color-hover: var(--color-surface-0);
}

.secondary:hover {
  color: var(--color-surface-0)
}

/* Primary appearance */
.primary {
  --btn-color: var(--color-surface-0);
  //update colors
  --btn-bg: var(--color-primary-strong);
  --btn-bg-hover: var(--color-primary-strong);

  --btn-bg-active: var(--color-primary-soft);
  --btn-color-active: var(--color-text);
  /* Icons tokens */
  --btn-icon-color: var(--color-surface-0);
}
.primary-white {
  --btn-color: var(--color-primary-strong);
  //update colors
  --btn-bg: var(--color-surface-0);
  --btn-bg-hover: var(--color-surface-0);

}

.primary.loading {
  --btn-bg-hover: var(--color-primary);
  --btn-bg-active: var(--color-primary);
  --btn-bg-disabled: var(--color-primary);
}

/* Subtle appearance */
.subtle {
  --btn-bg: transparent;
  --btn-color: var(--color-text);
  --btn-bg-hover: var(--color-surface-2);
  --btn-bg-active: var(--color-surface-3);
  --btn-bg-disabled: transparent;
}

.subtle:disabled {
  background: none;
}

/* Link appearance */
.link {
  --btn-color: var(--color-primary);
  --btn-bg: transparent;
  --btn-bg-hover: transparent;
  --btn-bg-active: transparent;
  --btn-color-active: var(--color-primary);
  --btn-bg-disabled: transparent;
  /* Icons tokens */
  --btn-icon-color: var(--color-primary);
  font-weight: var(--font-weight-regular);
  height: initial;
  width: initial;
  padding: 0;
}

.link:hover {
  color: var(--color-primary-strong);
}

.link:active {
  transform: initial;
}

.link:disabled:hover {
  text-decoration: none;
  color: var(--color-disabled);
}

/* Danger appearance */
.danger {
  --btn-color: var(--color-error);
  --btn-bg: var(--color-error-bg);
  --btn-bg-hover: var(--color-r50);
  --btn-bg-active: var(--color-r75);
  --btn-color-active: var(--color-error);
  --btn-icon-color: var(--color-error);
}

.danger.loading {
  --btn-bg-hover: var(--color-error-bg);
  --btn-bg-active: var(--color-error-bg);
  --btn-bg-disabled: var(--color-error-bg);
}

@include screen-m {
  .blockMobileOnly {
    width: auto;
  }
}
</style>
