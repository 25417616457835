<template>
  <div>
    <loader v-if="isLoading" class="center-loader"/>
    <div class="parent-account flex h-screen flex-col" v-else>
      <header class="max-container relative">
        <div class="p-7">
          <router-link tag="button" to="/" class="absolute left-7">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </router-link>
          <p class="header-title display-1 white text-align-center">{{$t('settings.title')}}</p>
        </div>
      </header>
      <div class="main-container wrap rounded-t-3xl flex-grow">
        <div class="basic-container grid-container grid">
          <div class="parent-link-container">
            <p class="display-1">{{ $t('settings.parent.title') }}</p>
            <div class="margin-bot-16">
              <h2 class="grey inline margin-right-4">{{ name }}</h2>
              <p class="grey caption inline">{{ currentFamily.email }}</p>
              <p class=" grey caption">{{ $t('settings.parent.membership') }} {{ $sexyDate(new Date(currentFamily.created_at.replace(/-/g, "/"))) }}</p>
            </div>
            <div class="grid grid-cols-3 justify-items-center gap-y-4 mb-8 text-align-center">
              <router-link :to="{name: 'updateUser', params: {id: adult.id}}" v-for="(adult, index) in familyState.users.filter(u => u.user_type === 'adult')">
                <Avatar :img="adult.attachment_url || adult.avatar"
                        type="parent" size="large" :show-sticker="false" :edit="true"></Avatar>
                <p class="mt-1 opacity-40">{{ adult.firstname }}</p>
              </router-link>
            </div>
            <a class="uni-link display-2 mb-8"
               v-if="currentFamily.isSubscribed()"
               @click="openCustomerPortal">{{ $t('settings.subscription') }}</a>
            <div>
              <div class="mb-4 flex align-items-center mb-8" v-if="!currentFamily.isInvited()">
                <p class="uni-link display-2 inline mr-2" :class="{'active' : changeInfos}"
                   @click="changeInfos = !changeInfos">{{ $t('settings.personalInfos.title') }}</p>
                <sprite-icon name="chevron-down" size="big" class="inline chevron"
                             :style="changeInfos? '': 'transform: rotate(180deg);'"></sprite-icon>
              </div>
              <div class="personal-info" v-if="changeInfos && !currentFamily.isInvited()">
                <validation-observer tag="form" ref="formInput" v-slot="{valid}" @submit.prevent="test">
                  <uni-input
                      :label="$t('settings.personalInfos.placeholder')"
                      id="input-test"
                      type="email"
                      :placeholder="$t('settings.personalInfos.placeholder')"
                      v-model="email"
                      rules="required|email"
                  />
                  <primary-button type="submit" :clicked="clicked"
                                  @click.native="valid? clicked = true: clicked = false"
                                  class="mb-5">{{ $t('settings.personalInfos.action') }}
                  </primary-button>
                </validation-observer>
              </div>
              <div class="mb-4 flex align-items-center" v-if="!currentFamily.isInvited()">
                <p class="uni-link display-2 inline mr-2" :class="{'active' : changePwd}"
                   @click="changePwd = !changePwd">{{ $t('settings.password.title') }}</p>
                <sprite-icon name="chevron-down" size="big" class="inline chevron"
                             :style="changePwd? '': 'transform: rotate(180deg);'"></sprite-icon>
              </div>
              <div class="personal-info" v-if="changePwd && !currentFamily.isInvited()">
                <validation-observer tag="form" ref="formPwd" v-slot="{valid}" @submit.prevent="modifyPwd()">
                  <uni-input-password
                      :label="$t('settings.password.placeholder')"
                      id="password"
                      :placeholder="$t('settings.password.placeholder')"
                      vid="password"
                      v-model="pwd"
                      rules="required|passwordTest"/>
                  <UniInputPassword
                      :label="$t('settings.password.newPlaceholder')"
                      id="input-password-confirm"
                      :placeholder="$t('settings.password.placeholder')"
                      v-model="pwdConfirm"
                      rules="required|confirmed:password|passwordTest"
                      min="6"
                  />
                  <primary-button type="submit" :clicked="clicked"
                                  @click.native="valid? clicked = true: clicked = false"
                                  class="mb-5">{{ $t('settings.password.action') }}
                  </primary-button>
                </validation-observer>
              </div>
            </div>
          </div>

          <div class="kid-link-container">
            <p class="display-1 margin-bot-16">{{ $t('settings.children.title') }}</p>
            <div v-if="hasKids">
              <div class="grid grid-cols-3 justify-items-center gap-y-4 mb-8 text-align-center">
                <router-link :to="{name: 'updateUser', params: {id: kid.id}}" v-for="(kid, index) in kids">
                  <Avatar :img="kid.attachment_url || kid.avatar"
                          type="child" size="large" :show-sticker="false" :edit="true"></Avatar>
                  <p class="mt-1 opacity-40">{{ kid.firstname }}</p>
                </router-link>
              </div>
              <div class="mb-4 flex align-items-center">
                <p class="uni-link display-2 inline mr-2" :class="{'active' : seePowers}"
                   @click="seePowers = !seePowers">{{ $t('settings.children.superpowers') }}</p>
                <sprite-icon name="chevron-down" size="big" class="inline chevron"
                             :style="seePowers? '': 'transform: rotate(180deg);'"></sprite-icon>
              </div>
              <div v-if="seePowers">
                <div class="card-power-preview rounded-3xl relative mb-4">
                  <button class="absolute left-4 top-2" @click="prev('kids')" v-if="kids.length > 1">
                    <sprite-icon name="chevron-left" size="big" class="inline chevron"></sprite-icon>
                  </button>

                  <button class="absolute right-4 top-2" @click="next('kids')" v-if="kids.length > 1">
                    <sprite-icon name="chevron-right" size="big" class="inline chevron"></sprite-icon>
                  </button>
                  <transition-group name="name" tag="div"
                                    class="flex text-align-center overflow-hidden name-parent">
                    <p class="name" v-for="(kid, index) in kids" ref="kid" :id="kid.id" :data-index="index"
                       :key="index">
                      {{ kid.firstname }}</p>
                  </transition-group>

                  <hr>
                  <router-link :to="{name: 'kidPowerView', params: {name: kids[0].firstname, id: kids[0].id, powerId: power.sys.id} }"
                               class="parent-power m-4" ref="parentPower" :id="index"
                       v-for="(power, index) in powers.slice(0,3)" v-if="powers.length>0">
                    <div class="second-parent flex">
                      <div class="pentagon">
                        <img class="" alt="pictogramme de pentagone" src="../assets/img/pentagon_unlocked.png">
                        <img class="power" :src="power.fields.icon.fields.file.url" alt="">
                      </div>
                      <div class="flex justify-center flex-col ml-4 flex-1 ">
                        <p class="strong mb-1">{{ power.fields.title }}</p>
                        <p class="caption"> {{ power.fields.subtitle }}</p>
                      </div>
                    </div>
                  </router-link>
                  <p class="text-align-center m-4" v-if="powers.length === 0">{{$t('settings.children.noSuperpower')}}</p>
                  <hr>
                  <router-link class="uni-link display-2 margin-bot-16 text-align-center see-power-btn "
                               :to="{name: 'KidPower', params:{name: kids[0].firstname, id: kids[0].id}}"
                               v-if="powers.length>0">
                    {{$t('settings.children.seeSuperpowers')}}
                  </router-link>
                </div>
              </div>
              <div class="mb-4 flex align-items-center mt-8">
                <p class="uni-link display-2 inline mr-2" :class="{'active' : seeCaps}"
                   @click="seeCaps = !seeCaps">{{ $t('settings.children.capsuleTitle') }}</p>
                <sprite-icon name="chevron-down" size="big" class="inline chevron"
                             :style="seeCaps? '': 'transform: rotate(180deg);'"></sprite-icon>
              </div>
              <div v-if="seeCaps">
                <div class="card-power-preview rounded-3xl relative mb-4">
                  <button class="absolute left-4 top-2" @click="prev('secondKids')" v-if="kids.length > 1">
                    <sprite-icon name="chevron-left" size="big" class="inline chevron"></sprite-icon>
                  </button>

                  <button class="absolute right-4 top-2" @click="next('secondKids')" v-if="kids.length > 1">
                    <sprite-icon name="chevron-right" size="big" class="inline chevron"></sprite-icon>
                  </button>
                  <transition-group name="name" tag="div"
                                    class="flex text-align-center overflow-hidden name-parent">
                    <p class="name" v-for="(kid, index) in secondKids " ref="kid" :id="kid.id" :data-index="index"
                       :key="index">
                      {{ kid.firstname }}</p>
                  </transition-group>
                  <hr>
                  <router-link tag="div" v-for="(capsule, index) in capsules" class="cursor-pointer"
                               :to="{name: 'CapsuleRecap', params:{slug: capsInfo[index].fields.slug, capsId: capsule.id, user: capsule.user.id}}"
                               v-if="capsInfo[index]">
                    <card-mini-old-caps class="pb-10 mt-6 mx-4" color="#07BD58" :capsule="capsInfo[index]"/>
                    <hr v-if="index +1  !== capsInfo.length">
                  </router-link>
                </div>
              </div>
            </div>

            <div v-else class="mb-8">
              <p>{{$t('settings.children.noChildren')}}</p>
              <router-link class="underline" :to="{name: 'SignInWelcome', params: {type: 'child'}}">{{$t('settings.children.addChild')}}
              </router-link>
            </div>


            <section class="section-accounts  mt-8" v-if="currentFamily.admin && !fakeAccountsLoading">
              <p class="display-2 mb-4">Comptes abonnés</p>
              <primary-button @click.native="openAccountModal = true">Créer un compte</primary-button>
              <accountGrid :accounts="fakeAccounts"/>

            </section>
            <router-link class="underline mt-8" :to="{name: 'Logout'}">{{ $t('settings.logout') }}</router-link>
            <uni-modal title="Créer un compte" :is-visible="openAccountModal" @close-modal="openAccountModal = false">
              <template slot="body">
                <validation-observer tag="form" ref="accountForm" id="accountForm" v-slot="{valid}" @submit.prevent="handleForm">
                  <uni-input
                      label="adresse e-mail"
                      id="input-email"
                      type="email"
                      placeholder="Adresse e-mail"
                      v-model="newAccount.email"
                      rules="required|email|emailExists"
                  />
                  <uni-input
                      label="mot de passe"
                      id="input-pwd"
                      type="text"
                      placeholder="Mot de passe"
                      v-model="newAccount.password"
                      rules="required"
                  />
                </validation-observer>
              </template>
              <template slot="footer">
                <primary-button :clicked="loadingAccount" type="submit" form="accountForm" :disabled="loadingAccount">Créer un compte</primary-button>
              </template>
            </uni-modal>
            <!--<br>
            <button @click="askPermission()">S'abonner aux notifications</button>-->
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UniInput from "@/components/UniInput";
import UniInputPassword from "@/components/Password/UniInputPassword.vue";
import PrimaryButton from "@/components/PrimaryButton";
import SpriteIcon from "@/components/SpriteIcon";
import CardMiniOldCaps from "@/components/CardMiniOldCaps";
import Loader from "@/components/Loader";
import AccountGrid from "./AccountGrid";
import UniModal from "../components/UniModal";
import Avatar from "@/components/Avatar";

export default {
  name: "Account",
  data() {
    return {
      seeCaps: true,
      isLoading: true,
      capsInfo: [],
      kids: [],
      capsules: [],
      secondKids: [],
      changeInfos: false,
      changePwd: false,
      seePowers: true,
      kidName: '',
      name: '',
      email: '',
      pwd: '',
      pwdConfirm: '',
      clicked: false,
      loading: false,
      hasKids: false,

      powers: [],
      selectedKid: "",

      fakeAccountsLoading: true,
      openAccountModal: false,
      loadingAccount: false,
      newAccount: {
        email: '',
        password: ''
      },
      fakeAccounts: []
    }
  },
  components: {
    Avatar,
    UniModal, AccountGrid, Loader, CardMiniOldCaps, SpriteIcon, PrimaryButton, UniInputPassword, UniInput},
  computed: {
    ...mapGetters({
      currentFamily: 'currentFamily',
      familyState: "familyState",
      currentLocale: "currentLocale"
    })
  },
  methods: {
    async askPermission() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        await this.registerServiceWorker();
      }
    },
    async registerServiceWorker() {
      const registration = await navigator.serviceWorker.register("/sw.js");
      let subscription = await registration.pushManager.getSubscription();

      // L'utilisateur n'est pas déjà abonné, on l'abonne au notification push
      if (!subscription) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array("BJ1cHW_f1wCMnGAAAfzF--gcI-MEyZhSouwrkRq2vRPSWR2LKpNo5NhcO0o3EHjnuFIEO-8UUMospAr-dnmY26M"),
        });

      }
      await this.saveSubscription(subscription);
    },
    urlBase64ToUint8Array(base64String) {
      let padding = '='.repeat((4 - base64String.length % 4) % 4);
      let base64 = (base64String + padding)
          .replace(/\-/g, '+')
          .replace(/_/g, '/');

      let rawData = window.atob(base64);
      let outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },


    //TODO: ICI enregister dans la bdd la subscription des utilisateurs pour les notifs
    async saveSubscription(subscription) {
      /*await fetch("/push/subscribe", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: subscription.toJSON(),
      });
       */
    },


    handleForm() {
      this.$refs.accountForm.validate().then(resp => {
        if (resp) {
          this.loadingAccount = true
          let obj = {
            family: {
              email: this.newAccount.email,
              password: this.newAccount.password,
              password_confirmation: this.newAccount.password
            }
          }

          this.$http.post('admin/fake_accounts', obj)
          .then(resp => {
            this.fakeAccounts.push(resp.data)
            this.loadingAccount = false
            this.openAccountModal = false
          })
        } else {
          // this.$refs.formInput.reset()
        }
      })
    },

    loginSuccessful(req) {
      localStorage.token = req.data.auth_token
      this.$store.dispatch('login')
      window.location.reload()
      this.$nextTick(() => {
        this.$router.push({name: 'Account'})
      })
    },
    modifyPwd() {
      this.$refs.formPwd.validate().then(resp => {
        if (resp) {
          this.$http.put('/families/' + this.currentFamily.id, {
            family: {
              password: this.pwd,
              password_confirmation: this.pwdConfirm
            }

          }).then((res) => {
            this.loginSuccessful(res)
            this.clicked = false
          })

        } else {
          // this.$refs.formInput.reset()
        }
      })
    },
    openCustomerPortal() {
      this.loading = true
      this.$http.post('create-portal-session', {
        base_url: process.env.NODE_ENV === "development" ? "localhost" : window.location.origin
      })
          .then(res => {
            let session = res.data.session
            window.location.href = session.url
          })
    },
    next(array) {
      if (array === 'kids') {
        const first = this.kids.shift()
        this.kids = this.kids.concat(first)
        this.setPowers()
      } else {
        const first = this.secondKids.shift()
        this.secondKids = this.secondKids.concat(first)
        this.setCapsules()
      }


    },
    prev(array) {
      if (array === 'kids') {
        const last = this.kids.pop()
        this.kids = [last].concat(this.kids)
        this.setPowers()
      } else {
        const last = this.secondKids.pop()
        this.secondKids = [last].concat(this.secondKids)
        this.setCapsules()
      }


    },
    setPowers() {
      this.powers = []
      let comma = null
      if (this.kids.length) {
        this.$http.get('/users/' + this.kids[0].id + '/super_powers').then(resp => {
          let powersId = []
          resp.data.forEach(el => powersId.push(el.contentful_id))
          let string = ""
          powersId.length > 1 ? comma = true : comma = false
          powersId.forEach(el => string += el + (comma ? ',' : ''))
          this.$contentful.getEntries({
            content_type: "superPower",
            locale: this.currentLocale,
            'sys.id[in]': string
          }).then(res => {
            this.powers = res.items
            this.isLoading = false
          });
        })
      }
    },
    setCapsules() {
      this.capsInfo = []
      if (this.secondKids.length) {
        this.$http.get('/users/' + this.secondKids[0].id + '/capsules').then(resp => {
          let caps = resp.data.filter(el => el.is_completed === true)
          this.capsules = caps
        }).finally(() => {
          let filter = {
            content_type: "capsule",
            include: 2,
            locale: this.currentLocale,
            'sys.id[in]': this.$createStringForContentfulFilter(this.capsules)
          }
          this.$contentful.getEntries(filter).then(res => {
            this.capsInfo = res.items
          })

        })
      }
    },


  },
  updated() {
  },
  created() {



    if (this.$route.query.update) {
      this.$http.post('regenerate-auth-token', {family: {email: this.currentFamily.email}})
          .then(resp => {
            if (resp.data.auth_token) {
              localStorage.token = resp.data.auth_token
              this.$store.dispatch('login')
              this.isLoading = false
              location.replace(window.location.pathname)
            }
          })
    }
    if (this.familyState.users.filter(el => el.user_type === "child").length > 0) {
      this.familyState.users.filter(el => el.user_type === "child").forEach(el => {
        this.kids.push(el)
      })
      this.secondKids = JSON.parse(JSON.stringify(this.kids))
      this.setPowers()
      this.setCapsules()
      this.hasKids = true
      this.isLoading = false
    } else {
      this.name = this.familyState.users.find(el => el.user_type === "adult").firstname
      this.email = this.currentFamily.email
      this.isLoading = false
    }

    this.$http.get('admin/fake_accounts')
    .then(resp => {
      this.fakeAccounts = resp.data
      this.fakeAccountsLoading = false
    })


  }
}
</script>

<style scoped lang="scss">
.center-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > ::v-deep svg {
    width: 50px;
    height: 50px;
  }
}

.see-power-btn {
  margin: 0 auto;
  display: block !important;
  width: min-content;

  &:hover:after {
    left: 0;
    bottom: -10px;
  }
}

// preview power
.parent-power {
  border-radius: var(--radius-default);
  position: relative;
  background: linear-gradient(to top, #F2A53A, #FFD85A);

  //background: linear-gradient(to top, #D1D1D1, transparent);
  padding: 3px;

  .second-parent {
    border-radius: var(--radius-default);
    //transition: background-color 0.2s cubic-bezier(0.15, 0.95, 1, 1);
    background: white;
    padding: var(--space-12);

    .pentagon {
      width: 64px;
      height: 64px;
      position: relative;
      transition: filter 0.2s ease-in-out;
    }

    .power {
      display: block;
      width: 32px;
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
}

// --- end preview power
.name-parent {
  max-width: 621px;
  width: 100%;
  height: 40px
}

.name {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.slide:first-of-type {
  opacity: 0;
}

.slide:last-of-type {
  opacity: 0;
}

.card-power-preview {
  padding-bottom: 8px;
  border: 2px solid var(--color-text-soft);
  //min-height: 464px;

  hr {
    margin: 0px 0px 8px 0px;
    border: 1px solid var(--color-text-soft);
  }

  select {
    display: block;
    width: 180px;
    margin: 0 auto;

    option:hover {
      background-color: var(--color-text-soft);
    }
  }
}

.chevron {
  transition: 0.1s ease;
}

.uni-link {
  cursor: pointer;
  display: inline-flex;
  width: fit-content;
  position: relative;

  &.active:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    border-radius: var(--radius-default);
    bottom: -4px;
    background: var(--color-primary);

  }
}

.uni-link:hover:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  border-radius: var(--radius-default);
  bottom: -4px;
  background: var(--color-primary);

}

.grid-container {
  grid-gap: var(--space-80)
}

header {
  min-height: 150px;
}
</style>