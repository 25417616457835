<template>
  <div class="main-container rounded-t-3xl wrap auth h-full w-full">
    <h2 class="text-align-center mb-8">{{ $t('forgotPassword.title') }}</h2>
    <validation-observer tag="form" ref="formInput" v-slot="{valid}" @submit.prevent="sendReset">
      <uni-input
          :label="$t('validation.placeholders.email')"
          id="input-test"
          type="email"
          :placeholder="$t('validation.placeholders.email')"
          v-model="user.email"
          rules="required|email|emailOk"
      />
      <primary-button type="submit" :disabled="disabled" :clicked="loading">{{ $t('forgotPassword.action') }}</primary-button>
      <uni-section-message v-if="sent" type="success" :title="$t('forgotPassword.info.title')"
                           :description="$t('forgotPassword.info.content')"/>
    </validation-observer>
  </div>
</template>

<script>
import UniInput from "@/components/UniInput";
import PrimaryButton from "@/components/PrimaryButton";
import UniSectionMessage from "@/components/UniSectionMessage";

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
      },
      sent: false,
      clicked: false,
      badCredentials: false,
      loading: false,
      disabled: false
    }
  },
  components: {
    UniSectionMessage,
    PrimaryButton, UniInput
  },

  methods: {
    sendReset() {
      this.$refs.formInput.validate().then(success => {
        if (!success) {
          return;
        } else {
          this.disabled = true
          this.loading = true
          this.$http('/recover-password?email=' + this.user.email)
              .then((result) => {
                if (result.data.message.status === 200) {
                  this.requestSuccess(result)
                } else {

                  return;

                }
              })
              .catch(err => {
                this.failed()
              })
        }
      })
    },
    login() {

    },
    requestSuccess(req) {
      this.sent = true
      this.disabled = false
      this.loading = false
    },
    failed() {
    },
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>
</style>