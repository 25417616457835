<template>
  <button :class="currentClass" class="button flex justify-center align-items-center" :type="type"
          :disabled="disabled">
    <slot></slot>
    <svg class="ml-2" v-if="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M4.16663 10H15.8333" stroke="white" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="1.7" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
    <loader class="ml-2" v-if="clicked"></loader>

  </button>
</template>
<script>
import Loader from "@/components/Loader";

export default {
  name: "PrimaryButton",
  components: {Loader},
  props: {
    color: {
      type: String,
    },
    arrow: {
      type: Boolean
    },
    clicked: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    currentClass() {
      switch (this.color) {
        case 'yellow' :
          return 'yellow'
        case 'white':
          return 'white'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
.button {
  transition: box-shadow 0.1s;
  box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  //background: linear-gradient(180deg, #E62145 0%, #B81230 100%);
  background: var(--color-primary);
  color: white;
  border-radius: var(--radius-default);
  padding: var(--space-12);


  &.white {
    color: var(--color-text) !important;
    background: var(--color-surface-soft);
    border: 2px solid #CAC5C4;
    box-shadow: inset 0px -4px 0px #EBECF0 !important;
    border-bottom: none;
  }

  &.yellow {
    background: var(--color-secondary);
  }

  &:disabled {
    background: var(--color-surface-divider);
    color: var(--color-text-disabled) !important;

    &:hover {
      cursor: default;
    }
  }

  &:hover {
    box-shadow: none;
  }
}

</style>