<template>
  <div class="card"
       :style="{'width': cardWidth === 'full' ? '100%' : '88%', 'max-width': cardWidth === 'full' ? 'none' : ''}">
    <img class="illus" v-if="bigButton" src="../assets/illus/trop-wow.png" alt="">
    <div class="img-container relative" style="background: white;">
      <picture>
        <source type="image/webp"
                media="(min-width: 1101px)"
                :srcset="img +'?fit=fill&w=600&h=600&fm=webp'"/>
        <source type="image/jpeg"
                media="(min-width: 1101px)"
                :srcset="img +'?fit=fill&w=600&h=600&fm=jpg'"/>
        <source type="image/webp"
                media="(min-width: 480px)"
                :srcset="img +'?fit=fill&w=500&h=440&fm=webp'"/>
        <source type="image/jpeg"
                media="(min-width: 480px)"
                :srcset="img +'?fit=fill&w=500&h=440&fm=jpg'"/>
        <img class="" :src="img+'?fit=fill&w=375&h=220&fm=jpg'"/>
      </picture>
      <div class="free" v-if="!currentFamily.isFullAccess() && isFree">
        <img src="../assets/img/new_gift.svg" alt="">
      </div>
      <tag class="absolute top-3 left-3" :capsTags="tags"/>

    </div>

    <InfoCaps :big-button="bigButton" :read-duration="readDuration" :date="date" :title="title" :author="author"
              :slug="slug"
              :description="description" :is-free="isFree" :new-caps="newCaps" @open-modal="$emit('open-modal')"/>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import InfoCaps from "@/components/InfoCaps";
import {mapActions, mapGetters} from "vuex";
import Avatar from "@/components/Avatar";

export default {
  name: "CardCapsule",
  components: {Avatar, InfoCaps, Tag},

  props: {
    title: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true,
    },
    readDuration: {
      type: Number,
      required: true
    },
    date: {
      type: Date,
      required: false
    },
    author: {
      type: Object,
      required: false
    },
    slug: {
      type: String,
      required: true
    },
    tags: {
      default: function () {
        return []
      },
      type: Array,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    cardWidth: {
      type: String,// 'full' | 'little'
      required: false,
      default: 'full',
      validate: function (value) {
        return ['full', 'little'].includes(value)
      }
    },
    bigButton: {
      type: Boolean,
      required: false,
      default: true
    },
    isFree: {
      type: Boolean,
      required: false,
      default: false
    },
    newCaps: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentFamily'])
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.img-container {
  height: 160px;
  border-radius: 16px 16px 0 0;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.card {
  max-width: 340px;
  border-radius: 16px;
  padding: 0;
  position: relative;
  flex-shrink: 0;
  scroll-snap-align: start;

  .illus {
    position: absolute;
    top: -95px;
    right: 20px;
    z-index: 10;
  }
}

.free {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
</style>