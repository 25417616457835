import {mapGetters} from "vuex";
import Storage from "../../class/storage";

export default {
    computed: {
        ...mapGetters(['currentFamily', 'familyState']),
    },
    methods: {
        /**
         * Return le l'utilisateur authentifié du store
         * @returns {boolean}
         */
        isAllowed() {
            return this.currentFamily
        },

        //redirection si il n'y pas d'utilisateur authentifié
        updated() {
        },
        created() {
        },
    }
}