import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import fr from './fr'
import Storage from "../../class/storage";

const storage = new Storage('locale')
Vue.use(VueI18n)
const currentLocale = storage.get.locale || 'fr'
export default new VueI18n({
    locale: currentLocale,
    fallbackLocale: currentLocale,
    messages: {
        en, fr
    }
})