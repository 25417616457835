<template slot="body">
  <div>
    <p class="mb-8">{{ $t('pricing.description') }}</p>
    <!--            <p v-else>Chaque semaine découvrez une nouvelle capsule et accédez aux anciennes capsules, en illimité et-->
    <!--              sans pub. <br> Choisissez une formule et profitez de 14 jours d’essai gratuit :</p>-->
    <div class="offre-container">
      <strong>{{ $t('pricing.month.title') }}</strong><br>
      <i>{{ $t('pricing.month.info') }}</i>
      <p>{{ $t('pricing.month.price', {price: 6.99}) }}</p>
      <primary-button class="mt-4 primary-btn"
                      @click.native="getCheckoutLink('month')"
                      :clicked="paymentButtonLoading"
                      :disabled="paymentButtonLoading"

      ><a
      >{{ isOnTrial() ? $t('pricing.action') : $t('capsule.actions.subscribe') }}</a>
      </primary-button>
    </div>
    <div class="offre-container mt-8">
      <strong>{{ $t('pricing.year.title') }}</strong><br>
      <i>{{ $t('pricing.year.info') }}</i>
      <p>{{ $t('pricing.year.price', {monthPrice: 5, percent: 25}) }}</p>
      <primary-button class="mt-4 primary-btn"
                      @click.native="getCheckoutLink('year')"
                      :clicked="paymentButtonLoading"
                      :disabled="paymentButtonLoading"

      ><a
      >{{ isOnTrial() ? $t('pricing.action') : $t('capsule.actions.subscribe') }}</a>
      </primary-button>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import {mapGetters} from "vuex";
import {loadStripe} from "@stripe/stripe-js";

export default {
  name: "handleSubscription",
  components: {PrimaryButton},
  props: {
    capsuleId: {
      type: String,
    }
  },
  watch: {},
  data() {
    return {
      paymentButtonLoading: false,
      stripe: null
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentFamily']),
  },
  methods: {
    /**
     * Crée le lien de paiement Stripe Checkout
     * @param mode {String} month|year - durée de l'abonnement
     */
    getCheckoutLink(mode) {
      this.$emit('create-checkout')
      this.$nextTick(_ => {
        this.paymentButtonLoading = true
        let url = window.location.hostname
        if (this.capsuleId) {
          this.$http.post('create-checkout-session', {
            base_url: url,
            ctfid: this.capsuleId,
            mode
          }).then(resp => {
            this.$emit('checkout-payment')
            return this.stripe.redirectToCheckout({sessionId: resp.data.sessionId})
          }).catch(_ => {
            // TODO: erreur bus
          })
        } else {
          this.$http.post('create-checkout-session', {
            base_url: url,
            mode
          }).then(resp => {
            this.$emit('checkout-payment')
            return this.stripe.redirectToCheckout({sessionId: resp.data.sessionId})
          })
        }
      })
    },

    isOnTrial() {
      return !this.currentFamily.freeTrial && this.currentFamily.isFullAccess();
    }
  },
  async mounted() {
    let stripe
    if (process.env.NODE_ENV === "development") {
      stripe = await loadStripe('pk_test_51HC69yHZbT98Fpj08Jffgp3gruxKZ5Rfd2HhxD9rIIewXj8N0w6pGCf5EVMyVZrIeZTI2SevC5iR0j8zC9gRuj3m00DjsjkjNJ')
    } else {
      stripe = await loadStripe(process.env.VUE_APP_STRIPE_API_KEY)
    }
    this.stripe = stripe


  },
  updated() {
  },
  created() {
  }
}
</script>

<style scoped>

</style>