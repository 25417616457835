import Family from '../models/Family'
import * as MutationTypes from './mutation_types'
import store from './'

const state = {
    userIsAuthorized: false,
    family: Family.from(localStorage.token)
}

const getters = {
    currentFamily(state) {
        return state.family
    },
}

const mutations = {
    [MutationTypes.LOGIN](state) {
        state.family = Family.from(localStorage.token)
    },
    [MutationTypes.LOGOUT](state) {
        state.family = null
    },
    FAMILY_SUBSCRIBED: (state, data) => {
        state.family.stripeId = data.stripe_id
        state.paymentStatus = data.payment_status
    }
}

const actions = {
    login({commit, dispatch}) {
        commit(MutationTypes.LOGIN)

        dispatch('toggleDyslexiaMode', state.family.dyslexia, {root:true})
    },

    logout({commit, dispatch}) {
        commit(MutationTypes.LOGOUT)

        dispatch('toggleDyslexiaMode', false, {root:true})
        dispatch('clearFamily', null, {root:true})
        dispatch('removeCurrentUser', null, {root:true})
    },

    familySubscribed(store, data) {
        store.commit('FAMILY_SUBSCRIBED', data)
    }

}

export default {
    state,
    mutations,
    actions,
    getters
}
