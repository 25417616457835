<template>
  <div>
    <Loader :color="true" v-if="Object.keys(currentUser).length === 0"/>
    <header v-else class="flex space-between align-items-center">
      <router-link :to="{name: 'Home'}" class="logo-container">
        <img src="../assets/wow-logo.png">
      </router-link>
      <div class="flex align-items-center">
        <router-link :to="{name: 'capsules.filtered', query: {title: $t('home.search'),
         filters: encodeUrl({
        skip: 0,
        include: 2,
        locale: this.currentLocale,
        content_type: 'capsule',
        order: '-sys.createdAt',
       'fields.title[exists]': true,
       'fields.cover[exists]': true,
      })
          }}" href="#">
          <sprite-icon name="search" size="big" fill="black"/>
        </router-link>

        <locale-switcher @localeSwitched="reloadData"/>
        <button class="avatar overflow-hidden rounded-full" @click="openPasswordModal = true">
          <img class="object-cover" style="height: 36px; width: 36px;"
               :src="currentUser.attachment_url || currentUser.avatar" alt="">
        </button>
      </div>

    </header>
    <div class="wrapper">
      <Loader :color="true" v-if="Object.keys(currentUser).length === 0"/>
      <div v-else>
        <h1 class="display-1">{{ $t('home.title') }} {{ currentUser.firstname }} !</h1>
        <p class="strong mt-1 mb-3" style="color: #5E6C70;">{{ $t('home.capsuleOfTheWeek') }}</p>
      </div>
      <div class="wrapper" v-if="isLoadingHero">
        <card-capsule-skeleton/>
      </div>
      <card-capsule v-else :title="heroCapsule.fields.title"
                    :img="heroCapsule.fields.cover.fields.file.url"
                    :read-duration="heroCapsule.fields.readDuration" :date="new Date(heroCapsule.sys.createdAt)"
                    :description="heroCapsule.fields.accroche || heroCapsule.fields.description"
                    :slug="heroCapsule.fields.slug"
                    :author="heroCapsule.fields.author.fields" :tags="heroCapsule.fields.capsuleTags"
                    :new-caps="!!heroCapsule.fields.blocs"
                    is-free
                    @open-modal="openModal(heroCapsule)"/>

    </div>
    <div v-if="preferedCapsules.length > 0">
      <div class="wrapper">
        <div class="mt-8 mb-4 flex space-between align-items-center">
          <h2>{{ $t('home.interest') }}</h2>
          <router-link v-if="userHasTags" :to="{name: 'capsules.filtered', query: {title: $t('home.interest'),
             filters: encodeUrl({
                skip: 0,
                include: 2,
                locale: this.currentLocale,
                content_type: 'capsule',
                'fields.title[exists]': true,
                'fields.cover[exists]': true,
                links_to_entry: JSON.parse(currentUser.prefered_tag_id)[0],
                order: '-sys.createdAt'
              })
              }}" class="big-link" href="#">{{ $t('home.all') }}
          </router-link>
        </div>
      </div>

      <div class="wrapper" v-if="isLoadingPrefered">
        <card-capsule-skeleton></card-capsule-skeleton>
      </div>
      <div class="container-of-card-container" v-if="preferedCapsules.length > 0">
        <div v-if="userHasTags" class="card-container">
          <card-capsule v-for="capsule in preferedCapsules" :title="capsule.fields.title"
                        :img="Object.assign({}, capsule.fields.cover.fields.file).url"
                        :read-duration="capsule.fields.readDuration" :date="new Date(capsule.sys.createdAt)"
                        :description="capsule.fields.accroche || capsule.fields.description"
                        :slug="capsule.fields.slug"
                        :big-button="false" card-width="little"
                        :author="capsule.fields.author.fields" :tags="capsule.fields.capsuleTags"
                        :new-caps="!!capsule.fields.blocs"
                        @open-modal="openModal(capsule)"></card-capsule>
        </div>
        <div v-else>
          <div class="wrapper">
            <p>{{ $t('home.noTheme') }}
              <router-link style="text-decoration: underline;" :to="{name: 'updateUser', params: {id: currentUser.id}}">
                {{ $t('home.noThemeHere') }}
              </router-link>
            </p>
          </div>
        </div>
      </div>

    </div>


    <div class="wrapper">
      <Loader :color="true" v-if="isLoadingPowers"></Loader>
      <div v-else>
        <div class="mt-8 mb-4 flex space-between align-items-center">
          <h2>{{ $t('home.superpowers') }}</h2>
          <router-link :to="{name: 'KidPowers'}" class="big-link">{{ $t('home.all') }}</router-link>
        </div>
        <div class="powers-container">
          <div v-if="powers.length === 0">
            <p>{{ $t('home.noSuperpowers') }}</p>
          </div>
          <div class="powers" v-else>
            <router-link
                :to="{name: 'kidPowerView', params:{name: currentUser.firstname, id: currentUser.id, powerId:power.sys.id}}"
                v-for="power in powers">
              <div v-if="!power.fields.new" class="badge">
                <img class="pentagone" v-if="!power.fields.new" src="../assets/img/pentagone.png" alt="Polygone">
                <img class="power" :src="Object.assign({}, power.fields.icon.fields.file).url" alt="Pouvoir">
              </div>
              <div v-else class="new-badge">
                <img class="power"
                     :src="Object.assign({}, power.fields.superPowerCategory.fields.picto.fields.file).url"
                     alt="Pouvoir">
              </div>
            </router-link>

          </div>
        </div>
      </div>
      <div class="mt-8 mb-4 flex space-between align-items-center" v-if="capsules.length > 0">
        <h2>{{ $t('home.discover') }}</h2>
        <router-link :to="{name: 'capsules.filtered', query: {title: $t('home.discover'),
         filters: encodeUrl({
          skip: 0,
          include: 2,
          locale: this.currentLocale,
          'fields.title[exists]': true,
          'fields.cover[exists]': true,
          content_type: 'capsule',
          order: '-sys.createdAt',
        })
          }}" class="big-link" href="#">{{ $t('home.all') }}
        </router-link>
      </div>
    </div>
    <div class="wrapper" v-if="isLoadingCapsules">
      <card-capsule-skeleton></card-capsule-skeleton>
    </div>
    <div v-else-if="capsules.length > 0" class="container-of-card-container">
      <div class="card-container">
        <card-capsule v-for="capsule in capsules" :title="capsule.fields.title"
                      :img="Object.assign({}, capsule.fields.cover.fields.file).url"
                      :read-duration="capsule.fields.readDuration" :date="new Date(capsule.sys.createdAt)"
                      :description="capsule.fields.accroche || capsule.fields.description"
                      :slug="capsule.fields.slug"
                      :big-button="false" card-width="little"
                      :author="capsule.fields.author.fields" :tags="capsule.fields.capsuleTags"
                      :new-caps="!!capsule.fields.blocs"
                      @open-modal="openModal(capsule)"></card-capsule>
      </div>
    </div>


    <div class="wrapper">
      <div class="mt-8 mb-4 flex space-between align-items-center">
        <h2>{{ $t('home.journalists') }}</h2>
        <router-link :to="{name: 'journalists'}" class="big-link" href="#">{{ $t('home.all') }}</router-link>
      </div>
      <Loader :color="true" v-if="isLoadingAuthors"></Loader>
      <div v-else class="authors-container">
        <div @click="openAuthorModal(author)" v-for="author in authors">
          <img class="rounded-full" :src="Object.assign({}, author.fields.profil.fields.file).url + '?fit=thumb'"
               :alt="author.fields.firstname">
          <p>{{ author.fields.firstname }}</p>
        </div>
      </div>
    </div>
    <uni-modal :is-visible="isAuthorModalOpen" @close-modal="closeAuthorModal"
               :has-footer-slot="false">
      <template slot="body">
        <Loader :color="true" v-if="!currentAuthor"></Loader>
        <div v-else>
          <h2 class="text-align-center">{{ currentAuthor.fields.firstname }} {{ currentAuthor.fields.name }}</h2>
          <img class="rounded-full block mx-auto my-4" style="width: 80px;"
               :src="currentAuthor.fields.profil.fields.file.url + '?fit=thumb'"
               :alt="currentAuthor.fields.firstname">
          <api-rich-renderer :timer="false" text-type="article"
                             :rich-text="currentAuthor.fields.description.content"></api-rich-renderer>

          <h2 class="mt-6 mb-4">{{ $t('home.journalistLastNews') }} {{ currentAuthor.fields.firstname }}</h2>
          <Loader :color="true" v-if="authorCapsuleLoading"></Loader>
          <div v-else>
            <CardCapsule v-if="currentAuthor.capsule.fields.title" :title="currentAuthor.capsule.fields.title"
                         :img="Object.assign({}, currentAuthor.capsule.fields.cover.fields.file).url"
                         :read-duration="currentAuthor.capsule.fields.readDuration"
                         :date="new Date(currentAuthor.capsule.sys.createdAt)"
                         :description="currentAuthor.capsule.fields.accroche || currentAuthor.capsule.fields.description"
                         :slug="currentAuthor.capsule.fields.slug"
                         :big-button="false" card-width="full"
                         :author="currentAuthor.capsule.fields.author.fields"
                         :tags="currentAuthor.capsule.fields.capsuleTags"
                         :new-caps="!!currentAuthor.capsule.fields.blocs"
                         @open-modal="openModal(currentAuthor.capsule)"></CardCapsule>
          </div>
        </div>
      </template>
    </uni-modal>
    <!-- Mise en standby des abonnements -->
    <!-- <handle-subscription-modal :capsule-id="capsuleId" :open-modal="modalSub" @close-modal="modalSub = false"/>-->

    <uni-modal :is-visible="openPasswordModal" @close-modal="closePwdModal"
               :has-footer-slot="false" title="Entrez votre mot de passe">
      <template slot="body">
        <validation-observer tag="form" ref="formPwd" v-slot="{valid}" @submit.prevent="checkPasswordOptions">
          <uni-input-password
              :label="$t('validation.placeholders.password')"
              id="options-password"
              :placeholder="$t('validation.placeholders.password')"
              v-model="password"
              :helperMessage="$t('validation.errors.password')"
              rules="required|passwordTest"/>
          <primary-button :clicked="loadingPwdCheck"
                          @click.native="valid? loadingPwdCheck = true: loadingPwdCheck = false" class="mt-8">
            {{ $t('settings.validate') }}
          </primary-button>
          <uni-section-message v-if="badCredentials" type="warning" :title="$t('signIn.errors.badCredentials.title')"
                               :description="$t('signIn.errors.badCredentials.title')"/>
        </validation-observer>
      </template>
    </uni-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CardCapsule from "@/components/CardCapsule";
import UniModal from "@/components/UniModal";
import Loader from "@/components/Loader";
import ApiRichRenderer from "@/components/ApiRichRenderer";
import CardCapsuleSkeleton from "@/components/CardCapsuleSkeleton";
import HandleSubscriptionModal from "@/components/handleSubscriptionModal";
import LocaleSwitcherOnRight from "@/views/security/LocaleSwitcherOnRight";
import LocaleSwitcher from "@/components/localeSwitcher";
import UniInputPassword from "@/components/Password/UniInputPassword.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import UniSectionMessage from "@/components/UniSectionMessage.vue";
import InfoCaps from "@/components/InfoCaps.vue";
import SpriteIcon from "@/components/SpriteIcon.vue";
import UniInput from "@/components/UniInput.vue";

export default {
  name: "Home",
  components: {
    InfoCaps,
    LocaleSwitcher,
    LocaleSwitcherOnRight,
    HandleSubscriptionModal, CardCapsuleSkeleton, ApiRichRenderer, Loader, UniModal, CardCapsule,
    UniInputPassword,
    PrimaryButton,
    UniSectionMessage,
    SpriteIcon,
    UniInput
  },
  computed: {
    ...mapGetters(['currentUser', 'currentFamily', 'familyState', "currentLocale"])
  },
  data() {
    return {
      heroCapsule: null,
      capsules: [],
      authors: [],
      powers: [],
      preferedCapsules: [],
      isAuthorModalOpen: false,
      currentAuthor: null,
      // modalSub: false,
      capsuleId: null,
      userHasTags: true,

      isLoadingHero: true,
      isLoadingCapsules: true,
      isLoadingPrefered: true,
      isLoadingPowers: true,
      isLoadingAuthors: true,
      authorCapsuleLoading: true,

      openPasswordModal: false,
      badCredentials: false,
      password: null,
      loadingPwdCheck: false,
    }
  },
  methods: {
    ...mapActions(['addCurrentUser']),
    checkPasswordOptions() {
      this.$refs.formPwd.validate().then(success => {
        if (success) {
          this.$http.post('/check-password', {family: {password: this.password}})
              .then((result) => {
                if (result.data.status === 200) {
                  this.$router.push({name: 'Account'})
                } else {
                  this.badCredentials = true;
                }
              })
              .catch()
              .finally(_ => {
                this.loadingPwdCheck = false
              })
        }
      })
    },
    closePwdModal() {
      this.openPasswordModal = false
    },
    openModal(capsule) {
      this.capsuleId = capsule.sys.id
    },
    openAuthorModal(author) {
      this.isAuthorModalOpen = true;
      this.currentAuthor = author

      this.$contentfulv2.getEntries({
        content_type: "capsule",
        limit: 1,
        locale: this.currentLocale,
        order: "-sys.createdAt",
        "fields.title[exists]": true,
        "fields.blocs[exists]": true,
        links_to_entry: author.sys.id,
      })
          .then(resp => {
            let data = resp.items.map(item => {
              if (item.fields.title) {
                return item
              }
            }).filter(el => el !== undefined)
            this.currentAuthor.capsule = data[0]
            this.authorCapsuleLoading = false
          })

    },
    closeAuthorModal() {
      this.isAuthorModalOpen = false;
      this.currentAuthor = null
      this.authorCapsuleLoading = true
    },
    encodeUrl(obj) {
      return Object.keys(obj).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
      }).join('&');
    },
    loadContentful() {
      // On récupère la capsule à mettre en avant
      this.$contentfulv2.getEntries({
        skip: 0,
        limit: 1,
        include: 2,
        locale: this.currentLocale,
        content_type: 'capsule',
        "fields.title[exists]": true,
        "fields.blocs[exists]": true,
        "fields.spotlight[exists]": true,
        "fields.spotlight": true,
        order: '-sys.createdAt',
      }).then(res => {
        let data = res.items.map(item => {
          if (item.fields.title) {
            return item
          }
        }).filter(el => el !== undefined)
        if (data.length !== 0) {
          this.heroCapsule = data.shift()
          console.log(this.heroCapsule)
          if (this.currentLocale === 'en') {
            this.$contentfulv2.getAsset(this.heroCapsule.fields.cover.sys.id)
                .then(res => {
                  this.heroCapsule.fields.cover.fields.file = res.fields.file
                  this.isLoadingHero = false
                })
          } else {
            this.isLoadingHero = false
          }
        }
      })

      // ON récupère les dernières capsules dans l'ordre chronologique
      this.$contentfulv2.getEntries({
        skip: 0,
        limit: 4,
        include: 2,
        locale: this.currentLocale,
        content_type: 'capsule',
        "fields.title[exists]": true,
        "fields.blocs[exists]": true,
        order: '-sys.createdAt',
      }).then(res => {
        let data = res.items.map(item => {
          if (item.fields.title) {
            return item
          }
        }).filter(el => el !== undefined)
        if (this.heroCapsule === null) {
          this.heroCapsule = data.shift()
        }
        this.capsules = data
        if (this.capsules.some(e => e.sys.id === this.heroCapsule.sys.id)) {
          this.capsules.splice(this.capsules.findIndex(e => e.sys.id === this.heroCapsule.sys.id), 1)
        }
        if (this.currentLocale === 'en') {
          if (this.heroCapsule === {}) {
            this.$contentfulv2.getAsset(this.heroCapsule.fields.cover.sys.id)
                .then(res => {
                  this.heroCapsule.fields.cover.fields.file = res.fields.file
                  this.isLoadingHero = false
                })
          }
          let promises = []
          this.capsules.forEach(capsule => {
            promises.push(this.$contentfulv2.getAsset(capsule.fields.cover.sys.id))
          })
          Promise.all(promises).then(res => {
            res.forEach((asset, index) => {
              this.capsules[index].fields.cover.fields.file = asset.fields.file
            })
            this.isLoadingCapsules = false
          })

        } else {
          this.isLoadingHero = false
          this.isLoadingCapsules = false
        }
      })

      // on récupère les auteurs
      this.$contentfulv2.getEntries({
        content_type: 'authors',
        include: 2,
        limit: 4,
        locale: this.currentLocale,
        order: 'fields.order'
      }).then(res => {
        this.authors = res.items

        if (this.currentLocale === 'en') {
          // loop through authors, add the promise to an array, then execute Promise.all
          let promises = []
          this.authors.forEach(author => {
            promises.push(this.$contentfulv2.getAsset(author.fields.profil.sys.id))
          })
          Promise.all(promises).then(res => {
            res.forEach((asset, index) => {
              this.authors[index].fields.profil.fields.file = asset.fields.file
            })
            this.isLoadingAuthors = false
          })
        } else {
          this.$nextTick(() => {
            this.isLoadingAuthors = false
          })
        }


      })

      // On récupère les sujets préférés de l'utilisateur
      if (this.currentUser.prefered_tag_id) {
        this.$contentfulv2.getEntries({
          skip: 0,
          limit: 4,
          include: 2,
          locale: this.currentLocale,
          content_type: 'capsule',
          "fields.title[exists]": true,
          // 'fields.tags.sys.id[in]': JSON.parse(this.currentUser.prefered_tag_id).join(','),
          links_to_entry: JSON.parse(this.currentUser.prefered_tag_id)[0],
          order: '-sys.createdAt',
        }).then(res => {
          let data = res.items.map(item => {
            if (item.fields.blocs && item.sys.id !== this.heroCapsule.sys.id) {
              return item
            }
          }).filter(el => el !== undefined)
          // if data  length is more than 3, we remove the last one
          if (data.length > 3) {
            data.shift()
          }
          this.preferedCapsules = data

          if (this.currentLocale === 'en') {
            let promises = []
            this.preferedCapsules.forEach(capsule => {
              promises.push(this.$contentfulv2.getAsset(capsule.fields.cover.sys.id))
            })
            Promise.all(promises).then(res => {
              res.forEach((asset, index) => {
                this.preferedCapsules[index].fields.cover.fields.file = asset.fields.file
              })
              this.isLoadingPrefered = false
            })
          } else {
            this.$nextTick(() => {
              this.isLoadingPrefered = false
            })
          }

        })
      } else {
        this.userHasTags = false
        this.isLoadingPrefered = false
      }

      // On récupère les super pouvoirs
      this.$http.get('/users/' + this.currentUser.id + '/super_powers').then(resp => {
        let powersId = []
        let comma = null
        resp.data.forEach(el => powersId.push(el.contentful_id))
        let string = ""
        powersId.length > 1 ? comma = true : comma = false
        powersId.forEach(el => string += el + (comma ? ',' : ''))
        this.$contentfulv2.getEntries({
          content_type: "superPower",
          locale: this.currentLocale,
          'sys.id[in]': string,
          include: 4,
          limit: 5,
        }).then(res => {
          this.powers = res.items.map(item => {
            if (item.fields.title) {
              return item
            }
          }).filter(el => el !== undefined)
          this.isLoadingPowers = false
        });
      })
    },
    reloadData() {
      this.isLoadingHero = true
      this.isLoadingCapsules = true
      this.isLoadingPrefered = true
      this.isLoadingPowers = true
      this.isLoadingAuthors = true

      this.authors = []
      this.capsules = []
      this.preferedCapsules = []
      this.powers = []
      this.heroCapsule = null

      this.$nextTick(() => {
        this.loadContentful()
      })
    },
  },
  created() {
    if (this.$route.params.reload) {
      window.location.reload()
    }
    if (!this.currentFamily.isOnboarded()) {
      this.$router.push({name: 'SignInWelcomeHome'})
    }
    if (Object.keys(this.currentUser).length === 0) {
      this.addCurrentUser(this.familyState.users.find(user => user.user_type === 'child'))
      window.location.reload()
    }
    this.loadContentful()
  }
}
</script>

<style scoped lang="scss">

header {
  padding: 16px;
  background: transparent;
  max-width: 700px;
  margin: 0 auto;

  .avatar {
    width: 36px;
    height: 36px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.logo-container {
  width: 108px;
  background: transparent;

  > img, svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.big-link {
  width: 100px;
  text-align: right;
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
}

.card-container {
  max-width: 700px;
  margin: 0 auto;
  scroll-snap-type: x mandatory;
  overflow-scrolling: touch;
  display: flex;
  gap: 12px;
  overflow-x: auto;
  width: 100%;
  scroll-padding-left: 16px;
  scroll-padding-right: 16px;

  > div:first-child {
    margin-left: 16px;
  }

  > div:last-child {
    margin-right: 16px;
  }
}

.authors-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  grid-gap: 20px;
  padding-bottom: 72px;

  > div {
    cursor: pointer;
    text-align: center;

    p {
      font-weight: bold;
    }

    > img {
      width: 72px;
      height: 72px;
      object-fit: cover;
    }
  }
}

.powers-container {
  > .powers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;

    > a {

      > .badge {
        width: 72px;
        height: 72px;
        position: relative;

        img {
          width: 100%;
        }

        .power {
          width: 36px;
          height: auto;
          position: absolute;
          top: calc(50% - 18px);
          left: calc(50% - 18px);
        }
      }

      > .new-badge {
        width: 72px;
        height: 72px;
      }


    }
  }
}

.tag-search {
  margin: auto;
}

@include screen-l {
  .card-container {
    max-width: none;
    justify-content: center;
  }
  .container-of-card-container {
    // max-width: 688px;
    margin: 0 auto;
  }
}
</style>