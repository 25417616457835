import Storage from "../../class/storage";

const storage = new Storage("family");

const state = storage.init({
    sessionExpired: false,
    openLogin: false,
    users: [],
});

const getters = {
    familyState: (state) => {
        return state;
    },
};

const mutations = {
    ADD_USER: (state, user) => {
        state.users.push(user)
        storage.update(state)
    },
    UPDATE_USER: (state, user) => {

        state.users.find(u => u.id === user.id).firstname = user.firstname
        state.users.find(u => u.id === user.id).avatar = user.avatar
        state.users.find(u => u.id === user.id).attachment_url = user.attachment_url
        state.users.find(u => u.id === user.id).custom_image = user.custom_image

        storage.update(state)
    },
    DELETE_USER: (state, id) => {
        state.users = state.users.filter(u => u.id !== id)
        storage.update(state)
    },
    SET_USERS: (state, users) => {
        state.users = users
        storage.update(state)
    },
    SESSION_EXPIRED: (state, bool) => {
        state.sessionExpired = bool
        storage.update(state)
    },
    TOGGLE_LOGIN: (state, bool) => {
        state.openLogin = bool
        storage.update(state)
    },
    CLEAR_FAMILY: (state) => {
        Object.assign(state, storage.init({
            sessionExpired: false,
            openLogin: false,
            users: [],
        }))
        storage.update(state)
    }
};

const actions = {
    addUser(store, user) {
        store.commit("ADD_USER", user)
    },
    updateUser(store, user) {
        store.commit("UPDATE_USER", user)
    },
    deleteUser(store, id) {
        store.commit("DELETE_USER", id)
    },
    setUsers(store, users) {
        store.commit("SET_USERS", users)
    },
    sessionExpired(store, bool) {
        store.commit("SESSION_EXPIRED", bool);
    },
    toggleLogin(store, bool) {
        store.commit("TOGGLE_LOGIN", bool);
    },
    clearFamily(store) {
        store.commit("CLEAR_FAMILY");
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};

