<template>
  <div>
    <div class="head pt-8">
      <div class="wrapper">
        <div class="back-button">
          <router-link :to="{name: 'Home'}" class="mr-6 cursor-pointer">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="#1C2326" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.81836 18H28.182" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 10.7273L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 25.2727L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </router-link>
        </div>
        <!--        <div class="author text">-->
        <!--          <div class="author-avatar">-->
        <!--            <img :src="author.fields.profil.fields.file.url" alt="">-->
        <!--          </div>-->
        <!--          <div class="author-name">-->
        <!--            Écrit par {{ author.fields.firstname }} · {{ $relativeDate(date) }}-->

        <!--          </div>-->
        <!--        </div>-->
        <h1 class="text-align-center mt-2">{{ title }}</h1>
        <p class="text-align-center mt-3">{{ description }}</p>
      </div>
    </div>
    <div class="bubble">
      <div class="triangle"></div>
    </div>

    <div class="wrapper relative">
      <img class="mascot" src="../../assets/img/wow-phone-1.png" alt="wow phone">
      <div class="img-container">
        <picture>
          <source type="image/webp"
                  media="(min-width: 1101px)"
                  :srcset="cover +'?fit=fill&w=600&h=600&fm=webp'"/>
          <source type="image/jpeg"
                  media="(min-width: 1101px)"
                  :srcset="cover +'?fit=fill&w=600&h=600&fm=jpg'"/>
          <source type="image/webp"
                  media="(min-width: 480px)"
                  :srcset="cover+'?fit=fill&w=500&h=440&fm=webp'"/>
          <source type="image/jpeg"
                  media="(min-width: 480px)"
                  :srcset="cover +'?fit=fill&w=500&h=440&fm=jpg'"/>
          <img
              :src="cover+'?fit=fill&w=375&h=220&fm=jpg'"
              alt="image illustrative de la capsule"/>
        </picture>
      </div>

      <div class="blocks wrapper">
        <div class="block">
          <div class="title">
            <div class="icon-container" style="--icon-container-color: #F6365C;">
              <img src="../../assets/img/search.png" alt="" style="transform: rotate(180deg);">
            </div>
            <h3>{{ $t('capsuleV2.summary.subject') }}</h3>
          </div>
          <p class="mt-2">{{ block.fields.subject }}</p>
        </div>
        <div class="block">
          <div class="title">
            <div class="icon-container" style="--icon-container-color: #09A6E6;">
              <img src="../../assets/img/idea.png" alt="">
            </div>
            <h3>{{ $t('capsuleV2.summary.solution') }}</h3>
          </div>
          <p class="mt-2">{{ block.fields.solution }}</p>
        </div>
        <div class="block">
          <div class="title">
            <div class="icon-container" style="--icon-container-color: #63C1A8;">
              <img src="../../assets/img/Frame15.png" alt="">
            </div>
            <h3>{{ $t('capsuleV2.summary.action') }}</h3>
          </div>
          <p class="mt-2">{{ block.fields.action }}</p>
        </div>
        <div class="p-4 rounded-2xl flex flex-col items-center" style="background: var(--color-secondary);" v-if="block.fields.WorldOfSolution">
          <img style="width: 300px;" :src="require(`@/assets/img/${$t('capsuleV2.summary.worldOfSolutionImg')}`)" alt="Logo A world of solution">
          <p class="text-xs text-align-center">{{$t('capsuleV2.summary.worldOfSolutionText')}}</p>
        </div>
      </div>

    </div>
    <div class="my-8">
      <div class="wrapper">
        <PrimaryButton @click.native="nextStep">{{ block.fields.buttonText }}</PrimaryButton>

      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";

export default {
  name: "MainSummary",
  components: {PrimaryButton},
  props: {
    block: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    author: {
      type: Object,
      required: true
    },
    date: {
      type: Date,
      required: true
    },
    cover: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  methods: {
    nextStep() {
      this.$emit('next-step', this.index)
    }
  },
  created() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped lang="scss">
.head {
  background: #FBD354;

  .author {
    display: flex;
    align-items: center;
    justify-content: center;

    .author-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .author-name {
      margin-left: 1rem;
      color: #1C2326;
    }
  }

  p {
    color: #1C2326;
    font-size: 17px;
    line-height: 24px;
  }


}

.bubble {
  background: #FBD354;
  position: relative;
  z-index: -1;
  height: 100px;
  border-radius: 100%;
  transform: translateY(-50%);
}

.triangle {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 100px);
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid #FBD354;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  transform: rotate(10deg);
}

.img-container {
  position: relative;
  height: 160px;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 72px;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.mascot {
  position: absolute;
  width: 90px;
  top: -78px;
  left: 36px;
  z-index: 10;
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
}

.block {
  .title {
    display: flex;
    align-items: center;
    gap: 14px;

    .icon-container {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--icon-container-color);


      img {
        width: 20px;
      }
    }
  }

  &:first-child .icon-container {
    transform: rotate(-180deg);
  }
}
</style>