export default {
    methods: {
        checkCacheVersion() {
            if (localStorage.getItem('cacheId')) {
                if (localStorage.getItem('cacheId') !== "1.2") {
                    localStorage.clear()
                    localStorage.setItem('cacheId', "1.2")
                    Cache.delete()
                    location.reload()
                }
            } else {
                localStorage.clear()
                localStorage.setItem('cacheId', "1.2")
                Cache.delete()
                location.reload()
            }
        }
    },
    created() {
        this.checkCacheVersion()
    }
}