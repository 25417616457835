<template>
  <div class="grid gap-4">
    <div class="parent-power" ref="parentPower" :id="index"
         :class="{'unlock': unlocked.includes(index) || index === current}"
         v-for="(power, index) in powers" v-on:click="doAnim($event, index)">
      <div class="second-parent flex">
        <div>
          <div class="pentagon">
            <img class="" alt="pictogramme de pentagone" src="../assets/img/pentagon_unlocked.png">
            <img class="power" :src="power.fields.icon.fields.file.url" alt="">
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="locker">
              <g filter="url(#filter0_i)">
                <rect x="0.974609" y="0.383057" width="47.6087" height="47.6087" rx="19.0435"
                      fill="url(#paint0_linear)"/>
                <path class="top-of-locker"
                      d="M20.0176 21.807V18.2363V18.2363C20.0176 15.6071 22.1493 13.4755 24.7784 13.4755V13.4755C27.4076 13.4755 29.5393 15.6071 29.5393 18.2363V18.2363V21.807"
                      stroke="white" stroke-width="2.97554" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.7787 26.5679V30.1385" stroke="white" stroke-width="2.97554" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M30.7299 34.8994H18.8277C17.5125 34.8994 16.4473 33.8342 16.4473 32.519V24.1874C16.4473 22.8723 17.5125 21.807 18.8277 21.807H30.7299C32.0451 21.807 33.1103 22.8723 33.1103 24.1874V32.519C33.1103 33.8342 32.0451 34.8994 30.7299 34.8994Z"
                      stroke="white" stroke-width="2.97554" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <filter id="filter0_i" x="0.974609" y="0.383057" width="47.6087" height="47.6087"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset dy="-3.57065"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 0 0.1125 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                </filter>
                <linearGradient id="paint0_linear" x1="24.779" y1="0.383057" x2="24.779" y2="47.9918"
                                gradientUnits="userSpaceOnUse">
                  <stop stop-color="#989898"/>
                  <stop offset="1" stop-color="#303030"/>
                </linearGradient>
              </defs>
            </svg>

          </div>

        </div>
        <div class="flex justify-center flex-col ml-4 ">
          <p class="strong mb-1">{{ power.fields.title }}</p>
          <p class="caption"> {{ power.fields.subtitle }}</p>
        </div>
      </div>
    </div>
    <div v-show="showModal" class="modal">
      <header class="mb-4 wrap-large">
        <div class="basic-container">
          <div class="pt-14">
            <button @click="goBack()" class="mr-6 -ml-1 ">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </header>
      <div class="main-container wrap rounded-t-3xl flex flex-col h-full">
        <div class="basic-container">
          <div class="pentagon-modal" ref="animPower">
            <img class="" alt="pictogramme de pentagone" src="../assets/img/pentagon_unlocked.png">
            <img :src="powers[indexModal].fields.icon.fields.file.url" alt="">
          </div>
          <div class="modal-text flex flex-col h-full" ref="modalText">
            <div class="flex flex-col flex-grow">
              <p class="mt-4 grey text-align-center">{{ powers[indexModal].fields.category }}</p>
              <h1 class="text-align-center mb-6"> {{ powers[indexModal].fields.title }}</h1>
              <ApiRichRenderer :rich-text="richText" text-type="power-text" v-if="!isLoading"/>
            </div>
            <primary-button class="mt-12" @click.native="handlePower" v-if="!unblock">
              {{ $t('capsule.superpower.action') }}
            </primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRichRenderer from "@/components/ApiRichRenderer";
import PrimaryButton from "@/components/PrimaryButton";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SuperPower",
  components: {PrimaryButton, ApiRichRenderer},
  computed: {
    ...mapGetters({
      capsules: "capsules",
    }),

  },
  data() {
    return {
      clicked: false,
      current: null,
      unlocked: [],
      showModal: false,
      indexModal: 0,
      richText: [],
      isLoading: true,
      unblock: false,
    }
  },
  props: {
    powers: {
      type: Array,
      required: true,
    }
  },
  methods: {
    ...mapActions(['unblockPower']),
    goBack() {
      this.showModal = false
      this.current = null
    },
    checkIfPowerExist() {
      return new Promise((resolve, reject) => {
        this.$http.get('/users/' + this.capsules.currentUsers.child.id + '/super_powers').then((resp) => {
          if (resp.data.find(el => el.contentful_id === this.powers[this.indexModal].sys.id)) {
            reject()
          } else {
            resolve()
          }
        })
      })
    },
    /**
     *
     */
    handlePower() {
      this.showModal = false
      this.unblockPower([this.powers[this.indexModal].fields.title, this.powers[this.indexModal].fields.subtitle, this.powers[this.indexModal].fields.icon.fields.file.url, this.indexModal])
      this.unblock = true
      this.unlocked.push(this.indexModal)
      this.checkIfPowerExist().then(() => {
        this.$http.post('/super_powers', {
          'super_power': {
            contentful_id: this.powers[this.indexModal].sys.id,
            user_id: this.capsules.currentUsers.child.id
          }
        }).then(resp => {
          if (!this.$route.query.env) {
            this.capsGettingUpdated = true
            this.$http.put('/capsules/' + this.capsules.capsuleIdApi, {blocks: this.capsules.blocks}).then(() => {
              this.capsGettingUpdated = false
            })
          }
        })
      }).catch(err => {
      })
      this.$emit('update-caps')
    },
    /**
     * function qui requête contentful suivant l'index du pouvoir par défault 0
     */
    richTextRenderer(id) {
      this.richText = this.powers[id].fields.content.content
      this.isLoading = false
    },
    /**
     * function qui récupère l'index du pouvoir et lance l'animation
     * @params {e, index}
     */
    doAnim(e, index) {
      this.$nextTick(() => {
        this.richTextRenderer(index)
        this.current = index
      })
      setTimeout(() => {
        this.animation(index)
      }, 500)
    },
    /**
     * affiche la modal et lance l'animation du contenu à l'intérieur
     */
    animation(id) {
      this.showModal = true
      this.indexModal = id
      setTimeout(() => {
        this.$refs.animPower.classList.add('top')
        setTimeout(() => {
          this.$refs.modalText.classList.add('anim')
        }, 100)
      }, 500)
      // this.requestRenderer()
    }
  },
  /**
   * function qui à la création du composant load les données
   */
  created() {
    if (this.capsules.power.length > 0) {
      this.unlocked.push(this.capsules.power[3])
      this.unblock = true
    }
    this.$nextTick(() => {
      this.richText = this.powers[0].fields.content.content
      this.isLoading = false
    })

  }
}
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-surface-soft);
  background: #FBD354;

  .pentagon-modal {
    margin: 0 auto;
    width: 146px;
    height: 146px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    transform: scale(1) translateY(0);
    transition: all 2s ease;

    img:nth-child(2) {
      position: absolute;
      z-index: 2;
      width: 62px;
    }

    svg {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .pentagon-modal.top {
    transform: scale(0.5) translateY(-250px);
  }

  .modal-text {
    transform: translateY(0);
    opacity: 0;
    transition: all 1s ease;


  }

  .modal-text.anim {
    transform: translateY(-144px);
    opacity: 1;
    height: calc(100vh - 32%);
    overflow: scroll;
  }
}

.parent-power {
  border-radius: var(--radius-default);
  position: relative;
  background: linear-gradient(to top, #D1D1D1, transparent);
  padding: 3px;

  .second-parent {
    border-radius: var(--radius-default);
    transition: background-color 0.2s cubic-bezier(0.15, 0.95, 1, 1);
    background-color: var(--color-surface-soft);
    padding: var(--space-12);

    .pentagon {
      width: 64px;
      height: 64px;
      position: relative;
      transition: filter 0.2s ease-in-out;
      filter: grayscale(1);
    }

    .power {
      display: block;
      width: 32px;
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .locker {
      transition: opacity 0.2s linear;
      transition-delay: 0.2s;
      opacity: 1;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;

      .top-of-locker {
        transition: 0.15s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        transform: none;

      }
    }
  }
}

.parent-power.unlock {
  background: linear-gradient(to top, #F2A53A, #FFD85A);

  .second-parent {
    background: #FFE593;

    .pentagon {
      filter: grayscale(0);
    }

    .locker {
      opacity: 0;

      .top-of-locker {
        transform: rotate(
                45deg
        ) translateY(-5px) translateX(-4px);
        transform-origin: 50% 50%;
      }
    }
  }


}

@media screen and (min-width: 576px) {
  .modal-text {
    > p:nth-child(1) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
    &.anim {
      overflow: hidden !important;
    }
  }


}

</style>