<template>
  <div class="smiley">
    <slot>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'smiley-circle'
}
</script>
<style lang="scss" scoped>
.smiley {
  position: absolute;
  display: grid;
  align-content: center;
  text-align: center;
  width: 124px;
  height: 124px;
  border-radius: 9999px;
  border: 2px solid var(--color-surface-default);
  background: var(--color-secondary-soft);
  word-break: break-word;

  > p {
    font-size: 1.3rem;
    line-height: 1.5rem;

  }


}

@media screen and (min-width: 576px) {
  .smiley {
    > p {
     // font-size: 1.25rem;
      //line-height: 1.75rem;
    }

  }
}


</style>