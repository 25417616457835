// Vee Validate

import Vue from 'vue'
import axios from "../backend/vue-axios/axios";
import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate';
import {required, confirmed, email} from 'vee-validate/dist/rules';
import fr from "@vee-validate/i18n/dist/locale/fr.json"
import en from "@vee-validate/i18n/dist/locale/en.json"
import i18n from '../locales/index'
import Storage from "../../class/storage";
const storage = new Storage('locale')

localize({
    [storage.get.locale || 'fr']: [storage.get.locale || fr]
})

extend('required', {
    ...required,
    message: i18n.t('validation.errors.required')
});

extend('confirmed', {
    ...confirmed,
    message: i18n.t('validation.errors.passwordConfirm')
});

extend('passwordTest', {
    validate: (value) => {
        return value.length >= 6;
    },
    message: i18n.t('validation.errors.password')
})

extend('email', {
    ...email,
    message: i18n.t('validation.errors.email')
});

extend('emailExists', {
    validate(value) {
        return axios.get('/check-email?email=' + value)
            .then(res => {
                return {
                    valid: res.data.status !== 500
                }
            })
    },
    message: i18n.t('validation.errors.emailExists')
})

extend('emailOk', {
    validate(value) {
        return axios.get('/check-email?email=' + value)
            .then(res => {
                return {
                    valid: res.data.status !== 200
                }
            })
    },
    message: i18n.t('validation.errors.emailOk')
})
// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
