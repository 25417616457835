<template>
	<validation-provider
		v-slot="{ errors, classes }"
		tag="div"
		class="input-password"
		:rules="rules"
		mode="eager"
		:vid="vid"
	>
		<label
			:for="uid"
			:class="[{isFocused: ( inputVal !== '' || isFocused)}, classes, {hasFocused: isFocused}]"
		>
			<div class="label">{{ label }}</div>
			<input
				:id="uid"
				v-model="inputVal"
				:type="isPasswordVisible ? 'text' : 'password'"
				:placeholder="placeholder"
				@focusin="isFocused = true"
				@blur="isFocused = false"
				@input="onInputValueChange()"
				:ref="reference"
				:vid="vid"
				:min="min"
			/>
			<UniButton
          type="button"
				:icon-name="isPasswordVisible ? 'eye' : 'eye-off'"
				appearance="subtle"
				tabindex="-1"
				@click.native="togglePasswordVisibility"
			/>
		</label>
		<small v-if="errors[0] || helperMessage" class="helper" :class="classes">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="14"
				viewBox="0 0 24 24"
				width="14"
				fill="var(--color-r100)"
			>
				<path
					d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
				/>
			</svg>
      <span v-if="errors[0]">{{ errors[0] }}</span>
      <span v-else>{{ helperMessage }}</span>
		</small>
	</validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import UniButton from "../UniButton.vue";
import uidMixin from "@/library/uid-mixin";

export default {
	name: "UniInputPassword",
	mixins: [uidMixin],
	components: {
		ValidationProvider,
		UniButton
	},
	props: {
		value: {
			type: String
		},
		label: {
			type: String,
			required: true
		},
		placeholder: {
			type: String
		},
		helperMessage: {
			type: String
		},
		rules: {
			type: String
		},
		reference: {
			type: String
		},
		vid: {
			type: String
		},
		min: {
			type: String
		}
	},
	data: function() {
		return {
			isPasswordVisible: false,
			inputVal: this.value,
			isFocused: false
		};
	},
	methods: {
		onInputValueChange() {
			this.$emit("input", this.inputVal);
		},
		togglePasswordVisibility() {
			this.isPasswordVisible = !this.isPasswordVisible;
		}
	}
};
</script>

<style scoped lang="scss">
.label {
	width: 100%;
	position: absolute;
	padding: 16px;
	font-size: 1rem;
	display: inline;
	z-index: 1;
	border: none;
	transition: var(--transition-quick);
	color: var(--color-text);
}
.input-password {
	margin-bottom: var(--space-16);
}
label {
	display: flex;
	overflow: hidden;

	outline: none;
	width: 100%;
	position: relative;
	height: 56px;
  border: 2px solid #DFE1E6;
  border-radius: var(--radius-default);
	transition: var(--transition-quick);
  background: #F2F4F6;
	::v-deep .button {
		margin: 8px 8px 0 8px;
		opacity: 0;
	}
	&:hover {
    border: 2px solid var(--color-surface-extra-strong);
	}
	&.hasFocused {
    border: 2px solid #DFE1E6;
	}
}
label.isFocused {
	.label {
		padding: 2px 12px 0 12px;
		font-size: 0.8125rem;
		color: var(--color-text-disabled);
	}
	input {
		opacity: 1;
	}
	::v-deep .button {
		opacity: 1;
	}
}
input {
	background: transparent;
	padding: 26px 12px 10px 12px;
	border: none;
	outline: none;
	width: calc(100% - 56px);
	opacity: 0;
	transition: var(--transition-quick);
}
/* Errors and helper */
.input-password.invalid {
	border: 1px solid var(--color-error);
}
.helper {
	margin: var(--space-4) 0 0 var(--space-4);
	display: inline-flex;
	align-items: center;
}
.helper.invalid > span {
  flex: 1;
	width: calc(100% - var(--space-20));
}
.helper.invalid {
	color: var(--color-error);
}
.helper > svg {
	margin-right: var(--space-4);
}
</style>
