<template>
  <div ref="text">
    <div class="parent-my-img" v-if="textType.includes('article') && imagePresent"></div>
    <div class="w-full timer" ref="timer" v-show="!$route.query.env && timer">
      <timer v-if="!loading" :time-min="duration" class="mb-4"/>
    </div>
    <rich-text-renderer :document="document" :nodeRenderers="renderNodes()"/>
  </div>
</template>

<script>
import {BLOCKS, INLINES} from '@contentful/rich-text-types';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import Countable from 'countable';
import Timer from "@/components/Timer";
import {mapGetters} from 'vuex'

export default {
  name: "ApiRichRenderer",
  data() {
    return {
      document: {
        nodeType: 'document',
        content: [],
      },
      legends: [],
      richTextHero: [],
      duration: null,
      loading: true,
      barTop: null,
      imagePresent: false,
      sticky: true,
    }
  },
  props: {
    richText: {
      type: Array,
      required: false
    },
    textType: {
      type: String,
      required: true
    },
    timer: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters(['capsules']),

    article() {
      return this.capsules.currentStep.contentType === "article"
    }
  },

  watch: {
    richText(newVal, oldVal){
      if(newVal !== oldVal){
        this.document.content = newVal
      }
    },
    article(newVal, oldVal) {
      if (newVal !== 'article') {
        const bar = this.$refs.timer
        const text = this.$refs.text
        text.style.paddingTop = 0 + 'px'
        bar.classList.remove("stickypos");
        bar.style.width = 'inherit'
      }
    },
  },
  methods: {
    renderNodes() {
      return {
        [BLOCKS.EMBEDDED_ASSET]: (node, key, h, next) => {
          let type = node.data.target.fields.file.contentType
          let url = node.data.target.fields.file.url
          let title = node.data.target.fields.title
          if (type.includes('image')) {
            this.imagePresent = true
            return h('img', {attrs: {src: url, class: "my-img mb-4", alt:title, id: title}})
          } else {
            return h('audio', {attrs: {src: url, controls: true, class: "my-audio"}})
          }
        },
        [INLINES.ENTRY_HYPERLINK]: (node, key, h, next) => {
          return `<a href="/${node.data.url}">${node.content[0].value}</a>`;
        },
        [BLOCKS.PARAGRAPH]: (node, key, h, next) => {
          return h('p', {attrs: {class: "mb-4"}}, next(node.content, key, h, next))
        },
        [BLOCKS.HEADING_1]: (node, key, h, next) => {
          return h('H1', {attrs: {class: "mb-8"}}, next(node.content, key, h, next))
        },
        [BLOCKS.HEADING_2]: (node, key, h, next) => {
          return h('H2', {attrs: {class: "mb-6"}}, next(node.content, key, h, next))
        },
        [BLOCKS.HEADING_3]: (node, key, h, next) => {
          return h('H3', {attrs: {class: "mb-6"}}, next(node.content, key, h, next))
        },

        [BLOCKS.HEADING_4]: (node, key, h, next) => {
          return h('H3', {attrs: {class: "mb-6"}}, next(node.content, key, h, next))
        },
      }
    },
    /**
     * function qui mets la bar de temps de lecture en sticky
     */
    stickyTimer() {
      if (this.capsules.currentStep.contentType === "article" && !this.$route.query.env) {
        if (this.$route.path !== "/") {
          const bar = this.$refs.timer
          const text = this.$refs.text
          const barHeight = bar.offsetHeight
          let barWidth = 0
          let fixTop = 0
          if (window.matchMedia("(min-width: 600px)").matches) {
            fixTop = +56
            barWidth = bar.offsetWidth
          } else {
            fixTop = -56 - barHeight
            barWidth = window.innerWidth
          }
          if (window.pageYOffset > this.capsules.timer.top) {
            text.style.paddingTop = 44 + 'px'
            bar.classList.add("stickypos")
            bar.style.width = barWidth + 'px'
          } else {
            text.style.paddingTop = 0 + 'px'
            bar.classList.remove("stickypos");
            bar.style.width = 'inherit'
          }
        }
      }
    },
    addParentToImg() {
      let img = document.querySelectorAll('.my-img');
      let wrapper = document.querySelector('.parent-my-img');
      img.forEach(el => {
        let clone = wrapper.cloneNode(true)
        el.parentNode.insertBefore(clone, el);
        clone.appendChild(el);
        let caption = document.createElement("figcaption")
        caption.classList.add('legend')

        caption.innerHTML = el.id
        clone.appendChild(caption)
      })
      if (wrapper) {
        wrapper.remove()
      }

    }
  },
  /**
   * function qui récupère l'image et ajoute un container autour
   */
  updated() {
    if (this.textType.includes('article')) {
      if (this.textType === "article") {
        Countable.count(this.$refs.text, counter => {
          let words = counter.words
          this.duration = Math.round(words / 100)
          this.loading = false
        })
      }
    }
    this.$nextTick(() => {
      this.document.content = this.richText
    })

    // audio.play()
    // console.log(audio)
  },
  components: {
    Timer,
    RichTextRenderer
  },
  /**
   * function qui une fois le composant près récupère la bar de timer et stock ça valeur du top et lance le scroll event
   */
  mounted() {
    if (this.$refs.timer) {
      let bar = this.$refs.timer
      this.barTop = bar.offsetTop
      if (this.textType.includes("article") && this.$route.path.includes('/capsule')) {
        window.addEventListener('scroll', this.stickyTimer);
      }
    }

    this.$nextTick(() => {
      this.addParentToImg()
    })

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.stickyTimer, true);
  },

  /**
   * au lancement génère le document
   */
  created() {
    this.$nextTick(() => {
      this.document.content = this.richText

    })

  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: underline;
}

.stickypos {
  padding: 8px 16px;
  position: fixed !important;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: var(--shadow-m);

  > div {
    margin-bottom: 0;
  }
}

.basic-container {

  > h1 {
    margin-bottom: 32px;
  }

  > p {
    margin-bottom: 16px;
  }

  > h2, h3, h4, h5 {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 576px) {
  p {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  .stickypos {
    padding: 8px 16px;
    left: inherit;
    border-radius: 22px;
    margin-top: 8px;
  }
  .parent-my-img {
    //strat pour ne pas ajouter un wraper sur chaque élément et quand même avoir les images qui prennet toute la largeur
    margin: 24px 0px !important;
    width: 100% !important;
    height: 100% !important;

    img{
      max-height: 100%!important;
    }
  }
}

p {
  //font-size: 1rem;
  //line-height: 1.5rem;
}

.parent-my-img {
  //strat pour ne pas ajouter un wraper sur chaque élément et quand même avoir les images qui prennet toute la largeur
  margin: 1.50rem 0 1.50rem -1rem;
  width: calc(100% + 2rem);
  //height: 254px;

  img {
    max-width: none;
    object-fit: cover;
    width: 100%;
    max-height: 254px;
  }
}


.my-audio {
  width: 100%;
  height: 32px;
}
</style>