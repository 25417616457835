const fr = {
    layout: {
        welcome: "Bienvenue sur"
    },
    home: {
        title: "Salut",
        capsuleOfTheWeek: "Voici le sujet de la semaine",
        interest: "Ça peut aussi t'intéresser",
        superpowers: "Tes super-pouvoirs",
        noSuperpowers: "Tu n'as pas encore de super-pouvoirs. Termine un article pour en débloquer !",
        freeCapsules: "Sujets gratuits",
        noTheme: "Vous n'avez pas encore de thèmes favoris. Vous pouvez en ajouter en cliquant",
        noThemeHere: "ici",
        discover: "Découvrir d'autres sujets",
        journalists: "Les journalistes de WoW!",
        journalistLastNews: "Le dernier sujet traité par",
        children: "Enfants",
        previousCapsules: "Sujets précédents",
        all: "Voir tous",
        free: "Gratuites",
        seeMore: "Voir plus de sujets",
        outOf: "sur",
        search: "Recherche : "
    },
    cards: {
        writtenBy: "Écrit par",
        today: "Aujourd'hui",
        yesterday: "Hier",
        thisWeek: "Cette semaine",
        readMore: "Lire la suite",
        subscribe: "S'abonner",
    },
    profil: {
        title: "Qui va là ?",
        edit: "Modifier",
    },
    child: {
        welcome: "Bienvenue sur WoW! News",
        title: "Ajouter un nouveau profil",
        placeholderChild: "Prénom de l'enfant",
        placeholderParent: "Prénom de l'adulte",
        error: "Veuillez compléter ce champs",
        next: "Suivant",
        selectImage: "Veuillez sélectionner une image",
        who: "Qui êtes-vous ?",
        avatar: "Choix de l'avatar"
    },

    // ************** SETTINGS ***************
    settings: {
        superpowers: "Mes super-pouvoirs",
        profiles: "Autres profils",
        handleProfiles: "Gérer les profils",
        help: "Aide",
        helpMessage: "Besoin d'aide ou d'assistance ? Contactez-nous par email : ",
        password: "Mot de passe",
        passwordIncorrect: "Mot de passe incorect",
        preferences: "Préférences",
        preferencesQuestion: "Souhaitez-vous recevoir notre newsletter&nbsp;?",
        preferencesDyslexia: "Activer la police d'écriture pour la dyslexie.",
        preferencesText: "En cochant cette case, vous acceptez de recevoir chaque semaine une dose d’infos positives à partager en famille ou en classe.",
        preferencesChoose: "Dans quelle langue souhaitez-vous la recevoir ?",
        preferencesSave: "Enregistrer",
        preferencesDelete: "Supprimer",
        preferencesDeleteUnauthorized: "Vous ne pouvez pas supprimer cet utilisateur, vous devez avoir au moins un utilisateur.",
        appSettings: "Préférences de l'application",
        handleSubscription: "Gérer l'abonnement",
        cancelSubscription: "Êtes-vous sûr de vouloir supprimer votre compte ? Cela mettra fin à votre abonnement.",
        logout: "Se déconnecter",
        delete: "Supprimer mon compte",
        checkPassword: "Validation du mot de passe",
        checkPasswordSentence: "Veuillez valider votre mot de passe afin d'effectuer cette action",
        validate: "Valider",
        deleteAccountTitle: "Suppression du compte",
        deleteAccountText: "Si vous n'arrivez pas à supprimer votre compte, contactez-nous par email : contact@wow-news.eu",
        updatePassword: {
            title: "Mettre à jour le mot de passe",
            old: "Ancien mot de passe",
            new: "Nouveau mot de passe",
            confirm: "Confirmez",
            noMatch: "Les deux mots de passes doivent être identiques",
            supportTitle: "Mise à jour du mot de passe",
            supportTitleLogout: "Pas de panique !",
            supportTitleError: "Une erreur est survenue",
            supportText: "Si vous n'arrivez pas à mettre à jour votre mot de passe, contactez-nous par email: contact@wow-news.eu.",
            supportTextLogout: "Vous allez être déconnecté.e, un instant.",
            supportTextError: "Merci de contacter le support."
        },
        admin: {
            createAccount: {
                title: "Créer un compte",
                email: "Adresse e-mail",
                password: "Mot de passe",
                notifCb: "Envoyer un e-mail de demande de changement de mot de passe ?",
                testCb: "Est-ce un compte invité ?",
                genereatePwdButton: "Générer un mot de passe aléatoire"
            },
            stats: {
                type: "Type",
                startValue: "Valeur de départ",
                endValue: "Valeur de fin",
                percentage: "Évolution",
                rate: "Taux",
            },
            updateState: "Changement d'état"
        },
        mandatory: "Obligatoire"
    },

    // ************ PASSWORD ******************* //

    password: {
        resetTitle: "Réinitialiser le mot de passe",
        createTitle: "Création du mot de passe"
    },

    // ************ CAPSULE ******************* //
    capsule: {
        free: "Sujet gratuit",
        finished: "Sujet terminée",
        memories: "Souvenirs",
        superpowerFinished: "Le super-pouvoir choisi par {name} :",
        next: "Suivant",
        solutionTitle: "Prêt·e à activer tous tes neurones ?",
        actionTitle: "À toi de jouer !",
        titles: {
            problem: "Problème ?", solution: "Solution !", action: "Action"
        },
        closeQuestion: {
            yes: "Oui",
            no: "Non",
            titleYes: "Super ! je vois que tu connais déja un peu le problème",
            titleNo: "C'est normal de ne pas tout connaître",
            content: "Regarde cette vidéo pour en savoir plus !",
            action: "Découvrir en vidéo"
        },
        qcm: {
            title: "WoW! News a mené l'enquête, laquelle de ces solutions vous semble la bonne ?",
            false: "Pas mal ! Mais ce n’est pas la bonne réponse, essayez encore.",
            true: "Bonne réponse ! C'est cette solution que WoW! News vous présente cette semaine",
            falseAction: "Réessayer",

        },
        content: {
            title: "Voulez-vous regarder une courte vidéo ou lire un petit article ?",
            description: "Vous pouvez aussi voir les deux !",
            actions: {
                video: "Voir la vidéo", post: "Voir l'article", both: "Voir les deux"
            }
        },
        solutionVideo: {
            title: "Regardons ensemble la solution en vidéo",
            help: "N'oublie pas de mettre en plein écran",
            helpParent: "N'oubliez pas de mettre en plein écran",
            question: "Vous avez regardé la vidéo ?",
            action: "Passer à l'étape suivante",
        },
        emotions: {
            title: "Alors, vous en pensez quoi de cette solution ? On va jouer ensemble pour décrire nos émotions !",
            conclusion: "Merci l'équipe pour ce joli nuage de mots !",
            capsuleFinished: "Le ou les mots en commun du nuage de mots :",
            child: {
                choose: ", Choisis 4 mots qui correspondent à ce que tu ressens en découvrant cette solution",
                hero: ", qu’est-ce que tu aurais envie de dire à "
            },
            parent: {
                choose: ", choisissez 4 mots qui correspondent à ce que vous ressentez en découvrant cette solution",
                hero: ", qu’avez-vous envie de dire à "
            }

        },
        problem: {
            title: "Voilà le problème !",
            question: "Vous avez regardé la vidéo ?",
            help: "N'oublie pas de mettre en plein écran",
            action: "Passer à l'étape suivante"
        },
        action: {
            info: "Apprendre qu'il existe des solutions, c'est important pour nous donner envie de passer à l'action. On y va ?",
            strength: {
                title: "Heureusement, nous pouvons tous agir, avec nos forces et nos talents.",
                content: "Si chacun·e de nous les utilise, mis bout à bout, cela fait des super-pouvoirs ! Et tous ensemble, nous pouvons changer le monde !",
                action: "Découvrir mes super-pouvoirs"
            }
        },
        superpower: {
            action: "Ajouter à la collection", congrats: "On compte sur toi pour essayer de l'utiliser au quotidien."
        },
        imagine: {
            title: "À ton tour maintenant d'imaginer un super-pouvoir !",
            description: "Filme-toi ou enregistre-toi en train de nous décrire ton super-pouvoir pour nous aider à trouver des solutions !",
            video: "Filmer une courte vidéo",
            audio: "Enregistrer un message vocal",
            idea: "L'idée de ",
            separator: "Ou",
            skip: "Passer cette étape",
            newVideo: "nouvelle vidéo",
            newAudio: "nouvel audio",
            deleteVideo: "Supprimer la vidéo",
            deleteAudio: "Supprimer l'audio",
            consentVideo: "Je souhaite partager ma vidéo avec la Tribu WoW!",
            consentAudio: "Je souhaite partager mon enregistrement avec la Tribu WoW!",
            conclusion: {
                title: "Ton super-pouvoir est super intéressant !",
                content: "Tu pourras retrouver ta vidéo dans tes souvenirs !"
            }
        },
        actions: {
            freeTrial: "Offre d'essai",
            subscribe: "S'abonner",
            start: "C'est parti !",
            back: "Retourner à l'accueil",
        },
        selectChild: {
            title: "Qui va là ?", parent: "Adulte", child: "Enfant", action: "C'est parti !", modale: {
                title: "Choix de l'enfant", action: "Faire la capsule avec"
            }
        },
        continueModale: {
            title: "Voulez-vous ...",
            contentFinish: "L'utilisateur sélectionné a déjà fini le sujet précédemment, voulez-vous",
            contentKeep: "L'utilisateur séléctionné a déjà avancé dans le sujet, voulez-vous",
            actions: {
                restart: "Recommencer le sujet", keep: "Continuer le sujet", see: "Visualiser le sujet"
            }
        },
        end: {
            title: "Ça y est, c'est terminé pour aujourd'hui.",
            content: "J’ai passé un chouette moment en votre compagnie et j’espère que vous également. Vous pourrez retrouver tous vos souvenirs dans la page de votre sujet.",
            action: "Terminer"
        },
        limitedAccess: {
            title: "Accès limité",
            text: "Voici un aperçu des sujets que nous proposons. Si vous êtes interessés pour en voir plus, contactez-nous par mail à l'adresse catherine@wow-news.eu ou alastair@wow-news.eu."
        }

    },
    capsuleV2: {
        summary: {
            subject: "Le sujet",
            solution: "La solution",
            action: "Action !",
            worldOfSolutionText: "Ce sujet a été réalisé dans le cadre de l'appel à projets lancé par le European Journalism Centre et le Solutions Journalism Network, avec le soutien de la Fondation Bill et Melinda Gates. Il fait partie de notre série intitulée \"Un monde de solutions\"",
            worldOfSolutionImg: "awos-fr.png",
        },
        quizz: {
            check: "Vérifie ta réponse",
            good: "Super, bonne réponse !",
            bad: "Ça se pourrait mais ce n'est pas la bonne réponse !"
        },
        subject: {
            emotions: {
                title: "Ça te fait quoi ?",
                sad: "Ça me rend triste",
                worry: "Ça m'inquiète",
                neutral: "Ça m'est égal",
                angry: "Ça me met en colère",
                nothing: "Rien de tout ça",
            }
        },
        fix: {
            emotions: {
                title: "Découvrir cette solution, ça te fait quoi ?",
                impressed: "Ça m'impressionne",
                amazed: "Ça m'émerveille",
                happy: "Ça me rend heureux",
                reassure: "Ça me rassure",
                inspire: "Ça m'inspire",
                nothing: "Rien de tout ça",
            },
            printPdfText: "En savoir plus sur le pays",
            print: "Imprimer",
        },
        superpowers: {
            title: "Choisis ton super-pouvoir",
            learnMore: "En savoir plus",
            add: "Ajouter à ma collection",
        },
        end: {
            think: "Qu'as-tu pensé de ce sujet ?",
            enjoy: "Est-ce que ça t'a plu ?",
            learn: "Est-ce que tu as appris des choses ?",
            inspire: "Est-ce que ça te donne envie d'agir ?",
            save: "Sauvegarde ta note",
            printPdfText: "Imprime ton article à compléter",
            print: "Imprimer",
            retry: "Recommencer",
            backHome: "Retour à l'accueil",
            otherSubjects: "D'autres sujets similaires",
            savedRating: "Ta note a été sauvegardée !"
        }
    },
    // ****************** SIGN IN ************
    signIn: {
        title: "Se connecter",
        action: "Connexion",
        separator: "Ou",
        notAccount: "Pas encore de compte ?",
        createAction: "Créer mon compte",
        forgotPassword: "Mot de passe oublié",
        errors: {
            sessionExpired: {
                title: "Session expirée", content: "Votre session a expiré, veuillez vous reconnecter."
            }, badCredentials: {
                title: "Identifiants incorrects", content: "Veuillez réessayer la connexion"
            }, google: {
                title: "Erreur", content: "Aucun compte n'est associé à ce compte Google. Veuillez créer un compte."
            }, facebook: {
                title: "Erreur", content: "Aucun compte n'est associé à ce compte Facebook. Veuillez créer un compte."
            },
            unitedSchools: {
                title: "Erreur",
                unknown: "Cet utilisateur n'a pas de compte United Schools avec ces identifiants.",
                badCredentials: "Mauvais identifiants United Schools.",
                emailTaken: "Un compte WoW News existe déjà pour cette adresse email, contactez le support.",
                idTaken: "Un compte existe déjà avec ces identifiants United Schools, utilisez la page de connexion."
            }
        },
        unitedSchools: {
            login: "Entrez vos identifiants United Schools",
            username: "Nom d'utilisateur",
            password: "Mot de passe"
        }
    },

    // ************* FORGOT PASSWORD ************
    forgotPassword: {
        title: "Mot de passe oublié",
        email: "Adresse e-mail",
        action: "Réinitialiser le mot de passe",
        info: {
            title: "E-mail envoyé",
            content: "Un e-mail de réinitialisation de mot de passe vous a été envoyé"
        }
    },

    // ***************** SIGNUP ¨*****************
    signUp: {
        title: "Créer mon compte",
        discover: "Vous voulez découvrir WoW! à l'école ou à la maison ? Inscrivez-vous gratuitement et découvrez tous les sujets disponibles.",
        howItWorks: "Comment ça marche ?",
        howItWorksVideo: "https://www.youtube.com/shorts/-1t3klAi_Vo",
        action: "Créer mon compte",
        teacher: "Je suis un·e enseignant·e",
        separator: "Ou",
        alreadyAccount: "Déjà un compte ?",
        alreadyAccountAction: "Connexion",
        confirmAccount: "Veuillez valider votre compte, un email a été envoyé à l'adresse suivante : ",
        email: {
            title: "Consultez vos emails",
            content: "Pour finaliser la création de votre compte, veuillez consulter vos emails et cliquer sur le lien que nous avons envoyé",
            spam: "Pensez à vérifier vos spams"
        },
        errors: {
            google: {
                title: "Erreur", content: "Ce compte Google est déjà enregistré. Veuillez vous connecter"
            }, facebook: {
                title: "Erreur", content: "Ce compte facebook est déjà enregistré. Veuillez vous connecter"
            }, email: {
                title: "Erreur", content: "Cette adresse email est déjà associée à un compte. Veuillez vous connecter."
            }
        }
    },

    //********* PRICING ***********

    pricing: {
        title: "Choix de la formule",
        description: "Chaque semaine découvrez un nouveau sujet et accédez aux anciens sujets, en illimité et sans pub. En prenant un abonnement annuel, économisez 25%.",
        action: "je démarre l'offre d'essai",
        month: {
            title: "Abonnement mensuel sans engagement",
            price: "{price}€/mois",
            info: "(possibilité de suspendre l'abonnement à tout moment)"

        },
        year: {
            title: "Abonnement annuel avec engagement",
            price: "59,99€/an ({monthPrice}€/mois soit {percent}% d'économie)",
            info: "(abonnement pour 1 an minimum)",
            promotion: "Offre SPME (-50%) <br /> 29,99€ pour 1 an ! (soit 2,50€/mois) <br /> Code promotionnel : <i>SPME 2023</i>"
        }
    },

    thanks: {
        title: "Bienvenue ! {name} 🎉",
        content: "Vous avez désormais accès à tous les sujets WoW! News !<br />Pour débuter l'expérience avec votre enfant, appuyez sur le bouton ci-dessous et choisissez un sujet.",
        action: "Continuer",
        actionArticle: "Continuer vers la capsule"
    },
    onboard: {
        title: "Moi, c'est SuperWoW !",
        p1: "Avec les journalistes, j'accompagne les enfants ou les élèves, dans les parcours au sein de l'appli WoW !",
        p2: "Chaque semaine, nous leur présentons un sujet à regarder seul ou accompagné pendant 15 min environ.",
        p3: "Pour accéder aux sujets, créez le profil d'un enfant.",
        p4: "(Vous pourrez en créer autant que vous le souhaitez par la suite)",
        steps: {
            video: "https://youtu.be/GaeXBPKbf1Y",
            howItWorks: "Comment ça marche ?",
            freeContent: "1 article gratuit par mois",
            freeContentText: "Si vous voyez cette image, c'est que le sujet est offert !\n" +
                "\n" +
                "Aucun abonnement n'est requis pour le consulter.",
            beforeYouStart: "Avant de commencer",
            action: "Continuer sans s'abonner",
            locale: {
                title: "Communication",
                content: "Dans quelle langue voulez-vous qu'on communique avec vous&nbsp;?",
                newsletter: "S'abonner à la newsletter"
            }
        }
    },
    validation: {
        placeholders: {
            childName: "Prénom de l'enfant",
            theme: "Quel·s thème·s l’intéresse·nt le plus ? \n",
            optional: "Optionnel",
            age: "Âge",
            ageHelp: "L'âge nous permet d'adapter notre contenu à votre enfant.",
            email: "Adresse e-mail",
            password: "Mot de passe",
            confirmPassword: "Confirmez le mot de passe"
        }, errors: {
            required: "Veuillez compléter ce champ.",
            imageRequired: "Veuillez sélectionner une image",
            email: "Cette adresse e-mail n'est pas valide.",
            password: "Doit contenir au moins 6 caractères",
            passwordConfirm: "Doit être identique au mot de passe",
            emailExists: "Cette adresse e-mail est déja enregistrée.",
            emailOk: "Cette adresse e-mail n'est pas enregistrée."

        }

    }
}

export default fr