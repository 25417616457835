<template>
  <transition :name="name" :mode="mode">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "TransitionBlock",
  props: {
    name: {
      type: String, required: true
    },
    mode: {
      type: String, required: true
    }
  }
}
</script>

<style scoped>

/*class pour les transition fade*/

.fade-enter-active {
  transition: all .2s ease;
}

.fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/*class pour les prochaine transitions*/


</style>