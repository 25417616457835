import Storage from "../../class/storage";

const storage = new Storage("capsules")

function initialState() {
    return {
        currentStep: {step: 0, contentType: "description"},
        steps: [],
        stepsLength: 0,
        capsuleID: "",

        resultsCloseQuestion: [],
        currentQuestion: 0,

        currentSolution: {id: null, answer: null},
        // currentUsers: [],
        currentUsers: {
            parent: {name: '', avatar: '', currentSmileyParent: null, currentWord: [], id: null},
            child: {name: '', avatar: '', currentSmileyKid: null, currentWord: [], id: null}
        },

        timer: {top: null, screenSize: null, start: false,},
        capsuleIdApi: null,
        isCompleted: false,
        power: [],
        firstStepCheck: false,
        userAudio: '',
        userVideo: '',
        videoSolution: null,
        gameWords: [],
        volumeIsOn: false,
        audioIsSet: false
    }
}

const state = storage.init(initialState())


const getters = {
    capsules: state => {
        return state
    }
}


const mutations = {
    SET_GAME_WORDS: (state, array) => {
        state.gameWords = array
        storage.update(state)
    },
    SET_VIDEO_SOLUTION: (state, bool) => {
        state.videoSolution = bool
        storage.update(state)
    },
    DELETE_USER_FILE: (state) => {
        state.userAudio = ''
        state.userVideo = ''
        storage.update(state)
    },
    SET_USER_FILE: (state, obj) => {
        if (obj.type !== null) {
            if (obj.type.includes('mp3')) {
                state.userAudio = obj.file
            } else {
                state.userVideo = obj.file
            }
        }

        storage.update(state)
    },
    SET_FIRST_STEP_CHECK: (state, bool) => {
        state.firstStepCheck = bool
        storage.update(state)
    },
    UNBLOCK_POWER: (state, array) => {
        state.power = array
        storage.update(state)
    },
    SET_IS_COMPLETED: (state, bool) => {
        state.isCompleted = bool
        storage.update(state)
    },
    SET_CAPSULE_CONTENT_ID: (state, id) => {
        state.capsuleID = id
        storage.update(state)
    },
    SET_CAPSULE_ID: (state, id) => {
        state.capsuleIdApi = id
        storage.update(state)
    },
    SET_INIT_STORE: (state, data) => {
        state.currentStep = data.currentStep
        state.resultsCloseQuestion = data.resultsCloseQuestion
        state.capsuleID = data.capsuleID
        state.capsuleIdApi = data.capsuleIdApi
        state.currentQuestion = data.currentQuestion
        state.currentSolution = data.currentSolution
        state.currentUsers = data.currentUsers
        state.isCompleted = data.isCompleted
        state.steps = data.steps
        state.stepsLength = data.stepsLength
        state.power = data.power
        state.gameWords = data.gameWords

        storage.update(state)
    },

    SET_CURRENT_STEP: (state, currentStep) => {
        state.currentStep = currentStep
        storage.update(state)
    },
    SET_STEPS: (state, steps) => {
        state.steps = steps
        storage.update(state)
    },
    SET_STEPS_LENGTH: (state, stepslength) => {
        state.stepsLength = stepslength
        storage.update(state)
    },
    SET_RESULTS_CLOSE_QUESTION: (state, resultsCloseQuestion) => {
        state.resultsCloseQuestion = resultsCloseQuestion
        storage.update(state)
    },
    SET_CURRENT_QUESTION: (state, currentQuestion) => {
        state.currentQuestion = currentQuestion
        storage.update(state)
    },
    SET_CURRENT_SOLUTION: (state, currentSolution) => {
        state.currentSolution = currentSolution
        storage.update(state)
    },
    SET_CURRENT_SMILEY_KID: (state, currentSmiley) => {
        // state.currentSmileyKid = currentSmiley
        state.currentUsers.child.currentSmileyKid = currentSmiley
        storage.update(state)
    },
    SET_CURRENT_WORD_KID: (state, currentSmiley) => {
        state.currentUsers.child.currentWord = currentSmiley
        storage.update(state)
    },
    SET_CURRENT_SMILEY_PARENT: (state, currentSmiley) => {
        state.currentUsers.parent.currentSmileyParent = currentSmiley
        storage.update(state)
    },
    SET_CURRENT_WORD_PARENT: (state, currentSmiley) => {
        state.currentUsers.parent.currentWord = currentSmiley
        storage.update(state)
    },
    SET_CURRENT_USERS: (state, currentUsers) => {
        state.currentUsers = currentUsers
        // state.currentUsers.child.name = currentUsers.child.name
        storage.update(state)
    },
    SET_CURRENT_CAPSULE: (state, currentCapsule) => {
        state.capsuleID = currentCapsule
        storage.update(state)
    },
    RESET_CURRENT_CAPSULE: (state) => {
        state = {
            currentStep: {step: 0, contentType: "description"},
            steps: [],
            stepsLength: 0,
            capsuleID: "",

            resultsCloseQuestion: [],
            currentQuestion: 0,

            currentSolution: {id: null, answer: null},
            currentUsers: [],
            // currentSmileyKid: null,
            // currentSmileyParent: null
        }
        storage.update(state)
    },
    SET_TOP_TIMER: (state, top) => {
        state.timer.top = top
        storage.update(state)
    },
    SET_SCREEN_SIZE_TIMER: (state, size) => {
        state.timer.screenSize = size
        storage.update(state)
    },
    SET_START_TIMER: (state, bool) => {
        state.timer.start = bool
        storage.update(state)
    },
    SET_AUDIO: (state, bool) => {
        state.volumeIsOn = bool
        state.audioIsSet = true
        storage.update(state)
    },
    reset(state) {
        // acquire initial state
        const s = initialState()
        Object.keys(s).forEach(key => {
            //TODO : Terminer audio
           // if (key !== "volumeIsOn" && key !== "audioIsSet")
            state[key] = s[key]
        })
    }


}
const actions = {
    setGameWords(store, array) {
        store.commit('SET_GAME_WORDS', array)
    },
    setVideoSolution(store, bool) {
        store.commit('SET_VIDEO_SOLUTION', bool)
    },
    deleteUserFile(store) {
        store.commit('DELETE_USER_FILE')
    },
    setUserFile(store, obj) {
        store.commit('SET_USER_FILE', obj)
    },
    setCapsuleIdContent(store, string) {
        store.commit('SET_CAPSULE_CONTENT_ID', string)
    },
    setFirstStepCheck(store, bool) {
        store.commit('SET_FIRST_STEP_CHECK', bool)
    },

    unblockPower(store, array) {
        store.commit('UNBLOCK_POWER', array)
    },
    setIsCompleted(store, bool) {
        store.commit('SET_IS_COMPLETED', bool)
    },
    setCapsuleId(store, id) {
        store.commit('SET_CAPSULE_ID', id)
    },
    setInitStore(store, data) {
        store.commit('SET_INIT_STORE', data)
    },
    setCurrentStep(store, currentStep) {
        store.commit("SET_CURRENT_STEP", currentStep)
    },
    setSteps(store, steps) {
        store.commit("SET_STEPS", steps)
    },
    setStepsLength(store, stepslength) {
        store.commit("SET_STEPS_LENGTH", stepslength)
    },
    setResultsCloseQuestion(store, resultsCloseQuestion) {
        store.commit("SET_RESULTS_CLOSE_QUESTION", resultsCloseQuestion)
    },
    setCurrentQuestion(store, currentQuestion) {
        store.commit("SET_CURRENT_QUESTION", currentQuestion)
    },
    setCurrentSolution(store, currentSolution) {
        store.commit("SET_CURRENT_SOLUTION", currentSolution)
    },
    setCurrentSmileyKid(store, currentSmiley) {
        store.commit("SET_CURRENT_SMILEY_KID", currentSmiley)
    },
    setCurrentSmileyParent(store, currentSmiley) {
        store.commit("SET_CURRENT_SMILEY_PARENT", currentSmiley)
    },
    setCurrentUsers(store, currentUsers) {
        store.commit("SET_CURRENT_USERS", currentUsers)
    },
    setCurrentCapsule(store, currentCapsule) {
        store.commit("SET_CURRENT_CAPSULE", currentCapsule)
    },
    resetCurrentCapsule(store) {
        store.commit("RESET_CURRENT_CAPSULE")
    },
    setCurrentWordKid(store, currentWord) {
        store.commit("SET_CURRENT_WORD_KID", currentWord)
    },
    setCurrentWordParent(store, currentWord) {
        store.commit("SET_CURRENT_WORD_PARENT", currentWord)
    },
    setTopTimer(store, top) {
        store.commit("SET_TOP_TIMER", top)
    },
    setScreenSizeTimer(store, size) {
        store.commit("SET_SCREEN_SIZE_TIMER", size)
    },
    setStartTimer(store, bool) {
        store.commit("SET_START_TIMER", bool)
    },
    setAudio(store, bool) {
        store.commit("SET_AUDIO", bool)
    },
    reset(store) {
        store.commit("reset")
    }
}

export default {
    state,
    mutations,
    actions,
    getters
};