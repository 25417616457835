<template>
  <button class="uppercase box-shadow-network">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M22.6763 24C23.4077 24 24.0008 23.4069 24.0008 22.6754V1.32457C24.0008 0.592862 23.4077 0 22.6763 0H1.32554C0.593839 0 0.000976562 0.592952 0.000976562 1.32457V22.6753C0.000976562 23.4069 0.593839 23.9999 1.32554 23.9999H22.6763V24Z" fill="white"/>
        <path d="M16.5595 24.0002V14.7061H19.6791L20.1462 11.084H16.5594V8.77147C16.5594 7.72279 16.8506 7.00817 18.3545 7.00817L20.2725 7.00727V3.76771C19.9407 3.72365 18.8021 3.625 17.4777 3.625C14.7123 3.625 12.819 5.31294 12.819 8.41286V11.084H9.69141V14.7061H12.819V24.0001H16.5595V24.0002Z" fill="#485a96"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
      <span v-if="!isSmall">Continuer avec Facebook</span>
      <span v-else>Facebook</span>
  </button>
</template>

<script>
  export default {
    name: "FacebookButton",
      props: {
          isSmall: {
              type: Boolean,
              default: false
          }
      }
  }
</script>

<style lang="scss" scoped>
  button{
    background: var(--color-surface-soft);
    width: 100%;
    border-radius: var(--radius-default);
    border: 2px solid #E5E0DF;
    border-bottom: none;
    padding: var(--space-12);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3C5A9A;
  }
  svg{
    margin-right: var(--space-8);
  }
</style>