<template>
  <div>
    <header class="wrap basic-container flex space-between align-items-center">
      <div class="logo-container">
        <img src="../assets/wow-logo.png">
      </div>
      <router-link to="/">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M22.1841 11.8373L13.1279 2.78573L12.5208 2.1787C12.3825 2.0413 12.1955 1.96419 12.0005 1.96419C11.8056 1.96419 11.6185 2.0413 11.4802 2.1787L1.81694 11.8373C1.67521 11.9785 1.5632 12.1466 1.48753 12.3318C1.41185 12.5169 1.37403 12.7154 1.37631 12.9154C1.38569 13.7404 2.0724 14.399 2.8974 14.399H3.8935V22.0326H20.1076V14.399H21.1247C21.5255 14.399 21.9029 14.242 22.1865 13.9584C22.3261 13.8192 22.4367 13.6537 22.512 13.4714C22.5872 13.2892 22.6255 13.0938 22.6247 12.8967C22.6247 12.4982 22.4677 12.1209 22.1841 11.8373V11.8373ZM13.313 20.3451H10.688V15.5639H13.313V20.3451ZM18.4201 12.7115V20.3451H14.813V15.0014C14.813 14.4834 14.3935 14.0639 13.8755 14.0639H10.1255C9.60756 14.0639 9.18803 14.4834 9.18803 15.0014V20.3451H5.581V12.7115H3.331L12.0029 4.04667L12.5443 4.58807L20.6724 12.7115H18.4201Z"
              fill="#262626"/>
        </svg>
      </router-link>
    </header>
    <div class="main-container">
      <loader v-if="isLoading" class="center-loader"/>
      <div v-else>
        <h1 class="text-align-center mb-8">Recapitulatif de la capsule pour {{ user }}</h1>

        <div class="wrap basic-container">
          <h2 class="mb-4">Problématique</h2>
          <div class="card-recap grid">
            <div v-for="closed in closedQuestionResult" class="closed">
              {{ closed }}
            </div>
          </div>
          <h2 class="mb-4 mt-4">Solution</h2>
          <div class="card-recap grid mb-8">
            <div v-for="open in openQuestion" class="open">
              {{ open }}
            </div>
          </div>

          <primary-button class="mb-4" @click.native="toggleArticle = !toggleArticle">Relire l'article</primary-button>
          <ApiRichRenderer v-show="toggleArticle" :rich-text="capsule.post.content" text-type="article-recap"/>
          <primary-button class="mt-4 mb-8" @click.native="youtubeVideo = !youtubeVideo">Regarder les vidéos de la
            capsule
          </primary-button>
          <div v-show="youtubeVideo">
            <h2 class="mb-2">Problème :</h2>
            <div class="container-embed mb-4" ref="embed" v-html="firstVideo"/>
            <h2 class="mb-2">Solution :</h2>
            <div class="container-embed mb-4" ref="embed" v-html="secondVideo"/>
          </div>
          <h2 class="mb-4 mt-4">Le ou les émotions en commun entre le parent et l'enfant :</h2>
          <!---<div class="relative parent-rel-avatar">
            <div class="grid gap-2 avatar-smiley relative">
              <avatar class="justify-self-center" type="child" :img="userResp.currentUsers.child.avatar"
                      :show-sticker="false" size="medium"/>
              <avatar class="justify-self-center" type="child" :img="userResp.currentUsers.parent.avatar"
                      :show-sticker="false" size="medium"/>
            </div>
            <div class="smiley-parent">
              <smiley-circle v-for="(smiley, index) in userResp.gameWords" :id=index
                             style="position: absolute" :class="smiley.same?  'same-text': ''">
                <p>{{ decodeSmiley(smiley.emoji) }}</p>
              </smiley-circle>
            </div>

          </div>---->
          <p class="inline mr-4" v-for="(word, index) in userResp.gameWords" v-if="word.same"> {{ word.same ? word.name : '' }}</p>
          <h2 class="mb-4 mt-4">Super-pouvoir débloqué :</h2>
          <div class="parent-power" ref="parentPower" v-if="userResp.power.length>0">
            <div class="second-parent flex">
              <div class="pentagon">
                <img class="" src="../assets/img/pentagon_unlocked.png">
                <img class="power" :src="userResp.power[2]" alt="">
              </div>
              <div class="flex justify-center flex-col ml-4 flex-1 ">
                <p class="strong mb-1">{{ userResp.power[0] }}</p>
                <p class="caption"> {{ userResp.power[1] }}</p>
              </div>
            </div>
          </div>
          <p v-else>{{ user }} n'à pas débloqué/e de super-pouvoir</p>
          <div v-if="file[0] !== null ">
            <h2 class="mb-4 mt-4">Le super-pouvoir de {{ user }}</h2>
            <video class="w-full" v-if="file[0].includes('mp4')" :src="file[1]" controls></video>
            <audio class="w-full" v-if=" file[0].includes(
        'mp3')" :src="file[1]" controls></audio>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import ApiRichRenderer from "@/components/ApiRichRenderer";
import Loader from "@/components/Loader";
import SmileyCircle from "@/views/SmileyCircle";
import Avatar from "@/components/Avatar";
import {mapGetters} from "vuex";

export default {
  name: "CapsuleRecap",
  components: {Avatar, SmileyCircle, Loader, ApiRichRenderer, PrimaryButton},
  computed: {
    ...mapGetters(['currentLocale'])
  },
  data() {
    return {
      toggleArticle: false,
      youtubeVideo: false,
      isLoading: true,
      slug: this.$route.params.slug,
      capsId: this.$route.params.capsId,
      user: '',
      userId: null,
      capsule: [],
      userResp: [],
      closedQuestionResult: [],
      openQuestion: [],
      firstVideo: '',
      secondVideo: '',
      file: [],
      smileys: [
        {name: 'Merci !', emoji: '🙏'},
        {name: 'Bravo !', emoji: '✅'},
        {name: 'Hourra !', emoji: '🎉'},
        {name: 'Trop classe !', emoji: '🎩'},
        {name: 'C’est top !', emoji: '🏆'},
        {name: 'Cool !', emoji: '🕶️'},
      ],
      smileyWords: [
        {name: 'émerveillé·e', emoji: '😯'},
        {name: 'impressionné·e', emoji: '🤩'},
        {name: 'rassuré·e', emoji: '😊'},
        {name: 'joyeux·se', emoji: '😃'},
        {name: 'étonné·e', emoji: '😯'},
        {name: 'surpris·e', emoji: '😳'},
        {name: 'inspiré·e', emoji: '💡'},
        {name: 'admiratif·ve', emoji: '👍'},
        {name: 'amusé·e', emoji: '😆'},
        {name: 'intrigué·e', emoji: '🤔'},
      ],

    }
  },
  methods: {
    decodeSmiley(smiley) {
      return decodeURIComponent(escape(window.atob(smiley)))
    },
    setIcon() {
      let open = document.querySelectorAll('.open')
      open.forEach((el) => {
        if (el.textContent.includes("true")) {
          el.innerHTML = "<svg   width=\"32\" height=\"32\" viewBox=\"0 0 47 47\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<rect width=\"47\" height=\"47\" rx=\"23.5\" fill=\"#68EA5D\"/>\n" +
              "<path d=\"M20.0266 30.163L14.6419 24.6441C14.0367 24.0239 13.0591 24.0239 12.4539 24.6441C11.8487 25.2644 11.8487 26.2664 12.4539 26.8867L18.9403 33.5348C19.5455 34.1551 20.5231 34.1551 21.1283 33.5348L37.5461 16.7078C38.1513 16.0875 38.1513 15.0855 37.5461 14.4652C36.9409 13.8449 35.9633 13.8449 35.3581 14.4652L20.0266 30.163Z\" fill=\"white\"/>\n" +
              "</svg>\n"
        }
        if (el.textContent.includes("false")) {
          el.innerHTML = "<svg width=\"32\" height=\"32\" viewBox=\"0 0 47 47\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M40.1154 40.117C30.9359 49.2943 16.0531 49.2943 6.87361 40.117C-2.2912 30.9336 -2.2912 16.0664 6.87361 6.88299C16.0531 -2.29433 30.9359 -2.29433 40.1154 6.88299C49.2949 16.0603 49.2949 30.9397 40.1154 40.117ZM23.1332 20.2488L16.268 13.3852C15.4698 12.5872 14.1757 12.5872 13.3774 13.3852C12.5792 14.1833 12.5792 15.4771 13.3774 16.2751L20.2426 23.1387C20.4421 23.3382 20.4421 23.6617 20.2426 23.8612L13.3774 30.7247C12.5792 31.5227 12.5792 32.8166 13.3774 33.6146C14.1757 34.4127 15.4698 34.4127 16.268 33.6146L23.1332 26.7511C23.3327 26.5516 23.6563 26.5516 23.8558 26.7511L30.721 33.6146C31.5192 34.4127 32.8134 34.4127 33.6116 33.6146C34.4098 32.8166 34.4098 31.5227 33.6116 30.7247L26.7464 23.8612C26.5469 23.6617 26.5469 23.3382 26.7464 23.1387L33.6116 16.2751C34.4098 15.4771 34.4098 14.1833 33.6116 13.3852C32.8134 12.5872 31.5192 12.5872 30.721 13.3852L23.8558 20.2488C23.6563 20.4483 23.3327 20.4483 23.1332 20.2488Z\" fill=\"#F14141\"/>\n" +
              "</svg>\n"
        }
      })
    },
    userRespApi() {
      return new Promise((resolve, reject) => {
        this.$http.get('/capsules/' + this.capsId)
            .then(res => {
              this.userResp = JSON.parse(res.data.blocks)
              this.user = res.data.user.firstname
              this.userId = res.data.user.id
              this.file.push(res.data.filename, res.data.attachment_url)
              resolve(JSON.parse(res.data.blocks))
            })
      })
    },
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
          ? match[2]
          : null;
    },
  },
  updated() {
  },
  created() {
    let filter = {
      content_type: "capsule",
      include: 2,
      locale: this.currentLocale,
      'fields.slug[in]': this.slug
    }
    this.userRespApi().then((res) => {
      this.$contentful.getEntries(filter).then(res => {
        let resp = res.items[0]
        this.capsule = res.items[0].fields
        this.$nextTick(() => {
          this.capsule.closedQuestions.forEach((el, index) => {
            this.closedQuestionResult.push(el.fields.title)
            let rep = ""
            if (this.userResp) {
              this.userResp.resultsCloseQuestion[index] === true ? rep = this.user + ' connaissait déjà là réponse, il/elle à répondu/e oui.' : this.userResp.resultsCloseQuestion[index] === false ? rep = this.user + " ne connaissait pas là réponse, il/elle à répondu/e non." : rep = 'réponse non renseignée'
              this.closedQuestionResult.push(rep || "pas de réponse enregistré")
            }

          })
          this.capsule.UniqueQuestion.fields.answers.forEach((el) => {
            this.openQuestion.push(el.fields.title)
            this.openQuestion.push(el.fields.goodAnswer)
          })
          this.isLoading = false
          this.$nextTick(() => {
            this.setIcon()
          })
          if (resp.fields.video) {
            const videoId = this.getId(resp.fields.video);
            const videoId2 = this.getId(resp.fields.videoSolution);
            const iframeMarkup1 = '<iframe allowfullscreen="allowfullscreen" src="//www.youtube.com/embed/'
                + videoId + '?rel=0;picture-in-picture=1&amp;accelerometer=1&amp;gyroscope=1&amp;autoplay=0" frameborder="0" style="border-radius: var(--radius-default);\n' +
                '    position: absolute;\n' +
                '    top: 0;\n' +
                '    bottom: 0;\n' +
                '    left: 0;\n' +
                '    width: 100%;\n' +
                '    height: 100%;\n' +
                '    border: 0;" ></iframe>';
            const iframeMarkup2 = '<iframe allowfullscreen="allowfullscreen" src="//www.youtube.com/embed/'
                + videoId2 + '?rel=0;picture-in-picture=1&amp;accelerometer=1&amp;gyroscope=1&amp;autoplay=0" frameborder="0" style="border-radius: var(--radius-default);\n' +
                '    position: absolute;\n' +
                '    top: 0;\n' +
                '    bottom: 0;\n' +
                '    left: 0;\n' +
                '    width: 100%;\n' +
                '    height: 100%;\n' +
                '    border: 0;" ></iframe>';
            this.firstVideo = iframeMarkup1
            this.secondVideo = iframeMarkup2

          }
        })
      });
    })

  }
}
</script>

<style scoped lang="scss">
.smiley {
  width: 76px;
  height: 76px;
}

.avatar-smiley {
  max-width: 136px;
  //top: 50%;
  grid-template-columns: repeat(2, 1fr);
}

.parent-rel-avatar {
  margin-top: 50%;
  max-width: 136px;
  left: 50%;
  transform: translateX(-50%);
}

.smiley-parent {
  min-height: 145px;

  > div {
    justify-self: center;

    > .same-text > p {
      color: var(--color-primary);
      font-weight: bold;
      font-size: 21px;
    }

    &:nth-child(1) {
      top: 16%;
      right: 107%;
    }

    &:nth-child(2) {
      top: 80px;
      left: 76px;


      &.same-text {
        left: 125px;
        top: -190px;
      }

    }

    &:nth-child(3) {
      right: 40%;
      top: -39%;

      &.same-text {
        right: 35%;
        top: -70%;
      }
    }

    &:nth-child(4) {
      left: 100%;
      top: -20px;

      &.same-text {
        left: 152px;
        top: -29%;
      }
    }

    &:nth-child(5) {
      top: 29%;
      left: 156px;

      &.same-text {
        left: 152px;
        top: 34%;
      }
    }

    &:nth-child(6) {
      right: 49%;
      top: 81px;


    }

    &:nth-child(7) {
      left: 90px;
      top: -90px;

    }

    &:nth-child(8) {
      right: 100%;
      top: -51%;
    }


    &.same-text {
      width: 124px;
      height: 124px;

    }
  }
}

header {
  padding-top: 56px;
}

.logo-container {
  width: 108px;
  height: 49px;

  > img, svg {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.grid-parent {
  border-radius: 32px;
  border: 1px solid var(--color-surface-divider);

  > * {
    border: 1px solid var(--color-surface-divider);
    padding: var(--space-16);
  }

  > h3 {
    &:nth-child(1) {
      border-top-left-radius: 32px;
    }

    &:nth-child(2) {
      border-top-right-radius: 32px;
    }
  }

  > div {
    &:last-child {
      border-bottom-right-radius: 32px;
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: 32px;
    }
  }
}

.smiley {
  position: relative;
  margin: 0 auto;
}

.parent-power {
  border-radius: var(--radius-default);
  position: relative;
  background: linear-gradient(to top, #F2A53A, #FFD85A);
  padding: 3px;

  .second-parent {
    border-radius: var(--radius-default);
    background: white;
    padding: var(--space-12);

    .pentagon {
      width: 64px;
      height: 64px;
      position: relative;
      transition: filter 0.2s ease-in-out;
    }

    .power {
      display: block;
      width: 32px;
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
}

.container-embed {
  border-radius: var(--radius-default);
  position: relative;
  width: 100%;
  height: 215px;


  .embed-responsive-item {
    border-radius: var(--radius-default);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.center-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > ::v-deep svg {
    color: var(--color-primary);
    width: 50px;
    height: 50px;
  }
}

.icon-open {
  width: 32px;
  height: 32px;
}

.card-recap {
  grid-template-columns: repeat(1, 1fr);
  border-radius: 32px;
  border: 2px solid var(--color-surface-divider);

  > div {
    font-weight: bold;
    padding: var(--space-16);
  }

  > div:nth-child(2n) {
    font-weight: normal;
    border-bottom: 2px solid var(--color-surface-divider);

    &.closed {
      display: grid;
      place-content: center;
      text-align: center;
    }
  }


  > div:nth-last-child(-n+2) {
    border-bottom: none !important;
  }

  > .open {
    display: grid;
    place-content: center;
  }
}

@media screen and (min-width: 576px) {
  .card-recap {
    grid-template-columns: repeat(2, 1fr);

    > div {
      border-bottom: 2px solid var(--color-surface-divider)
    }

    > .open {
      place-content: start;
    }

    > .open:nth-child(2n) {
      place-content: center;
    }
  }
}
</style>