<template>
  <div>
    <h1>Liste des familles</h1>

    <div class="header-form">
      <uni-input
          label="Recherche"
          type="text"
          v-model="filterKey"
          class="col-6"
      />
      <primary-button @click.native="openAccountModal = true">Créer un compte</primary-button>
      <primary-button
          @click.native="exportData"
          color="yellow">
        Export .CSV
        <sprite-icon v-if="isLoadingExport === 2" name="check" size="big" class="copy-button" color="white" fill="white"/>
      </primary-button>
    </div>

    <loader class="center-loader" v-if="loadingFamilies" :color="true"/>

    <table class="family-table" ref="family-table" v-if="families.length > 0">
      <thead>
      <tr class="family-header-row">
        <th class="family_cell" @click="sortBy('email')">Email <span
            v-if="this.currentSort === 'email:asc'">▼</span><span v-else-if="this.currentSort === 'email:desc'">▲</span>
        </th>
        <th class="family_cell" @click="sortBy('state')">État <span
            v-if="this.currentSort === 'state:asc'">▼</span><span v-else-if="this.currentSort === 'state:desc'">▲</span>
        </th>
        <th class="family_cell" @click="sortBy('plan')">Plan <span v-if="this.currentSort === 'plan:asc'">▼</span><span
            v-else-if="this.currentSort === 'plan:desc'">▲</span></th>
        <th class="family_cell" @click="sortBy('teacher')">École <span
            v-if="this.currentSort === 'teacher:asc'">▼</span><span
            v-else-if="this.currentSort === 'teacher:desc'">▲</span></th>
        <th class="family_cell" @click="sortBy('admin')">Admin <span
            v-if="this.currentSort === 'admin:asc'">▼</span><span v-else-if="this.currentSort === 'admin:desc'">▲</span>
        </th>
        <th class="family_cell" @click="sortBy('last_connected_at')">Dernière connexion <span
            v-if="this.currentSort === 'last_connected_at:asc'">▼</span><span
            v-else-if="this.currentSort === 'last_connected_at:desc'">▲</span></th>
        <th class="family_cell">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr class="family-row">
      </tr>
      <tr class="family-row" v-for="family in filteredFamilies">
        <td class="family_cell">{{ family.email }}</td>
        <td class="family_cell">{{ family.state }}</td>
        <td class="family_cell">
          <a :href="'https://dashboard.stripe.com/test/customers/' + family.stripe_id" target="_blank" class="tooltip">
            <span class="tooltiptext">Cliquer pour accéder au dashboard Stripe</span>
            {{ family.plan }}
          </a>
        </td>
        <td class="family_cell center"><span v-if="family.teacher"
                                             style="color: green;">✓</span><span v-else
                                                                                 style="color: red;">X</span>
        </td>
        <td class="family_cell center"><span v-if="family.admin"
                                             style="color: green;">✓</span><span v-else
                                                                                 style="color: red;">X</span>
        </td>
        <td class="family_cell">{{ formatDate(family.last_connected_at) }}</td>
        <td class="family_cell center">
          <button @click="deleteAccount(family)">
            <sprite-icon name="trash" size="small" fill="transparent" color="red" title="Supprimer le compte"/>
          </button>
          <button @click="selectedFamily = family; newState = family.state; openStateModal = true">
            <sprite-icon name="edit" size="small" fill="transparent" color="blue" title="Supprimer le compte"/>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!--Modal de création de compte -->
    <uni-modal :title="$t('settings.admin.createAccount.title')" :is-visible="openAccountModal"
               @close-modal="openAccountModal = false">
      <template slot="body">

        <validation-observer tag="form" ref="accountForm" id="accountForm" v-slot="{valid}"
                             @submit.prevent="handleForm">
          <uni-input
              :label="$t('settings.admin.createAccount.email')"
              id="input-email"
              type="email"
              v-model="newAccount.email"
              rules="required|email|emailExists"
          />
          <uni-input
              :label="$t('settings.admin.createAccount.password')"
              id="input-pwd"
              type="text"
              v-model="newAccount.password"
              rules="required"
          />
          <PrimaryButton type="button" @click.native="generateRandomPwd">
            {{ $t('settings.admin.createAccount.genereatePwdButton') }}
          </PrimaryButton>

          <!--Sélection langue-->
          <AskLocale v-model="newAccount.locale"/>
          <UniCheckbox
              value="notifCb"
              v-model="newAccount.notifCb"
              :label="$t('settings.admin.createAccount.notifCb')"/>
          <UniCheckbox
              value="notifCb"
              v-model="newAccount.testCb"
              :label="$t('settings.admin.createAccount.testCb')"/>
        </validation-observer>
      </template>
      <template slot="footer">
        <primary-button :clicked="loadingAccount" type="submit" form="accountForm" :disabled="loadingAccount">Créer un
          compte
        </primary-button>
      </template>
    </uni-modal>

    <!-- Modal de changement d'état de compte manuel -->
    <uni-modal :title="$t('settings.admin.updateState')" :is-visible="openStateModal"
               @close-modal="openStateModal = false">
      <template slot="body">
        <validation-observer tag="form" ref="accountUpdateForm" id="accountUpdateForm" v-slot="{valid}"
                             @submit.prevent="handleAccountUpdate">
          <dropdown-list v-model="newState" :data="states"/>
        </validation-observer>
      </template>
      <template slot="footer">
        <primary-button :clicked="loadingAccount" type="submit" form="accountUpdateForm" :disabled="loadingAccount">Modifier l'état
        </primary-button>
      </template>
    </uni-modal>
  </div>
</template>

<script>
import UniInput from "@/components/UniInput.vue";
import UniModal from "@/components/UniModal.vue";
import UniCheckbox from "@/components/UniCheckbox.vue";
import AskNewsletter from "@/components/Locale/AskNewsletter.vue";
import AskLocale from "@/components/Locale/AskLocale.vue";
import Loader from "@/components/Loader";
import moment from 'moment';
import PrimaryButton from "@/components/PrimaryButton";
import SpriteIcon from "@/components/SpriteIcon.vue";
import DropdownList from "@/components/DropdownList.vue";

export default {
  name: "AdminHome",
  components: {SpriteIcon, UniInput, Loader, PrimaryButton, UniModal, AskLocale, AskNewsletter, UniCheckbox, DropdownList},
  computed: {
    filteredFamilies() {
      return this.sortedFamilies.filter(x => {
        if (x.email.includes(this.filterKey) || x.state.includes(this.filterKey) || x.plan && x.plan.includes(this.filterKey)) return true
      })
    }
  },
  methods: {
    sortBy(sortKey) {
      if (this.sortKey === sortKey && this.sortedByAsc === true) {
        this.sortedFamilies = this.families.slice()
        this.sortKey = null
        this.sortedByAsc = true
        this.currentSort = null
      } else if (this.sortedByAsc) {
        if (sortKey === 'email') {
          this.sortedFamilies.sort((x, y) => (x[sortKey] == null ? -1 : y[sortKey] == null ? 1 : x[sortKey].toLowerCase() > y[sortKey].toLowerCase() ? -1 : 1));
        } else {
          this.sortedFamilies.sort((x, y) => (x[sortKey] == null ? -1 : y[sortKey] == null ? 1 : x[sortKey] > y[sortKey] ? -1 : 1));
        }
        this.sortKey = sortKey
        this.sortedByAsc = false
        this.currentSort = sortKey + ':asc'
      } else {
        if (sortKey === 'email') {
          this.sortedFamilies.sort((x, y) => (x[sortKey] == null ? 1 : y[sortKey] == null ? -1 : x[sortKey].toLowerCase() < y[sortKey].toLowerCase() ? -1 : 1));
        } else {
          this.sortedFamilies.sort((x, y) => (x[sortKey] == null ? 1 : y[sortKey] == null ? -1 : x[sortKey] < y[sortKey] ? -1 : 1));
        }
        this.sortKey = sortKey
        this.sortedByAsc = true
        this.currentSort = sortKey + ':desc'
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
      }
    },
    handleForm() {
      this.$refs.accountForm.validate().then(resp => {
        if (resp) {
          this.loadingAccount = true
          this.$http.post('admin/account_creation', {
            family: {
              email: this.newAccount.email,
              password: this.newAccount.password,
              password_confirmation: this.newAccount.password
            },
            locale: this.newAccount.localRb,
            has_notification: this.newAccount.notifCb,
            is_test: this.newAccount.testCb
          }).then(resp => {
            this.sortedFamilies.push(resp.data)
            this.families.push(resp.data)
            this.$refs.accountForm.reset()
            this.loadingAccount = false
            this.openAccountModal = false
          })
        }
      })
    },
    handleAccountUpdate() {
      this.$refs.accountUpdateForm.validate().then(resp => {
        this.$http.put(`families/${this.selectedFamily.id}`, {
          family: {
            state: this.newState
          }
        }).then(resp => {
          if (resp.status === 200) {
            this.selectedFamily.state = this.newState
          }
          this.openStateModal = false
        })
      })
    },
    generateRandomPwd() {
      this.newAccount.password = Math.random().toString(36).slice(2, 10)
    },
    deleteAccount(family) {
      if (confirm("Voulez-vous vraiment supprimer ce compte ?")) {
        this.$http.delete('/families/' + family.id)
            .then(r => {
              this.sortedFamilies.splice(this.sortedFamilies.indexOf(family), 1)
              this.families.splice(this.families.indexOf(family), 1)
            })
      }
    },
    /**
     * Export data to csv
     */
    exportData(e, type) {
      this.isLoadingExport = 1
      let csv = 'SEP=,\n'
      let lines = this.$refs['family-table'].querySelectorAll("tbody tr")
      lines.forEach(line => {
        let cells = line.querySelectorAll("td")
        cells.forEach(cell => {
          csv += `${cell.innerText},`
        })
        csv += '\n'
      })

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `Export_${moment(String(new Date())).format('DD-MM-YYYY')}.csv`;
      anchor.click();
      this.isLoadingExport = 2
    }
  },
  watch: {
    'isLoadingExport': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingExport = 0
        }, 2000)
      }
    }
  },
  data() {
    return {
      loadingFamilies: false,
      families: [],
      sortedFamilies: [],
      sortKey: '',
      filterKey: '',
      sortedByAsc: true,
      currentSort: null,
      openAccountModal: false,
      loadingAccount: false,
      newAccount: {
        email: '',
        password: '',
        localRb: 'fr',
        notifCb: true,
        testCb: true
      },
      openStateModal: false,
      newState: null,
      selectedFamily: null,
      states: ['created', 'validated', 'freemium', 'subscribed', 'invited'],
      isExportLoading: 0
    }
  },
  mounted() {
    this.loadingFamilies = true;
    this.$http.get('/families')
        .then(res => {
          this.families = res.data
          this.sortedFamilies = this.families.slice()
          this.loadingFamilies = false;
        })
  },
  created() {
  }
}
</script>

<style scoped lang="scss">

.center-loader {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  > ::v-deep svg {
    width: 50px;
    height: 50px;
  }
}

.header-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 8px 0;

  > * {
    margin: auto;
    height: 90%;
    width: 80%;
  }
}

.family-table {
  margin: 0;
  width: 100%;
  display: table;
  border-collapse: separate;
  border-spacing: 0;

  > thead {
    display: table-header-group;
  }
}

.family-row {
  display: table-row;
  width: 100%;

  &:last-child {
    .family_cell {
      border-bottom: var(--color-text-soft) 3px solid;

      &:last-child {
        border-bottom-right-radius: 1.5rem;
      }

      &:first-child {
        border-bottom-left-radius: 1.5rem;
      }
    }
  }
}

.title_col {
  max-width: 30px;
}

.family-header-row {
  display: table-row;
  border-top: var(--color-text-soft) 3px solid;
  border-bottom: var(--color-text-soft) 1px solid;
  background: var(--color-secondary);
  color: black;
  padding: 12px;
  font-weight: 700;
  column-span: all;

  th {
    text-align: center;
    display: table-cell;
    border-top: var(--color-text-soft) 2px solid;
    padding: 10px 8px;

    &:last-child {
      border-right: var(--color-text-soft) 3px solid;
      border-top-right-radius: 1.5rem;
    }

    &:first-child {
      border-left: var(--color-text-soft) 3px solid;
      border-top-left-radius: 1.5rem;
    }

    &:hover {
      cursor: pointer;
      background: var(--color-secondary-soft);
    }
  }
}

.family_cell {
  display: table-cell;
  border-top: var(--color-text-soft) 2px solid;
  padding: 12px 8px;

  border-left: var(--color-text-soft) 1px solid;

  &:first-child {
    border-left: var(--color-text-soft) 3px solid;
  }

  &:last-child {
    border-right: var(--color-text-soft) 3px solid;
  }

  &.center {
    text-align: center;
    vertical-align: middle;
    margin: auto;
  }
}

</style>