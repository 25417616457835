<template>
  <button class="uppercase box-shadow-network">
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M22.9873 12.2242C22.9873 11.2409 22.909 10.5234 22.7397 9.7793H11.7285V14.2173H18.1918C18.0616 15.3202 17.3579 16.9811 15.7942 18.0972L15.7722 18.2458L19.2538 20.996L19.495 21.0205C21.7102 18.9344 22.9873 15.8649 22.9873 12.2242" fill="#4285F4"/>
        <path d="M11.7289 23.9178C14.8954 23.9178 17.5537 22.8548 19.4954 21.0212L15.7946 18.0978C14.8042 18.8021 13.475 19.2937 11.7289 19.2937C8.62755 19.2937 5.99531 17.2076 5.05699 14.3242L4.91945 14.3361L1.2993 17.193L1.25195 17.3272C3.18052 21.2337 7.14196 23.9178 11.7289 23.9178Z" fill="#34A853"/>
        <path d="M5.05695 14.3238C4.80937 13.5797 4.66608 12.7824 4.66608 11.9586C4.66608 11.1347 4.80937 10.3375 5.04393 9.59342L5.03737 9.43495L1.37185 6.53223L1.25192 6.59039C0.457066 8.21149 0.000976562 10.0319 0.000976562 11.9586C0.000976562 13.8853 0.457066 15.7056 1.25192 17.3267L5.05695 14.3238" fill="#FBBC05"/>
        <path d="M11.7289 4.62403C13.9311 4.62403 15.4166 5.59402 16.2637 6.40461L19.5735 3.10928C17.5407 1.1826 14.8954 0 11.7289 0C7.14195 0 3.18052 2.68406 1.25195 6.59057L5.04396 9.59359C5.9953 6.7102 8.62754 4.62403 11.7289 4.62403" fill="#EB4335"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="23" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <span v-if="!isSmall">Continuer avec Google</span>
    <span v-else>Google</span>
  </button>
</template>

<script>
  export default {
    name: "GoogleButton",
      props: {
        isSmall: {
            type: Boolean,
            default: false
        }
      }
  }
</script>

<style lang="scss" scoped>
  button{
    background: var(--color-surface-soft);
    width: 100%;
    border-radius: var(--radius-default);
    padding: var(--space-12);
    border: 2px solid #E5E0DF;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4285F4;
  }
  svg{
    margin-right: var(--space-8);
  }
</style>