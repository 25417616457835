<template>
  <div class="step" :style="'--bg-color-caps-step: ' +color">
    <header class="wrap-large" :class="layout==='avatar'? 'mb-12': ''" v-if="layout !== 'article'">
      <div class="basic-container">
        <div class="pt-7 pb-4 header-progressbar">
          <button @click="previousPage" ref="backButton" class="mr-6 -ml-1 ">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>
          <progress-bar/>
          <div v-if="audio && audio !== ''">
            <button class="volume-button" @click="toggleVolume">
              <div v-if="replay">
                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
                  <g id="Arrow_Outline" data-name="Arrow Outline">
                    <path
                        d="m25.9912 22.4838-4.537-.5041a1.3208 1.3208 0 0 0 -1.08.3787l-.0015.0015a1.2118 1.2118 0 0 0 .6288 2.047l.9579.1873a10.3361 10.3361 0 0 1 -5.9813 1.877 10.4567 10.4567 0 0 1 -10.1031-13.127 1.5 1.5 0 0 0 -2.9-.7685 13.4332 13.4332 0 0 0 21.36 13.9607l.2924 1.4955a1.2117 1.2117 0 0 0 2.0468.6288l.0016-.0016a1.3206 1.3206 0 0 0 .3787-1.08l-.5045-4.5365a.6326.6326 0 0 0 -.5588-.5588z"/>
                    <path
                        d="m16.0215 2.5288a13.4916 13.4916 0 0 0 -8.3561 2.9348l-.2924-1.4955a1.2117 1.2117 0 0 0 -2.0468-.6288l-.0016.0016a1.3206 1.3206 0 0 0 -.3787 1.08l.5041 4.5365a.6326.6326 0 0 0 .5588.5588l4.537.5041a1.3208 1.3208 0 0 0 1.08-.3787l.0015-.0015a1.2118 1.2118 0 0 0 -.6288-2.047l-.9585-.1872a10.3362 10.3362 0 0 1 5.9813-1.8771 10.4567 10.4567 0 0 1 10.1037 13.127 1.5008 1.5008 0 0 0 1.0664 1.8342 1.52 1.52 0 0 0 .3848.05 1.5007 1.5007 0 0 0 1.4492-1.1162 13.4584 13.4584 0 0 0 -13.0039-16.895z"/>
                  </g>
                </svg>
              </div>
              <div v-if="volumeIsOn && !replay">
                <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="475.082px" height="475.081px" viewBox="0 0 475.082 475.081"
                     xml:space="preserve">
<g>
	<g>
		<path d="M200.999,63.952c-4.946,0-9.229,1.812-12.847,5.426l-95.074,95.075H18.276c-4.952,0-9.234,1.812-12.85,5.424
			C1.809,173.493,0,177.778,0,182.726v109.63c0,4.949,1.809,9.233,5.426,12.848c3.619,3.617,7.902,5.427,12.85,5.427h74.798
			l95.074,95.078c3.617,3.61,7.9,5.424,12.847,5.424c4.952,0,9.234-1.813,12.85-5.424c3.617-3.614,5.426-7.901,5.426-12.847V82.228
			c0-4.948-1.809-9.234-5.422-12.85C210.23,65.764,205.951,63.952,200.999,63.952z"/>
    <path d="M316.769,277.936c8.093-12.467,12.135-25.93,12.135-40.395s-4.042-27.992-12.135-40.556
			c-8.094-12.562-18.791-21.41-32.121-26.551c-1.902-0.949-4.284-1.427-7.139-1.427c-4.944,0-9.232,1.762-12.847,5.282
			c-3.61,3.521-5.427,7.852-5.427,12.99c0,3.997,1.143,7.376,3.432,10.137c2.283,2.762,5.041,5.142,8.282,7.139
			c3.23,1.998,6.468,4.188,9.708,6.567c3.238,2.38,5.996,5.758,8.278,10.135c2.276,4.38,3.426,9.804,3.426,16.277
			c0,6.471-1.143,11.896-3.426,16.276c-2.282,4.381-5.04,7.755-8.278,10.14c-3.24,2.379-6.478,4.569-9.708,6.567
			c-3.241,1.992-5.999,4.377-8.282,7.132c-2.282,2.765-3.432,6.143-3.432,10.14c0,5.144,1.816,9.47,5.427,12.99
			c3.614,3.521,7.902,5.288,12.847,5.288c2.854,0,5.236-0.479,7.139-1.424C297.978,299.304,308.679,290.403,316.769,277.936z"/>
    <path d="M377.728,318.194c16.18-24.646,24.273-51.531,24.273-80.654c0-29.124-8.094-56.005-24.273-80.666
			c-16.177-24.645-37.6-42.583-64.241-53.815c-2.471-0.95-4.948-1.427-7.416-1.427c-4.948,0-9.236,1.809-12.854,5.426
			c-3.613,3.616-5.424,7.898-5.424,12.847c0,7.424,3.713,13.039,11.139,16.849c10.657,5.518,17.888,9.705,21.693,12.559
			c14.089,10.28,25.077,23.173,32.976,38.686c7.898,15.514,11.848,32.026,11.848,49.537c0,17.512-3.949,34.023-11.848,49.536
			c-7.898,15.516-18.894,28.407-32.976,38.684c-3.806,2.857-11.036,7.043-21.693,12.563c-7.426,3.809-11.139,9.424-11.139,16.847
			c0,4.948,1.811,9.236,5.424,12.847c3.617,3.621,7.991,5.432,13.131,5.432c2.286,0,4.668-0.483,7.139-1.428
			C340.128,360.783,361.551,342.844,377.728,318.194z"/>
    <path d="M438.824,116.92c-24.171-36.638-56.343-63.622-96.505-80.943c-2.471-0.95-4.948-1.425-7.416-1.425
			c-4.948,0-9.236,1.811-12.847,5.424c-3.621,3.615-5.432,7.902-5.432,12.85c0,6.851,3.714,12.469,11.14,16.846
			c1.335,0.756,3.467,1.755,6.42,2.996c2.95,1.232,5.089,2.231,6.427,2.993c8.754,4.755,16.56,9.611,23.418,14.56
			c23.407,17.318,41.682,38.922,54.816,64.809c13.134,25.885,19.697,53.388,19.697,82.512c0,29.129-6.563,56.626-19.697,82.512
			c-13.131,25.889-31.409,47.496-54.816,64.809c-6.858,4.948-14.664,9.801-23.418,14.562c-1.338,0.756-3.477,1.752-6.427,2.99
			c-2.953,1.232-5.085,2.231-6.42,2.998c-7.426,4.374-11.14,9.993-11.14,16.844c0,4.949,1.811,9.233,5.432,12.848
			c3.61,3.617,7.898,5.427,12.847,5.427c2.468,0,4.945-0.476,7.416-1.431c40.162-17.315,72.334-44.3,96.505-80.94
			c24.174-36.638,36.258-76.849,36.258-120.625S463.001,153.554,438.824,116.92z"/>
	</g>
</g>
</svg>
              </div>
              <div v-else-if="!volumeIsOn && !replay">
                <svg id="Capa_1" viewBox="0 0 448.075 448.075"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="m352.021 16.075c0-6.08-3.52-11.84-8.96-14.4-5.76-2.88-12.16-1.92-16.96 1.92l-141.76 112.96 167.68 167.68z"/>
                  <path
                      d="m443.349 420.747-416-416c-6.24-6.24-16.384-6.24-22.624 0s-6.24 16.384 0 22.624l100.672 100.704h-9.376c-9.92 0-18.56 4.48-24.32 11.52-4.8 5.44-7.68 12.8-7.68 20.48v128c0 17.6 14.4 32 32 32h74.24l155.84 124.48c2.88 2.24 6.4 3.52 9.92 3.52 2.24 0 4.8-.64 7.04-1.6 5.44-2.56 8.96-8.32 8.96-14.4v-57.376l68.672 68.672c3.136 3.136 7.232 4.704 11.328 4.704s8.192-1.568 11.328-4.672c6.24-6.272 6.24-16.384 0-22.656z"/>
                </svg>
              </div>

            </button>
            <audio ref="capsAudio" @ended="finished" :src="audio && audio.split('?')[0] + '?raw=1'"
                   muted="muted"></audio>
          </div>
        </div>
        <p class="display-2 text-align-center" v-if="avatarText && layout === 'avatar'">{{ avatarText }}</p>
        <div class="container-img mx-auto mb-12"
             :class="imgType === ('big' || 'default')? 'big': imgType === 'small' ? 'small' : imgType==='medium'? 'medium': ''"
             v-if="img && layout==='img'">
          <img class="object-cover" :class="imgType === ('big' || 'default')? 'w-full h-full': ''" :src="img" alt=""
               :style="imgType === 'small' ? 'border: none': 'border-color: '+color">
        </div>
      </div>
    </header>
    <header class="big img" v-if="layout === 'article'">
      <div class="w-full relative container-header-img">
        <div class="absolute top-0 left-0 right-0 bottom-0 gradient-header"></div>
        <picture>
          <source type="image/webp"
                  media="(min-width: 1101px)"
                  :srcset="img +'?fit=fill&w=600&h=600&fm=webp'"/>
          <source type="image/jpeg"
                  media="(min-width: 1101px)"
                  :srcset="img +'?fit=fill&w=600&h=600&fm=jpg'"/>
          <source type="image/webp"
                  media="(min-width: 480px)"
                  :srcset="img +'?fit=fill&w=500&h=440&fm=webp'"/>
          <source type="image/jpeg"
                  media="(min-width: 480px)"
                  :srcset="img +'?fit=fill&w=500&h=440&fm=jpg'"/>
          <img class="object-cover w-full h-full"
               :src="img+'?fit=fill&w=375&h=220&fm=jpg'"
               alt="image illustrative de la capsule"/>
        </picture>
      </div>
      <div class="wrap-large absolute w-full top-7">
        <div class="basic-container flex align-items-center">
          <button @click="previousPage" class="mr-6 -ml-1 ">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>
          <progress-bar/>
        </div>
      </div>
    </header>
    <div class="main-container rounded-t-3xl flex flex-col wrap"
         ref="mainContainer"
         :style="layout==='article'? 'margin-top:-20px; position: relative': layout.includes('full')? 'background: var(--bg-color-caps-step)': ''"
         :class="avatarType? 'relative': layout.includes('full')? 'container-full' : ''">
      <current-family-avatar v-if="avatarType" :avatar="avatarType"/>
      <div class="basic-container" :class="layout.includes('full')? 'full relative bubble'  : ''">
        <div v-if="layout.includes('full')">
          <!---<div class="absolute top-bubble" v-if="layout.includes('full')"></div>
          <div class="absolute bottom-bubble" v-if="layout.includes('full')"></div>--->
          <div class="absolute peak" v-if="layout.includes('full')"></div>
          <div class="wrap mx-auto bulltext" :class="layout.includes('full')? 'relative mb-12': ''">
            <slot>
            </slot>
          </div>
        </div>
        <slot v-else>
        </slot>
      </div>
      <div class="basic-container" v-if="layout.includes('full')">
        <img :src="illu" class="img-full" :class="illu ? illu.includes('13')?  'custom': '': ''" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar";
import {mapActions, mapGetters} from "vuex";
import currentFamilyAvatar from "@/components/CurrentFamilyAvatar";

export default {
  name: "CapsStep",
  components: {currentFamilyAvatar, ProgressBar},
  data() {
    return {
      redirect: false,
      replay: false,
      volumeIsOn: false
    }
  },
  props: {
    model: {
      type: String,
      required: true
    },
    illu: {
      type: String,
    },
    fix: {
      default: 'no',
      type: String,
      required: false,
    },
    color: {
      default: 'var(--color-secondary)',
      type: String,
      required: false,
    },
    img: {
      type: String,
    },
    avatarType: {
      validator: function (value) {
        // La valeur passée doit être l'une de ces chaines de caractères
        return ['kid', 'parent', 'both'].indexOf(value) !== -1
      },
      required: false,
    },
    avatarText: {
      type: String,
      required: false,
    },
    layout: {
      validator: function (value) {
        // La valeur passée doit être l'une de ces chaines de caractères
        return ['full', 'default', 'img', 'avatar', 'article', 'intro'].indexOf(value) !== -1
      }
    },
    imgType: {
      validator: function (value) {
        // La valeur passée doit être l'une de ces chaines de caractères
        return ['big', 'medium', 'small'].indexOf(value) !== -1
      }
    },
    audio: {
      type: String
    }
  },
  watch: {
    currentStep(val, old) {
      if (val === this.model) {
        if (this.audio) {
          this.toggleVolume()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      capsules: "capsules"
    }),
    currentStep() {
      return this.capsules.currentStep.contentType
    }
  },
  methods: {
    ...mapActions(['setCurrentStep', 'setSteps', 'setAudio']),
    /**
     * fonction qui update le state de step pour retourner au step précédent
     * emit un message au parant pour update l'affichage
     * @param e
     */
    previousPage(e) {
      if (this.capsules.currentStep.step === 1) {
        this.setCurrentStep({step: 0, contentType: "description"})
        this.$emit('previous-step')
      } else {
        if (this.capsules.currentStep.step !== 0) {
          this.setCurrentStep({
            step: this.capsules.currentStep.step - 1,
            contentType: this.capsules.steps[this.capsules.currentStep.step - 2].contentType
          })
        }
        this.$emit('previous-step')
      }

    },
    toggleVolume() {
      // https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
      let audio = this.$refs.capsAudio
      if (this.replay) {
        audio.play()
        this.replay = false
        this.volumeIsOn = true
      } else {
        if (this.volumeIsOn) {
          audio.pause()
        } else {
          audio.play()
        }
        this.volumeIsOn = !this.volumeIsOn
      }


      // this.setAudio(!this.capsules.volumeIsOn)
      // this.$emit('toggleVolume')
    },
    finished() {
      this.replay = true
    }
  },
  mounted() {
    if (this.capsules.currentStep.contentType === this.model) {
      if (this.audio) {
        this.toggleVolume()
      }
    }
  }
}
</script>

<style lang="scss">
.bulltext {
  max-width: 92%;
}

.header-progressbar {
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-gap: 16px;
  align-items: center;
}

.volume-button {

  width: 48px;
  height: 48px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    outline: none;
    width: 28px;
    height: 28px;

    path {
      fill: var(--bg-color-caps-step);
    }
  }
}

@media screen and (min-width: 576px) {
  .basic-container {
    &.bubble {
      border-radius: 9999px !important;
      width: 120%;
      transform: none !important;
      left: 0px !important;
    }

    .peak {
      left: 90px !important;
      transform: rotate(150deg) !important;
    }
  }
  header.big.img {
    height: 375px;

    .container-header-img {
      height: 375px;
    }
  }


  .basic-container {
    .img-full {
      margin-top: 60px !important;
    }
  }

  .container-header-img {
    height: 215px !important;
    width: 323px;
    margin: 0 auto;
    border: 4px solid #FBD354;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: var(--shadow-l);
    top: 50%;
    transform: translateY(-50%);

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover
    }

    .gradient-header {
      position: relative !important;
      background-image: none !important;
    }
  }


}

.container-full {
  padding-left: 0;
  padding-right: 0;
}

//style depending on layout
.step {
  background: var(--bg-color-caps-step);
  overflow: hidden;

  .gradient-header {
    background-image: linear-gradient(#4c4c4c, transparent);
  }

  //end header for article

  // style for image layout
  .container-img {
    max-width: 375px;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-l);
    border-radius: var(--radius-default);

    img {
      border-radius: var(--radius-default);
      border: 4px solid #FFD85A;
    }

    &.big {
      height: 215px;
    }

    &.small {
      width: 72px;
      box-shadow: none;
    }

    &.medium {
      width: 163px;

      /* @media (min-aspect-ratio: 2/3) {
         opacity: 0;
       }
       @media (max-aspect-ratio: 16/9) {
         opacity: 1;
       }
       */
    }
  }


  .basic-container {

    padding-right: 0px;
    padding-left: 0px;

    .img-full {
      max-width: 161px;
      margin-top: 40px;
      //margin-left: -20px;

      &.custom {
        height: 170px;
      }

    }
  }


  .basic-container {
    &.bubble {
      border-radius: 100px;
      width: 120%;
      transform: translateX(-50%);
      left: 50%;
      padding: 0 24px;

    }

    &.full {
      background: white;


      .peak {
        bottom: -7px;
        left: 80px;
        width: 70px;
        height: 40px;
        transform: rotate(-45deg);
        background: white;
      }
    }
  }
}

</style>