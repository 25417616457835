import JwtDecode from 'jwt-decode'


export default class Family {
    static from(token) {
        try {
            let obj = JwtDecode(token)
            return new Family(obj)
        } catch (_) {
            return null
        }
    }

    constructor({
                    family_id,
                    admin,
                    email,
                    free_trial,
                    state,
                    created_at,
                    locale,
                    newsletter,
                    teacher,
                    dyslexia
                }) {
        this.id = family_id // eslint-disable-line
        this.email = email
        this.admin = admin
        this.freeTrial = free_trial
        this.state = state
        this.created_at = created_at
        this.locale = locale
        this.newsletter = newsletter
        this.teacher = teacher
        this.dyslexia = dyslexia
    }

    isValidated() {
        return this.isSubscribed() ||
            this.isInvited() ||
            this.isOnboarded() ||
            this.state === 'validated'
    }

    isFullAccess() {
        return this.isSubscribed() || this.isInvited()
    }
    isSubscribed() {
        return this.state === 'subscribed'
    }
    isOnboarded() {
        return this.isInvited() ||
        this.isSubscribed() ||
        this.state === 'freemium'
    }
    isInvited() {
        return this.state === 'invited'
    }

}
