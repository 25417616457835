<template>
    <div class="ask-locale flag-container grid grid-cols-2 gap-2 justify-items-center">
      <div>
        <input type="radio" :id="'fr_' + rand" value="fr" v-model="loc">
        <label :for="'fr_' + rand">
          <svg height="64" viewBox="0 0 32 32" width="64" xmlns="http://www.w3.org/2000/svg">
            <g id="France">
              <path
                  d="m20.354 1.938h-8.708c-5.354 0-9.709 4.355-9.709 9.708v8.709c0 5.353 4.355 9.708 9.709 9.708h8.708c5.353 0 9.708-4.355 9.708-9.708v-8.709c.001-5.354-4.355-9.708-9.708-9.708z"
                  fill="#efefef"/>
              <path d="m10.834 1.979c-4.975.415-8.896 4.588-8.896 9.667v8.709c0 5.079 3.922 9.252 8.896 9.667z"
                    fill="#282780"/>
              <path
                  d="m21.166 1.979v28.043c4.974-.415 8.896-4.588 8.896-9.667v-8.709c.001-5.08-3.922-9.253-8.896-9.667z"
                  fill="#ce2b37"/>
              <path
                  d="m30.06 11.65v8.7c0 5.36-4.35 9.71-9.71 9.71h-8.7c-1.141 0-2.23-.2-3.25-.56l8.77-.75c9.061-1.25 9.41-7.37 9.41-7.37l-.04-10.63s-.19-6.25-7.72-8.81h1.529c5.361 0 9.711 4.35 9.711 9.71z"
                  fill="#160204" opacity=".13"/>
              <path
                  d="m1.94 20.35v-8.7c0-5.36 4.35-9.71 9.71-9.71h3.369c-2.64.53-9.77 2.58-10.85 9.14l-.05 10.7s.26 4.36 2.189 6.68c-2.628-1.74-4.368-4.72-4.368-8.11z"
                  fill="#fff" opacity=".2"/>
            </g>
          </svg>
        </label>
      </div>
      <div>
        <input type="radio" :id="'en_' + rand" value="en" v-model="loc">
        <label class="self-start" :for="'en_' + rand">
          <svg height="64" viewBox="0 0 512 512" width="64" xmlns="http://www.w3.org/2000/svg">
            <g id="United_Kingdom">
              <path
                  d="m329.58 18.381h-147.142c-90.46 0-164.056 73.587-164.056 164.038v147.159c0 90.451 73.596 164.039 164.056 164.039h147.142c90.452 0 164.039-73.588 164.039-164.039v-147.159c0-90.451-73.587-164.038-164.039-164.038z"
                  fill="#efefef"/>
              <g fill="#d90028">
                <path
                    d="m493.62 213.34v85.32h-194.96v194.96h-85.32v-194.96h-194.96v-85.32h194.96v-194.96h85.32v194.96z"/>
                <g>
                  <path d="m455.64 434.43c-6.41 7.7-13.51 14.801-21.21 21.21l-110.27-110.27v-21.21h21.21z"/>
                  <path d="m187.84 166.63v21.21h-21.21l-110.27-110.27c6.41-7.69 13.52-14.801 21.22-21.2z"/>
                </g>
                <g>
                  <path d="m455.65 77.57-110.28 110.27h-21.21v-21.21l110.27-110.28c7.7 6.42 14.8 13.52 21.22 21.22z"/>
                  <path d="m187.84 324.16v21.21l-110.26 110.27c-7.7-6.409-14.81-13.52-21.22-21.21l110.27-110.27z"/>
                </g>
              </g>
              <g fill="#0153b5">
                <path d="m41.036 99.335c-14.385 24.383-22.653 52.784-22.653 83.084v5.425h112.262z"/>
                <path d="m18.383 324.156v5.422c0 30.28 8.258 58.664 22.625 83.036l89.613-88.458z"/>
                <path d="m470.935 412.72c14.404-24.396 22.685-52.817 22.685-83.142v-5.422h-112.29z"/>
                <path d="m493.619 187.844v-5.425c0-30.28-8.257-58.665-22.623-83.037l-89.617 88.462z"/>
                <path d="m324.156 130.62 88.463-89.615c-24.373-14.367-52.758-22.624-83.039-22.624h-5.423z"/>
                <path d="m187.844 381.38-88.458 89.61c24.377 14.368 52.767 22.627 83.053 22.627h5.405z"/>
                <path d="m324.157 381.329v112.288h5.424c30.323 0 58.744-8.28 83.14-22.684z"/>
                <path d="m187.843 130.645v-112.264h-5.405c-30.307 0-58.715 8.27-83.103 22.656z"/>
              </g>
              <path
                  d="m18.424 329.503v-147.009c0-90.568 73.505-164.072 164.072-164.072h56.946c-44.611 8.96-165.087 43.596-183.337 154.443l-.842 180.796s4.39 73.679 37.004 112.877c-44.438-29.397-73.843-79.751-73.843-137.035z"
                  fill="#fff" opacity=".22"/>
              <path
                  d="m493.578 182.494v147.009c0 90.567-73.505 164.072-164.072 164.072h-147.01c-19.266 0-37.681-3.383-54.917-9.464l148.19-12.672c153.09-21.122 159.006-124.535 159.006-124.535l-.677-179.617s-3.209-105.607-130.45-148.865h25.857c90.568 0 164.073 73.504 164.073 164.072z"
                  fill="#160204" opacity=".13"/>
            </g>
          </svg>
        </label>
    </div>
  </div>
</template>

<script>
import Storage from "../../../class/storage";
import UniCheckbox from "../UniCheckbox.vue";

const storage = new Storage('locale')
export default {
  name: "AskLocale",
  components: {UniCheckbox},
  props: {
    locale: {
      type: String,
      default: 'fr'
    },
  },
  data() {
    return {
      loc: this.locale,
      // To avoid same id in case of multiple component use in one page
      rand: Math.random()
    }
  },
  watch: {
    loc(val) {
      this.$emit('handleChange', {locale: val})
    }
  }
}
</script>

<style lang="scss" scoped>
.ask-locale {
  position: relative;

  label {
    border-radius: 26px;
    border: 4px solid transparent;
  }

  input:checked + label {
    border: 4px solid var(--color-secondary);
  }

  input:not(:last-child) {
    position: absolute;
    left: -999px;
    visibility: hidden;
  }
}

@include screen-m {
  .flag-container {
    margin: 16px auto;
  }
}
</style>