import {mapActions} from "vuex";

export default {
    methods: {
        ...mapActions({
            sessionExpired: "sessionExpired",
        })
    },
    /**
     * A la création de la page je regarde si il y a un paramètre expiré si c'est la cas => redirection
     */
    created() {
        if (this.$route.params.q === "expired") {
            this.sessionExpired(true);
        }
    }
}