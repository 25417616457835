import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import '../public/sw'
import ContentfulVue from "./library/contentfulVue";
import ContentfulV2 from "./library/ContentfulV2";
import axios from "./backend/vue-axios";
import router from './router'
import store from './store'
import usefull from "./library/usefull.js";
import GAuth from 'vue-google-oauth2'
import VueYoutubeEmbed from "vue-youtube-embed";
import i18n from "./locales/index"
Vue.config.productionTip = false
Vue.prototype.window = document.window
require("./library/formValidationRules.js");
// let tag = document.createElement('script');
// tag.src = "https://www.youtube.com/iframe_api";
// let firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
// Ajout des layouts
import Security from "./layouts/security";
import Default from "./layouts/default"
import Home from "./layouts/home"
import Account from "./layouts/account"
import Article from "@/layouts/Article";
import SuperPower from "@/layouts/SuperPower";
import Admin from "@/layouts/Admin";

Vue.component("security-layout", Security);
Vue.component("default-layout", Default);
Vue.component("home-layout", Home);
Vue.component("account-layout", Account);
Vue.component("article-layout", Article);
Vue.component("SuperPower-layout", SuperPower);
Vue.component("admin-layout", Admin);

Vue.use(VueYoutubeEmbed, {global: true})
Vue.use(usefull);
Vue.use(ContentfulVue, {
    space: "pj60a3cyj2i1",
    accessToken: "lLjSJuhLR1_cwKmPth8BS32hi5ceOVoLrCZa554sAEI"
});
Vue.use(ContentfulV2, {
    space: "pj60a3cyj2i1",
    accessToken: "8nNXFJ9_UdSVfcWwXNfkOuwHkE1fQdaOXn4S6uXtuSY"
});

const gauthOption = {
    clientId: '653395535509-3snmvhevn4napi86auagn788hc4fru0i.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

new Vue({
    i18n,
    router,
    store,
    axios,
    render: h => h(App)
}).$mount('#app')
