<template>
  <div class="accounts-container">
    <div class="table">
      <div class="theader">
        <div class="table_header">Email</div>
        <div class="table_header">Actions</div>
      </div>
      <div class="table_row" v-for="account in dataAccounts">
        <div class="table_small">
          <div class="table_cell">Email</div>
          <div class="table_cell">{{ account.email }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">Actions</div>
          <div class="table_cell"><button @click="deleteFakeAccount(account)"
                                          style="background: var(--color-primary);"
                                          class="text-white font-bold py-2 px-4 rounded inline-flex items-center"
                                          :disabled="isAccountLoading">
            <svg v-if="isAccountLoading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Supprimer</button></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'accountGrid',
  props: {
    accounts: {
      type: Array,
      required: true
    }
  },
  watch: {
    accounts: function () {
      this.dataAccounts = this.accounts;
    }
  },
  data() {
    return{
      dataAccounts: [],
      isAccountLoading: false
    }
  },
  methods: {
    deleteFakeAccount(account) {
      this.isAccountLoading = true
      if (!window.confirm("Êtes-vous sur ?")) {
        this.isAccountLoading = false
        return;
      }

      this.$http.delete('admin/fake_accounts/' + account.id)
      .then(resp => {
        this.dataAccounts.splice(this.dataAccounts.findIndex(el => el.id === account.id), 1)
        this.isAccountLoading = false
      })


    }
  },
  created() {
    this.dataAccounts = this.accounts
  },
  updated() {
    this.dataAccounts = this.accounts
  }
}
</script>
<style scoped lang="scss">

.table {
  margin-top: 1rem;
  width: 100%;
  display: table;
  text-align: center;
  border-collapse: separate;
  font-weight: 400;
}

.table_row {
  display: table-row;
}

.theader {
  display: table-row;
}

.table_header {
  display: table-cell;
  border-bottom: var(--color-text-soft) 2px solid;
  border-top: var(--color-text-soft) 2px solid;
  background: var(--color-secondary);
  color: black;
  padding: 12px;
  font-weight: 700;
}

.table_header:first-child {
  border-left: var(--color-text-soft) 2px solid;
  border-top-left-radius: 1.5rem;
}

.table_header:last-child {
  border-right: var(--color-text-soft) 2px solid;
  border-top-right-radius: 1.5rem;
}

.table_small {
  display: table-cell;
}

.table_row > .table_small > .table_cell:nth-child(odd) {
  display: none;
  background: var(--color-secondary);
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table_row > .table_small > .table_cell {
  padding: 12px 8px;
  border-bottom: var(--color-text-soft) 2px solid;
}

.table_row > .table_small:first-child > .table_cell {
  border-left: var(--color-text-soft) 2px solid;
  padding: 20px 8px;
}

.table_row > .table_small:last-child > .table_cell {
  border-right: var(--color-text-soft) 2px solid;
}

.table_row:last-child > .table_small:last-child > .table_cell:last-child {
  border-bottom-right-radius: 1.5rem;
}
.table_row:last-child > .table_small:first-child > .table_cell:last-child {
  border-bottom-left-radius: 1.5rem;
}

@media screen and (max-width: 650px) {
  .table {
    display: block;
  }
  .table_row:nth-child(2n+3) {
    background: none;
  }
  .theader {
    display: none;
  }
  .table_row > .table_small > .table_cell:nth-child(odd) {
    display: table-cell;
    width: 50%;
  }
  .table_cell {
    display: table-cell;
    width: 50%;
  }
  .table_row {
    display: table;
    width: 100%;
    border-collapse: separate;
    padding-bottom: 20px;
    margin: 5% auto 0;
    text-align: center;
  }
  .table_small {
    display: table-row;
  }
  .table_row > .table_small:first-child > .table_cell:last-child {
    border-left: none;
    border-top-right-radius: 1.5rem;
  }
  .table_row > .table_small > .table_cell:first-child {
    border-left: var(--color-text-soft) 2px solid;
    border-right: var(--color-text-soft) 2px solid;
  }
  .table_row > .table_small:first-child > .table_cell:first-child {
    border-top: var(--color-text-soft) 2px solid;
    border-top-left-radius: 1.5rem;
  }
  .table_row > .table_small:first-child > .table_cell:last-child {
    border-top: var(--color-text-soft) 2px solid;
  }
  .table_row > .table_small > .table_cell:last-child {
    border-right: var(--color-text-soft) 2px solid;
  }
  .table_row > .table_small:last-child > .table_cell:first-child {
    border-bottom-left-radius: 1.5rem;
  }
  .table_row > .table_small:last-child > .table_cell:last-child {
    border-bottom-right-radius: 1.5rem;
  }

  .table_row:last-child > .table_small:first-child > .table_cell:last-child {
    border-bottom-left-radius: 0;
  }
}

</style>