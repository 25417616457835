import Storage from "../../class/storage";
import i18n from "../locales/index"
const storage = new Storage('locale')
export default {
    // The install method will be called with the Vue constructor as
    // the first argument, along with possible options
    install(Vue) {
        /**
         *
         *  Permet de savoir si la signature a expirée pour rediriger sur la page de login
         *
         * @param message
         * @returns {boolean}
         */
        Vue.prototype.$isSignatureExpired = (message) => {
            return message === "Signature has expired";
        }

        /**
         * Permet d'écrire la date de façon relative à la date du jour
         * @param {Date} date
         */
        Vue.prototype.$relativeDate = (date) => {
            let today = new Date()

            const isToday = (someDate) => {
                return someDate.getDate() === today.getDate() &&
                    someDate.getMonth() === today.getMonth() &&
                    someDate.getFullYear() === today.getFullYear()
            }

            const isYesterday = (someDate) => {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);

                return yesterday.toDateString() === someDate.toDateString();

            }

            const isThisWeek = (someDate) => {
                const todayObj = today
                const todayDate = todayObj.getDate();
                const todayDay = todayObj.getDay();

                // get first date of week
                const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

                // get last date of week
                const lastDayOfWeek = new Date(firstDayOfWeek);
                lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

                // if date is equal or within the first and last dates of the week
                return date >= firstDayOfWeek && date <= lastDayOfWeek;
            }
            if (isToday(date)) {

                return i18n.t('cards.today')
            }

            if (isYesterday(date)) {
                return i18n.t('cards.yesterday')
            }

            if(isThisWeek(date)) {
                return i18n.t('cards.thisWeek')
            }  else {
                return new Intl.DateTimeFormat(i18n.locale || "fr", {day: 'numeric', month: 'short', year: 'numeric'}).format(date);
            }


        }

        /**
         * Permet de tronquer une chaine de caractère
         * @param string
         * @param length
         * @returns {string|*}
         */
        Vue.prototype.$truncate = (string, length) => {
            if (string.length > length) {
                return string.substring(0, length) + "..."
            } else {
                return string
            }
        }


    }


}






