var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox"},[_c('input',_vm._g(_vm._b({ref:"input",staticClass:"bx--checkbox",attrs:{"type":"checkbox","aria-checked":`${_vm.isChecked}`,"id":_vm.uid},domProps:{"checked":_vm.isChecked === true,"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.$attrs,false),_vm.inputListeners)),_c('label',{class:[
      'label',
      {
        'disabled': _vm.$attrs.disabled !== undefined && _vm.$attrs.disabled,
        'focus': _vm.hasFocus,
      },
    ],attrs:{"data-contained-checkbox-state":_vm.isChecked,"data-contained-checkbox-disabled":_vm.$attrs.disabled,"for":_vm.uid}},[_c('div',{staticClass:"box"},[_c('svg',{attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","d":"M1.73,12.91 8.1,19.28 22.79,4.59"}})])]),_c('span',{staticClass:"display-6 regular"},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }