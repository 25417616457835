<template>
  <div>
    <h2 class="pb-4">{{ title }}</h2>
    <div class="mb-4">
      <p class="caption flex align-middle " v-if="freeCapsule">
        <svg class="mr-2" height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg">
          <path
              d="m421 60c0 33-27 60-60 60v-30c16.5 0 30-13.5 30-30s-13.5-30-30-30c-10.5 0-19.800781 5.398438-25.5 14.097656-15.898438 25.503906-39 49.203125-70.5 72.902344l-9-12-9 12c-31.5-23.699219-54.601562-47.398438-70.5-72.902344-5.699219-8.699218-15-14.097656-25.5-14.097656-16.5 0-30 13.5-30 30s13.5 30 30 30v30c-33 0-60-27-60-60s27-60 60-60c20.699219 0 39.601562 10.5 50.699219 28.199219 12.601562 19.800781 30.601562 38.699219 54.300781 57.601562 23.699219-18.902343 41.699219-37.800781 54.300781-57.601562 11.097657-17.699219 30-28.199219 50.699219-28.199219 33 0 60 27 60 60zm0 0"
              fill="#ffbd86"/>
          <path
              d="m421 60c0 33-27 60-60 60v-30c16.5 0 30-13.5 30-30s-13.5-30-30-30c-10.5 0-19.800781 5.398438-25.5 14.097656-15.898438 25.503906-39 49.203125-70.5 72.902344l-9-12v-19.199219c23.699219-18.902343 41.699219-37.800781 54.300781-57.601562 11.097657-17.699219 30-28.199219 50.699219-28.199219 33 0 60 27 60 60zm0 0"
              fill="#f6a96c"/>
          <path d="m482 180v332h-181l-30-30h-30l-30 30h-181v-332zm0 0" fill="#ff4949"/>
          <path d="m482 180v332h-181l-30-30h-15v-302zm0 0" fill="#ff193d"/>
          <path d="m512 90v120h-512v-120h211l30 30h30l30-30zm0 0" fill="#ff6c6c"/>
          <path d="m512 90v120h-256v-90h15l30-30zm0 0" fill="#ff4949"/>
          <path d="m211 90h90v422h-90zm0 0" fill="#fed2a4"/>
          <path d="m256 90h45v422h-45zm0 0" fill="#ffbd86"/>
        </svg>
        {{$t('capsule.free')}}
      </p>
    </div>
    <div class="info-caps flex justify-between align-items-center">
      <div class="flex align-items-center ">
        <div class="container-avatar margin-right-8 rounded-full">
          <img class="avatar object-cover rounded-full w-full h-full" :src="author.profil.fields.file.url + '?fit=thumb'">
        </div>
        <div>
          <p class="name">{{author.firstname}}</p>
          <p class="caption">{{$relativeDate(date)}}</p>
        </div>
      </div>
      <div class="flex-grow flex justify-end align-items-center">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M10.9999 5.99792C14.4531 5.99792 17.2525 8.79731 17.2525 12.2505C17.2525 15.7037 14.4531 18.5031 10.9999 18.5031C7.5467 18.5031 4.74731 15.7037 4.74731 12.2505C4.74731 8.79731 7.5467 5.99792 10.9999 5.99792"
              stroke="#565151" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.0001 8.49896V11.8337" stroke="#565151" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M9.33276 3.49692H12.6675" stroke="#565151" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M17.6694 6.83161L16.8357 5.99792" stroke="#565151" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <p>{{ readDuration }}min</p>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'InfoCapsOld',
  props: {
    readDuration: {},
    title: {},
    date: Date,
    freeCapsule: Boolean,
    author: {
      type: Object,
    }
  }
}
</script>
<style lang="scss" scoped>

.container-avatar {
  width: 40px;
  height: 40px;
}

.caption {
  color: var(--color-text-disabled)
}
</style>