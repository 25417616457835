<template>
  <div class="container absolute left-1/2 flex" :class="avatar === 'both'? 'space-between' : 'justify-center'">
    <div v-if="avatar === 'both' || avatar === 'kid'">
      <avatar type="child" :showSticker="false" :img="capsules.currentUsers.child.avatar"/>
      <!--      <p class="text-align-center">enfant</p>-->
    </div>
    <div v-if="avatar === 'both' || avatar === 'parent'">
      <avatar type="parent" :img="capsules.currentUsers.parent.avatar"/>
    </div>
  </div>
</template>

<script>
import avatar from "@/components/Avatar";
import {mapGetters} from "vuex"

export default {
  name: "currentFamilyAvatar",
  components: {avatar},
  computed: {
    ...mapGetters({
      capsules: "capsules",
    })
  },
  props: {
    avatar: {
      type: String,
      required: false
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 136px;
  top: -30px;
  transform: translateX(-50%);

}
</style>