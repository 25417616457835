import Storage from "../../class/storage";

const storage = new Storage("currentUser");

const state = storage.init({});

const getters = {
    currentUser: (state) => {
        return state;
    },
};

const mutations = {
    ADD_CURRENT_USER: (state, user) => {
        state = user
        storage.update(state)
    },
    REMOVE_CURRENT_USER: (state) => {
        state = {}
        storage.update(state)
    }
};

const actions = {
    addCurrentUser(store, user) {
        store.commit("ADD_CURRENT_USER", user)
    },
    removeCurrentUser(store) {
        store.commit("REMOVE_CURRENT_USER")
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};

