<template>
  <div>
    <div v-for="(solution, index ) in solutions "
         :class="{'rounded-t-2xl': checkTopSolution(index) , 'rounded-b-2xl': checkBottomSolution(index), 'selected': index === solutionClicked.id }"
         class="p-3 solution"
         @click="clicked($event, index)"
         :id="solution.fields.goodAnswer || (solution.fields.goodAnswer === false? 'false': '')">
      <div class="inline-flex mr-4 letter rounded-full" :style="'background : '+alphabet[index].color">
        <p class="display-2">{{ alphabet[index].letter }}</p>
      </div>
      <p class="inline-flex">{{ solution.fields.title }}</p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SolutionBlock",
  data() {
    return {
      id: null,
      solutionClicked: {id: null, answer: null},
      alphabet: [
        {letter: 'A', color: '#5E2DEA'},
        {letter: 'B', color: 'var(--color-warning)'},
        {letter: 'C', color: 'var(--color-success)'},
        {letter: 'D', color: '#09A6E6'},
        {letter: 'E', color: '#5E2DEA'}
      ]
    }

  },
  props: {
    solutions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      capsules: "capsules"
    }),

    solution() {
      return this.capsules.currentSolution
    }
  },
  watch: {
    solution(newSol, oldSol) {
      this.solutionClicked = newSol
    }
  },
  /**
   * Si composant doit être réutilisé utiliser le store dans le composant parent
   */
  methods: {
    ...mapActions(['setCurrentSolution']),
    /**
     * function qui regarde si on est sur le dernier element du tableau qui est cliqué
     * @param index
     * @returns {boolean}
     */
    checkBottomSolution(index) {
      return index === this.solutions.length - 1
    },
    /**
     * function qui regardfe si c'est le premier element du tableau qui est cliqué
     * @param index
     * @returns {boolean}
     */
    checkTopSolution(index) {
      return index === 0
    },
    /**
     * function qui permet de savoir quel element est cliqué et donc d'afficher la selection
     * remplie le store et envoie un msg au parent
     * @param e
     * @param index
     */
    clicked(e, index) {
      let answer = e.target.id
      this.$emit('answerOpen', answer)
      this.solutionClicked = {id: index, answer: answer}
      this.setCurrentSolution(this.solutionClicked)
    },

  },
  updated() {
    this.solutionClicked = this.capsules.currentSolution
  },
  created() {
    this.solutionClicked = this.capsules.currentSolution

  },
}
</script>

<style lang="scss" scoped>
.letter {
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;

  > p {
    color: white;
  }

}

.solution {
  border: 2px solid var(--color-surface-default);
  background: var(--color-surface-soft);

  &.selected {
    background: #E6F6FC;
    color: #09A6E6;
    border: 2px solid #09A6E6;
  }

  > * {
    pointer-events: none;
  }
}

</style>