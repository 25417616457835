<!--suppress ALL -->
<template>
  <div class="template">
    <div >
      <div class="head pt-8" :style="{'--head-bg': colors[block.fields.color]}">
        <div class="wrapper">
          <div class="head-infos">
            <span @click="$emit('go-back', index)" class="mr-6 cursor-pointer">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </span>
            <div class="progress" :style="{'--width': getProgress}"></div>
            <div class="p-2">
              <router-link :to="{name: 'Home'}"><sprite-icon name="home" size="big" color="white"/></router-link>
            </div>
          </div>
          <h2 class="white mt-5">{{ block.fields.question }}</h2>
        </div>
      </div>
      <div class="bubble" :style="{'--head-bg': colors[block.fields.color]}">
        <div class="triangle" :style="{'--head-bg': colors[block.fields.color]}"></div>
      </div>

      <div>
        <div class="wrapper">
          <img class="mb-2" src="../../assets/illus/1 Problème _.png" alt="" style="width: 80px;">
          <div class="answers-container">
            <div @click="selectAnswer(answer)" class="answer"
                 :class="{selected: selectedAnswer === answer, good: (isVerifying && selectedAnswer === answer && isAnswerGood) || (isVerifying && isAnswerWrong && answer.fields.goodAnswer), wrong: isVerifying && selectedAnswer === answer && isAnswerWrong}"
                 v-for="(answer, index) in block.fields.answers">
              <div class="letter-container">
                <svg v-if="(isVerifying && selectedAnswer === answer && isAnswerGood) || (isVerifying && isAnswerWrong && answer.fields.goodAnswer)" width="20" height="20"
                     viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="white" stroke-width="3" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
                <svg v-else-if="isVerifying && selectedAnswer === answer && isAnswerWrong" width="20" height="20"
                     viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 15L15 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 15L5 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span v-else>{{ letters[index].letter }}</span>
              </div>
              <span>{{ answer.fields.title }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="footer" :style="{'--footer-bg': isVerifying ? '#F9F9F9' : 'white'}">
      <div class="content mb-4 wrapper">
        <div v-if="isAnswerGood && isVerifying" class="flex space-between align-items-center">
          <div>
            <p class="display-3">{{$t('capsuleV2.quizz.good')}}</p>
            <p>{{block.fields.goodAnswerSentence}} </p>
          </div>
          <img src="../../assets/img/wow-content.png" alt="">
        </div>
        <div v-if="isAnswerWrong && isVerifying" class="flex space-between align-items-center">
          <div>
            <p class="display-3">{{$t('capsuleV2.quizz.bad')}}</p>
            <p>{{block.fields.wrongAnswerSentence}}</p>
          </div>
          <img src="../../assets/img/wow-pas-content.png" alt="">
        </div>

      </div>
      <div class="wrapper">
        <PrimaryButton class="mt-2" @click.native="checkAnswer" v-if="!isVerifying" :disabled="!selectedAnswer" color="yellow">{{$t('capsuleV2.quizz.check')}}</PrimaryButton>
        <PrimaryButton class="mt-2" :disabled="loadingNext" :clicked="loadingNext" @click.native="nextStep" v-else>{{ block.fields.buttonText }}</PrimaryButton>
      </div>
    </div>

  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import SpriteIcon from "@/components/SpriteIcon";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Quizz",
  components: {SpriteIcon, PrimaryButton},
  props: {
    block: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      isVerifying: false,
      letters: [
        {
          letter: 'A',
          color: "#5E2DEA",

        },
        {
          letter: 'B',
          color: "#FDB600",

        },
        {
          letter: 'C',
          color: "#07BD58",

        },
        {
          letter: 'D',
          color: "#09A6E6",

        },
        {
          letter: 'E',
          color: "#FFF"
        },
        {
          letter: 'F',
          color: "#FFF"
        }
      ],
      selectedAnswer: null,
      canSelect: true,
      isAnswerGood: false,
      isAnswerWrong: false,
      loadingNext: false,
      colors: {
        rouge: "#F6365C",
        bleu: "#09A6E6",
        vert: "#63C1A8"
      }
    }
  },
  computed: {
    ...mapGetters(['newCapsule', 'currentBlock']),
    getProgress() {
      return ((this.index + 1) / this.totalSteps) * 100 + '%';
    }
  },
  methods: {
    ...mapActions(['updateBlock']),
    nextStep() {
      this.updateBlock({
        id: this.block.sys.id,
        answer: this.selectedAnswer
      });

      this.$emit('next-step', this.index)
    },
    selectAnswer(answer) {
      if (!this.canSelect) {
        return;
      }
      this.selectedAnswer = answer;
      if (answer.fields.goodAnswer) {
        this.isAnswerGood = true;
        this.isAnswerWrong = false;
      } else {
        this.isAnswerGood = false;
        this.isAnswerWrong = true;
      }
    },
    checkAnswer() {
      this.canSelect = false;
      this.isVerifying = true;
    }
  },
  created() {
    window.scrollTo(0,0);
    if (this.currentBlock && this.currentBlock.answer) {
      this.selectAnswer(this.currentBlock.answer)
      this.checkAnswer()
    }
  }
}
</script>

<style scoped lang="scss">
.head {
  background: var(--head-bg);

}

.bubble {
  background: var(--head-bg);
  position: relative;
  z-index: -1;
  height: 100px;
  border-radius: 100%;
  transform: translateY(-50%);
}

.triangle {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 100px);
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid var(--head-bg);
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  transform: rotate(10deg);
}

.head-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  height: 10px;
  width: 100%;
  position: relative;
}

.progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: 100%;
  border-radius: 12px;
  background: white;
}

.answers-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .answer {
    cursor: pointer;
    padding: 12px 8px;
    border-radius: 16px;
    border: 2px solid #C4C8C9;
    box-shadow: inset 0px -4px 0px #E5E0DF;
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
    grid-gap: 12px;

    .letter-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #F1F1F1;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 28px;
      font-weight: bold;
    }

    &.selected {
      background: #E6F6FC;
      border: 2px solid #09A6E6;
      box-shadow: 0 2px 0 #09A6E6;

      .letter-container {
        background: #09A6E6;
        color: white;
      }
    }

    &.good {
      background: #E9FBF1;
      border: 2px solid #07BD58;
      box-shadow: 0px 2px 0px #09A6E6;

      .letter-container {
        background: #07BD58;
      }
    }

    &.wrong {
      background: #F9EBEE;
      border: 2px solid #F6365C;
      box-shadow: 0px 2px 0px #F8657B;

      .letter-container {
        background: #F6365C;
      }
    }
  }
}

.footer {
  height: 100%;
  margin-top: 32px;
  padding-top: 12px;
  background: var(--footer-bg);

  .content {
    min-height: 80px;
  }
}

.template {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

}

@include screen-m {
  .template {
    grid-template-rows: 1fr 240px;
  }
}
</style>