import Storage from "../../class/storage";

const storage = new Storage("style");

const state = storage.init({
    dyslexiaMode: false,
});

const getters = {
    style: (state) => {
        return state;
    },
};

const mutations = {
    TOGGLE_DYSLEXIA_MODE: (state, bool) => {
        state.dyslexiaMode = bool
        document.documentElement.className = state.dyslexiaMode ? 'dyslexia-font' : 'main-font';
        storage.update(state)
    },
};

const actions = {
    toggleDyslexiaMode(store, bool) {
        store.commit('TOGGLE_DYSLEXIA_MODE', bool);
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};

