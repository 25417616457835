<template>
  <div>
    <div class="flex space-between">
      <div class="left">
        <tag v-if="capsule.fields.capsuleTags" size="small" :caps-tags="capsule.fields.capsuleTags"/>
        <p class="margin-bot-4 title strong">{{
            capsule.fields.title
          }}</p>


      </div>
      <div class="img-container justify-end">
        <img :src="capsule.fields.cover.fields.file.url+'?fit=fill&w=80&h=80'" alt="">
      </div>

    </div>
    <div class="flex mt-4 space-between">
      <div class="flex">
        <p class="caption mr-2">{{ capsule.fields.author.fields.firstname }}</p>
        <p class=" caption mr-2">{{ $relativeDate(new Date(capsule.sys.createdAt)) }}</p>
      </div>
      <p class="caption flex align-middle " v-if="capsule.fields.freeCapsule">
        <img src="../assets/img/new_gift.svg" alt="" class="mr-2">
        {{$t('capsule.free')}}
      </p>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Tag";

export default {
  name: "CardMiniOldCaps",
  components: {Tag},
  props: {
    capsule: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.left {
  max-width: 70%;
}

//.title{
//  font-weight: var(--font-weight-bold);
//}
.img-container {
  border-radius: var(--radius-default);
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
    border-radius: var(--radius-default);
    object-fit: cover;
  }
}
</style>