<template>
	<svg
		class="icon"
		xmlns="http://www.w3.org/2000/svg"
		:width="size === 'small' ? '16px' : size === 'big' ? '24px' : '20px'"
		:height="size === 'small' ? '16px' : size === 'big' ? '24px' : '20px'"
		stroke-width="2.5px"
		viewBox="0 0 24 24"
		role="presentation"
		:stroke="color"
		stroke-linecap="round"
		stroke-linejoin="round"
		tabindex="-1"
		:fill="fill"
	>
		<use class="use-icon" :xlink:href="'/icons/iconSprite.svg#icon-' + name">
      <title v-if="title != null">{{ title }}</title>
    </use>
	</svg>
</template>
<script>

export default {
	name: "SpriteIcon",
	props: {
		name: String,
		size: String, // small or big
		color: {
			type: String,
			default: "var(--color-text)"
		},
    fill: {
      type: String,
      default: "transparent"
    },
    title: {
      type: String,
      default: null
    }
  },
};
</script>

<style scoped lang="scss">
svg {
	outline: none;
}
</style>
