<template>
  <div>
    <div v-if="isLoading"></div>
    <div v-else class="main-container rounded-t-3xl wrap auth h-full w-full">
      <div class="back-button">
        <router-link :to="{name: 'Account'}" class="mr-6 cursor-pointer">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="#1C2326" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81836 18H28.182" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 10.7273L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 25.2727L7.81836 18" stroke="#1C2326" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
      <p class="display-1 mb-14 text-align-center title-step1">{{ $t('profil.title') }}</p>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-2">
        <div class="flex align-items-center flex-col" v-for="kid in kids" :key="kid.id">
          <div @click="goToHome(kid)" class="kid-start-avatar rounded-full selected cursor-pointer">
            <img :src="kid.custom_image ? kid.attachment_url : kid.avatar" alt="">
          </div>

          <p class="display-3 mt-3">{{ kid.firstname || "-" }}</p>
          <router-link class="underline mt-2" :to="{name: 'updateUser', params: { id: kid.id }}">{{ $t('profil.edit') }}
          </router-link>
        </div>
        <div class="justify-self-center">
          <router-link :to="{name: 'SignInWelcome', params: {type: 'child', from: 'profil'}}" class="add-kid"
                       @click="kidOn=true">
            <svg width="" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="39.2593" fill="white" fill-opacity="0.3" stroke="#EEB706"
                      stroke-width="1.48148"
                      stroke-dasharray="3.7 3.7"/>
              <path
                  d="M33.2255 43.1955C32.8166 43.1955 32.4611 43.0444 32.1588 42.7422C31.8566 42.4399 31.7055 42.0844 31.7055 41.6755V41.3022C31.7055 40.8933 31.8566 40.5377 32.1588 40.2355C32.4611 39.9333 32.8166 39.7822 33.2255 39.7822H37.5722C37.7322 39.7822 37.8122 39.7022 37.8122 39.5422V35.2755C37.8122 34.8666 37.9544 34.5111 38.2388 34.2088C38.5411 33.9066 38.8966 33.7555 39.3055 33.7555H39.9455C40.3544 33.7555 40.7011 33.9066 40.9855 34.2088C41.2877 34.5111 41.4388 34.8666 41.4388 35.2755V39.5422C41.4388 39.7022 41.5188 39.7822 41.6788 39.7822H46.0255C46.4344 39.7822 46.79 39.9333 47.0922 40.2355C47.3944 40.5377 47.5455 40.8933 47.5455 41.3022V41.6755C47.5455 42.0844 47.3944 42.4399 47.0922 42.7422C46.79 43.0444 46.4344 43.1955 46.0255 43.1955H41.6788C41.5188 43.1955 41.4388 43.2755 41.4388 43.4355V47.7022C41.4388 48.1111 41.2877 48.4666 40.9855 48.7688C40.7011 49.0711 40.3544 49.2222 39.9455 49.2222H39.3055C38.8966 49.2222 38.5411 49.0711 38.2388 48.7688C37.9544 48.4666 37.8122 48.1111 37.8122 47.7022V43.4355C37.8122 43.2755 37.7322 43.1955 37.5722 43.1955H33.2255Z"
                  fill="#EEB706"/>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Avatar from "@/components/Avatar";
import PrimaryButton from "@/components/PrimaryButton";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Qui",
  components: {PrimaryButton, Avatar},
  computed: {
    ...mapGetters(['currentFamily', "familyState", 'currentUser'])
  },
  data() {
    return {
      kidSelected: "",
      firstChild: "",
      kids: [],
      kidOn: false,
      isLoading: true
    }
  },
  methods: {
    ...mapActions(['setUsers', 'addCurrentUser']),
    goToHome(kid) {
      this.addCurrentUser(kid)
      this.$nextTick(() => {
        this.$router.push({name: "Home", params: {reload: true}});
      })
    },
  },
  created() {
    if (!this.currentFamily.isOnboarded()) {
      this.$router.push({name: 'SignInWelcomeHome'})
    }

    if (this.familyState.users.length === 0) {
      this.$http.get('/families/' + this.currentFamily.id + '/users')
          .then((resp) => {
            if (resp.data) {
              this.setUsers(resp.data)
              if (this.familyState.users) {
                this.familyState.users.filter(el => el.user_type === 'child').forEach(el => this.kids.push(el))
              }
            }
          })
    } else {
      this.familyState.users.filter(el => el.user_type === 'child').forEach(el => this.kids.push(el))
      this.firstChild = this.kids[0]
    }

    this.isLoading = false

  }
}
</script>

<style scoped lang="scss">
.add-kid, .parent-start-avatar, .kid-start-avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;

  > svg, img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>