<template>
  <div class="basic-container wrap text-align-center h-screen flex justify-center align-center flex-col">
    <H1 class="display-1 mb-8">
      {{ $t('thanks.title', {name: familyState.users.find(u => u.user_type === "child").firstname}) }} 🎉</H1>
    <p class="mb-8" v-html="$t('thanks.content')"></p>
    <primary-button v-if="loadingSubscribed" :disabled="loadingSubscribed" :clicked="loadingSubscribed">
      {{ $t('thanks.action') }}
    </primary-button>
    <router-link v-else-if="ctfid && !loadingSubscribed" tag="button"
                 :to="{name: newCaps ? 'article' : 'Capsule', params: {slug:  slug}}">
      {{ $t('thanks.actionArticle') }}
    </router-link>
    <router-link v-else tag="button" :to="{name: 'Home'}" class="">{{
        $t('thanks.action')
      }}
    </router-link>
  </div>
</template>

<script>
import PrimaryButton from "../components/PrimaryButton";
import {mapGetters} from "vuex";
import MD5 from "crypto-js/md5";

export default {
  name: "Thanks",
  components: {PrimaryButton},
  computed: {
    ...mapGetters({
      currentFamily: 'currentFamily',
      familyState: 'familyState'
    })
  },
  methods: {
    async getArticle(ctfid) {
      let r = await this.$contentful.getEntries({'sys.id': ctfid})
      if (r.items.length > 0) {
        this.articleTitle = r.items[0].fields.title
        this.slug = r.items[0].fields.slug
        this.newCaps = false
        return true
      }
      let r2 = await this.$contentfulv2.getEntries({'sys.id': ctfid})
      if (r2.items.length > 0) {
        this.articleTitle = r2.items[0].fields.title
        this.slug = r2.items[0].fields.slug
        this.newCaps = true
        return true
      }
      return null
    }
  },
  data() {
    return {
      ctfid: null,
      slug: null,
      articleTitle: null,
      loadingSubscribed: false,
      newCaps: false
    }
  },
  created() {
    this.ctfid = this.$route.query.ctfid
    //Redirect de stripe après sub
    if (this.$route.query.success && this.$route.query.id) {
      if (!this.currentFamily.isFullAccess()) {
        this.loadingSubscribed = true
        this.$http.get('checkout-session?id=' + this.$route.query.id)
            .then(async res => {
              localStorage.token = res.data.auth_token
              let aeEvent = {}
              if (res.data.interval === "month") {
                /* {{KEY}} must be updated for each rule */
                aeEvent.key = '61f7e9451a58796fc7b0736c-61f3a7a166d3891597f0e3e7';
                aeEvent.Conversion = {};
                /* Values below must be updated */
                aeEvent.Conversion.amount = '0.83';
                aeEvent.Conversion.payment = "online";
                aeEvent.Conversion.id = MD5(this.currentFamily.email).toString();
                aeEvent.Conversion.customer = this.currentFamily.id;
                aeEvent.Conversion.currency = 'EUR';

              } else {
                /* {{KEY}} must be updated for each rule */
                aeEvent.key = '61f7ebd11a58796fc7b0736f-61f3a7a166d3891597f0e3e7';
                aeEvent.Conversion = {};
                /* Values below must be updated */
                aeEvent.Conversion.amount = '90';
                aeEvent.Conversion.payment = "online";
                aeEvent.Conversion.id = MD5(this.currentFamily.email).toString();
                aeEvent.Conversion.customer = this.currentFamily.id;
                aeEvent.Conversion.currency = 'EUR';
              }

              ('AeTracker' in window)
                  ? AeTracker.sendConversion(aeEvent)
                  : (window.AE = window.AE || []).push(aeEvent);

              if (this.ctfid) {
                await this.getArticle(this.ctfid)
                this.$nextTick(() => {
                  this.loadingSubscribed = false
                })
              } else {
                this.loadingSubscribed = false
              }
              await this.$store.dispatch('login')
            })
            .catch(error => {
              // TODO: error bus
              this.loadingSubscribed = false
            })
      } else {
        this.$router.push({name: "Home"})
      }
    } else {
      this.$router.push({name: "Home"})
    }
  }
}
</script>

<style scoped lang="scss">

button {
  transition: box-shadow 0.1s;
  box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  //background: linear-gradient(180deg, #E62145 0%, #B81230 100%);
  background: var(--color-primary);
  color: white;
  border-radius: var(--radius-default);
  padding: var(--space-12);
}

</style>