import Vue from "vue";
import Router from "vue-router";
import Meta from 'vue-meta';

import store from './store'

//imported Pages
import Login from "./views/security/Login"
import SignIn from "./views/security/SignIn"
import SignInEmail from "./views/security/SignInEmail"
import SignInWelcome from "@/views/security/SignInWelcome";
import SignInWelcomeHome from "@/views/security/SignInWelcomeHome";
import OnBoard from "@/views/security/OnBoard";
import Capsule from "@/views/Capsule";
import Families from "@/views/security/Families";
import CapsuleRecap from "@/views/CapsuleRecap";
import Logout from "@/views/security/Logout";
import Account from "@/views/Account";
import newAccount from "@/views/newAccount";
import Home from "@/views/Home";
import KidPower from "@/views/KidPower";
import ForgotPwd from "@/views/security/ForgotPwd";
import ResetPwd from "@/views/security/ResetPwd";
import CreatePwd from "@/views/security/CreatePwd";
import RecoverPwd from "@/views/security/RecoverPwd";
import Thanks from "@/views/Thanks"
import Qui from "@/views/Qui"
import kidPower from "@/views/KidPower";
import kidPowers from "@/views/kidPowers";
import kidPowerView from "@/views/KidPowerView";
import UpdateUser from "@/views/account/UpdateUser";
import Article from "@/views/Article";
import CapsulesFromCategory from "@/views/CapsulesFromCategory";
import Journalists from "@/views/Journalists";

// ======== ADMIN ========
import AdminHome from "@/views/admin/AdminHome";
import AdminStats from "@/views/admin/AdminStats.vue";
import AdminStatsCapsules from "@/views/admin/AdminStatsCapsules.vue";
import AdminStatsCapsulesDetails from "@/views/admin/AdminStatsCapsulesDetails.vue";

Vue.use(Router);
Vue.use(Meta)

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        // V2

        {
            path: "/profil",
            name: "profile",
            meta: {layout: "security", header: "logo", requiresAuth: true},
            component: Qui
        },
        {
            path: "/",
            name: "Home",
            component: Home,
            meta: {layout: "home", requiresAuth: true}
        },

        {
            path: "/articles/:slug",
            name: "article",
            component: Article,
            meta: {layout: "article", requiresAuth: true}

        },
        {
            path: "/sujets-filtres",
            name: "capsules.filtered",
            component: CapsulesFromCategory,
            meta: {layout: "home", requiresAuth: true}
        },

        {
            path: "/journalistes",
            name: "journalists",
            component: Journalists,
            meta: {layout: "home", requiresAuth: true}
        },

        {
            path: "/mon-compte",
            name: "Account",
            component: newAccount,
            meta: {layout: "account", requiresAuth: true}

        },

        /////////////////

        // {
        //     path: "/",
        //     name: "Home",
        //     meta: {layout: "home"},
        //     component: Home,
        // },
        {
            path: "/connexion",
            name: "Login",
            meta: {layout: "security", header: "logo"},
            component: Login,
        },
        {
            path: "/deconnexion",
            name: "Logout",
            meta: {layout: "security"},
            component: Logout,
        },
        {
            path: "/mot-de-passe-oublie",
            name: "ForgotPwd",
            meta: {layout: "security", header: "logo"},
            component: ForgotPwd,
        },
        {
            path: "/nouveau-mot-de-passe",
            name: "ResetPwd",
            meta: {layout: "security", header: "logo"},
            component: ResetPwd,
        },
        {
            path: "/create-password",
            name: "CreatePwd",
            meta: {layout: "security", header: "logo"},
            component: CreatePwd,
        },
        {
            path: "/recover-password",
            name: "RecoverPwd",
            meta: {layout: "security", header: "logo"},
            component: RecoverPwd
        },

        {
            path: "/families/validate-token",
            name: "Families",
            meta: {layout: "security", header: "logo"},
            component: Families,
        },
        {
            path: "/inscription",
            name: "SignIn",
            meta: {layout: "security", header: "logo"},
            component: SignIn,
        },
        {
            path: "/inscription/email",
            name: "SignInEmail",
            meta: {layout: "security", header: "email"},
            component: SignInEmail,
        },
        {
            path: "/inscription/bienvenue",
            name: "SignInWelcomeHome",
            meta: {layout: "security", header: "welcome"},
            component: SignInWelcomeHome,
        },
        {
            path: "/inscription/bienvenue/:type",
            name: "SignInWelcome",
            meta: {layout: "security", header: "welcome", requiresAuth: true},
            component: SignInWelcome,
        },
        {
            path: "/bienvenue",
            name: "OnBoard",
            meta: {layout: "security", requiresAuth: true},
            component: OnBoard,
        },
        {
            path: "/capsule/:slug",
            name: "Capsule",
            meta: {layout: "default", requiresAuth: true},
            component: Capsule,
        },
        {
            path: "/capsule-recap/:slug/:capsId",
            name: "CapsuleRecap",
            meta: {layout: "home", requiresAuth: true},
            component: CapsuleRecap
        },

        // =================  ACCOUNT ===================

        // {
        //     path: "/mon-compte",
        //     name: "Account",
        //     component: Account,
        //
        // },
        {
            path: "/mon-compte/super-pouvoirs",
            name: "KidPowers",
            component: kidPowers,
            meta: {layout: "account", requiresAuth: true}
        },
        {
            path: "/mon-compte/super-pouvoirs/:name/:id",
            name: "KidPower",
            component: kidPower,
        },

        {
            path: "/mon-compte/super-pouvoirs/:name/:id/:powerId",
            name: "kidPowerView",
            component: kidPowerView,
            meta: {layout: "SuperPower"}
        },
        {
            path: "/mon-compte/modifier/:id",
            name: "updateUser",
            component: UpdateUser
        },

        // ============ PAYMENT ============

        {
            path: "/merci",
            name: "onbothanks",
            component: Thanks,
            meta: {layout: "home"},
        },


        // ============== ADMIN ==============
        {
            path: "/admin",
            name: "admin.home",
            component: AdminHome,
            meta: {layout: "admin", requiresAuth: true},
        },
        {
            path: "/admin/stats",
            name: "admin.stats",
            component: AdminStats,
            meta: {layout: "admin", requiresAuth: true},
        },
        {
            path: "/admin/stats/capsules",
            name: "admin.statsCapsules",
            component: AdminStatsCapsules,
            meta: {layout: "admin", requiresAuth: true}
        },
        {
            path: "/admin/stats/capsules/:capsule_id/detail",
            name: "admin.statsCapsules.details",
            component: AdminStatsCapsulesDetails,
            meta: {layout: "admin", requiresAuth: true},
        }
    ]
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

    if (localStorage.token && requiresAuth || !requiresAuth) {
        next()
    } else {
        window.localStorage.clear()
        store.dispatch('logout')
        next({name: "Login"})
    }
})

export default router;