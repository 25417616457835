<template>
  <div class="locale-container">
    <div></div>
    <locale-switcher @localeSwitched="localeSwitched"></locale-switcher>
  </div>
</template>
<script>
import LocaleSwitcher from "../../components/localeSwitcher"

export default {
  name: 'LocaleSwitcherOnRight',
  components: {LocaleSwitcher},
  methods: {
    localeSwitched(locale) {
      this.$emit('localeSwitched', locale)
    }
  }
}
</script>
<style scoped lang="scss">
.locale-container {
  display: grid;
  grid-template-columns: 1fr 48px;

  >div:first-child {
    width: 100%;
  }
}
</style>
