<template>

</template>

<script>
export default {
  name: "Logout",
  created() {
    window.localStorage.clear()
    this.$store.dispatch('logout')
    this.$router.push({name: 'Home'})
  }
}
</script>

<style scoped>

</style>