<template>
  <div class="admin-stats">
    <h1>Statistiques</h1>
    <div class="contain">
      <div class="date-container">
        <date-range-picker
            ref="picker"
            :date-range="dateRange"
            :locale-data="localeData"
            :ranges="ranges"
            :autoApply="true"
            opens="right">
          <template v-slot:input="picker" style="min-width: 350px;">
            {{ displayDate(picker.startDate) }} - {{ displayDate(picker.endDate) }}
          </template>
        </date-range-picker>
        <div class="max-w">
          <primary-button @click.native="getData" :clicked="isLoading">Exporter les données</primary-button>
        </div>
      </div>
      <div v-if="hasData">
        <div class="subtitle">
          <span><h3>Vues de page unique quotidiennes</h3></span>
          <span>
            <primary-button
                @click.native="exportData($event,'upv')"
                color="yellow">
              Export .CSV
              <sprite-icon v-if="isLoadingExport.upv === 2" name="check" size="big" class="copy-button" color="white" fill="white"/>
            </primary-button>
          </span>
          <span>
            <primary-button @click.native="copyData($event,'upv')">
              <sprite-icon v-if="isLoadingCopy.upv === 0" name="copy" size="big" color="white" fill="white"/>
              <sprite-icon v-if="isLoadingCopy.upv === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span v-if="isLoadingCopy.upv === 2" style="display: flex; align-items: center;"> Copié !</span>
        </div>
        <table class="table" ref="upv_table">
          <thead class="theader">
          <th class="table_header">Page</th>
          <th class="table_header">#</th>
          </thead>
          <tbody>
          <tr class="trow">
            <td class="table_cell">/inscription</td>
            <td class="table_cell">{{ this.stats.upv.registration }}</td>
          </tr>
          </tbody>
        </table>
        <div class="subtitle">
          <span><h3>Inscriptions</h3></span>
          <span>
            <primary-button
                @click.native="exportData($event,'registration')"
                color="yellow">
              Export .CSV
              <sprite-icon v-if="isLoadingExport.registration === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span>
            <primary-button
                @click.native="copyData($event,'registration')">
              <sprite-icon v-if="isLoadingCopy.registration === 0" name="copy" size="big" color="white" fill="white"/>
              <sprite-icon v-if="isLoadingCopy.registration === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span v-if="isLoadingCopy.registration === 2" style="display: flex; align-items: center;"> Copié !</span>
        </div>
        <table class="table" ref="registration_table">
          <thead class="theader">
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.type') }}</td>
          </th>
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.startValue') }}</td>
          </th>
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.endValue') }}</td>
          </th>
          <th class="table_header" colspan="2">
          <td>{{ $t('settings.admin.stats.percentage') }}</td>
          </th>
          </thead>
          <tbody>
          <tr class="trow" id="totals">
            <td class="table_cell">Total</td>
            <td class="table_cell">{{ this.stats.totals.startNbr }}</td>
            <td class="table_cell">{{ this.stats.totals.endNbr }}</td>
            <td :class="this.stats.totals.nbr >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.totals.nbr }}
            </td>
            <td :class="this.stats.totals.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.totals.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="validated">
            <td class="table_cell">Comptes validés</td>
            <td class="table_cell">{{ this.stats.validated.startNbr }}</td>
            <td class="table_cell">{{ this.stats.validated.endNbr }}</td>
            <td :class="this.stats.validated.nbr >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.validated.nbr }}
            </td>
            <td :class="this.stats.validated.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.validated.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="freemium">
            <td class="table_cell">Comptes gratuits</td>
            <td class="table_cell">{{ this.stats.freemium.startNbr }}</td>
            <td class="table_cell">{{ this.stats.freemium.endNbr }}</td>
            <td :class="this.stats.freemium.nbr >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.freemium.nbr }}
            </td>
            <td :class="this.stats.freemium.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.freemium.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="subscribed">
            <td class="table_cell">Comptes payants</td>
            <td class="table_cell">{{ this.stats.subscribed.startNbr }}</td>
            <td class="table_cell">{{ this.stats.subscribed.endNbr }}</td>
            <td :class="this.stats.subscribed.nbr >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.subscribed.nbr }}
            </td>
            <td :class="this.stats.subscribed.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.subscribed.percentage }} %
            </td>
          </tr>
          <tr class="trow trow_sub" id="month">
            <td class="table_cell table_cell_sub">Mensuels</td>
            <td class="table_cell table_cell_sub">{{ this.stats.month.startNbr }}</td>
            <td class="table_cell table_cell_sub">{{ this.stats.month.endNbr }}</td>
            <td
                :class="this.stats.month.nbr >= 0 ? 'table_cell table_cell_sub positive' : 'table_cell table_cell_sub negative'">
              {{ this.stats.month.nbr }}
            </td>
            <td
                :class="this.stats.month.percentage >= 0 ? 'table_cell table_cell_sub positive' : 'table_cell table_cell_sub negative'">
              {{ this.stats.month.percentage }} %
            </td>
          </tr>
          <tr class="trow trow_sub" id="month">
            <td class="table_cell table_cell_sub">Annuels</td>
            <td class="table_cell table_cell_sub">{{ this.stats.year.startNbr }}</td>
            <td class="table_cell table_cell_sub">{{ this.stats.year.endNbr }}</td>
            <td
                :class="this.stats.year.nbr >= 0 ? 'table_cell table_cell_sub positive' : 'table_cell table_cell_sub negative'">
              {{ this.stats.year.nbr }}
            </td>
            <td
                :class="this.stats.year.percentage >= 0 ? 'table_cell table_cell_sub positive' : 'table_cell table_cell_sub negative'">
              {{ this.stats.year.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="churn">
            <td class="table_cell">Résiliations abonnement</td>
            <td class="table_cell">{{ this.stats.churn.startNbr }}</td>
            <td class="table_cell">{{ this.stats.churn.endNbr }}</td>
            <td :class="this.stats.churn.nbr >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.churn.nbr }}
            </td>
            <td :class="this.stats.churn.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.churn.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="invited">
            <td class="table_cell">Comptes invités</td>
            <td class="table_cell">{{ this.stats.invited.startNbr }}</td>
            <td class="table_cell">{{ this.stats.invited.endNbr }}</td>
            <td :class="this.stats.invited.nbr >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.invited.nbr }}
            </td>
            <td :class="this.stats.invited.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.invited.percentage }} %
            </td>
          </tr>
          </tbody>
        </table>
        <div class="subtitle">
          <span><h3>Types de compte</h3></span>
          <span>
            <primary-button
                @click.native="exportData($event,'account_type')"
                color="yellow">
              Export .CSV
              <sprite-icon v-if="isLoadingExport.accountType === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span>
            <primary-button
                @click.native="copyData($event,'account_type')">
              <sprite-icon v-if="isLoadingCopy.accountType === 0" name="copy" size="big" color="white" fill="white"/>
              <sprite-icon v-if="isLoadingCopy.accountType === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span v-if="isLoadingCopy.accountType === 2" style="display: flex; align-items: center;"> Copié !</span>
        </div>
        <table class="table" ref="account_type_table">
          <thead class="theader">
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.type') }}</td>
          </th>
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.startValue') }}</td>
          </th>
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.endValue') }}</td>
          </th>
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.percentage') }}</td>
          </th>
          </thead>
          <tbody>
          <tr class="trow" id="email_acc">
            <td class="table_cell">Email</td>
            <td class="table_cell">{{ this.stats.email.startNbr }}</td>
            <td class="table_cell">{{ this.stats.email.endNbr }}</td>
            <td :class="this.stats.email.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.email.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="google_acc">
            <td class="table_cell">Google</td>
            <td class="table_cell">{{ this.stats.google.startNbr }}</td>
            <td class="table_cell">{{ this.stats.google.endNbr }}</td>
            <td :class="this.stats.google.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.google.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="facebook">
            <td class="table_cell">Facebook</td>
            <td class="table_cell">{{ this.stats.facebook.startNbr }}</td>
            <td class="table_cell">{{ this.stats.facebook.endNbr }}</td>
            <td :class="this.stats.facebook.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.facebook.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="dekuple">
            <td class="table_cell">Dekuple</td>
            <td class="table_cell">{{ this.stats.dekuple.startNbr }}</td>
            <td class="table_cell">{{ this.stats.dekuple.endNbr }}</td>
            <td :class="this.stats.dekuple.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.dekuple.percentage }} %
            </td>
          </tr>
          <tr class="trow trow_sub" id="teacher">
            <td class="table_cell ">Comptes enseignants</td>
            <td class="table_cell ">{{ this.stats.teachers.startNbr }}</td>
            <td class="table_cell ">{{ this.stats.teachers.endNbr }}</td>
            <td :class="this.stats.teachers.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.teachers.percentage }} %
            </td>
          </tr>
          <tr class="trow" id="families">
            <td class="table_cell">Comptes familles</td>
            <td class="table_cell">{{ this.stats.families.startNbr }}</td>
            <td class="table_cell">{{ this.stats.families.endNbr }}</td>
            <td :class="this.stats.families.percentage >= 0 ? 'table_cell positive' : 'table_cell negative'">
              {{ this.stats.families.percentage }} %
            </td>
          </tr>
          </tbody>
        </table>
        <div class="subtitle">
          <span><h3>Taux</h3></span>
          <span>
            <primary-button
                @click.native="exportData($event,'rate')"
                color="yellow">
              Export .CSV
              <sprite-icon v-if="isLoadingExport.rate === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span>
            <primary-button @click.native="copyData($event, 'rate')">
              <sprite-icon v-if="isLoadingCopy.rate === 0" name="copy" size="big" color="white" fill="white"/>
              <sprite-icon v-if="isLoadingCopy.rate === 2" name="check" size="big" color="white" fill="white"/>
            </primary-button>
          </span>
          <span v-if="isLoadingCopy.rate === 2" style="display: flex; align-items: center;"> Copié !</span>
        </div>
        <table class="table" ref="rate_table">
          <thead class="theader">
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.type') }}</td>
          </th>
          <th class="table_header">
          <td>{{ $t('settings.admin.stats.rate') }}</td>
          </th>
          </thead>
          <tbody>
          <tr class="trow" v-for="(value, name) in this.stats.rate">
            <td class="table_cell">{{ prettifyRateType(name) }}</td>
            <td class="table_cell" v-if="value">{{ value }} %</td>
            <td class="table_cell" v-else>Non calculable</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import UniButton from "@/components/UniButton.vue";
import SpriteIcon from "@/components/SpriteIcon.vue";
import moment from 'moment';

export default {
  name: "AdminStats",
  components: {SpriteIcon, UniButton, PrimaryButton, DateRangePicker},
  data() {
    return {
      isLoading: false,
      isLoadingExport: {
        upv: 0,
        registration: 0,
        accountType: 0,
        rate: 0
      },
      isLoadingCopy: {
        upv: 0,
        registration: 0,
        accountType: 0,
        rate: 0
      },
      localeData: {
        format: 'dd-mm-yyyy HH:mm:ss',
        separator: ' - ',
        applyLabel: 'Choisir',
        cancelLabel: 'Annuler',
        weekLabel: 'W',
        customRangeLabel: 'Choix des dates',
        daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        monthNames: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 1
      },
      ranges: {
        'Aujourd\'hui': [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()],
        'Hier': [new Date(new Date().setDate(new Date().getDate() - 2)), new Date(new Date().setDate(new Date().getDate() - 1))],
        '7 derniers jours': [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
        '30 derniers jours': [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()],
        'Dernière année': [new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()), new Date()],
        '∞': [new Date(new Date(2000), new Date().getMonth(), new Date().getDate()), new Date()],
      },
      dateRange: {
        startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        endDate: new Date()
      },
      hasData: false,
      stats: {
        upv: {},
        totals: {},
        validated: {},
        freemium: {},
        subscribed: {},
        month: {},
        year: {},
        churn: {},
        invited: {},
        email: {},
        google: {},
        facebook: {},
        dekuple: {},
        teachers: {},
        families: {},
        rate: {}
      }
    }
  },
  watch: {
    'isLoadingCopy.upv': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingCopy.upv = 0
        }, 2000)
      }
    },
    'isLoadingCopy.registration': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingCopy.registration = 0
        }, 2000)
      }
    },
    'isLoadingCopy.accountType': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingCopy.accountType = 0
        }, 2000)
      }
    },
    'isLoadingCopy.rate': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingCopy.rate = 0
        }, 2000)
      }
    },
    'isLoadingExport.upv': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingExport.upv = 0
        }, 2000)
      }
    },
    'isLoadingExport.registration': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingExport.registration = 0
        }, 2000)
      }
    },
    'isLoadingExport.accountType': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingExport.accountType = 0
        }, 2000)
      }
    },
    'isLoadingExport.rate': function (newValue, oldValue) {
      if (newValue === 2) {
        setTimeout(_ => {
          this.isLoadingExport.rate = 0
        }, 2000)
      }
    },
  },
  methods: {
    displayDate(date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    getData() {
      this.isLoading = true
      let startDate = moment(String(this.$refs.picker.start)).set({hour:0,minute:0,second:0,millisecond:0}).toDate()
      let endDate = moment(String(this.$refs.picker.end)).set({hour:0,minute:0,second:0,millisecond:0}).toDate()

      this.$http.get(`/stats?start_date=${startDate}&end_date=${endDate}`)
          .then(res => {
            this.stats.upv = res.data.upv
            this.stats.totals = {
              startNbr: res.data.accounts.start_nbr,
              nbr: res.data.accounts.nbr,
              endNbr: res.data.accounts.end_nbr,
              percentage: res.data.accounts.per ?? 0,
            }
            this.stats.validated = {
              startNbr: res.data.validated_acc.start_nbr,
              nbr: res.data.validated_acc.nbr,
              endNbr: res.data.validated_acc.end_nbr,
              percentage: res.data.validated_acc.per ?? 0,
            }
            this.stats.freemium = {
              startNbr: res.data.freemium_acc.start_nbr,
              nbr: res.data.freemium_acc.nbr,
              endNbr: res.data.freemium_acc.end_nbr,
              percentage: res.data.freemium_acc.per ?? 0,
            }
            this.stats.subscribed = {
              startNbr: res.data.sub_acc.start_nbr,
              nbr: res.data.sub_acc.nbr,
              endNbr: res.data.sub_acc.end_nbr,
              percentage: res.data.sub_acc.per ?? 0,
            }
            this.stats.month = {
              startNbr: res.data.month_sub.start_nbr,
              nbr: res.data.month_sub.nbr,
              endNbr: res.data.month_sub.end_nbr,
              percentage: res.data.month_sub.per ?? 0,
            }
            this.stats.year = {
              startNbr: res.data.year_sub.start_nbr,
              nbr: res.data.year_sub.nbr,
              endNbr: res.data.year_sub.end_nbr,
              percentage: res.data.year_sub.per ?? 0,
            }
            this.stats.churn = {
              startNbr: res.data.churn_sub.start_nbr,
              nbr: res.data.churn_sub.nbr,
              endNbr: res.data.churn_sub.end_nbr,
              percentage: res.data.churn_sub.per ?? 0,
            }
            this.stats.invited = {
              startNbr: res.data.invited_acc.start_nbr,
              nbr: res.data.invited_acc.nbr,
              endNbr: res.data.invited_acc.end_nbr,
              percentage: res.data.invited_acc.per ?? 0,
            }
            this.stats.email = {
              startNbr: res.data.email_acc.start_nbr,
              nbr: res.data.email_acc.nbr,
              endNbr: res.data.email_acc.end_nbr,
              percentage: res.data.email_acc.per ?? 0,
            }
            this.stats.google = {
              startNbr: res.data.go_acc.start_nbr,
              nbr: res.data.go_acc.nbr,
              endNbr: res.data.go_acc.end_nbr,
              percentage: res.data.go_acc.per ?? 0,
            }
            this.stats.facebook = {
              startNbr: res.data.fb_acc.start_nbr,
              nbr: res.data.fb_acc.nbr,
              endNbr: res.data.fb_acc.end_nbr,
              percentage: res.data.fb_acc.per ?? 0,
            }
            this.stats.dekuple = {
              startNbr: res.data.dekuple_acc.start_nbr,
              nbr: res.data.dekuple_acc.nbr,
              endNbr: res.data.dekuple_acc.end_nbr,
              percentage: res.data.dekuple_acc.per ?? 0,
            }
            this.stats.teachers = {
              startNbr: res.data.school_acc.start_nbr,
              nbr: res.data.school_acc.nbr,
              endNbr: res.data.school_acc.end_nbr,
              percentage: res.data.school_acc.per ?? 0,
            }
            this.stats.families = {
              startNbr: res.data.family_acc.start_nbr,
              nbr: res.data.family_acc.nbr,
              endNbr: res.data.family_acc.end_nbr,
              percentage: res.data.family_acc.per ?? 0,
            }
            this.stats.rate = res.data.rate
            this.isLoading = false
            this.hasData = true
          })
          .catch(err => {
            this.isLoading = false
          })
    },
    /**
     * Export data to csv
     */
    exportData(e, type) {
      this.isLoadingExport[type] = 1
      let csv = 'SEP=,\n'
      switch (type) {
        case 'upv':
          csv = this.formatCsv('upv_table')
          break
        case 'registration':
          csv = this.formatCsv('registration_table')
          break;
        case 'account_type':
          csv = this.formatCsv('account_type_table')
          break
        case 'rate':
          csv = this.formatCsv('rate_table')
          break
      }

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `${moment(String(this.$refs.picker.start)).format('DD-MM-YYYY')}_${moment(String(this.$refs.picker.end)).format('DD-MM-YYYY')}_${type}.csv`;
      anchor.click();
      this.isLoadingExport[type] = 2
    },
    copyData(e, type) {
      this.isLoadingCopy[type] = 1
      let csv = ''
      switch (type) {
        case 'upv':
          csv = this.formatCsv('upv_table')
          break
        case 'registration':
          csv = this.formatCsv('registration_table')
          break;
        case 'account_type':
          csv = this.formatCsv('account_type_table')
          break
        case 'rate':
          csv = this.formatCsv('rate_table')
          break
      }
      navigator.clipboard.writeText(csv);
      this.isLoadingCopy[type] = 2
    },
    formatCsv(table_ref) {
      let csv = ''
      let lines = this.$refs[table_ref].querySelectorAll("tbody tr")
      lines.forEach(line => {
        let cells = line.querySelectorAll("td")
        cells.forEach(cell => {
          csv += `${cell.innerText},`
        })
        csv += '\n'
      })
      return csv
    },
    prettifyRateType(type) {
      switch (type) {
        case 'conversion':
        case 'abandon':
        case 'validation':
          return type.charAt(0).toUpperCase() + type.slice(1);
        case 'onboarding':
          return 'Freemium';
        case 'subscription':
          return 'Abonnement';
        case 'month_sub':
          return 'Mensuel';
        case 'year_sub':
          return 'Annuel';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contain {
  margin-top: 20px;
}

.date-container {
  display: inline-flex;

  * {
    padding: 6px;
  }
}

.subtitle {
  display: inline-flex;
  width: 100%;

  * {
    padding: 6px;
  }

  span {
    margin: 1rem 0;
  }
}

.max-w {
  max-width: 300px;
}

.table {
  margin-bottom: 2rem;
  width: 100%;
  display: table;
  text-align: center;
  font-weight: 400;
  border-collapse: separate;
  border-spacing: 0
}

.theader {
  display: table-row;
}

.title_col {
  max-width: 30px;
}

.table_header {
  display: table-cell;
  border-top: var(--color-text-soft) 3px solid;
  border-bottom: var(--color-text-soft) 1px solid;
  background: var(--color-secondary);
  color: black;
  padding: 12px;
  font-weight: 700;
  column-span: all;
  margin: auto;

  td {
    display: block;
    text-align: center;
  }

  &:last-child {
    border-right: var(--color-text-soft) 3px solid;
    border-top-right-radius: 1.5rem;
  }

  &:first-child {
    border-left: var(--color-text-soft) 3px solid;
    border-top-left-radius: 1.5rem;
  }
}

.table_cell {
  display: table-cell;
  border-top: var(--color-text-soft) 2px solid;
  padding: 20px 8px;

  &_sub {
    display: table-cell;
    padding: 10px 8px;
    font-style: italic;
    border-top: 0;
  }

  &:first-child {
    border-left: var(--color-text-soft) 3px solid;
  }

  &:last-child {
    border-right: var(--color-text-soft) 3px solid;
  }
}

.trow {
  display: table-row;

  &:last-child {
    .table_cell {
      border-bottom: var(--color-text-soft) 3px solid;

      &:last-child {
        border-bottom-right-radius: 1.5rem;
      }

      &:first-child {
        border-bottom-left-radius: 1.5rem;
      }
    }
  }
}

.positive {
  color: green;

  &:before {
    content: "+ "
  }
}

.negative {
  color: darkred;
}

.copy-button {
  margin: 0;
}

@media screen and (max-width: 650px) {
  .table {
    display: block;
  }

  .table_row:nth-child(2n+3) {
    background: none;
  }

  .theader {
    display: none;
  }

  .table_row > .table_cell:nth-child(odd) {
    display: table-cell;
    width: 50%;
  }

  .table_cell {
    display: table-cell;
    width: 50%;
  }

  .table_row {
    display: table;
    width: 100%;
    border-collapse: separate;
    padding-bottom: 20px;
    margin: 5% auto 0;
    text-align: center;
  }

  .table_row > .table_cell:last-child {
    border-left: none;
    border-top-right-radius: 1.5rem;
  }

  .table_row > .table_cell:first-child {
    border-left: var(--color-text-soft) 3px solid;
    border-right: var(--color-text-soft) 3px solid;
  }

  .table_row > .table_cell:first-child {
    border-top: var(--color-text-soft) 2px solid;
    border-top-left-radius: 1.5rem;
  }

  .table_row > .table_cell:last-child {
    border-top: var(--color-text-soft) 2px solid;
  }

  .table_row > .table_cell:last-child {
    border-right: var(--color-text-soft) 2px solid;
  }

  .table_row > .table_cell:first-child {
    border-bottom-left-radius: 1.5rem;
  }

  .table_row > .table_cell:last-child {
    border-bottom-right-radius: 1.5rem;
  }

  .table_row:last-child > .table_cell:last-child {
    border-bottom-left-radius: 0;
  }
}
</style>