import * as Contentful from 'contentful';

let client = null

const ContentfulV2 = {
    install(Vue, options) {

        client = Contentful.createClient({
            space: options.space,
            accessToken: options.accessToken,
            environment: 'wow-v2.1',
        });

        Vue.prototype.$contentfulv2 = client;
    },
};

export default ContentfulV2;