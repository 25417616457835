<template>
  <div v-if="isLoadingUser || isLoadingContenful">
    <loader></loader>
  </div>
  <div v-else>
    <div class="text-center pt-10 pb-6">
      <div class="relative w-max mx-auto">
        <router-link tag="button" :to="{name: 'Account'}" class="absolute back-button">
          <svg class="arrow" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.81836 18H28.182" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 10.7273L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M15.0911 25.2727L7.81836 18" stroke="white" stroke-width="2.90909" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </router-link>

        <h1 class="p-5">Modifier un profil</h1>
      </div>

    </div>
    <div class="main-container rounded-t-3xl wrap auth h-full w-full">
      <div class="grid justify-center align-items-center mb-8">
        <avatar :img="user.customImage ? user.file : user.selectedImage" :type="user.type"></avatar>
      </div>
      <h2 class="text-align-center mb-8">{{ user.name }}</h2>
      <user-form :type="user.type" :button-text="$t('settings.preferencesSave')" v-model="user"
                 @user-submitted="userSubmitted"/>
      <primary-button v-if="user.type === 'child'" color="white" class="mt-4 tooltip" :clicked="clicked"
                      @click.native="initDeleteUser" :disabled="!deletable">
        <span v-if="!deletable" class="tooltiptext">{{ $t('settings.preferencesDeleteUnauthorized') }}</span>
        {{ $t('settings.preferencesDelete') }}
      </primary-button>
    </div>
  </div>

</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import UniInput from "@/components/UniInput";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/Loader";
import Avatar from "@/components/Avatar";
import UniModal from "@/components/UniModal";
import Help from "@/components/Help";
import UserForm from "@/components/settings/UserForm.vue";

export default {
  name: "UpdateUser",
  components: {Help, UniModal, Avatar, Loader, PrimaryButton, UniInput, UserForm},
  data() {
    return {
      clicked: false,
      isLoadingUser: true,
      isLoadingContenful: true,
      loading: true,
      inputText: '',
      tags: [],
      tagField: [],
      user: {
        name: null,
        age: null,
        tagField: [],
        selectedImage: null,
        customImage: false,
        file: null
      },
      deletable: true,
    }
  },
  computed: {
    ...mapGetters(['currentFamily'])
  },
  methods: {
    ...mapActions(["updateUser", "deleteUser", "addCurrentUser"]),
    initDeleteUser() {
      if (confirm("Voulez-vous vraiment supprimer ce profil ?")) {
        this.clicked = true
        this.$http.delete('/users/' + this.$route.params.id)
            .then(_ => {
              this.deleteUser(this.$route.params.id)
              this.$nextTick(() => {
                this.$router.push({name: 'Account'})
              })
            })
      }
    },
    userSubmitted() {
      console.log("SUBMITTED")
      window.location.reload()
      this.$nextTick(() => {
        this.$router.push({name: "Home"});
      })
    }
  },
  created() {
    console.log("deletable: " + this.deletable)

    this.$http.get(`/families/${this.currentFamily.id}/users`)
        .then(resp => {
          if (resp.data.length === 1) {
            this.deletable = false
          }
        })

    this.$http.get('/users/' + this.$route.params.id)
        .then(resp => {
          this.user.name = resp.data.firstname
          this.user.date = resp.data.birth_date
          this.user.tagField = JSON.parse(resp.data.prefered_tag_id)
          this.user.selectedImage = resp.data.avatar
          this.user.customImage = resp.data.custom_image
          this.user.type = resp.data.user_type
          this.user.file = resp.data.attachment_url ?? null
          this.$nextTick(() => {
            this.isLoadingUser = false
          })
        })

    this.$contentfulv2.getEntries({
      content_type: 'tags',
    })
        .then(response => {
          this.tags = response.items
          this.$nextTick(() => {
            this.isLoadingContenful = false
          })
        })
        .catch(_ => {
          this.isLoadingContenful = false
        })
  }
}
</script>

<style lang="scss" scoped>

.back-button {
  left: -64px;
  top: calc(50% - 18px);
}

.helper {
  margin: var(--space-4) 0 0 var(--space-4);
  display: flex;
  align-items: center;
}

.helper.invalid > span {
  width: calc(100% - var(--space-20));
}

.helper.invalid {
  color: var(--color-error);
}

.helper > svg {
  margin-right: var(--space-4);
}

.upload-photo {
  cursor: pointer;
  width: 77px;
  height: 77px;
  border-radius: 100%;
  background: var(--color-surface-soft);
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo, .input-radio {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
}

.grid-container > * {
  align-self: center;
  justify-self: center;
}

.container-img {
  width: 77px;
  height: 77px;
  border-radius: 100%;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    border: 4px solid transparent;

  }

  /* CHECKED STYLES */
  input[type=radio]:checked + label > img {
    box-shadow: 0 3.81111px 7.62222px rgba(0, 0, 0, 0.15), 0 0 0.952778px rgba(0, 0, 0, 0.1);
    border: 4px solid #09A6E6;
  }

  /* IMAGE STYLES */
  input[type=radio] + label > img {
    cursor: pointer;
  }

  .label-input-radio {
    width: 100%;
    height: 100%;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  input {
    position: absolute;
    left: -9999px;

  }

  input:checked + label {
    box-shadow: 0 2px 0 #09A6E6;
    background: #E6F6FC;
    border: 2px solid #09A6E6;
  }
}

label.tag {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: var(--space-8);
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  border: 2px solid #C4C8C9;
  box-shadow: inset 0px -2px 0px #E5E0DF;

}
</style>